import React from 'react';
import classNames from 'classnames';
import { CloseIcon } from 'components/icons';

import './Dialog.css';

export function Dialog({ className, isForm, ...props }) {
	const Tag = isForm ? 'form' : 'div';
	return <Tag className={classNames('dialog', className)} role="dialog" {...props} />;
}

export function Header({ className, ...props }) {
	return <header className={classNames('dialog__header', className)} {...props} />;
}

export function Title({ className, ...props }) {
	// eslint-disable-next-line jsx-a11y/heading-has-content
	return <h2 className={classNames('dialog__title', className)} {...props} />;
}

export function CloseButton({ size = 24, className, ...props }) {
	return (
		<button className={classNames('dialog__close-button', className)} {...props}>
			<CloseIcon size={size} />
		</button>
	);
}

export function Content({ className, ...props }) {
	return <div className={classNames('dialog__content', className)} {...props} />;
}

export function Footer({ className, ...props }) {
	return <footer className={classNames('dialog__footer', className)} {...props} />;
}

import React, { createContext } from 'react';
import PropTypes from 'prop-types';
import AdalAuthenticationContext from 'adal-angular';
import Cookies from 'js-cookie';
import { getAnchor } from 'utils/urlUtils';

const {
	REACT_APP_AUTH_INSTANCE,
	REACT_APP_AUTH_TENANT,
	REACT_APP_AUTH_CLIENT_ID,
	REACT_APP_AUTH_RESOURCE_ID,
} = process.env;

// IE Polyfill for setting a window location origin
// from Modernizr
if (!window.location.origin) {
	window.location.origin =
		window.location.protocol +
		'//' +
		window.location.hostname +
		(window.location.port ? ':' + window.location.port : '');
}

if (window.location.pathname !== '/' && window.location.pathname !== '/signin') {
	const anchor = getAnchor();
	const redirectUrl = anchor
		? `${window.location.pathname}#${anchor}`
		: window.location.pathname;
	Cookies.set('redirect', redirectUrl);
}

const adalAuthContext = new AdalAuthenticationContext({
	instance: REACT_APP_AUTH_INSTANCE,
	tenant: REACT_APP_AUTH_TENANT,
	clientId: REACT_APP_AUTH_CLIENT_ID,
	postLogoutRedirectUri: window.location.origin,
	redirectUri: window.location.origin + '/signin',
	navigateToLoginRequestUrl: false,
});

const EVENT_ADAL_ACCESS_TOKEN_ERROR = 'eventAdalAccessTokenError';

adalAuthContext.handleWindowCallback();

export const acquireToken = (requireDispatch = true) =>
	new Promise((resolve, reject) => {
		adalAuthContext.acquireToken(
			REACT_APP_AUTH_RESOURCE_ID,
			(error, token, errorType) => {
				if (error) {
					if (requireDispatch) {
						const accessTokenErrorEvent = new CustomEvent(EVENT_ADAL_ACCESS_TOKEN_ERROR, {
							detail: {
								error,
								errorType,
							},
						});
						window.dispatchEvent(accessTokenErrorEvent);
					}
					return reject({ error, token, errorType });
				}

				return resolve(token);
			}
		);
	});

export const BASE_API_HEADERS = {
	Accept: 'application/json',
	'Content-Type': 'application/json',
};

const ADAL_ERROR_TOKEN = 'adal.error';
const ADAL_ERROR_DESCRIPTION = 'adal.error.description';
const ADAL_LOGIN_ERRORS = [
	'login required',
	'login_required',
	'Token Renewal Failed',
	'interaction_required',
];

const { Provider, Consumer } = createContext();

export { Consumer as AuthenticationContextConsumer };

export class AuthenticationContextProvider extends React.Component {
	static propTypes = {
		errorComponent: PropTypes.oneOfType([
			PropTypes.element,
			PropTypes.func,
			PropTypes.node,
		]).isRequired,
		children: PropTypes.node.isRequired,
	};

	state = {
		user: null,
		token: null,
		error: null,
		errorDescription: null,
		fetchAuthorizationToken: async () => {
			try {
				const token = await acquireToken(false);
				const { token: oldToken } = this.state;

				if (oldToken !== token) {
					this.setState({ token });
				}

				return token;
			} catch (error) {
				const { errorType, message } = error;
				this.setState({
					error: errorType,
					errorDescription: message,
				});

				throw error;
			}
		},
		logout: () => {
			adalAuthContext.logOut();
		},
	};

	async componentDidMount() {
		const error = sessionStorage.getItem(ADAL_ERROR_TOKEN);
		const errorDescription = sessionStorage.getItem(ADAL_ERROR_DESCRIPTION);

		const user = adalAuthContext.getCachedUser();
		let token = adalAuthContext.getCachedToken();

		window.addEventListener(EVENT_ADAL_ACCESS_TOKEN_ERROR, this.handleAccessTokenError);

		if (user && token) {
			this.setState({ user, token });
			return;
		}

		if (error) {
			this.setState({ error, errorDescription });
			return;
		}

		if (!user) {
			adalAuthContext.login();
			return;
		}

		if (!token) {
			try {
				token = await acquireToken(false);
			} catch (e) {
				const { errorType, message } = e;
				this.setState({
					error: errorType,
					errorDescription: message,
				});

				throw e;
			}
		}

		this.setState({
			user,
			token,
			error: null,
			errorDescription: null,
		});
	}

	componentWillUnmount() {
		window.removeEventListener(
			EVENT_ADAL_ACCESS_TOKEN_ERROR,
			this.handleAccessTokenError
		);
	}

	render() {
		const { user, token, error, errorDescription } = this.state;
		const { children, errorComponent: ErrorComponent } = this.props;

		if (error) {
			if (ADAL_LOGIN_ERRORS.includes(error)) {
				adalAuthContext.login();
				return null;
			} else {
				return (
					<ErrorComponent
						errorType={error}
						errorDescription={errorDescription}
						onClick={this.handleClick}
					/>
				);
			}
		}

		if (!user || !token) return null;

		return <Provider value={this.state}>{children}</Provider>;
	}

	handleClick = () => {
		adalAuthContext.login();
	};

	handleAccessTokenError = event => {
		const { message, errorType } = event.detail;
		this.setState({
			error: errorType,
			errorDescription: message,
		});
	};
}

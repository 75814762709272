import React from 'react';
import Fuse from 'fuse.js';
import { ApIcon, ApButtonSecondary, ApButtonMain } from '@alixpartners/ui-components';

import { MaxWidthLayout } from 'components/MaxWidthLayout';
import { FilterIcon } from 'components/icons/FilterIcon';
import { SearchInput } from 'components/SearchInput';
import { Overlay } from 'components/Overlay';
import { Loader } from 'components/Loader';
import { PageErrorDialog } from 'components/PageErrorDialog';
import { FilterCheckbox } from 'components/FilterCheckbox';
import { FilterTablet } from 'components/FilterTablet';
import { FilterCollapsible } from 'components/FilterCollapsible';
import { ProductListMasonry } from 'components/ProductListMasonry';
import { SearchSidebar } from 'components/SearchSidebar';

import { Page } from 'global/Page';
import { Footer } from 'global/Footer';
import { SortDropdown } from 'search/SortDropdown';

import { getSolutions } from 'utils/apiUtils';
import { sortItemsByProp } from 'utils/sortUtils';
import { getUrlByTransformingName } from 'utils/urlUtils';
import { useIsMounted } from 'hooks/useIsMounted';
import { useWindowSize } from 'hooks/useWindowSize';

import './Search.css';

const fuseOptions = {
	shouldSort: false,
	threshold: 0.2,
	location: 0,
	distance: 100,
	maxPatternLength: 32,
	minMatchCharLength: 1,
	keys: ['name', 'introText'],
};

const breakpointColumnsObj = {
	default: 3,
	1600: 3,
	1440: 3,
	1167: 3,
	960: 3,
	768: 2,
	559: 1,
	480: 1,
	375: 1,
	300: 1,
};

function formatSolutions(solutions) {
	return solutions
		? solutions
				.filter(solution => solution.isActive && solution.isVisible)
				.map(solution => ({
					url: getUrlByTransformingName(solution.name),
					...solution,
				}))
		: [];
}

export function Search({ history, areas, config }) {
	const [products, setProducts] = React.useState([]);
	const [apiErrors, setApiErrors] = React.useState({});
	const [isLoading, setIsLoading] = React.useState(true);
	const [filter, setFilter] = React.useState('');
	const [areaFilters, setAreaFilters] = React.useState([]);
	const [isSidebarOpen, setIsSidebarOpen] = React.useState(false);
	const [sortBy, setSortBy] = React.useState('order');

	const closeErrorDialog = () => setApiErrors({});

	const isMounted = useIsMounted();
	const windowSize = useWindowSize();

	React.useEffect(() => {
		getSolutions()
			.then(products => {
				if (!isMounted()) {
					return;
				}
				setProducts(formatSolutions(products));
				setIsLoading(false);
			})
			.catch(error => {
				if (!isMounted()) {
					return;
				}
				setIsLoading(false);
				setApiErrors({ products: error });
			});
	}, []);

	if (isLoading) {
		return (
			<Overlay style={{ minHeight: windowSize.height }}>
				<Loader />
			</Overlay>
		);
	}

	const fuse = new Fuse(products, fuseOptions);
	const filteredProducts = (filter ? fuse.search(filter) : products).filter(product => {
		const productAreas = product.areas.map(area => area.id);
		const activeFilterAreas = areaFilters.map(filter => filter.id);
		return areaFilters.length
			? productAreas.some(element => activeFilterAreas.includes(element))
			: product;
	});

	const sortedProducts = sortItemsByProp(filteredProducts, sortBy);

	const { areaTypes } = config;

	const getSearchBlock = (isExpaneded = false) => (
		<div className="search-container__search-block">
			<SearchInput
				className="search-container__filter-input"
				placeholder="Search"
				value={filter}
				onChange={setFilter}
			/>
			<div className="search-container__title">Filters</div>
			<div className="search-container__sections-wrapper">
				{areaTypes.map(areaType => (
					<FilterCollapsible
						title={areaType.name}
						key={`area-type-${areaType.id}`}
						expanded={isExpaneded}
					>
						<div className="search-container__filters">
							{areas
								.filter(a => a.typeId === areaType.id)
								.map(area => (
									<FilterCheckbox
										id={`area-${area.id}`}
										key={area.id}
										title={area.name}
										checked={!!areaFilters.find(a => a.id === area.id)}
										onChange={event => {
											event.stopPropagation();
											event.target.checked
												? setAreaFilters([...areaFilters, area])
												: setAreaFilters(areaFilters.filter(a => a.id !== area.id));
										}}
									/>
								))}
						</div>
					</FilterCollapsible>
				))}
			</div>
			<div className="sidebar__apply-button">
				<ApButtonMain onClick={handleCloseSidebar}>APPLY</ApButtonMain>
			</div>
		</div>
	);

	const handleOpenSidebar = () => {
		document.documentElement.style.overflowY = 'hidden';
		setIsSidebarOpen(true);
	};

	const handleCloseSidebar = () => {
		document.documentElement.style.overflowY = 'overlay';
		setIsSidebarOpen(false);
	};

	return (
		<Page className="search-page">
			<MaxWidthLayout fullWidth>
				<div className="search-container">
					<div className="search-container__left">{getSearchBlock()}</div>
					<div className="search-container__right">
						<div className="filter-actions">
							<h2 className="search-container__title">SEARCH ALL PRODUCTS & TOOLS</h2>
							<div className="search-container__search-filter">
								<div className="search-container__filter-actions">
									<div className="search-container__filter-button">
										<ApButtonSecondary onClick={handleOpenSidebar}>
											<FilterIcon size={24} />
											Filters
										</ApButtonSecondary>
									</div>
									<SortDropdown onSort={setSortBy} />
								</div>
								{filter && (
									<div className="current-search">
										<div className="current-search__message">
											{`${sortedProducts.length} results available for `}
											<strong>{` "${filter}"`}</strong>
										</div>
										<div
											className="current-search__icon"
											onClick={() => {
												setFilter('');
											}}
										>
											<ApIcon
												iconName="outline_cancel"
												iconColor="var(--color-grey-300)"
												iconSize="20px"
											/>
										</div>
									</div>
								)}
							</div>
							{areaFilters.length > 0 && (
								<div className="area-filters">
									{areaFilters.map(filter => {
										return (
											<FilterTablet
												title={filter.name}
												key={filter.id}
												onRemove={() => {
													setAreaFilters(areaFilters.filter(a => a.id !== filter.id));
												}}
											/>
										);
									})}
								</div>
							)}
						</div>
						<div className="search">
							<div className="search-sidebar__container">
								<SearchSidebar isOpen={isSidebarOpen} onClose={handleCloseSidebar}>
									{getSearchBlock(true)}
								</SearchSidebar>
							</div>
							<ProductListMasonry
								products={sortedProducts}
								breakpointCols={breakpointColumnsObj}
								className={`search-container__masonry-grid${
									isSidebarOpen ? ' hide-for-mobile' : ''
								}`}
								columnClassName="search-container__masonry-grid-column"
							/>
						</div>
					</div>
				</div>
				<PageErrorDialog apiErrors={apiErrors} onClose={closeErrorDialog} />
			</MaxWidthLayout>
			{!isSidebarOpen && <Footer />}
		</Page>
	);
}

import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { ApLoaderDefault } from '@alixpartners/ui-components';

import './EditorPageSavingLoader.css';

export function EditorPageSavingLoader({ isSaving }) {
	return (
		<div className="editor-page-saving__contianer">
			{isSaving && (
				<Fragment>
					<ApLoaderDefault loaderSize="sm" />
					<span className="editor-page-saving__label">Saving...</span>
				</Fragment>
			)}
		</div>
	);
}

EditorPageSavingLoader.propTypes = {
	isSaving: PropTypes.bool.isRequired,
};

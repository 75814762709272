import React from 'react';
import ClickOutside from 'react-click-outside';
import { CloseButton } from 'components/Dialog';

import './SearchSidebar.css';

export const SearchSidebar = ({ isOpen, onClose, children }) => (
	<div>
		{isOpen && <div className="sidebar__overlay" />}
		{isOpen && (
			<React.Fragment>
				<ClickOutside onClickOutside={onClose}>
					<div className={`sidebar${isOpen ? ' sidebar--opened' : ' sidebar--closed'}`}>
						<div className="sidebar__close-button-container">
							<CloseButton
								className="sidebar__close-button"
								onClick={onClose}
								size={24}
							/>
						</div>
						<div className="sidebar__search-container">{children}</div>
					</div>
					<div className="sidebar-mobile">{children}</div>
				</ClickOutside>
			</React.Fragment>
		)}
	</div>
);

import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';

const MODAL_ID = 'modal-root';

function addPortal() {
	const portal = document.createElement('div');
	portal.id = MODAL_ID;
	document.body.appendChild(portal);
	return portal;
}

function getPortal() {
	return document.getElementById(MODAL_ID) || addPortal();
}

export function Portal({ children }) {
	return ReactDOM.createPortal(children, getPortal());
}

Portal.propTypes = {
	children: PropTypes.node.isRequired,
};

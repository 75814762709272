import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import Fuse from 'fuse.js';

import { GridSelect } from 'components/GridSelect';
import { Overlay } from 'components/Overlay';
import { Loader } from 'components/Loader';
import { PageErrorDialog } from 'components/PageErrorDialog';
import { AreaGridSelectItem } from './AreaGridSelectItem';
import { MaxWidthLayout } from 'components/MaxWidthLayout';
import { PageSectionTitle } from 'components/PageSectionTitle';
import { EditorPageReorderLabel } from 'content-editor/EditorPageLayout';
import { ModalContainer } from 'components/ModalContainer';
import { DeleteItemDialog } from 'components/DeleteItemDialog';
import { ReorderItemsDialog } from 'components/ReorderItemsDialog';
import { SearchInput } from 'components/SearchInput';
import { EditorGridTitleRow } from 'content-editor/EditorGridTitleRow';
import { SortDropdown } from 'search/SortDropdown';
import { CreateAreaDialog } from './CreateAreaDialog';
import { getAreas, createArea, deleteArea, updateAreasOrder } from 'utils/apiUtils';
import { getPageUrl } from 'utils/urlUtils';
import { useIsMounted } from 'hooks/useIsMounted';
import { useWindowSize } from 'hooks/useWindowSize';
import { getKeyValueObject } from 'utils/parseUtils';
import { sortItemsByProp } from 'utils/sortUtils';

import './Areas.css';

const fuseOptions = {
	shouldSort: false,
	threshold: 0.2,
	location: 0,
	distance: 100,
	maxPatternLength: 32,
	minMatchCharLength: 1,
	keys: ['name'],
};

export const SECTION_PARAM_KEY = 'section';
export const ITEM_PARAM_KEY = 'id';
const sortOptions = [
	{
		id: 0,
		name: 'Spotlight',
	},
	{
		id: 1,
		name: 'Area type',
	},
	{
		id: 2,
		name: 'Name (A-Z)',
	},
	{
		id: 3,
		name: 'Name (Z-A)',
	},
];

const sortMapping = {
	0: 'order',
	1: 'typeId',
	2: 'name',
	3: 'name_desc',
};

export function Areas({ config, history }) {
	const baseUrl = getPageUrl('content-editor-areas');
	const onEdit = item => {
		history.push(`${baseUrl}/${item.id}?${SECTION_PARAM_KEY}=detail`);
	};
	const onCreate = () => setCreateDialogOpen(true);

	const [areas, setAreas] = React.useState([]);
	const [isLoading, setIsLoading] = React.useState(true);
	const [apiErrors, setApiErrors] = React.useState({});
	const [filter, setFilter] = React.useState('');
	const [createDialogOpen, setCreateDialogOpen] = React.useState(false);
	const [reorderDialogOpen, setReorderDialogOpen] = React.useState(false);
	const [isDeleting, setIsDeleting] = React.useState(false);
	const [isCreating, setIsCreating] = React.useState(false);
	const [deletingItem, setDeleteItem] = React.useState(null);
	const isMounted = useIsMounted();
	const windowSize = useWindowSize();
	const closeErrorDialog = () => setApiErrors({});
	const fetchAreas = () => {
		getAreas()
			.then(areas => {
				if (!isMounted()) {
					return;
				}

				setAreas(sortItemsByProp(areas, 'order'));
				setIsLoading(false);
			})
			.catch(error => {
				if (!isMounted()) {
					return;
				}
				setIsLoading(false);
				setApiErrors({ areas: error });
			});
	};

	React.useEffect(fetchAreas, []);

	const openReorderDialog = () => setReorderDialogOpen(true);
	const closeCreateDialog = () => setCreateDialogOpen(false);
	const closeDeleteDialog = () => setDeleteItem(null);
	const closeReorderDialog = () => setReorderDialogOpen(false);
	const handleDeleteArea = () => {
		setIsDeleting(true);

		deleteArea(deletingItem)
			.then(() => {
				setIsDeleting(false);
				closeDeleteDialog();
				fetchAreas();
			})
			.catch(errors => {
				setApiErrors({ area: errors });
			});
	};
	const handleCreateArea = (item, redirect) => {
		setIsCreating(true);

		createArea(item)
			.then(item => {
				setIsCreating(false);
				closeCreateDialog();
				fetchAreas();

				if (redirect) {
					history.push(`${baseUrl}/${item.id}`);
				}
			})
			.catch(errors => {
				setApiErrors({ area: errors });
			});
	};

	const handleReorderAction = list => {
		const data = [];
		list.map((item, index) => {
			return data.push({ id: item.id, order: index });
		});
		updateAreasOrder(data).then(() => {
			closeReorderDialog();
			fetchAreas();
		});
	};

	const fuse = new Fuse(areas, fuseOptions);
	const filteredAreas = filter ? fuse.search(filter) : areas;
	const areaTypes = getKeyValueObject(config.areaTypes, 'id', 'name');
	const renderItem = props => <AreaGridSelectItem types={areaTypes} {...props} />;

	if (isLoading) {
		return (
			<Overlay style={{ minHeight: windowSize.height }}>
				<Loader />
			</Overlay>
		);
	}

	return (
		<section className="areas-list">
			<MaxWidthLayout fullWidth>
				<EditorGridTitleRow
					left={() => <PageSectionTitle noBottomMargin>Areas</PageSectionTitle>}
					right={() => (
						<Fragment>
							<SortDropdown
								onSort={value => {
									setAreas(sortItemsByProp(areas, value));
								}}
								sortOptions={sortOptions}
								sortMapping={sortMapping}
							/>
							<EditorPageReorderLabel title="Reorder Areas" action={openReorderDialog} />
						</Fragment>
					)}
				/>
				<SearchInput value={filter} placeholder="Search an area" onChange={setFilter} />
				<GridSelect
					items={filteredAreas}
					onCreate={onCreate}
					onClick={onEdit}
					onEdit={onEdit}
					onDelete={setDeleteItem}
					renderItem={renderItem}
				/>
			</MaxWidthLayout>

			{deletingItem && (
				<ModalContainer onClose={closeDeleteDialog}>
					<DeleteItemDialog
						type="area"
						name={deletingItem.name}
						isDeleting={isDeleting}
						onCancel={closeDeleteDialog}
						onConfirm={handleDeleteArea}
					/>
				</ModalContainer>
			)}

			{createDialogOpen && (
				<ModalContainer onClose={closeCreateDialog}>
					<CreateAreaDialog
						config={config}
						areas={areas}
						isCreating={isCreating}
						onCancel={closeCreateDialog}
						onSave={handleCreateArea}
					/>
				</ModalContainer>
			)}

			{reorderDialogOpen && (
				<ModalContainer onClose={closeReorderDialog}>
					<ReorderItemsDialog
						title="Reorder Areas"
						itemsList={areas}
						onCancel={closeReorderDialog}
						onConfirm={handleReorderAction}
					/>
				</ModalContainer>
			)}

			<PageErrorDialog apiErrors={apiErrors} onClose={closeErrorDialog} />
		</section>
	);
}

Areas.propTypes = {
	history: PropTypes.object.isRequired,
	areas: PropTypes.array,
};

Areas.defaultProps = {
	areas: [],
};

import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { isEmpty } from 'ramda';
import { ApIcon } from '@alixpartners/ui-components';
import { AddButton, IconButton } from 'components/Button';
import { Add } from 'components/icons/Add';
import { CloseIcon } from 'components/icons';
import { getEntityLabelFromType } from 'utils/parseUtils';

import './EditableList.css';

export function EditableList({ type, parentKey, items, onCreate, onUpdate, onDelete }) {
	const canCreate = Boolean(onCreate);
	const canUpdate = Boolean(onUpdate);
	const canDelete = Boolean(onDelete);

	return (
		<div>
			{isEmpty(items) && <span>No {getEntityLabelFromType(type)}s</span>}
			{!isEmpty(items) && (
				<ul>
					{items.map((item, index) => {
						const isActive = item.isActive !== undefined ? item.isActive : true;
						const isOrphan = parentKey
							? !item[parentKey] || isEmpty(item[parentKey])
							: false;
						const hasDivider = canCreate || index < items.length - 1;
						return (
							<li
								className={classNames('editable-list__item', {
									'editable-list__item--divider': hasDivider,
								})}
								key={item.id}
							>
								{isActive && !isOrphan && (
									<i
										title="Active item"
										className="editable-list__status editable-list__status--active"
									/>
								)}
								{!isActive && !isOrphan && (
									<i
										title="Inactive item"
										className="editable-list__status editable-list__status--inactive"
									/>
								)}
								{isOrphan && (
									<i
										title="Orphan item"
										className="editable-list__status editable-list__status--orphan"
									/>
								)}

								<button
									className="editable-list__button"
									disabled={!canUpdate}
									onClick={() => onUpdate(item)}
								>
									{item.name}
								</button>
								{canUpdate && (
									<IconButton onClick={() => onUpdate(item)}>
										<ApIcon iconName="outline_edit" iconSize="24" iconColor="#999999" />
									</IconButton>
								)}
								{canDelete && (
									<IconButton onClick={() => onDelete(item)}>
										<CloseIcon size={24} />
									</IconButton>
								)}
							</li>
						);
					})}
				</ul>
			)}
			{canCreate && (
				<footer className="editable-list__footer">
					<AddButton onClick={onCreate}>
						<Add /> Add {getEntityLabelFromType(type)}
					</AddButton>
				</footer>
			)}
		</div>
	);
}

EditableList.propTypes = {
	type: PropTypes.string.isRequired,
	parentKey: PropTypes.string,
	items: PropTypes.arrayOf(
		PropTypes.shape({
			id: PropTypes.number.isRequired,
			name: PropTypes.string.isRequired,
		})
	).isRequired,
	onCreate: PropTypes.func,
	onUpdate: PropTypes.func,
	onDelete: PropTypes.func,
};

import React from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from 'ramda';
import { ModalContainer } from 'components/ModalContainer';
import { ErrorDialog } from 'components/ErrorDialog';
import { SecondaryButton } from 'components/Button';

export function PageErrorDialog({ apiErrors, onClose }) {
	return (
		!isEmpty(apiErrors) && (
			<ModalContainer onClose={onClose}>
				<ErrorDialog
					type="API"
					errors={apiErrors}
					button={
						<SecondaryButton onClick={() => window.location.reload()}>
							Reload
						</SecondaryButton>
					}
					onClose={onClose}
				/>
			</ModalContainer>
		)
	);
}

PageErrorDialog.propTypes = {
	apiErrors: PropTypes.object.isRequired,
};

import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { groupBy } from 'utils/parseUtils';

import './Select.css';

function Options({ options, groupKey }) {
	if (groupKey) {
		const groups = groupBy(options, groupKey);
		return Object.keys(groups).map(group => (
			<optgroup key={group} label={group}>
				{options
					.filter(option => option[groupKey] === group)
					.map(option => (
						<option key={option.id} value={option.id}>
							{option.name}
						</option>
					))}
			</optgroup>
		));
	}

	return options.map(option => (
		<option key={option.id} value={option.id}>
			{option.name}
		</option>
	));
}

export function Select({
	id,
	theme,
	options,
	label,
	value,
	groupKey,
	className,
	onChange,
	...props
}) {
	return (
		<div className={classNames('select', `select--theme-${theme}`, className)} {...props}>
			<select
				id={id}
				className="select__hidden-select"
				value={value}
				onChange={event => onChange(event.target.value)}
			>
				<option value="">—</option>
				<Options options={options} groupKey={groupKey} />
			</select>
			<span className="select__label">{label}</span>
		</div>
	);
}

Select.propTypes = {
	id: PropTypes.string,
	theme: PropTypes.oneOf(['home', 'admin']),
	options: PropTypes.arrayOf(
		PropTypes.shape({
			id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
			name: PropTypes.string.isRequired,
		})
	).isRequired,
	label: PropTypes.string.isRequired,
	value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
	groupKey: PropTypes.string,
	onChange: PropTypes.func.isRequired,
};

Select.defaultProps = {
	value: null,
	theme: 'home',
};

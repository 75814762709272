import React from 'react';
import PropTypes from 'prop-types';
import { ModalContainer } from 'components/ModalContainer';
import { ErrorDialog } from 'components/ErrorDialog';
import { SecondaryButton } from 'components/Button';

import { getAppPrimaryURL } from 'utils/urlUtils';

export function UnauthorizedDailog({ history }) {
	const urlFragment = getAppPrimaryURL();

	return (
		<ModalContainer onClose={() => history.push(urlFragment)}>
			<ErrorDialog
				errors={{ admin: 'You are not authorized to view this page' }}
				button={
					<SecondaryButton onClick={() => history.push(urlFragment)}>
						Go back
					</SecondaryButton>
				}
				onClose={() => history.push(urlFragment)}
			/>
		</ModalContainer>
	);
}

UnauthorizedDailog.propTypes = {
	history: PropTypes.object.isRequired,
};

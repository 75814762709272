import React from 'react';
import PropTypes from 'prop-types';

import { Label } from 'components/Label';
import { PageErrorDialog } from 'components/PageErrorDialog';

import { SearchableSelect } from 'components/SearchableSelect';
import { EditorPageFieldset } from 'content-editor/EditorPageLayout';
import { SelectedItemsList } from 'experts/SelectedItemsList';
import { getAreas, getAreasByPerson, patchAreaPersons } from 'utils/apiUtils';
import { useIsMounted } from 'hooks/useIsMounted';

import './AreaSelectList.css';

const PATCH_ADD = 'add';
const PATCH_REMOVE = 'remove';

function getMappedAreas(areas) {
	return areas
		? areas.map(area => ({
				...area,
				label: area.name,
				value: area.id,
		  }))
		: [];
}

export function AreaSelectList({ personId }) {
	const [areas, setAreas] = React.useState([]);
	const [personAreas, setPersonAreas] = React.useState([]);
	const [apiErrors, setApiErrors] = React.useState({});

	const isMounted = useIsMounted();

	React.useEffect(() => {
		let queries = [getAreas()];
		if (personId) {
			queries = [...queries, getAreasByPerson(personId)];
		}
		Promise.all(queries)
			.then(([areas, personAreas]) => {
				if (!isMounted()) {
					return;
				}
				setAreas(getMappedAreas(areas));
				setPersonAreas(getMappedAreas(personAreas));
			})
			.catch(error => {
				if (!isMounted()) {
					return;
				}
				setApiErrors({ areas: error });
			});
	}, [personId]);

	function addOrRemovePerson(operation, area) {
		patchAreaPersons(area.id, operation, [{ id: personId, isVisible: true }])
			.then(() => {
				if (!isMounted()) {
					return;
				}
				if (operation === PATCH_ADD) {
					const newpersonAreas = [...personAreas];
					newpersonAreas.push(area);
					setPersonAreas(newpersonAreas);
				} else if (operation === PATCH_REMOVE) {
					setPersonAreas(personAreas.filter(product => product.id !== area.id));
				}
			})
			.catch(error => {
				if (!isMounted()) {
					return;
				}
				setApiErrors({ person: error });
			});
	}

	function closeErrorDialog() {
		setApiErrors({});
	}

	return (
		<div className="areas-select-list">
			<EditorPageFieldset>
				<Label htmlFor="areas-select" value="Areas" />
				<SearchableSelect
					id="areas-select"
					className="areas-select"
					customPlaceHolderText={'Select an area'}
					options={areas}
					selectedValues={personAreas}
					onChange={items => {
						addOrRemovePerson(PATCH_ADD, items.slice(-1) && items.slice(-1)[0]);
					}}
				/>
				<SelectedItemsList
					listItems={personAreas}
					onDelete={item => {
						addOrRemovePerson(PATCH_REMOVE, item);
					}}
				/>
			</EditorPageFieldset>
			<PageErrorDialog apiErrors={apiErrors} onClose={closeErrorDialog} />
		</div>
	);
}

AreaSelectList.propTypes = {
	personId: PropTypes.number,
};

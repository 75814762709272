import React from 'react';

const Icon = ({ size, ...otherProps }) => {
	const props = {
		width: size,
		height: size,
		fill: 'currentcolor',
		...otherProps,
	};
	return (
		<svg viewBox="0 0 24 24" {...props}>
			<path opacity={0.87} fill="none" d="M24 24H0V0h24v24z" />
			<path d="M16.59 8.59L12 13.17 7.41 8.59 6 10l6 6 6-6-1.41-1.41z" />
		</svg>
	);
};

export const ExpandMore = Icon;

ExpandMore.defaultProps = {
	size: 24,
};

import React from 'react';
import PropTypes from 'prop-types';

import { Overlay } from 'components/Overlay';
import { Loader } from 'components/Loader';

import { PageErrorDialog } from 'components/PageErrorDialog';
import { MaxWidthLayout } from 'components/MaxWidthLayout';
import { PageSectionTitle } from 'components/PageSectionTitle';

import {
	EditorPageLayout,
	EditorPageLayoutLeft,
	EditorPageLayoutRight,
} from 'content-editor/EditorPageLayout';
import { ExpertEditSection } from 'experts/ExpertEditSection';
import { NEW_EXPERT, ExpertsGrid } from 'experts/ExpertsGrid';

import { getPersons } from 'utils/apiUtils';
import { getNumberFromString } from 'utils/parseUtils';
import { useWindowSize } from 'hooks/useWindowSize';
import { useIsMounted } from 'hooks/useIsMounted';

import './Experts.css';
import { ExpertsSearch } from './ExpertsSearch';

export const DEFAULT_PERSON = {
	name: '',
	solutionId: null,
	description: '',
	role: '',
	email: '',
	fileName: '',
	phoneNumber: undefined,
	country: '',
};

export function Experts({ match, history }) {
	const [isLoading, setIsLoading] = React.useState(true);
	const [isRefreshPersons, setIsRefreshPersons] = React.useState(false);
	const [isDisableAddButton, setIsDisableAddButton] = React.useState(false);

	const [persons, setPersons] = React.useState([]);
	const [apiErrors, setApiErrors] = React.useState({});
	const [selectedExpert, setSelectedExpert] = React.useState(null);

	const windowSize = useWindowSize();
	const isMounted = useIsMounted();

	const { expertId } = match.params;

	React.useEffect(() => {
		getPersons()
			.then(persons => {
				if (!isMounted()) {
					return;
				}
				setPersons(persons);
				setIsLoading(false);
			})
			.catch(error => {
				if (!isMounted()) {
					return;
				}
				setIsLoading(false);
				setApiErrors({ person: error });
			});
	}, [isRefreshPersons]);

	function closeErrorDialog() {
		setApiErrors({});
	}

	function handleRefreshPersons() {
		setIsRefreshPersons(!isRefreshPersons);
	}

	if (isLoading) {
		return (
			<Overlay style={{ minHeight: windowSize.height }}>
				<Loader />
			</Overlay>
		);
	}

	const isNew = expertId === NEW_EXPERT;

	const person = isNew
		? DEFAULT_PERSON
		: expertId
		? persons.find(person => person.id === getNumberFromString(expertId))
		: null;
	const filteredPersons = selectedExpert ? [selectedExpert] : [...persons];

	return (
		<section className="experts-section">
			<MaxWidthLayout>
				<EditorPageLayout>
					<EditorPageLayoutLeft>
						<PageSectionTitle>Experts</PageSectionTitle>
						<ExpertsSearch expertOptions={persons} onSelect={setSelectedExpert} />
						<ExpertsGrid
							disableAddButton={isDisableAddButton && !!person}
							persons={filteredPersons}
							expertId={expertId}
						/>
					</EditorPageLayoutLeft>
					<EditorPageLayoutRight>
						<ExpertEditSection
							history={history}
							person={person}
							isNew={expertId === NEW_EXPERT}
							onExpertUpdate={handleRefreshPersons}
							onChangeExpert={setIsDisableAddButton}
						/>
					</EditorPageLayoutRight>
				</EditorPageLayout>
			</MaxWidthLayout>
			<PageErrorDialog apiErrors={apiErrors} onClose={closeErrorDialog} />
		</section>
	);
}

Experts.propTypes = {
	match: PropTypes.object.isRequired,
	history: PropTypes.object.isRequired,
};

import React from 'react';
import { Engagements } from './engagements';
import './myEngagements.css';

export function ProjectSection(props) {
	return (
		<div>
			<Engagements {...props} />
		</div>
	);
}

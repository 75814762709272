import React from 'react';
import PropTypes from 'prop-types';

import { ProductPerson } from 'product/ProductPerson';

import './AreaExpertsGrid.css';

export function AreaExpertsGrid({ experts }) {
	return (
		<div className="area-experts-grid">
			{experts.map(person => {
				const { id, name, role, url, email, phoneNumber } = person;
				return (
					<ProductPerson
						key={id}
						theme="light"
						name={name}
						title={role}
						image={url}
						email={email}
						phoneNumber={phoneNumber}
					/>
				);
			})}
		</div>
	);
}

AreaExpertsGrid.propTypes = {
	experts: PropTypes.array.isRequired,
};

import React from 'react';
import PropTypes from 'prop-types';
import LinesEllipsis from 'react-lines-ellipsis';
import { ApIcon } from '@alixpartners/ui-components';
import { CDOIcon } from 'components/icons';
import { Media } from 'components/Media';

import './ProductMasonry.css';

export const ProductMasonry = ({ product, index, onClick, showWhenToUse }) => {
	const {
		name,
		introText,
		featureImageUrl,
		heroFeatureUrl,
		extension,
		featureImageExtension,
		color,
		onAlixip,
		showImage,
		whenToUseInfo,
		heroPreviewImageUrl,
	} = product;

	console.log(heroPreviewImageUrl);

	const image = heroFeatureUrl || featureImageUrl;
	const imageExtension = extension || featureImageExtension;

	return (
		<div
			onClick={onClick}
			style={{
				animationDuration: `${1 + index * 25}ms`,
				animationDelay: `${1 + index * 25}ms`,
			}}
			className="product"
		>
			<div
				className={`product-header product-header--${(color && color.toLowerCase()) ||
					'none'}`}
			>
				<div className="product-header__icon">
					{onAlixip && <CDOIcon fill="#FFFFFF" height={36} width={60} />}
				</div>
			</div>
			{image && showImage && (
				<div className="product-image">
					<Media
						previewClassName="product-header__video-preview"
						extension={imageExtension}
						previewImageUrl={heroPreviewImageUrl}
						url={image}
						alt={name}
						width="100%"
						height="100%"
					/>
				</div>
			)}
			<div className="product-details">
				<ApIcon
					iconName="baseline_arrow_forward"
					className="product-details__arrow"
					iconColor="var(--color-brand)"
				/>
				<h4 className="product-title">{name}</h4>
				<div className="product-intro-text product-intro-text--mobile">
					<div className="product-intro-text">
						<LinesEllipsis text={introText || ''} maxLine={2} ellipsis="..." />
					</div>
				</div>
				<div className="product-intro-text product-intro-text--desktop">
					<p
						className="product-intro-text"
						dangerouslySetInnerHTML={{ __html: introText.replaceAll('\n', '<br />') }}
					/>
				</div>
				{showWhenToUse && whenToUseInfo && (
					<>
						<div className="product-when-to-use-label">When to use</div>
						<p
							className="product-intro-text"
							dangerouslySetInnerHTML={{
								__html: whenToUseInfo.replaceAll('\n', '<br />'),
							}}
						/>
					</>
				)}
			</div>
		</div>
	);
};

ProductMasonry.propTypes = {
	showWhenToUse: PropTypes.bool,
	product: PropTypes.shape({
		id: PropTypes.number.isRequired,
		name: PropTypes.string.isRequired,
		introText: PropTypes.string,
		whenToUseInfo: PropTypes.string,
	}).isRequired,
};

import React from 'react';

const Icon = ({ size, ...otherProps }) => {
	const props = {
		width: size,
		height: size,
		fill: 'currentcolor',
		...otherProps,
	};
	return <img src="/images/CDO-logo-white.svg" height={props.height} alt="CDO" />;

	// return (
	// 	// <svg role="img" viewBox="0 0 136 70" {...props}>
	// 	// 	<path d="M135.750948 37.8512195C135.732693 49.9235337 125.899626 59.6955196 113.787729 59.6780062 101.675832 59.6604928 91.8712521 49.8601115 91.888139 37.7877953 91.9050259 25.7154792 101.736986 15.9423865 113.848884 15.9585366 119.669402 15.9555857 125.251464 18.2631745 129.361333 22.3712835 133.471203 26.4793925 135.770467 32.0497552 135.750948 37.8512195zM126.145166 37.8512195C126.164476 32.8734972 123.169231 28.3758152 118.559579 26.4606493 113.949928 24.5454833 108.636465 25.5911563 105.103008 29.1088605 101.569551 32.6265647 100.514103 37.9214108 102.430039 42.5182825 104.345976 47.1151543 108.854822 50.105983 113.848884 50.0926829 120.620884 50.0607163 126.105004 44.6010453 126.145166 37.8512195L126.145166 37.8512195zM39.9390564 50.7402439C34.319456 58.369071 24.4178452 61.5348653 15.3946292 58.5877151 6.37141314 55.640565.269829174 47.2478297.269829174 37.7835366.269829174 28.3192435 6.37141314 19.9265082 15.3946292 16.979358 24.4178452 14.0322079 34.319456 17.1980021 39.9390564 24.8268293L32.3814314 29.6817073C29.5661335 27.1207317 26.4278289 25.4670732 22.2654458 25.4670732 15.4581796 25.4670732 9.93980008 30.9674188 9.93980008 37.752439 9.93980008 44.5374592 15.4581796 50.0378049 22.2654458 50.0378049 26.4278289 50.0378049 29.0522591 48.6987805 32.3814314 45.8926829L39.9390564 50.7402439zM86.7970652 37.7231707C86.809735 43.234378 84.6231206 48.5243993 80.7191781 52.4272505 76.8152356 56.3301017 71.5144399 58.5253975 65.9851499 58.5292683L46.2633826 58.5292683 46.2633826 17.0414634 65.9851499 17.0414634C78.7292363 17.0414634 86.7970652 26.3121951 86.7970652 37.7231707zM76.8058776 37.7231707C76.8058776 31.5292683 72.1993603 27 66.9431587 27L56.2508996 27 56.2508996 48.5853659 66.9431587 48.5853659C72.1332907 48.3804878 76.8058776 43.9134146 76.8058776 37.7231707z" />
	// 	// 	<title id="ap-cdo-logo">Powered By CDO</title>
	// 	// </svg>
	// );
};

export const CDOIcon = Icon;

CDOIcon.defaultProps = {
	size: 24,
};

import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Error } from 'components/icons/Error';

import './Overlay.css';

export function Overlay({ className, children, style }) {
	return (
		<div className={classNames('overlay', className)} style={{ ...style }}>
			{children}
		</div>
	);
}

Overlay.propTypes = {
	children: PropTypes.node.isRequired,
};

export function NoDataOverlay({ message, style }) {
	return (
		<Overlay className="overlay--no-data" style={{ ...style }}>
			<Error size="48" />
			<span className="overlay__message">{message}</span>
		</Overlay>
	);
}

NoDataOverlay.propTypes = {
	message: PropTypes.string,
};

NoDataOverlay.defaultProps = {
	message: 'No data available',
};

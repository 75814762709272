import React from 'react';

const Icon = ({ size, ...otherProps }) => {
	const props = {
		width: size,
		height: size,
		fill: 'currentcolor',
		...otherProps,
	};
	return (
		<svg viewBox="0 0 40 40" {...props}>
			<path
				fill="#306EAD"
				d="M24.08 29.172v-2.388h12.338v-1.592H24.08v-2.388h12.338v-1.592H24.08v-2.388h12.338v-1.592H24.08v-2.388h12.338v-1.592H24.08v-2.388h12.338V9.272H24.08V5.49H40v29.651H24.08v-4.378h12.338v-1.592H24.08zM23.087 0v40L0 35.76V4.07L23.087 0zM11.391 14.577h.105l2.904 9.961h1.487l3.436-12.62H17.68l-2.51 10.26h-.104l-2.825-10.26h-1.592l-2.825 10.26h-.105l-2.51-10.26H3.565l3.437 12.62h1.487l.21-.721c.14-.481.32-1.099.542-1.854.222-.755.455-1.557.7-2.405.245-.848.478-1.65.7-2.405a861.805 861.805 0 0 0 .751-2.576z"
			/>
		</svg>
	);
};

export const DocIcon = Icon;

DocIcon.defaultProps = {
	size: 24,
};

import React from 'react';
import PropTypes from 'prop-types';

import { Overlay } from 'components/Overlay';
import { Loader } from 'components/Loader';
import { PageErrorDialog } from 'components/PageErrorDialog';

import { Footer } from 'global/Footer';
import { filterVisibleProducts } from 'area-editor/AreaPageEditor';
import { ProductTeam } from 'product/ProductTeam';
import { ProductList } from 'area/ProductList';
import { AreaDetail } from 'area/AreaDetail';

import { getArea, getPersonsByArea, getProductsByArea } from 'utils/apiUtils';
import { scrollToAnchor } from 'utils/urlUtils';
import { useWindowSize } from 'hooks/useWindowSize';
import { useIsMounted } from 'hooks/useIsMounted';

import './Area.css';

export function Area({ areaId }) {
	const [isLoading, setIsLoading] = React.useState(true);
	const [area, setArea] = React.useState([]);
	const [products, setProducts] = React.useState([]);
	const [persons, setPersons] = React.useState([]);
	const [apiErrors, setApiErrors] = React.useState({});

	const windowSize = useWindowSize();
	const isMounted = useIsMounted();
	scrollToAnchor();

	React.useEffect(() => {
		let queries = [getArea(areaId), getProductsByArea(areaId), getPersonsByArea(areaId)];
		Promise.all(queries)
			.then(([area, products, persons]) => {
				console.log(products);
				if (!isMounted()) {
					return;
				}
				setArea(area);
				setProducts(filterVisibleProducts(products));
				setPersons(persons);
				setIsLoading(false);
				scrollToAnchor();
			})
			.catch(error => {
				if (!isMounted()) {
					return;
				}
				setIsLoading(false);
				setApiErrors({ person: error });
			});
	}, [areaId]);

	function closeErrorDialog() {
		setApiErrors({});
	}

	if (isLoading) {
		return (
			<div className="home" style={{ minHeight: windowSize.height }}>
				<Overlay>
					<Loader />
				</Overlay>
			</div>
		);
	}

	const hasProducts = products && products.length > 0;
	const hasTeamMembers = persons && persons.length > 0;

	return (
		<div className="area-page home" style={{ minHeight: windowSize.height }}>
			<AreaDetail area={area} />
			{hasProducts && (
				<ProductList
					isKbArea={area.isKbArea}
					productsSectionTitle={area.productsSectionTitle}
					products={products}
				/>
			)}
			{hasTeamMembers && (
				<ProductTeam teamMembers={persons} title="Coordinators" darkTheme />
			)}
			<div className="area-page__footer-space" />
			<PageErrorDialog apiErrors={apiErrors} onClose={closeErrorDialog} />
			<Footer />
		</div>
	);
}

Area.propTypes = {
	areaId: PropTypes.number.isRequired,
};

import React from 'react';

import { useWindowSize } from 'hooks/useWindowSize';

import './Page.css';

export function Page({ className, children }) {
	const windowSize = useWindowSize();

	return (
		<div className={`page ${className}`} style={{ minHeight: windowSize.height }}>
			{children}
		</div>
	);
}

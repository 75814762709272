import React from 'react';

function Icon({ size, ...otherProps }) {
	const props = {
		width: size,
		height: size,
		fill: 'currentcolor',
		...otherProps,
	};
	return (
		<svg viewBox="0 0 24 24" {...props}>
			<path
				fill="#F4AD49"
				fillRule="nonzero"
				stroke="none"
				strokeWidth="1"
				d="M14.25 1.5l6.3 6.3v12.6c0 1.155-.945 2.1-2.1 2.1H5.85c-1.155 0-2.1-.945-2.1-2.1V3.6c0-1.155.945-2.1 2.1-2.1H11v.514h-1v.894h1V3.8h-1v.894h1v.894h-1v.894h1v.893h-1v.894h1v.894h-1v.893h1v.894h-1v.894h.5l-.09.007c-.233.038-.41.22-.41.44v4.468l.009.08c.042.209.246.367.492.367h2l.09-.007c.233-.038.41-.22.41-.44v-4.468l-.008-.08c-.043-.21-.247-.367-.492-.367H12v-.894h1v-.894h-1v-.893h1V8.27h-1v-.894h1v-.893h-1v-.894h1v-.894h-1v-.894h1v-.893h-1v-.894h1L13 1.5h1.25z"
			/>
		</svg>
	);
}

export const ZipIcon = Icon;

ZipIcon.defaultProps = {
	size: 24,
};

import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { assoc, equals, isEmpty } from 'ramda';

import { PrimaryButton, SecondaryButton } from 'components/Button';
import { ChevronLeft } from 'components/icons/ChevronLeft';
import { Overlay, NoDataOverlay } from 'components/Overlay';
import { Loader } from 'components/Loader';
import { MaxWidthLayout } from 'components/MaxWidthLayout';
import { PageErrorDialog } from 'components/PageErrorDialog';

import { EditorHeader } from 'content-editor/EditorHeader';
import {
	EditorPageLayout,
	EditorPageLayoutLeft,
	EditorPageLayoutRight,
} from 'content-editor/EditorPageLayout';
import { AreaDetailSection } from 'area-editor/AreaDetailSection';
import { EditorConfigOptions } from 'content-editor/EditorConfigOptions';
import { EditAreaToolsSection } from 'area-editor/EditAreaToolsSection';
import { AreaDetailEditSection } from 'area-editor/AreaDetailEditSection';
import { EditorPageSavingLoader } from 'content-editor/EditorPageSavingLoader';
import { AreaToolsSection } from 'area-editor/AreaToolsSection';
import { AreaTeamSection } from 'area-editor/AreaTeamSection';
import { EditAreaTeam } from 'area-editor/EditAreaTeam';
import {
	AREA_DETAIL,
	AREA_EDITOR_OPTIONS,
	AREA_TOOLS_PRODUCTS,
	AREA_TEAM,
} from 'area-editor/constants';
import {
	DEFAULT_ITEM,
	getValidItem,
	getValidationErrors,
	createPersonsHash,
} from 'admin/tabs/Area';
import {
	getAreas,
	getArea,
	updateArea,
	getProductsByArea,
	getSolutions,
	updateAreaProducts,
	getPersonsByArea,
	patchAreaPersons,
} from 'utils/apiUtils';
import { getNumberFromString, mergeNullKeysToDefaults } from 'utils/parseUtils';
import { getPageUrl, getUrlByTransformingName } from 'utils/urlUtils';
import { useIsMounted } from 'hooks/useIsMounted';
import { useWindowSize } from 'hooks/useWindowSize';

import './AreaPageEditor.css';

const LARGE_PAGE_SIZE = 100000;

function formatArea(area) {
	const { name, url } = area;
	if (!url) {
		return { ...area, url: getUrlByTransformingName(name) };
	}
	return area;
}

export function filterVisibleProducts(products) {
	return products ? products.filter(product => product.isVisible) : [];
}

export function AreaPageEditor({
	match,
	history,
	location,
	sectionKey,
	itemId,
	config,
	refreshData,
}) {
	const { url, params } = match;
	const areaId = getNumberFromString(params.areaId);
	const [isLoading, setIsLoading] = React.useState(true);
	const [isSaving, setIsSaving] = React.useState(false);
	const [committedItem, setCommittedItem] = React.useState(null);
	const [areas, setAreas] = React.useState([]);
	const [products, setProducts] = React.useState([]);
	const [areaExperts, setAreaExperts] = React.useState([]);
	const [areaProducts, setAreaProducts] = React.useState([]);
	const [liveItem, setLiveItem] = React.useState(null);
	const [apiErrors, setApiErrors] = React.useState({});
	const [validationErrors, setValidationErrors] = React.useState({});
	const [focusedInput, setFocusedInput] = React.useState(null);
	const [areaPersonsHash, setAreaPersonsHash] = React.useState({});

	const isMounted = useIsMounted();
	const windowSize = useWindowSize();

	React.useEffect(() => {
		let queries = [
			getAreas(),
			getArea(areaId),
			getProductsByArea(areaId, { page: 1, pageSize: LARGE_PAGE_SIZE }),
			getSolutions(),
			getPersonsByArea(areaId),
		];
		Promise.all(queries)
			.then(([areas, area, areaProducts, products, persons]) => {
				if (!isMounted()) {
					return;
				}
				setAreas(areas);
				setProducts(filterVisibleProducts(products));
				setAreaProducts(filterVisibleProducts(areaProducts));
				const formattedArea = formatArea(mergeNullKeysToDefaults(DEFAULT_ITEM, area));
				setCommittedItem(formattedArea);
				setLiveItem(formattedArea);
				setAreaPersonsHash(createPersonsHash(formattedArea.persons));
				setAreaExperts(persons);
				setIsLoading(false);
			})
			.catch(error => {
				if (!isMounted()) {
					return;
				}
				setIsLoading(false);
				setApiErrors({ area: error });
			});
	}, []);

	function validateUniqueAttr(attr, validItem, items, errors) {
		const urlDuplicationFound = items.find(
			item =>
				item.id !== validItem.id &&
				(item[attr] || '').toLowerCase() === (validItem[attr] || '').toLowerCase()
		);

		if (urlDuplicationFound) {
			return assoc(
				attr,
				`Area with this ${attr} allready exist. Please enter unique ${attr}`,
				errors
			);
		}

		return errors;
	}

	function onSave() {
		let shouldUpdateAreas = !equals(liveItem, committedItem);

		const validItem = getValidItem(liveItem);
		if (!validItem.url) {
			validItem.url = getUrlByTransformingName(validItem.name);
			shouldUpdateAreas = true;
		}
		let errors = getValidationErrors(validItem, areas);
		errors = validateUniqueAttr('name', validItem, areas, errors);
		errors = validateUniqueAttr('url', validItem, areas, errors);

		if (!isEmpty(errors)) {
			setValidationErrors(errors);
			return;
		}

		setValidationErrors({});
		setIsSaving(true);

		const queries = [];

		if (shouldUpdateAreas) {
			queries.push(updateArea(validItem));
		}

		return Promise.all(queries)
			.then(([item]) => {
				if (!isMounted()) {
					return;
				}

				if (shouldUpdateAreas) {
					setCommittedItem(item);
					setLiveItem(item);
				}

				setIsSaving(false);
				refreshData();
			})
			.catch(error => {
				if (!isMounted()) {
					return;
				}
				setIsSaving(false);
				refreshData();
				setApiErrors({ area: error });
			});
	}

	function closeErrorDialog() {
		setApiErrors({});
	}

	function handleSaveLiveItem(event) {
		setLiveItem(assoc(event.target.name, event.target.value));
	}

	function handleChangeProducts(operation, productId, products) {
		setAreaProducts(products);
		setIsSaving(true);
		updateAreaProducts(areaId, operation, [{ id: productId }])
			.then(() => {
				setIsSaving(false);
			})
			.catch(error => {
				if (!isMounted()) {
					return;
				}
				setIsSaving(false);
				setApiErrors({ products: error });
			});
	}

	function handleReorderProducts(products, id, order) {
		setAreaProducts(products);
		setIsSaving(true);
		updateAreaProducts(areaId, 'replace', [{ id, order }])
			.then(() => {
				setIsSaving(false);
			})
			.catch(error => {
				if (!isMounted()) {
					return;
				}
				setIsSaving(false);
				setApiErrors({ products: error });
			});
	}

	function refetchAreaAndPersons() {
		Promise.all([getArea(areaId), getPersonsByArea(areaId)])
			.then(([area, persons]) => {
				if (!isMounted()) {
					return;
				}

				const formattedArea = mergeNullKeysToDefaults(DEFAULT_ITEM, area);
				setCommittedItem(formattedArea);
				setLiveItem(formattedArea);
				setAreaPersonsHash(createPersonsHash(formattedArea.persons));
				setAreaExperts(persons);
				setIsSaving(false);
			})
			.catch(error => {
				if (!isMounted()) {
					return;
				}
				setIsSaving(false);
				setApiErrors({ area: error });
			});
	}

	function updateAreaForPersons(operation, persons) {
		const areaId = liveItem.id;
		setIsSaving(true);
		patchAreaPersons(areaId, operation, persons)
			.then(data => {
				refetchAreaAndPersons();
			})
			.catch(error => {
				setIsSaving(false);
				setApiErrors({ areaTeam: error });
			});
	}

	const detailSelected = sectionKey === AREA_DETAIL;
	const toolsSectionSelected = sectionKey === AREA_TOOLS_PRODUCTS && !!itemId;
	const teamsSectionSelected = sectionKey === AREA_TEAM;
	const { areaTypes } = config;

	if (isLoading) {
		return (
			<Overlay style={{ minHeight: windowSize.height }}>
				<Loader />
			</Overlay>
		);
	}

	if (!liveItem) {
		return <NoDataOverlay style={{ minHeight: windowSize.height }} />;
	}

	return (
		<div className="area-page-editor">
			<EditorHeader
				left={() => (
					<Link to={getPageUrl('content-editor-areas')}>
						<SecondaryButton className="area-page-editor__back-button">
							<ChevronLeft /> Back to areas
						</SecondaryButton>
					</Link>
				)}
				right={() => (
					<div className="area-page-editor__actions">
						<EditorPageSavingLoader isSaving={isSaving} />
						<PrimaryButton
							className="area-page-editor__publish-button"
							disabled={equals(liveItem, committedItem) || isSaving}
							onClick={onSave}
						>
							Publish
						</PrimaryButton>
					</div>
				)}
			/>
			<MaxWidthLayout>
				<EditorPageLayout>
					<EditorPageLayoutLeft>
						<AreaDetailSection
							url={url}
							area={liveItem}
							focusedInput={focusedInput}
							selected={detailSelected}
							onEditArea={handleSaveLiveItem}
						/>
						<AreaToolsSection
							url={url}
							history={history}
							location={location}
							area={liveItem}
							areaProducts={areaProducts}
							match={match}
							onChange={handleChangeProducts}
							selectedProductId={itemId === 'new' ? null : getNumberFromString(itemId)}
							onReorder={handleReorderProducts}
						/>
						<AreaTeamSection
							url={url}
							areaId={liveItem.id}
							areaExperts={areaExperts}
							areaPersonHash={areaPersonsHash}
							focusedInput={focusedInput}
						/>
					</EditorPageLayoutLeft>
					<EditorPageLayoutRight>
						{detailSelected && (
							<AreaDetailEditSection
								area={liveItem}
								errors={validationErrors}
								setFocusedInput={setFocusedInput}
								onEditArea={handleSaveLiveItem}
								areaTypes={areaTypes}
							/>
						)}
						{toolsSectionSelected && (
							<EditAreaToolsSection
								area={liveItem}
								selectedProductId={itemId === 'new' ? null : getNumberFromString(itemId)}
								areaProducts={areaProducts}
								products={products}
								onProductsChange={handleChangeProducts}
								url={url}
								history={history}
							/>
						)}
						{teamsSectionSelected && (
							<EditAreaTeam
								area={liveItem}
								areaExperts={areaExperts}
								areaPersonsHash={areaPersonsHash}
								setAreaPersonsHash={setAreaPersonsHash}
								setFocusedInput={setFocusedInput}
								setAreaExperts={setAreaExperts}
								updateAreaForPersons={updateAreaForPersons}
							/>
						)}
						{detailSelected && (
							<EditorConfigOptions url={url} options={AREA_EDITOR_OPTIONS} />
						)}
					</EditorPageLayoutRight>
				</EditorPageLayout>
			</MaxWidthLayout>
			<PageErrorDialog apiErrors={apiErrors} onClose={closeErrorDialog} />
		</div>
	);
}

AreaPageEditor.propTypes = {
	match: PropTypes.object.isRequired,
	history: PropTypes.shape({
		push: PropTypes.func.isRequired,
	}).isRequired,
	sectionKey: PropTypes.string.isRequired,
};

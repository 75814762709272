import React from 'react';
import PropTypes from 'prop-types';

import { Label } from 'components/Label';

import './LabelWithCount.css';

export function LabelWithCount({ value, count, maxLength, className, ...props }) {
	return (
		<div className="label-with-count">
			<Label value={value} className={className} {...props} />
			<span className="label-with-count__value">{count}</span>
		</div>
	);
}

LabelWithCount.propTypes = {
	value: PropTypes.string,
	className: PropTypes.string,
	count: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

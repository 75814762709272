import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import './MaxWidthLayout.css';

export function MaxWidthLayout({ fullWidth, children }) {
	return (
		<div
			className={classNames('max-width__layout', {
				'max-width__layout--full-width': fullWidth,
			})}
		>
			{children}
		</div>
	);
}

MaxWidthLayout.propTypes = {
	styles: PropTypes.object,
	children: PropTypes.node,
	className: PropTypes.string,
};

MaxWidthLayout.defaultProps = {
	styles: {},
	className: '',
};

import React from 'react';

const ESCAPE_KEY_CODE = 27;

export function useEscKeydown(onEscKeydown) {
	React.useEffect(() => {
		document.addEventListener('keydown', handleKeydown);
		return () => {
			document.removeEventListener('keydown', handleKeydown);
		};
	});

	function handleKeydown(event) {
		if (event.keyCode === ESCAPE_KEY_CODE && onEscKeydown) {
			onEscKeydown();
		}
	}
}

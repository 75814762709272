import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { ArrowDropDown } from 'components/icons/ArrowDropDown';
import { getStringFromNumber, getNumberFromString } from 'utils/parseUtils';
import { AppTooltip } from 'components/AppTooltip';

import './MenuDropdown.css';

export function MenuDropdown({
	menuContainerClassName,
	defaultLabel,
	headerScale,
	options,
	selectedId,
	theme,
	tippyProps,
	onChange,
}) {
	const selectedItem = (options || []).find(item => item.id === selectedId);
	const selectLabel = selectedItem ? selectedItem.name : defaultLabel;
	const [redrawToggle, forceMenuRedraw] = React.useState(false);

	return (
		<AppTooltip
			key={redrawToggle ? 'on' : 'off'}
			className={theme}
			theme="menu-dropdown-tooltip"
			html={
				<div
					className={classNames(
						'menu-dropdown__options-container',
						menuContainerClassName
					)}
					style={{ transform: `scale(${1 / headerScale})` }}
				>
					<ul>
						{options.length ? (
							options.map(option => (
								<Option
									key={option.id}
									option={option}
									onChange={(...args) => {
										onChange(...args);
										forceMenuRedraw(!redrawToggle);
									}}
								/>
							))
						) : (
							<div style={{ textAlign: `center` }}>No items</div>
						)}
					</ul>
				</div>
			}
			tippyProps={tippyProps}
		>
			<div className="menu-dropdown__label-row">
				<span className="menu-dropdown__label">{selectLabel}</span>
				<span className="menu-dropdown__icon">
					<ArrowDropDown size={20} />
				</span>
			</div>
		</AppTooltip>
	);
}

function Option({ className, option, onChange }) {
	return (
		<li className={classNames('menu-dropdown__option', className)}>
			<button
				type="button"
				className="menu-dropdown__option-button"
				value={getStringFromNumber(option.id)}
				onClick={event => {
					onChange(getNumberFromString(event.target.value));
				}}
			>
				{option.name}
			</button>
		</li>
	);
}

MenuDropdown.propTypes = {
	menuContainerClassName: PropTypes.string,
	defaultLabel: PropTypes.string,
	headerScale: PropTypes.number,
	options: PropTypes.arrayOf(
		PropTypes.shape({
			id: PropTypes.number.isRequired,
			name: PropTypes.string.isRequired,
		})
	).isRequired,
	selectedId: PropTypes.number,
	onChange: PropTypes.func.isRequired,
};

MenuDropdown.defaultProps = {
	defaultLabel: 'None',
	headerScale: 1,
	selectedId: null,
};

import React from 'react';

import './PageSectionTitle.css';

export function PageSectionTitle({ noBottomMargin, children }) {
	return (
		<h2
			className={`page-section-title ${
				noBottomMargin ? 'page-section-title--no-margin' : ''
			}`}
		>
			{children}
		</h2>
	);
}

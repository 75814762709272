import React from 'react';
import PropTypes from 'prop-types';

import { LinesEllipsis } from 'components/LinesEllipsis';
import { DefaultFileIcon } from 'components/icons/DefaultFileIcon';
import { PdfIcon } from 'components/icons/PdfIcon';
import { ExcelIcon } from 'components/icons/ExcelIcon';
import { TextIcon } from 'components/icons/TextIcon';
import { PptIcon } from 'components/icons/PptIcon';
import { DocIcon } from 'components/icons/DocIcon';
import { ImageIcon } from 'components/icons/ImageIcon';
import { CsvIcon } from 'components/icons/CsvIcon';
import { formatFileExtension } from 'utils/fileUtils';
import { SaveIcon } from 'components/icons';
import { ZipIcon } from 'components/icons/ZipIcon';
import { VideoIcon } from 'components/icons/VideoIcon';

import './FileItem.css';

/**
 *
 * @param {string} fileType - this file type should match with formatFileExtension return type
 */
function getFileIcon(fileType) {
	const icons = {
		XLS: ExcelIcon,
		XLSX: ExcelIcon,
		PPT: PptIcon,
		PPTX: PptIcon,
		PDF: PdfIcon,
		TXT: TextIcon,
		RTF: TextIcon,
		JPG: ImageIcon,
		PNG: ImageIcon,
		DOC: DocIcon,
		DOCX: DocIcon,
		CSV: CsvIcon,
		ZIP: ZipIcon,
		'7Z': ZipIcon,
		RAR: ZipIcon,
		GZ: ZipIcon,
		MP4: VideoIcon,
		MOV: VideoIcon,
		FLV: VideoIcon,
		WMV: VideoIcon,
		AVI: VideoIcon,
	};

	const FileIcon = icons[fileType];

	return FileIcon ? <FileIcon /> : <DefaultFileIcon />;
}

export function FileItemContent({
	allowDownload = false,
	name,
	description,
	version,
	extension,
}) {
	return (
		<div className="file-item">
			<span className="file-item__icon">
				{getFileIcon(formatFileExtension(extension))}
			</span>
			<div className="file-item__details">
				<div className="file-item__header">
					<h3 className="file-item__name">{name}</h3>
					{version && <h5 className="file-item__version">{`Version ${version}`}</h5>}
				</div>
			</div>
			{description ? (
				<div className="file-item__description">
					<LinesEllipsis text={description} maxLine={7} />
				</div>
			) : (
				<div className="file-item__description--blank">No description</div>
			)}
			{allowDownload && (
				<div className="file-item__save-icon">
					<SaveIcon size={32} />
				</div>
			)}
		</div>
	);
}

export function FileItem({ name, fileName, description, version, url, extension }) {
	return (
		<a
			href={url}
			title={`Download ${fileName}`}
			target="_blank"
			rel="noopener noreferrer"
			download
		>
			<FileItemContent
				name={name}
				description={description}
				version={version}
				extension={extension}
				allowDownload
			/>
		</a>
	);
}

export const FileItemPropTypes = {
	name: PropTypes.string.isRequired,
	fileName: PropTypes.string.isRequired,
	description: PropTypes.string,
	version: PropTypes.string,
	url: PropTypes.string.isRequired,
	extension: PropTypes.string,
};

FileItem.propTypes = FileItemPropTypes;

import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { ApLogo } from '@alixpartners/ui-components';

import { MaxWidthLayout } from 'components/MaxWidthLayout';
import { getAppPrimaryURL } from 'utils/urlUtils';

import './EditorHeader.css';

export function EditorHeader({ left, right }) {
	return (
		<div className="editor-header">
			<MaxWidthLayout>
				<div className="editor-header__inner">
					<div className="editor-header__logo">
						<Link className="header__nav-link" to={getAppPrimaryURL()}>
							<ApLogo />
						</Link>
						<div className="editor-header__logo-separator" />
						{left && <Fragment>{left()}</Fragment>}
					</div>
					{right && <Fragment>{right()}</Fragment>}
				</div>
			</MaxWidthLayout>
		</div>
	);
}

EditorHeader.propTypes = {
	left: PropTypes.func,
	right: PropTypes.func,
};

import React from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from 'ramda';
import { Overlay, NoDataOverlay } from 'components/Overlay';
import { Loader } from 'components/Loader';
import { ModalContainer } from 'components/ModalContainer';
import { ErrorDialog } from 'components/ErrorDialog';
import { PrimaryButton, SecondaryButton } from 'components/Button';
import { EditableList } from 'components/EditableList';
import { Header, Title, Actions, Content, Section, SectionTitle } from 'admin/Tab';
import { sortByName } from 'utils/sortUtils';
import { getEntityLabelFromType } from 'utils/parseUtils';
import { useIsMounted } from 'hooks/useIsMounted';

export function List({
	history,
	type,
	parentKey,
	name,
	getItems,
	sectionKey,
	getSections,
}) {
	const [isLoading, setIsLoading] = React.useState(true);
	const [items, setItems] = React.useState([]);
	const [sections, setSections] = React.useState([]);
	const [apiErrors, setApiErrors] = React.useState({});
	const isMounted = useIsMounted();

	const hasSections = Boolean(sectionKey && getSections);
	const noSectionItems = hasSections ? items.filter(item => !item[sectionKey]) : [];

	React.useEffect(fetchData, []);

	function fetchData() {
		let queries = [getItems()];
		if (hasSections) {
			queries = [...queries, getSections()];
		}
		setIsLoading(true);
		Promise.all(queries)
			.then(([items, sections = []]) => {
				if (!isMounted()) {
					return;
				}
				setItems(sortByName(items));
				setSections(sortByName(sections));
				setIsLoading(false);
			})
			.catch(error => {
				if (!isMounted()) {
					return;
				}
				setIsLoading(false);
				setApiErrors({ [name]: error });
			});
	}

	function closeErrorDialog() {
		setApiErrors({});
	}

	if (isLoading) {
		return (
			<Overlay>
				<Loader />
			</Overlay>
		);
	}

	return (
		<React.Fragment>
			<Header>
				<Title>{name}</Title>
				<Actions>
					<PrimaryButton onClick={() => history.push(`/admin/new-${type}`)}>
						Add {getEntityLabelFromType(type)}
					</PrimaryButton>
				</Actions>
			</Header>

			<Content>
				{isEmpty(items) && <NoDataOverlay />}
				{!isEmpty(items) &&
					sections.map(section => {
						const filteredItems = items.filter(item => item[sectionKey] === section.id);
						if (isEmpty(filteredItems)) {
							return null;
						}
						return (
							<Section key={section.id}>
								<SectionTitle>{section.name}</SectionTitle>
								<EditableList
									type={type}
									parentKey={parentKey}
									items={filteredItems}
									onUpdate={item => history.push(`/admin/${type}s/${item.id}`)}
								/>
							</Section>
						);
					})}

				{hasSections && !isEmpty(noSectionItems) && (
					<Section>
						<SectionTitle>Items without "{sectionKey}"</SectionTitle>
						<EditableList
							type={type}
							parentKey={parentKey}
							items={noSectionItems}
							onUpdate={item => history.push(`/admin/${type}s/${item.id}`)}
						/>
					</Section>
				)}

				{!hasSections && !isEmpty(items) && (
					<EditableList
						type={type}
						parentKey={parentKey}
						items={items}
						onUpdate={item => history.push(`/admin/${type}s/${item.id}`)}
					/>
				)}
			</Content>

			{!isEmpty(apiErrors) && (
				<ModalContainer onClose={closeErrorDialog}>
					<ErrorDialog
						type="API"
						errors={apiErrors}
						button={
							<SecondaryButton onClick={() => window.location.reload()}>
								Reload
							</SecondaryButton>
						}
						onClose={closeErrorDialog}
					/>
				</ModalContainer>
			)}
		</React.Fragment>
	);
}

List.propTypes = {
	history: PropTypes.shape({
		push: PropTypes.func.isRequired,
	}).isRequired,
	type: PropTypes.string.isRequired,
	parentKey: PropTypes.string,
	name: PropTypes.string.isRequired,
	getItems: PropTypes.func.isRequired,
	sectionKey: PropTypes.string,
	getSections: PropTypes.func,
};

import React from 'react';

export function useIsMounted() {
	const ref = React.useRef(false);

	React.useEffect(() => {
		ref.current = true;
		return () => {
			ref.current = false;
		};
	}, []);

	function isMounted() {
		return ref.current;
	}

	return isMounted;
}

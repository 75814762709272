import React from 'react';
import PropTypes from 'prop-types';
import { NavLink, Route } from 'react-router-dom';
import { ApAppBar } from '@alixpartners/ui-components';
import classNames from 'classnames';

import { AlixIPLogo } from 'components/icons';
import { SettingsDropdown } from 'global/SettingsDropdown';
import { getAppPrimaryURL } from 'utils/urlUtils';

import './AppNavbar.css';

export function AppNavbar({ left, right, title, className, ...rest }) {
	const homeURL = getAppPrimaryURL();

	return (
		<Route
			render={({ history }) => (
				<ApAppBar
					className={classNames('app-navbar', className)}
					{...rest}
					left={() => (
						<React.Fragment>
							<NavLink to={homeURL}>
								<AlixIPLogo size={59} className="app-navbar__alix-ip-logo" />
							</NavLink>
							{left()}
						</React.Fragment>
					)}
					right={() => (
						<React.Fragment>
							{right()}
							<SettingsDropdown
								onContentEditorClick={() => history.push(`/content-editor/`)}
							/>
						</React.Fragment>
					)}
				/>
			)}
		/>
	);
}

export function AppNavbarSeparatorGreen(props) {
	return <AppNavbarSeparator {...props} theme="green" />;
}

export function AppNavbarSeparator({ className, theme, ...props }) {
	const hasGreenTheme = theme === 'green';
	const hasBlackTheme = theme === 'black';

	return (
		<span
			className={classNames('app-navbar__separator', className, {
				'app-navbar__separator--green': hasGreenTheme,
				'app-navbar__separator--black': hasBlackTheme,
			})}
			{...props}
		/>
	);
}

AppNavbar.propTypes = {
	left: PropTypes.func,
	right: PropTypes.func,
	title: PropTypes.string,
	className: PropTypes.string,
};

AppNavbar.defaultProps = {
	left: () => {},
	right: () => {},
	title: 'Digital platform',
};

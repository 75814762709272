import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import './ErrorLabel.css';

export function ErrorLabel({ error }) {
	return (
		<Fragment>{error && <div className="editor-error-label">{error}</div>}</Fragment>
	);
}

ErrorLabel.propTypes = {
	error: PropTypes.string,
};

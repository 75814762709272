import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import './GridListItems.css';

export function GridListItems({ items, className, renderItem, columnsCount }) {
	const styles = columnsCount
		? {
				gridTemplateColumns: `repeat(${columnsCount}, 1fr)`,
		  }
		: {};
	return (
		<ul className={classNames('grid-list__items', className)} style={styles}>
			{items.map(item => (
				<li key={item.id || item} className="grid-list__item">
					{renderItem(item)}
				</li>
			))}
		</ul>
	);
}

GridListItems.propTypes = {
	items: PropTypes.arrayOf(PropTypes.object).isRequired,
	className: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
	renderItem: PropTypes.func.isRequired,
	columnsCount: PropTypes.number,
};

import React from 'react';
import classNames from 'classnames';

import './EditorPageLayout.css';

export function EditorPageLayout({ children }) {
	return <div className="editor-page-layout">{children}</div>;
}

export function EditorPageLayoutLeft({ children }) {
	return (
		<div className="editor-page-layout__left">
			<div className="editor-page-layout__left-inner">{children}</div>
		</div>
	);
}

export function EditorPageLayoutRight({ children }) {
	return (
		<div className="editor-page-layout__right">
			<div className="editor-page-layout__right-inner">{children}</div>
		</div>
	);
}

export function EditorPageFieldset({ className, children }) {
	return (
		<fieldset className={classNames('editor-page-fieldset', className)}>
			{children}
		</fieldset>
	);
}

export function EditorPageSeparator({ theme }) {
	return <div className={`editor-page-separator ${theme}`} />;
}

export function EditorPageSectionTitle({ title, isAdmin }) {
	return (
		<h3
			className={classNames('editor-page-section-title', {
				'editor-page-section-title--admin': isAdmin,
			})}
		>
			{title}
		</h3>
	);
}

export function EditorPageReorderLabel({ title, action }) {
	return (
		<div onClick={action} className="editor-page-order-change-label">
			{title}
		</div>
	);
}

export function EditorSectionActions({ isFixed, children }) {
	return (
		<div
			className={classNames('editor-section-actions', {
				'editor-section-actions--fixed': isFixed,
			})}
		>
			{children}
		</div>
	);
}

export function EditorSectionInfoLabel({ children }) {
	return <div className="editor-section-info-label">{children}</div>;
}

import React from 'react';
import { ApButtonLink } from '@alixpartners/ui-components';

import './NewEngagement.css';

export function NewEngagement() {
	const SERVICE_NOW_FORM_URL =
		'https://alixpartners.service-now.com/sp?id=sc_cat_item&sys_id=42b940e7dbd4c0d0174361f74b9619e7';

	return (
		<div className="new-engagement">
			<div className="new-engagement__actions">
				<div className="new-engagement-block">
					<div className="new-engagement-block__image">
						<img src="/images/oxr_image.png" alt="oxr" />
					</div>
					<div className="new-engagement-block__title">Request for Radial or OXR</div>
					<ApButtonLink
						href="https://radial.alixpartners.com"
						target="_blank"
						rel="noopener noreferrer"
					>
						Request Radial / OXR
					</ApButtonLink>
				</div>
				<div className="new-engagement-block__separator" />
				<div className="new-engagement-block">
					<div className="new-engagement-block__image">
						<div className="new-engagement-block__email-ets-image">
							<img src="/images/task-checklist-write.svg" alt="snow etrf" />
						</div>
					</div>
					<div className="new-engagement-block__title">
						Request for all other tools & products
					</div>
					<ApButtonLink href={SERVICE_NOW_FORM_URL} target="_blank">
						Create request
					</ApButtonLink>
				</div>
			</div>
			<div className="new-engagement__actions-info">
				New engagement requests are only available on your AlixPartners laptop at this
				time.
			</div>
		</div>
	);
}

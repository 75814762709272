import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import './Toggle.css';

export function Toggle({ id, checked, onChange, label, ...props }) {
	const [mouseDown, setMouseDown] = React.useState(false);

	return (
		<label className="toggle">
			<input
				{...props}
				id={id}
				className={classNames('toggle__checkbox', {
					'toggle__checkbox--clicked': mouseDown,
				})}
				type="checkbox"
				checked={checked}
				onClick={() => setMouseDown(true)}
				onBlur={() => setMouseDown(false)}
				onChange={event => onChange(event.target.checked)}
			/>
			<span className="toggle__slider" />
			{label && (
				<span htmlFor={id} className="toggle__label">
					{label}
				</span>
			)}
		</label>
	);
}

Toggle.propTypes = {
	id: PropTypes.string,
	checked: PropTypes.bool.isRequired,
	onChange: PropTypes.func.isRequired,
};

import React from 'react';
import PropTypes from 'prop-types';
import { Tooltip } from 'react-tippy';

import 'react-tippy/dist/tippy.css';
import './AppTooltip.css';

export function AppTooltip({ children, html, className, theme, tippyProps, ...props }) {
	return (
		<Tooltip
			useContext
			position="bottom-end"
			interactive
			animateFill={false}
			hideDelay={500}
			distance="4"
			className={`app-dropdown dark-nav ${className}`}
			theme={`light app-tooltip ${theme}`}
			style={{ display: 'inline-flex' }}
			html={html}
			{...tippyProps}
			{...props}
		>
			{children}
		</Tooltip>
	);
}

AppTooltip.propTypes = {
	children: PropTypes.node.isRequired,
	tippyProps: PropTypes.object,
	html: PropTypes.element.isRequired,
	className: PropTypes.string,
	theme: PropTypes.string,
};

export const INPUT_NAMES = {
	aboutText: 'aboutText',
	description: 'description',
	stats: 'stats',
	searchExperts: 'searchExperts',
};

export const AREA_DETAIL = 'detail';
export const AREA_TOOLS_PRODUCTS = 'tools-products';
export const AREA_TEAM = 'team';

export const AREA_EDITOR_OPTIONS = [
	{
		key: AREA_TOOLS_PRODUCTS,
		label: 'Edit products',
	},
	{
		key: AREA_TEAM,
		label: 'Edit team',
	},
];

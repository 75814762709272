import React from 'react';
import PropTypes from 'prop-types';

import { Loader } from 'components/Loader';
import { SecondaryButton } from 'components/Button';

import './LoadMoreButton.css';

export function LoadMoreButton({ onClick, isLoading }) {
	return (
		<div className="load-more-button">
			{isLoading ? (
				<Loader />
			) : (
				<SecondaryButton
					onClick={onClick}
					disabled={isLoading}
					className="load-more-button__secondary-button"
				>
					Load More Results
				</SecondaryButton>
			)}
		</div>
	);
}

LoadMoreButton.propTypes = {
	onClick: PropTypes.func.isRequired,
	isLoading: PropTypes.bool,
};

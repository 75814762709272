import React from 'react';
import { MenuDropdown } from 'components/MenuDropdown';

import './SortDropdown.css';

export function SortDropdown({ onSort, sortOptions, sortMapping }) {
	const [selectedId, setSelectedId] = React.useState(0);

	return (
		<div className="sort-dropdown">
			<span className="sort-dropdown__label">Sort by:</span>
			<MenuDropdown
				menuContainerClassName="sort-dropdown__menu-container"
				options={sortOptions}
				selectedId={selectedId}
				onChange={id => {
					setSelectedId(id);
					onSort(sortMapping[id]);
				}}
				tippyProps={{ trigger: 'click' }}
			/>
		</div>
	);
}

SortDropdown.defaultProps = {
	sortMapping: {
		0: 'order',
		1: 'name',
	},
	sortOptions: [
		{
			id: 0,
			name: 'Spotlight',
		},
		{
			id: 1,
			name: 'A-Z',
		},
	],
};

import React from 'react';

const Icon = ({ size, ...otherProps }) => {
	const props = {
		width: size,
		height: size,
		fill: 'currentcolor',
		...otherProps,
	};
	return (
		<svg viewBox="0 0 24 24" {...props}>
			<path fill="none" d="M0 0h24v24H0V0z" />
			<path d="M9 7v8l7-4zm12-4H3c-1.1 0-2 .9-2 2v12c0 1.1.9 2 2 2h5v2h8v-2h5c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm0 14H3V5h18v12z" />
		</svg>
	);
};

export const OndemandVideoIcon = Icon;

OndemandVideoIcon.defaultProps = {
	size: 24,
};

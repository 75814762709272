import React from 'react';
import PropTypes from 'prop-types';
import NavigationPrompt from 'react-router-navigation-prompt';
import { assoc, equals, isEmpty } from 'ramda';
import { DangerButton, PrimaryButton, SecondaryButton } from 'components/Button';
import { Overlay, NoDataOverlay } from 'components/Overlay';
import { Loader } from 'components/Loader';
import { ModalContainer } from 'components/ModalContainer';
import { DeleteItemDialog } from 'components/DeleteItemDialog';
import { ErrorDialog } from 'components/ErrorDialog';
import { NavigateAwayDialog } from 'components/NavigateAwayDialog';
import { TitleInput } from 'components/TitleInput';
import { Select } from 'components/Select';
import { SaveIcon } from 'components/icons/SaveIcon';
import { ProductSelectList } from 'experts/ProductSelectList';
import { AreaSelectList } from 'experts/AreaSelectList';

import {
	Header,
	Actions,
	Content,
	Fieldset,
	FieldsetTitle,
	Input,
	Textarea,
	ValidationError,
} from 'admin/Tab';

import {
	getSolutions,
	getPerson,
	createPerson,
	updatePerson,
	deletePerson,
	uploadFile,
} from 'utils/apiUtils';
import { sortByName } from 'utils/sortUtils';
import { mergeNullKeysToDefaults, isPhoneNumberValid } from 'utils/parseUtils';
import { isValidateFileType, getFileNameFromUrl } from 'utils/fileUtils';
import { getPageUrl } from 'utils/urlUtils';
import { useIsMounted } from 'hooks/useIsMounted';
import { apOfficeLocations } from 'data/apOfficeLocations';

import './Person.css';

const DEFAULT_ITEM = {
	name: 'Unnamed Subject Matter Expert',
	solutionId: null,
	description: '',
	role: '',
	email: '',
	fileName: '',
	phoneNumber: undefined,
	country: '',
};

export function getApOfficeLocations(officeLocations) {
	return officeLocations.map(officeLocation => ({
		id: officeLocation,
		name: officeLocation,
	}));
}

export const WHITELISTED_FILE_TYPES = ['.jpg', '.jpeg', '.png'];

export function getValidItem(item) {
	return {
		...item,
		name: item.name.trim(),
		description: item.description.trim(),
		role: item.role.trim(),
		email: item.email.trim(),
		phoneNumber: item.phoneNumber && item.phoneNumber.trim(),
		country: item.country && item.country.trim(),
	};
}

export function getValidationErrors(item) {
	const { name, role, email, country, fileName, phoneNumber } = item;
	let errors = {};
	if (!name) {
		errors = assoc('name', 'Please enter a valid name', errors);
	}
	if (!role) {
		errors = assoc('role', 'Please enter a valid role', errors);
	}
	if (!country) {
		errors = assoc('country', 'Please select a location', errors);
	}
	if (phoneNumber && !isPhoneNumberValid(phoneNumber)) {
		errors = assoc('phoneNumber', 'Please enter a valid phone number', errors);
	}
	if (!email || !email.match(/^\S+@\S+$/)) {
		errors = assoc('email', 'Please enter a valid email', errors);
	}
	if (fileName && !isValidateFileType(fileName)) {
		errors = assoc(
			'fileName',
			`Please try to upload a	file in following format: ${WHITELISTED_FILE_TYPES.join(
				', '
			)}.`
		);
	}
	return errors;
}

export function Person({ personId, solutionId, history, areaId }) {
	const isNew = !personId;

	const [isLoading, setIsLoading] = React.useState(true);
	const [isSaving, setIsSaving] = React.useState(false);
	const [isDeleting, setIsDeleting] = React.useState(false);
	const [committedItem, setCommittedItem] = React.useState(null);
	const [liveItem, setLiveItem] = React.useState(null);
	const [liveFile, setLiveFile] = React.useState({});
	const [deletingItem, setDeletingItem] = React.useState(null);
	const [validationErrors, setValidationErrors] = React.useState({});
	const [apiErrors, setApiErrors] = React.useState({});
	const isMounted = useIsMounted();

	const productURL = getPageUrl('product');

	React.useEffect(() => {
		let queries = [getSolutions()];
		if (!isNew) {
			queries = [...queries, getPerson(personId)];
		}
		Promise.all(queries)
			.then(([groups, solutions, item]) => {
				if (!isMounted()) {
					return;
				}

				const formattedItem = mergeNullKeysToDefaults(
					{ ...DEFAULT_ITEM, solutionId },
					item
				);
				setCommittedItem(formattedItem);
				setLiveItem(formattedItem);
				setIsLoading(false);
			})
			.catch(error => {
				if (!isMounted()) {
					return;
				}
				setIsLoading(false);
				setApiErrors({ person: error });
			});
	}, []);

	function onCancel() {
		if (solutionId) {
			history.push(`${productURL}/${solutionId}`);
			return;
		}
		history.push('/admin/persons');
	}

	function onSave() {
		const validItem = getValidItem(liveItem);
		const errors = getValidationErrors(validItem);
		if (!isEmpty(errors)) {
			setValidationErrors(errors);
			return;
		}
		setValidationErrors({});
		setIsSaving(true);
		const action = isNew ? createPerson : updatePerson;
		action(validItem)
			.then(item => {
				if (!isMounted()) {
					return;
				}
				if (!liveFile.data) {
					onFileSaveSuccess(item);
					return;
				} else {
					uploadFile(item.url, liveFile.data)
						.then(() => {
							if (!isMounted()) {
								return;
							}

							onFileSaveSuccess(item);
						})
						.catch(error => {
							if (!isMounted()) {
								return;
							}
							setIsSaving(false);
							setApiErrors({ file: error });
						});
				}
			})
			.catch(error => {
				if (!isMounted()) {
					return;
				}
				setIsSaving(false);
				setApiErrors({ person: error });
			});
	}

	function onDelete() {
		setIsDeleting(true);
		deletePerson(deletingItem)
			.then(() => {
				if (!isMounted()) {
					return;
				}
				setIsDeleting(false);
				if (solutionId) {
					history.push(`${productURL}/${solutionId}`);
					return;
				}
				history.push('/admin/persons');
			})
			.catch(error => {
				if (!isMounted()) {
					return;
				}
				setIsDeleting(false);
				setApiErrors({ person: error });
			});
	}

	function onFileSaveSuccess(item) {
		setCommittedItem(item);
		setLiveItem(item);
		setIsSaving(false);
		if (areaId) {
			history.push(`/admin/areas/${areaId}`);
			return;
		}
		if (solutionId) {
			history.push(`${productURL}/${solutionId}`);
			return;
		}
		if (isNew) {
			history.push(`/admin/persons/${item.id}`);
		}
	}

	function closeDeleteItemDialog() {
		setDeletingItem(null);
	}

	function closeErrorDialog() {
		setApiErrors({});
	}

	if (isLoading) {
		return (
			<Overlay>
				<Loader />
			</Overlay>
		);
	}

	if (!liveItem) {
		return <NoDataOverlay />;
	}

	const mappedOfficeLocations = sortByName(getApOfficeLocations(apOfficeLocations));
	return (
		<form className="admin-person-tab">
			<Header>
				<TitleInput
					theme="admin"
					value={liveItem.name}
					onChange={event => setLiveItem(assoc('name', event.target.value))}
					autoSelect={isNew}
					autoFocus={!isNew}
				/>
				{validationErrors.name && (
					<ValidationError>{validationErrors.name}</ValidationError>
				)}
				<Actions>
					{!isNew && (
						<DangerButton disabled={isDeleting} onClick={() => setDeletingItem(liveItem)}>
							Delete
						</DangerButton>
					)}
					<SecondaryButton style={{ marginLeft: 'auto' }} onClick={onCancel}>
						Cancel
					</SecondaryButton>
					<PrimaryButton
						disabled={equals(liveItem, committedItem) || isSaving}
						onClick={onSave}
					>
						{isNew && (isSaving ? 'Creating…' : 'Create')}
						{!isNew && (isSaving ? 'Updating…' : 'Update')}
					</PrimaryButton>
				</Actions>
			</Header>

			<Content>
				<Fieldset>
					<FieldsetTitle>
						<label htmlFor="person-description">Description</label>
					</FieldsetTitle>
					<Textarea
						id="person-description"
						value={liveItem.description}
						onChange={event => setLiveItem(assoc('description', event.target.value))}
					/>
				</Fieldset>
				<Fieldset>
					<FieldsetTitle>
						<label htmlFor="person-role">Role</label>
					</FieldsetTitle>
					<Input
						id="person-role"
						type="text"
						value={liveItem.role}
						onChange={event => setLiveItem(assoc('role', event.target.value))}
					/>
					{validationErrors.role && (
						<ValidationError>{validationErrors.role}</ValidationError>
					)}
				</Fieldset>
				<Fieldset>
					<FieldsetTitle>
						<label htmlFor="person-location">Location</label>
					</FieldsetTitle>
					<Select
						id="person-location"
						theme="admin"
						options={mappedOfficeLocations}
						label={liveItem.country || 'Select a location'}
						value={liveItem.country}
						onChange={country => {
							setLiveItem(assoc('country', country));
						}}
					/>
					{validationErrors.country && (
						<ValidationError>{validationErrors.country}</ValidationError>
					)}
				</Fieldset>
				<Fieldset>
					<FieldsetTitle>
						<label htmlFor="person-phone-number">Phone number (Ex: +442265404848)</label>
					</FieldsetTitle>
					<Input
						id="person-phone-number"
						type="tel"
						value={liveItem.phoneNumber}
						onChange={event => setLiveItem(assoc('phoneNumber', event.target.value))}
					/>
					{validationErrors.phoneNumber && (
						<ValidationError>{validationErrors.phoneNumber}</ValidationError>
					)}
				</Fieldset>
				<Fieldset>
					<FieldsetTitle>
						<label htmlFor="person-email">Email</label>
					</FieldsetTitle>
					<Input
						id="person-email"
						type="email"
						value={liveItem.email}
						onChange={event => setLiveItem(assoc('email', event.target.value))}
					/>
					{validationErrors.email && (
						<ValidationError>{validationErrors.email}</ValidationError>
					)}
				</Fieldset>
				{liveItem.url && (
					<Fieldset>
						<FieldsetTitle>Current profile image</FieldsetTitle>
						<a
							className="file__link"
							href={liveItem.url}
							target="_blank"
							rel="noopener noreferrer"
						>
							{getFileNameFromUrl(liveItem.url)}
							<SaveIcon size={20} />
						</a>
					</Fieldset>
				)}
				<Fieldset>
					<FieldsetTitle>
						<label htmlFor="file-picker">Upload new profile image</label>
					</FieldsetTitle>
					<input
						type="file"
						id="file-picker"
						name="file-picker"
						accept={WHITELISTED_FILE_TYPES.join(',')}
						onChange={event => {
							const file = event.target.files[0];
							setLiveItem(assoc('fileName', file.name));
							setLiveFile(assoc('data', file));
						}}
					/>
					{validationErrors.fileName && (
						<ValidationError>{validationErrors.fileName}</ValidationError>
					)}
				</Fieldset>
				{!isNew && <ProductSelectList personId={liveItem.id} />}
				{!isNew && <AreaSelectList personId={liveItem.id} />}
			</Content>

			{deletingItem && (
				<ModalContainer onClose={closeDeleteItemDialog}>
					<DeleteItemDialog
						type="person"
						name={deletingItem.name}
						isDeleting={isDeleting}
						onCancel={closeDeleteItemDialog}
						onConfirm={onDelete}
					/>
				</ModalContainer>
			)}

			{!isEmpty(apiErrors) && (
				<ModalContainer onClose={closeErrorDialog}>
					<ErrorDialog
						type="API"
						errors={apiErrors}
						button={
							<SecondaryButton onClick={() => window.location.reload()}>
								Reload
							</SecondaryButton>
						}
						onClose={closeErrorDialog}
					/>
				</ModalContainer>
			)}

			<NavigationPrompt
				disableNative
				when={(currentLocation, nextLocation) =>
					currentLocation.pathname !== nextLocation.pathname &&
					!equals(liveItem, committedItem)
				}
			>
				{({ onConfirm, onCancel }) => (
					<ModalContainer onClose={onCancel}>
						<NavigateAwayDialog onCancel={onCancel} onConfirm={onConfirm} />
					</ModalContainer>
				)}
			</NavigationPrompt>
		</form>
	);
}

Person.propTypes = {
	personId: PropTypes.number,
	areaId: PropTypes.number,
	solutionId: PropTypes.number,
	history: PropTypes.shape({
		push: PropTypes.func.isRequired,
	}).isRequired,
};

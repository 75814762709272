import React from 'react';
import { NavLink, Route, Redirect } from 'react-router-dom';

import { SecondaryButton } from 'components/Button';

import { AppNavbar, AppNavbarSeparator } from 'global/AppNavbar';
import { Areas } from 'areas/Areas';
import { Products } from 'products/Products';
import { Experts } from 'experts/Experts';

import { getPageUrl } from 'utils/urlUtils';

import './ContentEditor.css';

export function ContentEditor({ config, match }) {
	const areaContentEditUrl = getPageUrl('content-editor-areas');
	const productContentEditUrl = getPageUrl('content-editor-products');
	const expertsUrl = getPageUrl('experts-url');
	const expertsBaseUrl = getPageUrl('experts-base-url');

	return (
		<div className="content-editor">
			<AppNavbar
				title="Admin"
				className="areas-list__navbar"
				right={() => (
					<React.Fragment>
						<NavLink to={areaContentEditUrl}>
							<SecondaryButton className="areas-list__navbar-button">
								Areas
							</SecondaryButton>
						</NavLink>
						<NavLink to={productContentEditUrl}>
							<SecondaryButton className="areas-list__navbar-button">
								Products
							</SecondaryButton>
						</NavLink>
						<AppNavbarSeparator className="areas-list__navbar-separator--first" />
						<NavLink to={expertsBaseUrl}>
							<SecondaryButton className="areas-list__navbar-button">
								Experts
							</SecondaryButton>
						</NavLink>
						<AppNavbarSeparator className="areas-list__navbar-separator--last" />
					</React.Fragment>
				)}
			/>
			<div className="content-editor__body">
				<Route
					exact
					path={`${match.path}/`}
					render={() => <Redirect to={productContentEditUrl} />}
				/>
				<Route
					path={areaContentEditUrl}
					render={props => <Areas config={config} {...props} />}
				/>
				<Route
					path={productContentEditUrl}
					render={({ history }) => <Products history={history} />}
				/>
				<Route path={expertsUrl} component={Experts} />
			</div>
		</div>
	);
}

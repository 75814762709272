import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import classNames from 'classnames';

import { PageSectionTitle } from 'components/PageSectionTitle';
import { LinesEllipsis } from 'components/LinesEllipsis';

import { PRODUCT_SECTION_PARAM_KEY } from 'products/Products';
import { EditorAddButton } from 'content-editor/EditorAddButton';
import { INPUT_NAMES, PRODUCT_WHEN_TO_USE } from 'product-editor/constants';

import './ProductWhenToUseSection.css';

export function ProductWhenToUseSection({ url, product, focusedInput, selected }) {
	const { whenToUseInfo } = product;
	const whenToUseExist = !!whenToUseInfo;

	const whenToUseInfoFocused = focusedInput === INPUT_NAMES.whenToUseInfo;

	return (
		<section className="product-when-to-use-section">
			<PageSectionTitle>When to use</PageSectionTitle>
			<Link to={`${url}?${PRODUCT_SECTION_PARAM_KEY}=${PRODUCT_WHEN_TO_USE}`}>
				{!selected && !whenToUseExist ? (
					<EditorAddButton />
				) : (
					<div
						className={classNames('product-when-to-use__container', {
							selected: whenToUseInfoFocused,
							'product-when-to-use__container-no-text': !whenToUseExist,
						})}
					>
						{whenToUseExist ? (
							<LinesEllipsis text={whenToUseInfo} maxLine={2000} />
						) : (
							<span>Enter when to use information</span>
						)}
					</div>
				)}
			</Link>
		</section>
	);
}

ProductWhenToUseSection.propTypes = {
	url: PropTypes.string.isRequired,
	product: PropTypes.object.isRequired,
	focusedInput: PropTypes.string,
	selected: PropTypes.bool.isRequired,
};

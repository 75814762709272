import React, { Fragment } from 'react';
import { NavLink } from 'react-router-dom';
import { ApButtonMain, ApModal } from '@alixpartners/ui-components';

import { externalUrls } from 'utils/urlUtils';
import { MenuDropdown } from 'components/MenuDropdown';
import { AppNavbar, AppNavbarSeparator } from 'global/AppNavbar';
import { ResponsiveMenu } from 'global/ResponsiveMenu';
import { NewEngagement } from 'global/NewEngagement';

import './HomeNavbar.css';

// Later move it into constants
export const BENCHMARKS_URL = '/benchmarks';
export const REGISTERS_URL = '/registers';
export const COMMODITIES_URL = '/ip/commodities';
export const CODELIBRARY_URL = '/codelibrary';
export const KB_URL = '/ip/kb';

const knowledgeBaseItems = [BENCHMARKS_URL, COMMODITIES_URL, REGISTERS_URL, KB_URL];

const externalLinks = [
	{
		url: BENCHMARKS_URL,
		name: 'Benchmarks',
		external: false,
	},
	{
		url: COMMODITIES_URL,
		name: 'Commodities',
		external: false,
	},
	{
		url: REGISTERS_URL,
		name: 'Registers',
	},
	{
		url: CODELIBRARY_URL,
		name: 'Code Library',
	},
	{
		url: KB_URL,
		name: 'Learn more…',
	},
];

const searchAllUrl = '/search';
export const SERVICES_KEY = 'Services';
export const INDUSTRIES_KEY = 'Industries';
export const KNOWLEDGEBASE_KEY = 'Knowledgebase';

export function HomeNavbar({ location, history, areas, config, selectedAreaId }) {
	const [showEngagementModal, setShowEngagementModal] = React.useState(false);

	const byServiceAreas = [];
	const byIndustryAreas = [];
	const areaTypeMap = {};

	// Check if we are on one of knowledge base page
	const currentLocation = location.pathname;
	const isOnKnowledgeBase = knowledgeBaseItems.includes(currentLocation);
	const selectedArea = selectedAreaId
		? areas.find(area => area.id === selectedAreaId)
		: {};

	config &&
		config.areaTypes &&
		config.areaTypes.forEach(item => {
			return (areaTypeMap[item.name] = item.id);
		});

	areas.forEach(item => {
		if (item.typeId === areaTypeMap[SERVICES_KEY]) {
			byServiceAreas.push(item);
		} else if (item.typeId === areaTypeMap[INDUSTRIES_KEY]) {
			byIndustryAreas.push(item);
		}
	});

	const isIndustryArea =
		selectedArea.typeId && selectedArea.typeId === areaTypeMap[INDUSTRIES_KEY];
	const isServicesArea =
		selectedArea.typeId && selectedArea.typeId === areaTypeMap[SERVICES_KEY];
	const isMyEngagementsPage = currentLocation.includes('myEngagements');

	return (
		<AppNavbar
			className="homepage-navbar"
			title="AlixIP"
			right={() => (
				/*Above 960px screens*/
				<Fragment>
					<ul className="homepage-navbar__list">
						<li className="homepage-navbar__item">
							<NavLink className="homepage-navbar__link" to={searchAllUrl}>
								Search all
							</NavLink>
						</li>
						<li className={`homepage-navbar__item ${isServicesArea ? 'active' : ''}`}>
							<MenuDropdown
								defaultLabel="By service"
								options={byServiceAreas}
								onChange={id => {
									const area = areas.find(a => a.id === id);

									area && history.push(`/${area.url}`);
								}}
								hideNoneOption
							/>
						</li>
						<li className={`homepage-navbar__item ${isIndustryArea ? 'active' : ''}`}>
							<MenuDropdown
								defaultLabel="By industry"
								options={byIndustryAreas}
								trigger="hover"
								onChange={id => {
									const area = areas.find(a => a.id === id);

									area && history.push(`/${area.url}`);
								}}
								hideNoneOption
							/>
						</li>
						<li className={`homepage-navbar__item ${isOnKnowledgeBase ? 'active' : ''}`}>
							<MenuDropdown
								defaultLabel="Knowledge Base"
								options={externalLinks.map((link, index) => ({ id: index, ...link }))}
								trigger="hover"
								onChange={id => {
									const { url, external } = externalLinks[id] || {};
									if (external) {
										window.open(url);
									} else {
										history.push(url);
									}
								}}
								hideNoneOption
							/>
						</li>
						<li className={'homepage-navbar__item companies'}>
							<a
								href={externalUrls['companies']}
								className="home-page-container__services-block-button"
							>
								Companies
							</a>
						</li>
						<li className="homepage-navbar__item">
							<AppNavbarSeparator />
						</li>
						<li
							className={`homepage-navbar__item my-engagements ${
								isMyEngagementsPage ? 'active' : ''
							}`}
						>
							<NavLink
								to="/myEngagements"
								className="home-page-container__services-block-button"
							>
								My Engagements
							</NavLink>
						</li>
						<li className="homepage-navbar__item">
							<ApButtonMain
								onClick={() => {
									setShowEngagementModal(true);
								}}
							>
								New Engagement
							</ApButtonMain>
						</li>
					</ul>
					<ResponsiveMenu
						triggerNewEngagmentModal={() => {
							setShowEngagementModal(true);
						}}
						history={history}
						searchAllUrl={searchAllUrl}
						services={byServiceAreas}
						industries={byIndustryAreas}
						knowledgeBaseLinks={externalLinks}
					/>
					<ApModal
						isOpen={showEngagementModal}
						isDismissible
						header="New engagement"
						hasClosed={() => {
							setShowEngagementModal(false);
						}}
					>
						<NewEngagement />
					</ApModal>
				</Fragment>
			)}
		/>
	);
}

import React from 'react';
import PropTypes from 'prop-types';

import './AuthenticationError.css';

export const AuthenticationError = ({ errorType, errorDescription, onClick }) => (
	<div className="auth-error__wrapper">
		<p className="auth-error__title">Oh!</p>
		<h2 className="auth-error--type">{errorType}</h2>
		<div className="auth-error__description">{errorDescription}</div>
		<button type="button" className="auth-error__try-again-button" onClick={onClick}>
			Try Again
		</button>
	</div>
);

AuthenticationError.propTypes = {
	errorType: PropTypes.string.isRequired,
	errorDescription: PropTypes.string.isRequired,
	onClick: PropTypes.func.isRequired,
};

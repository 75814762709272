import React from 'react';

const Icon = ({ size, ...otherProps }) => {
	const props = {
		width: size,
		height: size,
		fill: 'currentcolor',
		...otherProps,
	};
	return (
		<svg viewBox="0 0 40 40" {...props}>
			<path
				d="M40 6.038V27.17h-1.455V6.038H24v-1.51h16v1.51zM24.078 28.88v-2.998h9.377v-1.999h-9.377V7.637h13.74v25.57h-13.74V30.88h9.377V28.88h-9.377zm5.337-16.615h-.066c-2.008 0-3.636 1.689-3.636 3.773s1.628 3.773 3.636 3.773c1.95 0 3.54-1.591 3.632-3.59h-3.566v-3.956zm2.633.353c-.841-.69-2.27-.834-2.27-.834v4.008h3.563s.034-.867-.221-1.642c-.199-.604-.739-1.259-1.072-1.532zM23.273 0v40L0 35.76V4.07L23.273 0zM7.515 11.33v13.096h1.587v-4.674h3.2c1.193 0 2.173-.392 2.94-1.175.767-.784 1.15-1.786 1.15-3.008 0-1.247-.377-2.265-1.133-3.054-.755-.79-1.735-1.184-2.94-1.184H7.515zm1.587 1.453h2.803c.923 0 1.63.238 2.12.716.491.478.736 1.168.736 2.07 0 .865-.25 1.536-.749 2.014-.5.478-1.202.717-2.107.717H9.102v-5.517z"
				fill="#E68163"
				fillRule="nonzero"
			/>
		</svg>
	);
};

export const PptIcon = Icon;

PptIcon.defaultProps = {
	size: 24,
};

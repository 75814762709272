import React from 'react';
import PropTypes from 'prop-types';

import { Label } from 'components/Label';
import { LabelWithCount } from 'components/LabelWithCount';
import { Input } from 'components/Input';
import { Toggle } from 'components/Toggle';
import { PageErrorDialog } from 'components/PageErrorDialog';
import { Textarea } from 'components/Textarea';
import { Media } from 'components/Media';
import { SECTION_PARAM_KEY, ITEM_PARAM_KEY } from 'areas/Areas';
import { AREA_TOOLS_PRODUCTS } from 'area-editor/constants';
import { SearchableSelect } from 'components/SearchableSelect';
import {
	SOLUTION_DESCRIPTION_LENGTH,
	SOLUTION_INTRO_TEXT_LENGTH,
	SOLUTION_WHEN_TO_USE_LENGTH,
} from 'admin/tabs/Solution';

import {
	EditorPageFieldset,
	EditorPageSeparator,
	EditorPageSectionTitle,
	EditorSectionInfoLabel,
} from 'content-editor/EditorPageLayout';

import './EditAreaToolsSection.css';

export function EditAreaToolsSection({
	selectedProductId,
	areaProducts,
	onProductsChange,
	url,
	history,
	products,
}) {
	const [apiErrors, setApiErrors] = React.useState({});
	const [useOriginalToolDescription, setUseOriginalToolDescription] = React.useState(
		true
	);
	const closeErrorDialog = () => setApiErrors({});
	const editingProduct = areaProducts.find(
		areaProduct => areaProduct.id === selectedProductId
	);

	const options = products
		.filter(
			product =>
				selectedProductId === product.id ||
				!areaProducts.find(areaProduct => areaProduct.id === product.id)
		)
		.map(({ id, name }) => ({ value: id, label: name }));

	const handleChange = ({ value: id }) => {
		if (!id) {
			return;
		}

		const newProduct = products.find(product => product.id === id);
		const newProducts = [...areaProducts];

		if (id !== selectedProductId) {
			newProducts.push(newProduct);
			onProductsChange('add', id, newProducts);
			const newProductUrl = `${url}?${SECTION_PARAM_KEY}=${AREA_TOOLS_PRODUCTS}&${ITEM_PARAM_KEY}=${id}`;
			history.push(newProductUrl);
		}
	};

	const selectedProductOption = selectedProductId
		? [options.find(({ value }) => value === selectedProductId)]
		: [];

	return (
		<section className="edit-area-tools-section">
			<EditorPageSectionTitle title="Edit Products" />
			<EditorPageSeparator />
			<EditorPageFieldset>
				<Label htmlFor="areas-select" value="Select a product" />
				<SearchableSelect
					id="areas-select"
					className="areas-select"
					// TODO: cleanup this when SearchableSelect bug with displaing placeholder text is fixed
					customPlaceHolderText={selectedProductOption ? '' : 'Select a product'}
					options={options}
					isMulti={false}
					selectedValues={selectedProductOption}
					onChange={handleChange}
					sortBy="label"
				/>
			</EditorPageFieldset>
			<EditorPageSeparator theme="dark" />
			{editingProduct && (
				<React.Fragment>
					<EditorSectionInfoLabel>
						Information below can not be edited.
					</EditorSectionInfoLabel>
					<EditorPageFieldset className="edit-area-tools-section__readonly-field">
						<Toggle
							id="readonly-info"
							label="Use original tool/product discriptions"
							checked={useOriginalToolDescription}
							onChange={setUseOriginalToolDescription}
							disabled
						/>
					</EditorPageFieldset>
					<EditorPageFieldset>
						<Label htmlFor="product-version" value="version" />
						<Input
							id="product-version"
							name="version"
							type="text"
							autoFocus
							value={editingProduct.version || ''}
							disabled={useOriginalToolDescription}
						/>
					</EditorPageFieldset>
					<EditorPageFieldset>
						<Label htmlFor="product-last-update" value="last-update" />
						<Input
							id="product-last-update"
							name="last-update"
							type="text"
							autoFocus
							value={editingProduct.lastUpdatedDate || ''}
							disabled={useOriginalToolDescription}
						/>
					</EditorPageFieldset>
					<EditorPageSeparator theme="dark" />
					<EditorPageFieldset>
						<LabelWithCount
							htmlFor="product-description"
							value="About"
							count={`${
								editingProduct.description ? editingProduct.description.length : 0
							} / ${SOLUTION_DESCRIPTION_LENGTH}`}
						/>
						<Textarea
							id="product-description"
							name="description"
							maxLength={SOLUTION_DESCRIPTION_LENGTH}
							value={editingProduct.description || ''}
							disabled={useOriginalToolDescription}
						/>
					</EditorPageFieldset>
					<EditorPageFieldset>
						<LabelWithCount
							htmlFor="product-intro-text"
							value="Intro text"
							count={`${
								editingProduct.introText ? editingProduct.introText.length : 0
							} / ${SOLUTION_INTRO_TEXT_LENGTH}`}
						/>
						<Textarea
							id="product-intro-text"
							name="introText"
							maxLength={SOLUTION_INTRO_TEXT_LENGTH}
							value={editingProduct.introText || ''}
							disabled={useOriginalToolDescription}
						/>
					</EditorPageFieldset>
					<EditorPageFieldset>
						<LabelWithCount
							htmlFor="product-when-to-use"
							value="When to use"
							count={`${
								editingProduct.whenToUseInfo ? editingProduct.whenToUseInfo.length : 0
							} / ${SOLUTION_WHEN_TO_USE_LENGTH}`}
						/>
						<Textarea
							id="product-when-to-use"
							name="whenToUseInfo"
							maxLength={SOLUTION_WHEN_TO_USE_LENGTH}
							value={editingProduct.whenToUseInfo || ''}
							disabled={useOriginalToolDescription}
						/>
					</EditorPageFieldset>
					<EditorPageFieldset>
						<Label value="Product or Tool image" />
						{editingProduct.heroFeatureUrl ? (
							<div className="edit-area-tools-section__product-media">
								<Media
									extension={editingProduct.extension}
									url={editingProduct.heroFeatureUrl}
									previewImageUrl={editingProduct.previewImageUrl}
									alt="hero feature image"
									width="auto"
									height="auto"
								/>
							</div>
						) : (
							<div className="edit-area-tools-section__product-media-upload">
								Upload new image
							</div>
						)}
					</EditorPageFieldset>
					<EditorPageSeparator theme="dark" />
				</React.Fragment>
			)}
			<PageErrorDialog apiErrors={apiErrors} onClose={closeErrorDialog} />
		</section>
	);
}

EditAreaToolsSection.propTypes = {
	area: PropTypes.object.isRequired,
	selectedProductId: PropTypes.number,
	selectedGroupId: PropTypes.number,
	url: PropTypes.string.isRequired,
	areaProducts: PropTypes.array,
	onProductsChange: PropTypes.func,
	onGroupChange: PropTypes.func,
};

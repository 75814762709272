import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import './Textarea.css';

export function Textarea({ className, autoFocus, ...props }) {
	return (
		<textarea
			autoFocus={autoFocus}
			className={classNames('textarea', className)}
			{...props}
		/>
	);
}

Textarea.propTypes = {
	className: PropTypes.string,
	autoFocus: PropTypes.bool,
};

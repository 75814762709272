import React from 'react';
import PropTypes from 'prop-types';

import { AppTooltip } from 'components/AppTooltip';
import { Settings as SettingsIcon } from 'components/icons/Settings';
import { AuthenticationContextConsumer } from 'contexts/AuthenticationContext';

import './SettingsDropdown.css';

export function SettingsDropdown({ theme, onContentEditorClick }) {
	return (
		<AuthenticationContextConsumer>
			{({ logout }) => (
				<AppTooltip
					className={theme}
					theme="settings-tooltip"
					html={
						<ul className="settings-dropdown__list">
							<li className="settings-dropdown__list-item">
								<button
									className="settings-dropdown__list-button"
									title="Content Editor"
									onClick={onContentEditorClick}
								>
									Content Editor
								</button>
							</li>
							<li className="settings-dropdown__list-item">
								<button
									className="settings-dropdown__list-button"
									title="Logout"
									onClick={logout}
								>
									Log out
								</button>
							</li>
						</ul>
					}
				>
					<SettingsIcon size={24} />
				</AppTooltip>
			)}
		</AuthenticationContextConsumer>
	);
}

SettingsDropdown.propTypes = {
	theme: PropTypes.string,
	onContentEditorClick: PropTypes.func.isRequired,
};

SettingsDropdown.defaultProps = {
	theme: 'dark-nav',
};

import React from 'react';

const Icon = ({ size, ...otherProps }) => {
	const props = {
		width: size,
		height: size,
		fill: 'currentcolor',
		...otherProps,
	};
	return (
		<svg viewBox="0 0 40 40" {...props}>
			<path
				fill="#6BC7B6"
				d="M2 8.997C2 7.894 2.89 7 4 7h32c1.105 0 2 .895 2 1.997v22.006C38 32.106 37.11 33 36 33H4c-1.105 0-2-.895-2-1.997V8.997zM11 17a3 3 0 1 0 0-6 3 3 0 0 0 0 6zm3.442 3.692L6 28.995h28l-9.314-12-6.519 7.39-3.725-3.693z"
			/>
		</svg>
	);
};

export const ImageIcon = Icon;

ImageIcon.defaultProps = {
	size: 24,
};

import React from 'react';

const Icon = ({ size, ...otherProps }) => {
	const props = {
		width: size,
		height: size,
		fill: 'currentcolor',
		...otherProps,
	};
	return (
		<svg viewBox="0 0 24 24" {...props}>
			<path fill="none" d="M0 0h24v24H0z" />
			<path d="M20 9H4v2h16V9zM4 15h16v-2H4v2z" />
		</svg>
	);
};

export const DragHandle = Icon;

DragHandle.defaultProps = {
	size: 24,
};

import React from 'react';
import classNames from 'classnames';

import './BreadCrumb.css';

export function BreadCrumbList({ className, children }) {
	return <ul className={classNames('breadcrumb__list', className)}>{children}</ul>;
}

export function BreadCrumbListItem({ className, children }) {
	return <li className={classNames('breadcrumb__list-item', className)}>{children}</li>;
}

import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { IconButton } from 'components/Button';
import { AddIcon, CloseIcon } from 'components/icons';

import { INPUT_NAMES } from 'area-editor/constants';

import './AreaStatsEditor.css';

export function AreaStatsEditor({ stats, focusedInput, onEditArea }) {
	const showAddStat = stats.length < 3;
	function deleteStat(index) {
		const newStats = [...stats];
		newStats.splice(index, 1);
		onEditArea({ target: { name: INPUT_NAMES.stats, value: newStats } });
	}

	function addStat() {
		const newStats = [...stats];
		newStats.push({
			label: '',
			value: '',
		});
		onEditArea({ target: { name: INPUT_NAMES.stats, value: newStats } });
	}

	return (
		<div className="area-stats-editor">
			{stats.map((stat, index) => {
				const { label, value } = stat;
				const noStat = !label && !value;

				return (
					<div
						key={`stat${index}`}
						className={classNames('area-stats-editor__stat', {
							'area-stats-editor__no-stat': noStat,
							selected: `${INPUT_NAMES.stats}${index + 1}` === focusedInput,
						})}
					>
						<IconButton
							type="button"
							className="area-stats-editor__delete-stat"
							onClick={event => deleteStat(index)}
						>
							<CloseIcon />
						</IconButton>
						{noStat ? (
							<span>{`Stat ${index + 1}`}</span>
						) : (
							<Fragment>
								<label className="area-stats-editor__stat-label">{stat.label}</label>
								<label className="area-stats-editor__stat-value">{stat.value}</label>
							</Fragment>
						)}
					</div>
				);
			})}
			{showAddStat && (
				<IconButton
					type="button"
					className="area-stats-editor__add-stat"
					onClick={addStat}
				>
					<AddIcon size={36} />
				</IconButton>
			)}
		</div>
	);
}

AreaStatsEditor.propTypes = {
	stats: PropTypes.array.isRequired,
	focusedInput: PropTypes.string,
	onEditArea: PropTypes.func.isRequired,
};

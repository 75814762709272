import React from 'react';
import PropTypes from 'prop-types';

import { PageSectionTitle } from 'components/PageSectionTitle';
import { GridListItems } from 'components/GridListItems';
import { MaxWidthLayout } from 'components/MaxWidthLayout';
import { FileItem, FileItemPropTypes } from './FileItem';

import './Attachments.css';

export function Attachments({ files, setApiErrors }) {
	return (
		<section className="attachments__section">
			<MaxWidthLayout>
				<PageSectionTitle>Attachments</PageSectionTitle>
				<GridListItems
					items={files}
					renderItem={item => (
						<FileItem {...item} onError={error => setApiErrors({ file: error })} />
					)}
				/>
			</MaxWidthLayout>
		</section>
	);
}

Attachments.propTypes = {
	files: PropTypes.arrayOf(PropTypes.shape(FileItemPropTypes).isRequired).isRequired,
	setApiErrors: PropTypes.func.isRequired,
};

import React from 'react';
import { Switch, Route, Redirect, NavLink } from 'react-router-dom';

import { AppNavbar } from 'global/AppNavbar';
import { List } from 'admin/tabs/List';
import { Area } from 'admin/tabs/Area';
import { Solution } from 'admin/tabs/Solution';
import { File } from 'admin/tabs/File';
import { Person } from 'admin/tabs/Person';
import { Feature } from 'admin/tabs/Feature';
import {
	getSolutions,
	getFiles,
	getPersons,
	getAreas,
	getFeatures,
} from 'utils/apiUtils';
import { getNumberFromString } from 'utils/parseUtils';
import { getValueFromQueryString, getAppPrimaryURL, getPageUrl } from 'utils/urlUtils';
import { useWindowSize } from 'hooks/useWindowSize';

import './Admin.css';

const SOLUTION_ID_PROP = 'solutionId';
const AREA_ID_PROP = 'areaId';

export function Admin() {
	const windowSize = useWindowSize();
	const homeURL = getAppPrimaryURL();
	const productURL = getPageUrl('product');

	return (
		<div className="admin" style={{ minHeight: windowSize.height }}>
			<AppNavbar
				className="admin__navbar"
				right={() => (
					<ul className="admin__navbar">
						<li className="admin__navbar-item">
							<NavLink className="admin__navbar-link" to={homeURL}>
								Engagement Platform
							</NavLink>
							<NavLink className="admin__navbar-link" to="/admin">
								Admin
							</NavLink>
						</li>
					</ul>
				)}
			/>
			<main className="admin__main">
				<div className="admin__subnav-panel">
					<nav className="admin__subnav">
						<ul>
							<li>
								<NavLink
									className="admin__subnav-link"
									to="/admin/areas"
									isActive={(match, location) =>
										match ? true : location.pathname === '/admin/new-area'
									}
								>
									Areas
								</NavLink>
							</li>
							<li>
								<NavLink
									className="admin__subnav-link"
									to={productURL}
									isActive={(match, location) =>
										match ? true : location.pathname === '/admin/new-product'
									}
								>
									Products
								</NavLink>
							</li>
							<li>
								<NavLink
									className="admin__subnav-link admin__subnav-link--sub"
									to="/admin/features"
									isActive={(match, location) =>
										match ? true : location.pathname === '/admin/new-feature'
									}
								>
									Features
								</NavLink>
							</li>
							<li>
								<NavLink
									className="admin__subnav-link admin__subnav-link--sub"
									to="/admin/files"
									isActive={(match, location) =>
										match ? true : location.pathname === '/admin/new-file'
									}
								>
									Files
								</NavLink>
							</li>
							<li>
								<NavLink
									className="admin__subnav-link admin__subnav-link--sub"
									to="/admin/persons"
									isActive={(match, location) =>
										match ? true : location.pathname === '/admin/new-person'
									}
								>
									Subject Matter Experts
								</NavLink>
							</li>
						</ul>
					</nav>
				</div>
				<div className="admin__tab-panel">
					<Switch>
						{/* Areas */}
						<Route
							path="/admin/areas"
							exact
							render={({ history }) => (
								<List
									key="areas"
									history={history}
									type="area"
									name="Areas"
									getItems={getAreas}
								/>
							)}
						/>
						<Route
							path="/admin/areas/:areaId"
							render={({ history, match }) => (
								<Area
									history={history}
									areaId={getNumberFromString(match.params.areaId)}
								/>
							)}
						/>
						<Route
							path="/admin/new-area"
							render={({ history }) => <Area history={history} />}
						/>
						{/* Solutions */}
						<Route
							path={productURL}
							exact
							render={({ history }) => (
								<List
									key="solutions"
									history={history}
									type="product"
									parentKey="groupIds"
									name="Products / Tools"
									getItems={getSolutions}
								/>
							)}
						/>
						<Route
							path={`${productURL}/:productId`}
							render={({ history, match }) => (
								<Solution
									history={history}
									solutionId={getNumberFromString(match.params.productId)}
								/>
							)}
						/>
						<Route
							path="/admin/new-product"
							render={({ history }) => <Solution history={history} />}
						/>
						{/* Features */}
						<Route
							path="/admin/features"
							exact
							render={({ history }) => (
								<List
									key="features"
									history={history}
									type="feature"
									parentKey="solutionId"
									name="Features"
									getItems={getFeatures}
									sectionKey="solutionId"
									getSections={getSolutions}
								/>
							)}
						/>
						<Route
							path="/admin/features/:featureId"
							render={({ history, location, match }) => (
								<Feature
									history={history}
									featureId={getNumberFromString(match.params.featureId)}
									solutionId={getValueFromQueryString(location, SOLUTION_ID_PROP)}
								/>
							)}
						/>
						<Route
							path="/admin/new-feature"
							render={({ history, location }) => (
								<Feature
									history={history}
									solutionId={getValueFromQueryString(location, SOLUTION_ID_PROP)}
								/>
							)}
						/>
						{/* Files */}
						<Route
							path="/admin/files"
							exact
							render={({ history }) => (
								<List
									key="files"
									history={history}
									type="file"
									parentKey="solutionId"
									name="Files"
									getItems={getFiles}
									sectionKey="solutionId"
									getSections={getSolutions}
								/>
							)}
						/>
						<Route
							path="/admin/files/:fileId"
							render={({ history, location, match }) => (
								<File
									history={history}
									fileId={getNumberFromString(match.params.fileId)}
									solutionId={getValueFromQueryString(location, SOLUTION_ID_PROP)}
								/>
							)}
						/>
						<Route
							path="/admin/new-file"
							render={({ history, location }) => (
								<File
									history={history}
									solutionId={getValueFromQueryString(location, SOLUTION_ID_PROP)}
								/>
							)}
						/>
						{/* Persons */}
						<Route
							path="/admin/persons"
							exact
							render={({ history }) => (
								<List
									key="persons"
									history={history}
									type="person"
									name="Subject Matter Experts"
									getItems={getPersons}
								/>
							)}
						/>
						<Route
							path="/admin/persons/:personId"
							render={({ history, location, match }) => (
								<Person
									history={history}
									personId={getNumberFromString(match.params.personId)}
									solutionId={getValueFromQueryString(location, SOLUTION_ID_PROP)}
									areaId={getValueFromQueryString(location, AREA_ID_PROP)}
								/>
							)}
						/>
						<Route
							path="/admin/new-person"
							render={({ history, location }) => (
								<Person
									history={history}
									solutionId={getValueFromQueryString(location, SOLUTION_ID_PROP)}
									areaId={getValueFromQueryString(location, AREA_ID_PROP)}
								/>
							)}
						/>
						{/* Redirect */}
						<Route>
							<Redirect to="/admin/areas" />
						</Route>
					</Switch>
				</div>
			</main>
		</div>
	);
}

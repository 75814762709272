import React from 'react';
import PropTypes from 'prop-types';
import { useEscKeydown } from 'hooks/useEscKeydown';
import { Portal } from 'components/Portal';

import './ModalContainer.css';

export function ModalContainer({ children, overlayOpacity, onClose }) {
	React.useEffect(() => {
		document.body.classList.add('no-scroll');
		return () => {
			document.body.classList.remove('no-scroll');
		};
	}, []);
	useEscKeydown(onClose);

	return (
		<Portal>
			<div className="modal">
				<div
					className="modal__overlay"
					style={{
						backgroundColor: `rgba(0, 0, 0, ${overlayOpacity})`,
					}}
					onClick={onClose}
				/>
				<div className="modal__wrapper">{children}</div>
			</div>
		</Portal>
	);
}

ModalContainer.propTypes = {
	children: PropTypes.node.isRequired,
	overlayOpacity: PropTypes.number,
	onClose: PropTypes.func,
};

ModalContainer.defaultProps = {
	overlayOpacity: 0.3,
};

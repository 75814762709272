import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import { PageSectionTitle } from 'components/PageSectionTitle';

import { SECTION_PARAM_KEY } from 'areas/Areas';
import { EditorAddButton } from 'content-editor/EditorAddButton';
import { AreaExpertsGrid } from 'area-editor/AreaExpertsGrid';
import { INPUT_NAMES, AREA_TEAM } from 'area-editor/constants';

import './AreaTeamSection.css';

export function AreaTeamSection({
	url,
	areaId,
	areaExperts,
	areaPersonHash,
	focusedInput,
}) {
	return (
		<section className="area-team-section">
			<PageSectionTitle>Coordinators</PageSectionTitle>
			<Link to={`${url}?${SECTION_PARAM_KEY}=${AREA_TEAM}`}>
				<EditorAddButton selected={focusedInput === INPUT_NAMES.searchExperts} />
			</Link>
			<AreaExpertsGrid experts={areaExperts} />
		</section>
	);
}

AreaTeamSection.propTypes = {
	url: PropTypes.string.isRequired,
	areaId: PropTypes.number.isRequired,
	areaExperts: PropTypes.arrayOf(
		PropTypes.shape({
			id: PropTypes.number.isRequired,
			name: PropTypes.string.isRequired,
			order: PropTypes.number.isRequired,
		})
	).isRequired,
	areaPersonHash: PropTypes.object.isRequired,
	focusedInput: PropTypes.string,
};

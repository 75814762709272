import React from 'react';

const Icon = ({ size, ...otherProps }) => {
	const props = {
		width: size,
		height: size,
		fill: 'currentcolor',
		...otherProps,
	};
	return (
		<svg viewBox="0 0 40 40" {...props}>
			<path
				d="M24.08 32.422h5.77v-4.13h-5.77v-1.24h5.77v-4.13h-5.77v-1.239h5.77v-4.13h-5.77v-1.239h5.77v-4.13h-5.77v-1.24h5.77v-4.13h-5.77V4.66H40v30.77H24.08v-3.008zM23.087 0v40L0 35.76V4.07L23.087 0zM6.4 24.81h1.777l3.353-5.008h.14l3.292 5.007h1.882l-4.26-6.319 4.355-6.38h-1.785l-3.362 5.051h-.14l-3.31-5.052h-1.89l4.25 6.31-.435.648a10259.232 10259.232 0 0 1-3.432 5.096l-.435.646zM31.045 6.814v4.13h6.567v-4.13h-6.567zm0 5.369v4.13h6.567v-4.13h-6.567zm0 5.37v4.13h6.567v-4.13h-6.567zm0 5.368v4.13h6.567v-4.13h-6.567zm0 5.37v4.13h6.567v-4.13h-6.567z"
				fill="#76B75B"
				fillRule="nonzero"
			/>
		</svg>
	);
};

export const ExcelIcon = Icon;

ExcelIcon.defaultProps = {
	size: 24,
};

import React from 'react';

import { IconButton } from 'components/Button';
import { CloseIcon, SearchIcon } from 'components/icons';

import './SearchInput.css';

export function SearchInput({ className, value, onChange, ...props }) {
	const handleChange = e => onChange(e.target.value);
	const handleClear = () => onChange('');
	const isEmpty = !value;

	return (
		<div className={`search-input ${className}`}>
			<input
				value={value}
				className="search-input__input"
				type="text"
				onChange={handleChange}
				{...props}
			/>
			{isEmpty ? (
				<SearchIcon className="search-input__search-icon" />
			) : (
				<IconButton
					className="search-input__close-button"
					onClick={handleClear}
					type="reset"
				>
					<CloseIcon />
				</IconButton>
			)}
		</div>
	);
}

import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import ClickOutside from 'react-click-outside';
import { getStringFromNumber, getNumberFromString } from 'utils/parseUtils';
import { ArrowDropDown } from 'components/icons/ArrowDropDown';

import './Dropdown.css';

function Option({ className, option, onChange }) {
	return (
		<li className={classNames('dropdown__option', className)}>
			<button
				type="button"
				className="dropdown__option-button"
				value={getStringFromNumber(option.id)}
				onClick={event => {
					onChange(getNumberFromString(event.target.value));
				}}
			>
				{option.name}
			</button>
		</li>
	);
}

export function Dropdown({
	defaultLabel,
	headerScale,
	options,
	selectedId,
	onChange,
	hideNoneOption,
}) {
	const [isOpen, setIsOpen] = React.useState(false);
	const selectedItem = (options || []).find(item => item.id === selectedId);
	const selectLabel = selectedItem ? selectedItem.name : defaultLabel;

	function handleOnChange(data) {
		setIsOpen(false);
		onChange(data);
	}

	return (
		<div className="dropdown">
			<div
				className="dropdown__label-row"
				onClick={() => {
					setIsOpen(true);
				}}
			>
				<span className="dropdown__label">{selectLabel}</span>
				<span className="dropdown__icon">
					<ArrowDropDown size={20} />
				</span>
			</div>
			<ClickOutside
				onClickOutside={() => {
					setIsOpen(false);
				}}
			>
				<div
					className={classNames('dropdown__options-container', {
						'dropdown__options-container--open': isOpen,
					})}
					style={{ transform: `scale(${1 / headerScale})` }}
				>
					{isOpen && (
						<ul>
							{!hideNoneOption && (
								<Option
									className="dropdown__option--none"
									option={{ name: 'None', value: '' }}
									onChange={handleOnChange}
								/>
							)}
							{options.length ? (
								options.map(option => (
									<Option key={option.id} option={option} onChange={handleOnChange} />
								))
							) : (
								<div style={{ textAlign: `center` }}>No items</div>
							)}
						</ul>
					)}
				</div>
			</ClickOutside>
		</div>
	);
}

Dropdown.propTypes = {
	defaultLabel: PropTypes.string.isRequired,
	headerScale: PropTypes.number,
	options: PropTypes.arrayOf(
		PropTypes.shape({
			id: PropTypes.number.isRequired,
			name: PropTypes.string.isRequired,
		})
	).isRequired,
	selectedId: PropTypes.number,
	onChange: PropTypes.func.isRequired,
	hideNoneOption: PropTypes.bool,
};

Dropdown.defaultProps = {
	headerScale: 1,
	selectedId: null,
	hideNoneOption: false,
};

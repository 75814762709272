export function sortByName(items, nameKey = 'name', direction = 1) {
	return [...items].sort((a, b) => {
		if (a[nameKey].toLowerCase() < b[nameKey].toLowerCase()) {
			return -1 * direction;
		}
		if (a[nameKey].toLowerCase() > b[nameKey].toLowerCase()) {
			return 1 * direction;
		}
		return 0;
	});
}

export function sortItemsByProp(items, prop) {
	if (prop === 'name') {
		return sortByName(items);
	}
	if (prop === 'order') {
		return sortByOrder(items);
	}
	if (prop === 'name_desc') {
		return sortByName(items, 'name', -1);
	}
	return [...items].sort((a, b) => a[prop] - b[prop]);
}

export function sortByStagesCount(items) {
	return [...items].sort((a, b) => b.stageIds.length - a.stageIds.length);
}

export function handleOnDrop(droppableId, result, items) {
	const { source, destination, reason } = result;
	return new Promise((resolve, reject) => {
		// dropped outside the list
		if (!destination) {
			reject(items);
			return;
		}

		const isRecognisedDropEvent = reason === 'DROP';
		const isWithinDesiredDropZone = destination.droppableId === droppableId;
		if (isWithinDesiredDropZone && isRecognisedDropEvent) {
			const newItems = [...items];
			const [removed] = newItems.splice(source.index, 1);
			newItems.splice(destination.index, 0, removed);
			resolve({ newItems, id: removed.id, index: destination.index });
		}
	});
}

export function sortByOrder(items) {
	return [...items].sort((a, b) => {
		return a.order - b.order;
	});
}

import React from 'react';

const Icon = ({ size, ...otherProps }) => {
	const props = {
		width: size,
		height: size,
		fill: 'currentcolor',
		...otherProps,
	};
	return (
		<svg viewBox="0 0 24 24" {...props}>
			<path fill="none" d="M0 0h24v24H0V0z" />
			<path d="M12 8l-6 6 1.41 1.41L12 10.83l4.59 4.58L18 14l-6-6z" />
		</svg>
	);
};

export const ExpandLess = Icon;

ExpandLess.defaultProps = {
	size: 24,
};

import React from 'react';

export function useScrollIntoView(isActive) {
	const inputRef = React.useRef();
	React.useEffect(() => {
		isActive && inputRef.current && inputRef.current.scrollIntoView();
	}, []);

	return inputRef;
}

import React from 'react';
import PropTypes from 'prop-types';
import Dropzone from 'react-dropzone';
import VideoPlayer from 'react-player';

import { DriveFileIcon, OndemandVideoIcon, CloseIcon } from 'components/icons';
import { Loader } from 'components/Loader';
import { ModalContainer } from 'components/ModalContainer';
import { Dialog } from 'components/Dialog';

import './EditProductFeaturesUpload.css';
import { IconButton } from 'components/Button';

export function EditProductFeaturesUpload({
	selectedFileName,
	videoFileUrl,
	label,
	maxSize,
	onChange,
	accept,
}) {
	// TODO: use this to display uploading progress, after 'Update/Cancel' buttons are removed
	const uploading = false;
	const [isVideoDialogVisible, setVideoDialogVisible] = React.useState(false);

	return (
		<React.Fragment>
			<div className="edit-product-features-upload__label-container">
				<label className="edit-product-features-upload__label-text">{label}</label>
				<span className="edit-product-features-upload__label-max-size">{`Max file size: ${maxSize} Mb`}</span>
			</div>
			{videoFileUrl && isVideoDialogVisible && (
				<ModalContainer onClose={setVideoDialogVisible}>
					<Dialog className="edit-product-features-upload__video-dialog">
						<IconButton onClick={() => setVideoDialogVisible(false)}>
							<CloseIcon size={24} />
						</IconButton>
						<div>
							<VideoPlayer
								url={videoFileUrl}
								alt="preview"
								width="auto"
								height="80vh"
								controls
								playing
							/>
						</div>
					</Dialog>
				</ModalContainer>
			)}
			<Dropzone
				onDrop={onChange}
				style={{}}
				accept={accept}
				maxSize={maxSize * 1024 * 1024}
			>
				{({ getRootProps, getInputProps }) => (
					<div {...getRootProps()}>
						<input {...getInputProps()} />
						{selectedFileName && (
							<div className="edit-product-features-upload">
								<div className="edit-product-features-upload__loader">
									<OndemandVideoIcon size={32} />
								</div>
								<span className="edit-product-features-upload__file-text">
									{selectedFileName}
								</span>
								<div className="edit-product-features-upload__action-links">
									<span className="edit-product-features-upload__upload-text">
										Select new file
									</span>
									<IconButton
										className="edit-product-features-upload__upload-text"
										onClick={e => {
											setVideoDialogVisible(true);
											e.stopPropagation();
											e.preventDefault();
										}}
									>
										Preview video
									</IconButton>
								</div>
							</div>
						)}
						{!selectedFileName && uploading && (
							<div className="edit-product-features-upload">
								<div className="edit-product-features-upload__loader">
									<Loader />
								</div>
								<span className="edit-product-features-upload__file-text">
									File is loading...
								</span>
							</div>
						)}
						{!selectedFileName && !uploading && (
							<div className="edit-product-features-upload">
								<DriveFileIcon size={32} />
								<div className="edit-product-features-upload__label">
									Drag media file here to upload
								</div>
								<span className="edit-product-features-upload__upload-text">Upload</span>
							</div>
						)}
					</div>
				)}
			</Dropzone>
		</React.Fragment>
	);
}

EditProductFeaturesUpload.propTypes = {
	selectedFileName: PropTypes.string,
	label: PropTypes.string.isRequired,
	maxSize: PropTypes.number.isRequired,
	onChange: PropTypes.func.isRequired,
	accept: PropTypes.string.isRequired,
};

EditProductFeaturesUpload.defaultProps = {
	selectedFileName: null,
};

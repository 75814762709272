import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import NavigationPrompt from 'react-router-navigation-prompt';
import { assoc, equals, isEmpty } from 'ramda';
import { DangerButton, PrimaryButton, SecondaryButton } from 'components/Button';
import { Overlay, NoDataOverlay } from 'components/Overlay';
import { Loader } from 'components/Loader';
import { ModalContainer } from 'components/ModalContainer';
import { DeleteItemDialog } from 'components/DeleteItemDialog';
import { PageErrorDialog } from 'components/PageErrorDialog';
import { NavigateAwayDialog } from 'components/NavigateAwayDialog';
import { TitleInput } from 'components/TitleInput';
import {
	Header,
	Content,
	Section,
	SectionTitle,
	FieldsetRow,
	Fieldset,
	FieldsetTitle,
	Input,
	Textarea,
	ValidationError,
	Actions,
} from 'admin/Tab';
import { EditAreaTeam } from 'area-editor/EditAreaTeam';
import {
	getAreas,
	getArea,
	createArea,
	updateArea,
	deleteArea,
	getPersonsByArea,
	patchAreaPersons,
} from 'utils/apiUtils';
import { mergeNullKeysToDefaults } from 'utils/parseUtils';
import { useIsMounted } from 'hooks/useIsMounted';

export const AREA_DESCRIPTION_MAX_LENGTH = 340;

export const DEFAULT_ITEM = {
	name: 'Unnamed Area',
	description: '',
	persons: [],
	stats: [
		{
			label: 'Subject Matter Experts',
			value: 0,
		},
		{
			label: 'Client engagments',
			value: 0,
		},
		{
			label: 'Total savings',
			value: 0,
		},
	],
	latestUpdatesDescription: '',
};

export function getValidItem(item) {
	return {
		...item,
		name: item.name.trim(),
		description: item.description.trim(),
		latestUpdatesDescription:
			item.latestUpdatesDescription && item.latestUpdatesDescription.trim(),
	};
}

//This is fixed array of stats for now, but for legacy data we show default stats
export function formatItemWithStats(item) {
	if (!item.stats || item.stats.length === 0) {
		item['stats'] = DEFAULT_ITEM.stats;
	}
	return item;
}

export function createPersonsHash(persons) {
	return persons
		? persons.reduce((acc, person) => {
				acc[person.personId] = person;
				return acc;
		  }, {})
		: {};
}

export function getValidationErrors(item, areas) {
	const { name, description } = item;

	const area = areas.filter(
		area => area.name.toLowerCase() === item.name.toLowerCase() && area.id !== item.id
	);

	let errors = {};
	if (!name) {
		errors = assoc('name', 'Please enter an area name', errors);
	} else if (area && area.length >= 1) {
		errors = assoc('name', 'Area name must be unique', errors);
	}
	if (description && description.length > AREA_DESCRIPTION_MAX_LENGTH) {
		errors = assoc(
			'description',
			`Description cannot be more than ${AREA_DESCRIPTION_MAX_LENGTH} characters`,
			errors
		);
	}
	return errors;
}

export function Area({ areaId, history }) {
	const isNew = !areaId;

	const [isLoading, setIsLoading] = React.useState(true);
	const [isSaving, setIsSaving] = React.useState(false);
	const [isDeleting, setIsDeleting] = React.useState(false);
	const [areas, setAreas] = React.useState(null);
	const [persons, setPersons] = React.useState([]);
	const [areaPersons, setAreaPersons] = React.useState({});
	const [committedItem, setCommittedItem] = React.useState(null);
	const [liveItem, setLiveItem] = React.useState(null);
	const [deletingItem, setDeletingItem] = React.useState(null);
	const [validationErrors, setValidationErrors] = React.useState({});
	const [apiErrors, setApiErrors] = React.useState({});
	const isMounted = useIsMounted();

	React.useEffect(() => {
		let queries = [getAreas()];
		if (!isNew) {
			queries = [...queries, getArea(areaId), getPersonsByArea(areaId)];
		}

		Promise.all(queries)
			.then(([areas, item, persons]) => {
				if (!isMounted()) {
					return;
				}
				setAreas(areas);
				setPersons(persons);
				const formattedItem = formatItemWithStats(
					mergeNullKeysToDefaults(DEFAULT_ITEM, item)
				);
				setCommittedItem(formattedItem);
				setLiveItem(formattedItem);
				setAreaPersons(createPersonsHash(formattedItem.persons));
				setIsLoading(false);
			})
			.catch(error => {
				if (!isMounted()) {
					return;
				}
				setIsLoading(false);
				setApiErrors({ area: error });
			});
	}, []);

	function onCancel() {
		history.push('/admin/areas');
	}

	function onSave() {
		const validItem = getValidItem(liveItem);
		const errors = getValidationErrors(validItem, areas);
		if (!isEmpty(errors)) {
			setValidationErrors(errors);
			return;
		}
		setValidationErrors({});
		setIsSaving(true);
		const action = isNew ? createArea : updateArea;

		action(validItem)
			.then(item => {
				if (!isMounted()) {
					return;
				}
				setCommittedItem(item);
				setLiveItem(item);
				setIsSaving(false);
				if (isNew) {
					history.push(`/admin/areas/${item.id}`);
				}
			})
			.catch(error => {
				if (!isMounted()) {
					return;
				}
				setIsSaving(false);
				setApiErrors({ area: error });
			});
	}

	function onDelete() {
		setIsDeleting(true);
		deleteArea(deletingItem)
			.then(() => {
				if (!isMounted()) {
					return;
				}
				setIsDeleting(false);
				history.push('/admin/areas');
			})
			.catch(error => {
				if (!isMounted()) {
					return;
				}
				setIsDeleting(false);
				setApiErrors({ area: error });
			});
	}

	function closeDeleteItemDialog() {
		setDeletingItem(null);
	}

	function closeErrorDialog() {
		setApiErrors({});
	}

	function setStatsUpdate(key, value, index) {
		const stats = [...liveItem.stats];
		const newStat = { ...stats[index] };
		newStat[key] = value;
		stats[index] = newStat;
		setLiveItem(assoc('stats', stats));
	}

	function refetchAreaAndPersons() {
		Promise.all([getArea(areaId), getPersonsByArea(areaId)])
			.then(([area, persons]) => {
				if (!isMounted()) {
					return;
				}

				const formattedArea = mergeNullKeysToDefaults(DEFAULT_ITEM, area);
				setCommittedItem(formattedArea);
				setLiveItem(formattedArea);
				setAreaPersons(createPersonsHash(formattedArea.persons));
				setPersons(persons);
				setIsSaving(false);
			})
			.catch(error => {
				if (!isMounted()) {
					return;
				}
				setIsSaving(false);
				setApiErrors({ area: error });
			});
	}

	function updateAreaForPersons(operation, persons) {
		const areaId = liveItem.id;
		setIsSaving(true);
		patchAreaPersons(areaId, operation, persons)
			.then(data => {
				refetchAreaAndPersons();
			})
			.catch(error => {
				setIsSaving(false);
				setApiErrors({ areaTeam: error });
			});
	}

	if (isLoading) {
		return (
			<Overlay>
				<Loader />
			</Overlay>
		);
	}

	if (!liveItem) {
		return <NoDataOverlay />;
	}

	return (
		<form>
			<Header>
				<TitleInput
					theme="admin"
					value={liveItem.name}
					onChange={event => setLiveItem(assoc('name', event.target.value))}
					autoSelect={isNew}
					autoFocus={!isNew}
				/>
				{validationErrors.name && (
					<ValidationError>{validationErrors.name}</ValidationError>
				)}
				<Actions>
					{!isNew && (
						<DangerButton disabled={isDeleting} onClick={() => setDeletingItem(liveItem)}>
							Delete
						</DangerButton>
					)}
					<SecondaryButton style={{ marginLeft: 'auto' }} onClick={onCancel}>
						Cancel
					</SecondaryButton>
					<PrimaryButton
						disabled={equals(liveItem, committedItem) || isSaving}
						onClick={onSave}
					>
						{isNew && (isSaving ? 'Creating…' : 'Create')}
						{!isNew && (isSaving ? 'Updating…' : 'Update')}
					</PrimaryButton>
				</Actions>
			</Header>

			<Content>
				<Section>
					<Fieldset>
						<FieldsetTitle>
							<label htmlFor="area-description">{`Description (Only ${AREA_DESCRIPTION_MAX_LENGTH} characters allowed)`}</label>
						</FieldsetTitle>
						<Textarea
							id="area-description"
							maxLength={AREA_DESCRIPTION_MAX_LENGTH}
							value={liveItem.description}
							onChange={event => setLiveItem(assoc('description', event.target.value))}
						/>
						{validationErrors.description && (
							<ValidationError>{validationErrors.description}</ValidationError>
						)}
					</Fieldset>
				</Section>
				<Section>
					<SectionTitle>Stats</SectionTitle>
					<FieldsetRow>
						{liveItem.stats.map((stat, index) => {
							const stasLabelId = `area-stats-label-${index + 1}`;
							const statsValueId = `area-stats-value-${index + 1}`;

							return (
								<Fragment key={`area-stat-${index}`}>
									<Fieldset>
										<FieldsetTitle>
											<label htmlFor={stasLabelId}>{`Stat ${index + 1} - Label`}</label>
										</FieldsetTitle>
										<Input
											id={stasLabelId}
											value={stat.label}
											onChange={event => {
												setStatsUpdate('label', event.target.value, index);
											}}
										/>
									</Fieldset>
									<Fieldset>
										<FieldsetTitle>
											<label htmlFor={statsValueId}>{`Stat ${index + 1} - Value`}</label>
										</FieldsetTitle>
										<Input
											id={statsValueId}
											maxLength="3"
											value={stat.value}
											onChange={event => {
												setStatsUpdate('value', event.target.value, index);
											}}
										/>
									</Fieldset>
								</Fragment>
							);
						})}
					</FieldsetRow>
				</Section>
				<Section>
					<SectionTitle>Latest Updates</SectionTitle>
					<Fieldset>
						<FieldsetTitle>
							<label htmlFor="area-latestUpdate-description">Description</label>
						</FieldsetTitle>
						<Textarea
							id="area-latestUpdate-description"
							value={liveItem.latestUpdatesDescription}
							onChange={event => {
								setLiveItem(assoc('latestUpdatesDescription', event.target.value));
							}}
						/>
					</Fieldset>
				</Section>
				{!isNew && (
					<Section>
						<EditAreaTeam
							isAdmin
							area={liveItem}
							areaExperts={persons}
							areaPersonsHash={areaPersons}
							setAreaPersonsHash={setAreaPersons}
							setAreaExperts={setPersons}
							updateAreaForPersons={updateAreaForPersons}
						/>
					</Section>
				)}
			</Content>

			{deletingItem && (
				<ModalContainer onClose={closeDeleteItemDialog}>
					<DeleteItemDialog
						type="area"
						name={deletingItem.name}
						isDeleting={isDeleting}
						onCancel={closeDeleteItemDialog}
						onConfirm={onDelete}
					/>
				</ModalContainer>
			)}

			<PageErrorDialog apiErrors={apiErrors} onClose={closeErrorDialog} />

			<NavigationPrompt
				disableNative
				when={(currentLocation, nextLocation) =>
					currentLocation.pathname !== nextLocation.pathname &&
					!equals(liveItem, committedItem)
				}
			>
				{({ onConfirm, onCancel }) => (
					<ModalContainer onClose={onCancel}>
						<NavigateAwayDialog onCancel={onCancel} onConfirm={onConfirm} />
					</ModalContainer>
				)}
			</NavigationPrompt>
		</form>
	);
}

Area.propTypes = {
	areaId: PropTypes.number,
	history: PropTypes.shape({
		push: PropTypes.func.isRequired,
	}).isRequired,
};

import React from 'react';

function Icon({ size, ...otherProps }) {
	const props = {
		width: size,
		height: size,
		fill: 'currentcolor',
		...otherProps,
	};
	return (
		<svg viewBox="0 0 24 24" {...props}>
			<path
				fill="#DB4564"
				fillRule="nonzero"
				stroke="none"
				strokeWidth="1"
				d="M14.25 1.5l6.3 6.3v12.6c0 1.155-.945 2.1-2.1 2.1H5.85c-1.155 0-2.1-.945-2.1-2.1V3.6c0-1.155.945-2.1 2.1-2.1h8.4zm-5.5 7v8l7-4-7-4z"
			/>
		</svg>
	);
}

export const VideoIcon = Icon;

VideoIcon.defaultProps = {
	size: 24,
};

import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import './MultiLevelFilter.css';

export const LEVEL1_OPTIONS_KEY = 'level1';
export const LEVEL2_OPTIONS_KEY = 'level2';

export const DEFAULT_OPTION_VALUE_LABEL = 'All';

function FilterOption({ name, label, value, selectedValue, onChange }) {
	const isSelected = selectedValue === value;
	return (
		<div
			className={classNames('multi-level-filter__radio-option', { selected: isSelected })}
		>
			<input
				type="radio"
				name={name}
				value={value}
				checked={isSelected}
				onChange={onChange}
			/>
			<label>{label || value}</label>
		</div>
	);
}

export function MultiLevelFilter({
	options,
	level1Key,
	level2Key,
	level1Option,
	level1OptionLabel,
	level2Option,
	level2OptionLabel,
	onChange,
}) {
	const level1Options = [...new Set(options.map(option => option[level1Key]))];
	const level2Options = level2Key
		? [
				...new Set(
					options
						.filter(e => e[level1Key] === level1Option)
						.map(option => option[level2Key])
				),
		  ]
		: [];

	return (
		<div className="multi-level-filter">
			<div className="multi-level-filter__radio-group level1">
				<FilterOption
					name="level1"
					label={level1OptionLabel}
					value={DEFAULT_OPTION_VALUE_LABEL}
					selectedValue={level1Option}
					onChange={event => onChange(event.target.value, DEFAULT_OPTION_VALUE_LABEL)}
				/>
				{level1Options.map(option => (
					<FilterOption
						key={option}
						name="level1"
						value={option}
						selectedValue={level1Option}
						onChange={event => {
							onChange(event.target.value, DEFAULT_OPTION_VALUE_LABEL);
						}}
					/>
				))}
			</div>
			{level2Options && level2Options.length > 0 && (
				<div className="multi-level-filter__radio-group level2">
					<FilterOption
						name="level2"
						label={level2OptionLabel}
						value={DEFAULT_OPTION_VALUE_LABEL}
						selectedValue={level2Option}
						onChange={event => {
							onChange(level1Option, event.target.value);
						}}
					/>
					{level2Options.map(option => (
						<FilterOption
							key={option}
							name="level2"
							value={option}
							selectedValue={level2Option}
							onChange={event => onChange(level1Option, event.target.value)}
						/>
					))}
				</div>
			)}
		</div>
	);
}

MultiLevelFilter.propTypes = {
	options: PropTypes.array.isRequired,
	level1Key: PropTypes.string,
	level2Key: PropTypes.string,
	level1Option: PropTypes.string,
	level1OptionLabel: PropTypes.string,
	level2Option: PropTypes.string,
	level2OptionLabel: PropTypes.string,
	onChange: PropTypes.func.isRequired,
};

MultiLevelFilter.defaultProps = {
	level1Key: LEVEL1_OPTIONS_KEY,
	level1Option: DEFAULT_OPTION_VALUE_LABEL,
	level2Option: DEFAULT_OPTION_VALUE_LABEL,
	level1OptionLabel: DEFAULT_OPTION_VALUE_LABEL,
	level2OptionLabel: DEFAULT_OPTION_VALUE_LABEL,
};

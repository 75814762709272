import React from 'react';
import PropTypes from 'prop-types';

import { Label } from 'components/Label';
import { LabelWithCount } from 'components/LabelWithCount';
import { Input } from 'components/Input';
import { Textarea } from 'components/Textarea';
import { Toggle } from 'components/Toggle';
import { ErrorLabel } from 'components/ErrorLabel';
import { SingleDatePicker } from 'components/DatePicker';
import {
	EditorPageFieldset,
	EditorPageSeparator,
	EditorPageSectionTitle,
	EditorSectionInfoLabel,
} from 'content-editor/EditorPageLayout';
import { Dropdown } from 'components/Dropdown';
import { INPUT_NAMES, PRODUCT_COLORS } from 'product-editor/constants';
import { getUrlByTransformingName } from 'utils/urlUtils';

import {
	SOLUTION_DESCRIPTION_LENGTH,
	SOLUTION_INTRO_TEXT_LENGTH,
} from 'admin/tabs/Solution';

import { CalendarToday } from 'components/icons/CalendarToday';

import './ProductDetailEditSection.css';
import { ProductAreasSearchableList } from './ProductAreasSearchableList';

const URL_MAX_LENGTH = 100;

export function ProductDetailEditSection({
	product,
	errors,
	onEditProduct,
	setFocusedInput,
	areas,
	areaTypes,
}) {
	const {
		name,
		version,
		introText,
		description,
		lastUpdatedDate,
		color,
		url,
		isActive,
		isVisible,
		onAlixip,
		showImage,
		buttonText,
		appUrl,
	} = product;
	const urlInputEl = React.createRef();
	const productColorsOptions = PRODUCT_COLORS.map((name, id) => ({
		id,
		name,
	}));
	const colorId = PRODUCT_COLORS.findIndex(colorName => color === colorName);

	function handleNameChange(e) {
		if (!url) {
			const generatedUrl = getUrlByTransformingName(e.target.value);
			urlInputEl.current.value = generatedUrl;
		}

		onEditProduct(e);
	}

	function handleUrlChange(e) {
		const input = e.target;

		const cleanValue = input.value.toLowerCase().replace(/[^a-z0-9-]+/g, '');

		if (cleanValue !== input.value) {
			input.value = cleanValue;
		}

		onEditProduct(e);
	}

	return (
		<section className="product-detail-edit-section">
			<EditorPageSectionTitle title="Edit Product" />
			<EditorPageSeparator />
			<div className="product-detail__fieldset" role="group">
				<Toggle
					id="product-visible-flag"
					name="isVisible"
					label="This is visible"
					checked={isVisible}
					onChange={value => {
						onEditProduct({ target: { name: 'isVisible', value } });
					}}
				/>
			</div>
			<EditorPageSeparator />
			<EditorPageFieldset>
				<div className="product-detail__fieldset-info">
					Select how your Product or Tool will be presented when browsed on the Search
					page of AlixIP.
				</div>
			</EditorPageFieldset>
			<div className="product-detail__fieldset" role="group">
				<Toggle
					id="product-on-alix-ip"
					name="onAlixip"
					label="On AlixIP"
					checked={onAlixip}
					onChange={value => {
						onEditProduct({ target: { name: 'onAlixip', value } });
					}}
				/>
			</div>
			<EditorPageFieldset>
				<Label htmlFor="product-color" value="Color" />
				<Dropdown
					defaultLabel="Select Color"
					options={productColorsOptions}
					selectedId={colorId}
					onChange={colorId => {
						onEditProduct({
							target: { name: 'color', value: PRODUCT_COLORS[colorId] },
						});
					}}
				/>
			</EditorPageFieldset>
			<div className="product-detail__fieldset" role="group">
				<Toggle
					id="product-show-image"
					name="showImage"
					label="Show product image"
					checked={showImage}
					onChange={value => {
						onEditProduct({ target: { name: 'showImage', value } });
					}}
				/>
			</div>
			<EditorPageSeparator />
			<EditorPageFieldset>
				<Label htmlFor="product-name" value="Name" />
				<Input
					id="product-name"
					name="name"
					type="text"
					autoFocus
					value={name}
					onChange={handleNameChange}
				/>
				<ErrorLabel error={errors.name} />
			</EditorPageFieldset>
			<EditorPageFieldset>
				<Label htmlFor="product-page-url" value="Page URL" />
				<input
					id="product-page-url"
					className="input"
					ref={urlInputEl}
					name="url"
					pattern="[a-zA-Z0-9-]+"
					type="text"
					value={url}
					maxLength={URL_MAX_LENGTH}
					onChange={handleUrlChange}
				/>
				<ErrorLabel error={errors.url} />
			</EditorPageFieldset>
			<div className="product-detail__fieldset" role="group">
				<Toggle
					id="product-active-state"
					name="isActive"
					label="Show on Search all page"
					checked={isActive}
					onChange={checked => {
						onEditProduct({ target: { name: 'isActive', value: checked } });
					}}
				/>
			</div>
			<EditorPageSeparator theme="dark" />
			<EditorPageFieldset>
				<Label htmlFor="product-version" value="Version" />
				<Input
					id="product-version"
					name="version"
					type="text"
					placeholder="Please enter version"
					value={version || ''}
					onChange={onEditProduct}
				/>
			</EditorPageFieldset>
			<EditorPageFieldset>
				<Label htmlFor="product-last-update-date" value="Updated on" />
				<SingleDatePicker
					id="product-last-update-date"
					date={lastUpdatedDate}
					openDirection="down"
					numberOfMonths={1}
					displayFormat="DD/MM/YYYY"
					customInputIcon={<CalendarToday size="20" />}
					onChange={date => {
						onEditProduct({ target: { name: 'lastUpdatedDate', value: date || '' } });
					}}
				/>
			</EditorPageFieldset>
			<EditorPageSeparator theme="dark" />
			<EditorPageFieldset>
				<Label htmlFor="env-button-name" value="Environment Button Name" />
				<Input
					id="env-button-name"
					name="buttonText"
					type="text"
					defaultValue={buttonText}
					onChange={onEditProduct}
				/>
				<ErrorLabel error={errors.buttonText} />
			</EditorPageFieldset>
			<EditorPageFieldset>
				<Label htmlFor="env-button-name" value="Environment Link" />
				<Input
					id="environment-link"
					name="appUrl"
					type="url"
					required
					defaultValue={appUrl}
					onChange={onEditProduct}
				/>
				<ErrorLabel error={errors.appUrl} />
			</EditorPageFieldset>
			<EditorPageSeparator theme="dark" />
			<EditorSectionInfoLabel>
				Intro text will appear when displaying the Product on an Area page
			</EditorSectionInfoLabel>
			<EditorPageFieldset>
				<LabelWithCount
					htmlFor="product-intro-text"
					value="Intro text"
					count={`${introText ? introText.length : 0} / ${SOLUTION_INTRO_TEXT_LENGTH}`}
				/>
				<Textarea
					id="product-intro-text"
					name={INPUT_NAMES.introText}
					maxLength={SOLUTION_INTRO_TEXT_LENGTH}
					value={introText || ''}
					onFocus={event => {
						setFocusedInput(event.target.name);
					}}
					onBlur={event => {
						setFocusedInput(null);
					}}
					onChange={onEditProduct}
				/>
				<ErrorLabel error={errors.introText} />
			</EditorPageFieldset>
			<EditorPageSeparator theme="dark" />
			<EditorPageFieldset>
				<LabelWithCount
					htmlFor="product-description"
					value="About product"
					count={`${
						description ? description.length : 0
					} / ${SOLUTION_DESCRIPTION_LENGTH}`}
				/>
				<Textarea
					id="product-description"
					name={INPUT_NAMES.description}
					maxLength={SOLUTION_DESCRIPTION_LENGTH}
					value={description || ''}
					onFocus={event => {
						setFocusedInput(event.target.name);
					}}
					onBlur={event => {
						setFocusedInput(null);
					}}
					onChange={onEditProduct}
				/>
				<ErrorLabel error={errors.description} />
			</EditorPageFieldset>
			<EditorPageSeparator theme="dark" />
			<ProductAreasSearchableList
				product={product}
				onEditProduct={onEditProduct}
				areas={areas}
				areaTypes={areaTypes}
			/>
		</section>
	);
}

ProductDetailEditSection.propTypes = {
	product: PropTypes.shape({
		name: PropTypes.string.isRequired,
		description: PropTypes.string,
		version: PropTypes.string,
		lastUpdatedDate: PropTypes.string,
	}).isRequired,
	errors: PropTypes.object,
	onEditProduct: PropTypes.func.isRequired,
	setFocusedInput: PropTypes.func.isRequired,
};

ProductDetailEditSection.defaultProps = {
	errors: {},
};

import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import { PageSectionTitle } from 'components/PageSectionTitle';

import { PRODUCT_SECTION_PARAM_KEY } from 'products/Products';
import { EditorAddButton } from 'content-editor/EditorAddButton';
import { AreaExpertsGrid } from 'area-editor/AreaExpertsGrid';
import { INPUT_NAMES } from 'product-editor/constants';

import './ProductTeamSection.css';

export function ProductTeamSection({
	sectionTitle,
	sectionName,
	url,
	team,
	focusedInput,
}) {
	return (
		<section className="product-team__section">
			<PageSectionTitle>{sectionTitle}</PageSectionTitle>
			<Link to={`${url}?${PRODUCT_SECTION_PARAM_KEY}=${sectionName}`}>
				<EditorAddButton selected={focusedInput === INPUT_NAMES.searchExperts} />
			</Link>
			<AreaExpertsGrid experts={team} />
		</section>
	);
}

ProductTeamSection.propTypes = {
	sectionTitle: PropTypes.string.isRequired,
	url: PropTypes.string.isRequired,
	sectionName: PropTypes.string.isRequired,
	team: PropTypes.arrayOf(
		PropTypes.shape({
			id: PropTypes.number.isRequired,
			name: PropTypes.string.isRequired,
			order: PropTypes.number.isRequired,
			isVisible: PropTypes.bool.isRequired,
		})
	).isRequired,
	focusedInput: PropTypes.string,
};

import './GridSelect.css';

import React from 'react';
import PropTypes from 'prop-types';

import { PopoutMenu } from './PopoutMenu';
import { MoreIcon } from 'components/icons';

import './GridSelectMenu.css';

export function GridSelectMenu({ item, onEdit, onDelete }) {
	return (
		<PopoutMenu
			options={[
				{
					label: 'Edit',
					onClick: onEdit,
				},
				{
					label: 'Delete',
					onClick: onDelete,
				},
			]}
			renderOption={({ label, onClick }) => (
				<button className="grid-select-menu__button" onClick={() => onClick(item)}>
					{label}
				</button>
			)}
			renderTrigger={() => <MoreIcon className="grid-select-menu__more-icon" size={20} />}
		/>
	);
}

GridSelectMenu.propTypes = {
	item: PropTypes.shape({
		id: PropTypes.oneOfType([PropTypes.number, PropTypes.number]).isRequired,
		name: PropTypes.string.isRequired,
	}).isRequired,

	onEdit: PropTypes.func,
	onDelete: PropTypes.func,
};

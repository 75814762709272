export const apOfficeLocations = [
	'USA',
	'Argentina',
	'UK',
	'UAE',
	'Germany',
	'France',
	'Italy',
	'Saudi Arabia',
	'Switzerland',
	'Hong Kong',
	'Korea',
	'China',
	'Japan',
];

export const INPUT_NAMES = {
	introText: 'introText',
	description: 'description',
	searchExperts: 'searchExperts',
	latestUpdate: 'latestUpdate',
	whenToUseInfo: 'whenToUseInfo',
	latestUpdateDescription: 'latestUpdateDescription',
};

export const PRODUCT_DETAIL = 'detail';
export const PRODUCT_WHEN_TO_USE = 'whenToUse';
export const PRODUCT_FEATURES = 'features';
export const PRODUCT_OWNERS = 'owners';
export const PRODUCT_TEAM = 'team';
export const PRODUCT_ATTACHMENTS = 'attachments';
export const PRODUCT_LATEST_UPDATES = 'latest-updates';

export const PRODUCT_EDITOR_OPTIONS = [
	{
		key: PRODUCT_FEATURES,
		label: 'Edit features',
	},
	{
		key: PRODUCT_WHEN_TO_USE,
		label: 'Edit when to use',
	},
	{
		key: PRODUCT_OWNERS,
		label: 'Edit owners',
	},
	{
		key: PRODUCT_TEAM,
		label: 'Edit team',
	},
	{
		key: PRODUCT_ATTACHMENTS,
		label: 'Edit attachments',
	},
	{
		key: PRODUCT_LATEST_UPDATES,
		label: 'Edit latest updates',
	},
];

export const PRODUCT_COLORS = ['Grey', 'Teal', 'Blue', 'Green'];

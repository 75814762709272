import React from 'react';

export function useAutoSelectInputRef() {
	const inputRef = React.useRef();

	React.useEffect(() => {
		inputRef.current && inputRef.current.select();
	}, []);

	return inputRef;
}

import React from 'react';
import PropTypes from 'prop-types';
import { Dialog, Header, Content, Footer, Title, CloseButton } from 'components/Dialog';
import { SecondaryButton, PrimaryButton } from 'components/Button';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { DragHandle } from 'components/icons/DragHandle';

import './ReorderItemsDialog.css';
import classNames from 'classnames';

const droppableId = 'reorder-items-dialog-droppable';

export function ReorderItemsDialog({ title, itemsList, onCancel, onConfirm }) {
	const [items, setItems] = React.useState(itemsList);
	const onReorder = newList => {
		setItems(newList);
	};

	function onDragEnd(result) {
		const { source, destination, reason } = result;

		// dropped outside the list
		if (!destination) {
			return;
		}

		const isRecognisedDropEvent = reason === 'DROP';
		const isWithinDesiredDropZone = destination.droppableId === droppableId;
		if (isWithinDesiredDropZone && isRecognisedDropEvent) {
			const newItems = [...items];
			const [removed] = newItems.splice(source.index, 1);
			newItems.splice(destination.index, 0, removed);
			onReorder(newItems, removed.id, destination.index);
		}
	}

	return (
		<Dialog className="reorder-items-dialog">
			<Header>
				<Title>{title}</Title>
				<CloseButton onClick={onCancel} size={28} />
			</Header>
			<Content>
				<DragDropContext onDragEnd={onDragEnd}>
					<Droppable droppableId={droppableId} ignoreContainerClipping>
						{(provided, snapshot) => (
							<ul
								ref={provided.innerRef}
								className={classNames({
									'reorder-items-dialog--dragging': snapshot.isDraggingOver,
								})}
							>
								{items.map((item, index) => {
									return (
										<Draggable key={item.id} draggableId={item.id} index={index}>
											{(provided, snapshot) => (
												<li
													ref={provided.innerRef}
													{...provided.draggableProps}
													className={classNames('reorder-items-dialog__item', {
														'reorder-items-dialog__item-drag-row': snapshot.isDragging,
													})}
												>
													<div
														className="reorder-items-dialog__item-drag-handle"
														{...provided.dragHandleProps}
													>
														<DragHandle />
													</div>
													<div className="reorder-items-dialog__name" title={item.name}>
														{item.name}
													</div>
												</li>
											)}
										</Draggable>
									);
								})}
							</ul>
						)}
					</Droppable>
				</DragDropContext>
			</Content>
			<Footer>
				<SecondaryButton onClick={onCancel}>Cancel</SecondaryButton>
				<PrimaryButton
					onClick={() => {
						onConfirm(items);
					}}
				>
					Apply reorder
				</PrimaryButton>
			</Footer>
		</Dialog>
	);
}

ReorderItemsDialog.propTypes = {
	title: PropTypes.string.isRequired,
	itemsList: PropTypes.array.isRequired,
	onCancel: PropTypes.func.isRequired,
	onConfirm: PropTypes.func.isRequired,
};

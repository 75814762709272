import React from 'react';
import PropTypes from 'prop-types';

import {
	getFilesBySolution,
	getPersonsBySolution,
	getFeaturesBySolution,
	getOwnersBySolution,
} from 'utils/apiUtils';

import { scrollToAnchor } from 'utils/urlUtils';
import { sortByName } from 'utils/sortUtils';
import { Overlay } from 'components/Overlay';
import { Loader } from 'components/Loader';
import { ProductDetail } from 'product/ProductDetail';
import { WhenToUse } from 'product/WhenToUse';
import { ProductTeam } from 'product/ProductTeam';
import { FeaturesCarousel } from 'product/FeaturesCarousel';
import { LatestUpdateSection } from 'product/LatestUpdateSection';
import { PageErrorDialog } from 'components/PageErrorDialog';
import { Attachments } from 'product/Attachments';
import { useIsMounted } from 'hooks/useIsMounted';
import { useWindowSize } from 'hooks/useWindowSize';
import { Footer } from 'global/Footer';

import './ProductPage.css';

export function ProductPage({ product, client, areas, config }) {
	const [isLoading, setIsLoading] = React.useState(true);
	const [files, setFiles] = React.useState([]);

	const [apiErrors, setApiErrors] = React.useState({});
	const [features, setFeatures] = React.useState([]);
	const [persons, setPersons] = React.useState([]);
	const [owners, setOwners] = React.useState([]);
	const isMounted = useIsMounted();
	const windowSize = useWindowSize();
	const productId = product.id;
	scrollToAnchor();

	React.useEffect(() => {
		setFiles(sortByName([]));
		setPersons([]);
		setOwners([]);
		setFeatures([]);

		Promise.all([
			getFilesBySolution(productId),
			getPersonsBySolution(productId),
			getOwnersBySolution(productId),
			getFeaturesBySolution(productId),
		])
			.then(([files, persons, owners, features]) => {
				if (!isMounted()) {
					return;
				}
				setFiles(sortByName(files));
				setPersons(persons);
				setOwners(owners);
				setFeatures(features);
				setIsLoading(false);
				scrollToAnchor();
			})
			.catch(error => {
				if (!isMounted()) {
					return;
				}
				setIsLoading(false);
				setApiErrors({ detail: error });
			});
	}, [productId]);

	function closeErrorDialog() {
		setApiErrors({});
	}

	if (isLoading) {
		return (
			<div className="home" style={{ minHeight: windowSize.height }}>
				<Overlay>
					<Loader />
				</Overlay>
			</div>
		);
	}

	const hasTeamMembers = !!persons && !!persons.length;
	const hasProductOwners = !!owners && !!owners.length;
	const hasAttachments = !!files && !!files.length;
	const hasFeatures = !!features && !!features.length;
	const hasLatestUpdate =
		product && (!!product.version || !!product.latestUpdateDescription);
	const heroFeature = hasFeatures ? features.find(feature => feature.isHero) : null;
	const hasWhenToUse = !!product.whenToUseInfo;

	return (
		<div className="home" style={{ minHeight: windowSize.height }}>
			<ProductDetail
				product={product}
				client={client}
				heroFeature={heroFeature}
				areas={areas}
			/>
			{hasFeatures && <FeaturesCarousel features={features} />}
			{hasWhenToUse && <WhenToUse text={product.whenToUseInfo} />}
			{hasProductOwners && (
				<ProductTeam
					teamMembers={owners}
					title="Product Owner"
					subTitle="Your first point of contact for this product."
					darkTheme
				/>
			)}
			{hasTeamMembers && <ProductTeam teamMembers={persons} title="Team" />}
			{hasAttachments && <Attachments files={files} setApiErrors={setApiErrors} />}
			{hasLatestUpdate ? (
				<LatestUpdateSection product={product} />
			) : (
				<div className="product-page__space-separator" />
			)}
			<PageErrorDialog apiErrors={apiErrors} onClose={closeErrorDialog} />
			<Footer />
		</div>
	);
}

export const areasProps = {
	areas: PropTypes.arrayOf(
		PropTypes.shape({
			id: PropTypes.number.isRequired,
			url: PropTypes.string.isRequired,
		})
	),
};

ProductPage.propTypes = {
	product: PropTypes.shape({
		id: PropTypes.number.isRequired,
		name: PropTypes.string.isRequired,
	}).isRequired,
	client: PropTypes.shape({
		id: PropTypes.number.isRequired,
		name: PropTypes.string.isRequired,
		solutionIds: PropTypes.arrayOf(PropTypes.number).isRequired,
	}),
	...areasProps,
};

ProductPage.defaultProps = {
	areas: [],
};

/**
 * Sign in page, reached after the user signs in with MS AD
 */
import React from 'react';
import Cookies from 'js-cookie';
import { Redirect } from 'react-router-dom';

export function SignIn() {
	const redirect = Cookies.get('redirect');
	if (redirect) {
		Cookies.remove('redirect');
		return <Redirect to={redirect} />;
	}

	return <Redirect to="/" />;
}

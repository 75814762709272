import React from 'react';

const Icon = ({ size, ...otherProps }) => {
	const props = {
		width: size,
		height: size,
		fill: 'currentcolor',
		...otherProps,
	};
	return (
		<svg viewBox="0 0 56 56" {...props}>
			<g fill="none" fillRule="evenodd">
				<circle cx={28} cy={28} r={28} fill="currentcolor" />
				<path fill="#FFF" d="M40 28L20 38V18z" />
			</g>
		</svg>
	);
};

export const PlayVideoIcon = Icon;

PlayVideoIcon.defaultProps = {
	size: 24,
};

import React from 'react';
import PropTypes from 'prop-types';
import { parsePhoneNumberFromString } from 'libphonenumber-js';

import { PersonIcon } from 'components/icons';

import './ProductPerson.css';

export function ProductPerson({
	theme,
	name,
	title,
	email,
	phoneNumber,
	image,
	className,
}) {
	return (
		<div className={`product-person ${className} ${theme}`}>
			{image ? (
				<div className={`product-person__image ${className}__image`}>
					<img src={image} alt={name} />
				</div>
			) : (
				<div className={`product-person__image ${className}__image`}>
					<PersonIcon size={40} />
				</div>
			)}
			<div className={`product-person__info ${className}__info`}>
				<div className={`product-person__title ${className}__title`}>{title}</div>
				<div className={`product-person__name ${className}__name`}>{name}</div>
				{phoneNumber && (
					<div className={`product-person__phone ${className}__phone`}>
						{parsePhoneNumberFromString(phoneNumber).formatInternational()}
					</div>
				)}
				{email && (
					<div className={`product-person__email ${className}__email`}>
						<a href={`mailto:${email}`}>{email}</a>
					</div>
				)}
			</div>
		</div>
	);
}

export const personType = {
	name: PropTypes.string.isRequired,
	url: PropTypes.string,
	email: PropTypes.string,
	phoneNumber: PropTypes.string,
	id: PropTypes.number,
	className: PropTypes.string,
};

ProductPerson.propTypes = {
	...personType,
	title: PropTypes.string.isRequired,
	theme: PropTypes.string,
};

ProductPerson.defaultProps = {
	theme: 'dark',
};

import React from 'react';
import PropTypes from 'prop-types';
import { assoc } from 'ramda';
import { Select } from 'components/Select';
import { sortByName } from 'utils/sortUtils';
import { getStringFromNumber, getNumberFromString } from 'utils/parseUtils';

function getGroupName(solution, groups) {
	const group = groups.find(group => solution.groupIds[0] === group.id);
	if (group) {
		return group.name;
	}
	return '—';
}

export function getSolutionsWithGroupName(solutions, groups, groupKey) {
	return sortByName(
		solutions.map(solution => assoc(groupKey, getGroupName(solution, groups), solution)),
		groupKey
	);
}

export const GROUP_KEY = 'groupName';

export function SolutionsSelect({ solutions, groups, value, onChange, ...props }) {
	const options = getSolutionsWithGroupName(solutions, groups, GROUP_KEY);
	const selectedItem = options.find(item => item.id === value);
	const selectLabel = selectedItem ? selectedItem.name : '-';
	return (
		<Select
			id="solutions-select"
			options={options}
			value={getStringFromNumber(value)}
			label={selectLabel}
			onChange={newValue => {
				onChange(getNumberFromString(newValue));
			}}
			groupKey={GROUP_KEY}
			{...props}
		/>
	);
}

SolutionsSelect.propTypes = {
	solutions: PropTypes.arrayOf(
		PropTypes.shape({
			id: PropTypes.number.isRequired,
			name: PropTypes.string.isRequired,
			groupIds: PropTypes.arrayOf(PropTypes.number).isRequired,
		})
	).isRequired,
	groups: PropTypes.arrayOf(
		PropTypes.shape({
			id: PropTypes.number.isRequired,
			name: PropTypes.string.isRequired,
		})
	).isRequired,
	value: PropTypes.number,
	onChange: PropTypes.func.isRequired,
};

SolutionsSelect.defaultProps = {
	value: null,
};

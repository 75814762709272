import React from 'react';
import { NavLink } from 'react-router-dom';
import { ExpandMore, ExpandLess } from 'components/icons';

import './ServiceList.css';

export default function ServiceList({ services, title, rowsToDisplay }) {
	const [isExpanded, setExpanded] = React.useState(false);
	const ServiceNamesList = isExpanded ? services : services.slice(0, 5);
	const showMore = services && services.length > rowsToDisplay;

	return (
		<div className="home-page-service-list">
			{title && <h5 className="home-page-service-list__title">{title}</h5>}
			<div className="home-page-service-list__content">
				{ServiceNamesList.map(service => {
					return (
						<NavLink
							to={service.url}
							key={service.url}
							className="home-page-service-list__row"
						>
							<div>{service.name}</div>
							<div className="home-page-service-list__arrow">&nbsp;</div>
						</NavLink>
					);
				})}
				{showMore && (
					<div
						className={`home-page-service-list__row show-more ${
							showMore ? '' : 'hidden'
						}`}
						onClick={() => setExpanded(!isExpanded)}
					>
						<div className="home-page-service-list__more">
							{isExpanded ? 'Show less' : 'Show more'}
						</div>
						<div className="home-page-service-list__collapsible-icon">
							{isExpanded ? <ExpandLess size={20} /> : <ExpandMore size={20} />}
						</div>
					</div>
				)}
			</div>
		</div>
	);
}

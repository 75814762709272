import React from 'react';

const Icon = ({ size, ...otherProps }) => {
	const props = {
		width: size,
		height: size,
		fill: 'currentcolor',
		...otherProps,
	};
	return (
		<svg viewBox="0 0 29 36" {...props}>
			<g fill="none" fillRule="evenodd">
				<path d="M-6-2h40v40H-6z" />
				<path
					d="M3.75.5h14L28.25 11v21a3.51 3.51 0 0 1-3.5 3.5h-21A3.51 3.51 0 0 1 .25 32V4A3.51 3.51 0 0 1 3.75.5zm.618 25h20.588v-1.667H3.544V25.5h.824zm0 5h20.588v-1.667H3.544V30.5h.824zM18.8 5.927H6.087L5.903 9.58h.437c.053-.704.222-1.263.505-1.677.283-.413.624-.692 1.022-.838.306-.107.823-.16 1.55-.16h1.872V18.8c0 .873-.084 1.432-.252 1.677-.276.398-.743.597-1.401.597h-.552v.425h6.604v-.425h-.54c-.605 0-1.053-.16-1.344-.482-.206-.238-.31-.835-.31-1.792V6.903h2.194c.643 0 1.162.106 1.556.316.394.21.71.538.947.982.146.276.26.735.345 1.378h.436L18.8 5.927z"
					fill="#9CBBC9"
				/>
			</g>
		</svg>
	);
};

export const TextIcon = Icon;

TextIcon.defaultProps = {
	size: 24,
};

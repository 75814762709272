import React from 'react';
import PropTypes from 'prop-types';
import NavigationPrompt from 'react-router-navigation-prompt';
import { assoc, equals, isEmpty } from 'ramda';

import { DangerButton, PrimaryButton, SecondaryButton } from 'components/Button';
import { Overlay, NoDataOverlay } from 'components/Overlay';
import { Loader } from 'components/Loader';
import { ModalContainer } from 'components/ModalContainer';
import { DeleteItemDialog } from 'components/DeleteItemDialog';
import { ErrorDialog } from 'components/ErrorDialog';
import { NavigateAwayDialog } from 'components/NavigateAwayDialog';
import { TitleInput } from 'components/TitleInput';
import { Toggle } from 'components/Toggle';
import { SaveIcon } from 'components/icons/SaveIcon';
import {
	Header,
	Actions,
	Content,
	Fieldset,
	FieldsetTitle,
	Textarea,
	ValidationError,
} from 'admin/Tab';
import { SolutionsSelect } from 'admin/SolutionsSelect';
import {
	getSolutions,
	getFeature,
	createFeature,
	updateFeature,
	deleteFeature,
	uploadFile,
} from 'utils/apiUtils';
import { sortByName } from 'utils/sortUtils';
import { mergeNullKeysToDefaults } from 'utils/parseUtils';
import { isValidateFileType, getFileNameFromUrl } from 'utils/fileUtils';
import { getPageUrl } from 'utils/urlUtils';
import { useIsMounted } from 'hooks/useIsMounted';

const DEFAULT_ITEM = {
	name: 'Unnamed Feature',
	solutionId: null,
	isHero: false,
	description: '',
	fileName: '',
	url: '',
};

export const WHITELISTED_VIDEO_TYPES = ['video/webm', 'video/ogg', 'video/mp4'];
export const WHITELISTED_IMAGE_TYPES = [
	'.jpeg',
	'.jpg',
	'.jpe',
	'.png',
	'.gif',
	'.tif',
	'.tiff',
];
export const WHITELISTED_FILE_TYPES = [
	...WHITELISTED_VIDEO_TYPES,
	...WHITELISTED_IMAGE_TYPES,
];

export const FEATURE_DESCRIPTION_MAX_LENGTH = 340;

export function getValidItem(item) {
	return {
		...item,
		name: item.name.trim(),
		description: item.description.trim(),
	};
}

export function getValidationErrors(item, data, isNew) {
	const { name, description, solutionId, fileName } = item;
	let errors = {};
	if (!name) {
		errors = assoc('name', 'Please enter a valid name', errors);
	}
	if (description && description.length > FEATURE_DESCRIPTION_MAX_LENGTH) {
		errors = assoc(
			'description',
			`Description should not exceed ${FEATURE_DESCRIPTION_MAX_LENGTH} characters`,
			errors
		);
	}
	if (!solutionId) {
		errors = assoc('solutionId', 'Please select a solution', errors);
	}
	if (fileName && !isValidateFileType(fileName)) {
		errors = assoc(
			'data',
			`Please try to upload a	file in following format: ${WHITELISTED_FILE_TYPES.join(
				', '
			)}.`,
			errors
		);
	}
	return errors;
}

export function Feature({ featureId, solutionId, history }) {
	const isNew = !featureId;

	const [isLoading, setIsLoading] = React.useState(true);
	const [isSaving, setIsSaving] = React.useState(false);
	const [isDeleting, setIsDeleting] = React.useState(false);
	const [solutions, setSolutions] = React.useState([]);
	const [committedItem, setCommittedItem] = React.useState(null);
	const [liveItem, setLiveItem] = React.useState(null);
	const [liveFile, setLiveFile] = React.useState({});
	const [deletingItem, setDeletingItem] = React.useState(null);
	const [validationErrors, setValidationErrors] = React.useState({});
	const [apiErrors, setApiErrors] = React.useState({});
	const isMounted = useIsMounted();

	const productURL = getPageUrl('product');

	React.useEffect(() => {
		let queries = [getSolutions()];
		if (!isNew) {
			queries = [...queries, getFeature(featureId)];
		}
		Promise.all(queries)
			.then(([solutions, item]) => {
				if (!isMounted()) {
					return;
				}

				const formattedItem = mergeNullKeysToDefaults(
					{ ...DEFAULT_ITEM, solutionId },
					item
				);
				setSolutions(sortByName(solutions));
				setCommittedItem(formattedItem);
				setLiveItem(formattedItem);
				setIsLoading(false);
			})
			.catch(error => {
				if (!isMounted()) {
					return;
				}
				setIsLoading(false);
				setApiErrors({ feature: error });
			});
	}, []);

	function onCancel() {
		if (solutionId) {
			history.push(`${productURL}/${solutionId}`);
			return;
		}
		history.push('/admin/features');
	}

	function onSave() {
		const validItem = getValidItem(liveItem);
		const errors = getValidationErrors(validItem, liveFile.data, isNew);
		if (!isEmpty(errors)) {
			setValidationErrors(errors);
			return;
		}
		setValidationErrors({});
		setIsSaving(true);
		const action = isNew ? createFeature : updateFeature;
		action(validItem)
			.then(item => {
				if (!isMounted()) {
					return;
				}
				if (!liveFile.data) {
					onFileSaveSuccess(item);
					return;
				} else {
					uploadFile(item.url, liveFile.data)
						.then(() => {
							if (!isMounted()) {
								return;
							}

							onFileSaveSuccess(item);
						})
						.catch(error => {
							if (!isMounted()) {
								return;
							}

							setIsSaving(false);
							setApiErrors({ file: error });
						});
				}
			})
			.catch(error => {
				if (!isMounted()) {
					return;
				}
				setIsSaving(false);
				setApiErrors({ feature: error });
			});
	}

	function onDelete() {
		setIsDeleting(true);
		deleteFeature(deletingItem)
			.then(() => {
				if (!isMounted()) {
					return;
				}
				setIsDeleting(false);
				if (solutionId) {
					history.push(`${productURL}/${solutionId}`);
					return;
				}
				history.push('/admin/features');
			})
			.catch(error => {
				if (!isMounted()) {
					return;
				}
				setIsDeleting(false);
				setApiErrors({ feature: error });
			});
	}

	function onFileSaveSuccess(item) {
		setCommittedItem(item);
		setLiveItem(item);
		setIsSaving(false);
		if (solutionId) {
			history.push(`${productURL}/${solutionId}`);
			return;
		}
		if (isNew) {
			history.push(`/admin/features/${item.id}`);
		}
	}

	function closeDeleteItemDialog() {
		setDeletingItem(null);
	}

	function closeErrorDialog() {
		setApiErrors({});
	}

	if (isLoading) {
		return (
			<Overlay>
				<Loader />
			</Overlay>
		);
	}

	if (!liveItem) {
		return <NoDataOverlay />;
	}

	return (
		<form>
			<Header>
				<TitleInput
					theme="admin"
					value={liveItem.name}
					onChange={event => setLiveItem(assoc('name', event.target.value))}
					autoSelect={isNew}
					autoFocus={!isNew}
				/>
				{validationErrors.name && (
					<ValidationError>{validationErrors.name}</ValidationError>
				)}
				<Actions>
					{!isNew && (
						<DangerButton disabled={isDeleting} onClick={() => setDeletingItem(liveItem)}>
							Delete
						</DangerButton>
					)}
					<SecondaryButton style={{ marginLeft: 'auto' }} onClick={onCancel}>
						Cancel
					</SecondaryButton>
					<PrimaryButton
						disabled={equals(liveItem, committedItem) || isSaving}
						onClick={onSave}
					>
						{isNew && (isSaving ? 'Creating…' : 'Create')}
						{!isNew && (isSaving ? 'Updating…' : 'Update')}
					</PrimaryButton>
				</Actions>
			</Header>

			<Content>
				<Fieldset>
					<FieldsetTitle>
						<label htmlFor="hero-feature">is Hero?</label>
					</FieldsetTitle>
					<Toggle
						id="hero-feature"
						checked={liveItem.isHero}
						onChange={checked => {
							setLiveItem(assoc('isHero', checked));
						}}
					/>
				</Fieldset>
				<Fieldset>
					<FieldsetTitle>
						<label htmlFor="solutions-select">Solution</label>
					</FieldsetTitle>
					<SolutionsSelect
						theme="admin"
						solutions={solutions}
						value={liveItem.solutionId}
						onChange={solutionId => setLiveItem(assoc('solutionId', solutionId))}
					/>
					{validationErrors.solutionId && (
						<ValidationError>{validationErrors.solutionId}</ValidationError>
					)}
				</Fieldset>
				<Fieldset>
					<FieldsetTitle>
						<label htmlFor="feature-description">Description</label>
					</FieldsetTitle>
					<Textarea
						id="feature-description"
						value={liveItem.description}
						onChange={event => setLiveItem(assoc('description', event.target.value))}
					/>
					{validationErrors.description && (
						<ValidationError>{validationErrors.description}</ValidationError>
					)}
				</Fieldset>
				{liveItem.url && (
					<Fieldset>
						<FieldsetTitle>Current feature image</FieldsetTitle>
						<a
							className="file__link"
							href={liveItem.url}
							target="_blank"
							rel="noopener noreferrer"
						>
							{getFileNameFromUrl(liveItem.url)}
							<SaveIcon size={20} />
						</a>
					</Fieldset>
				)}
				<Fieldset>
					<FieldsetTitle>
						<label htmlFor="file-picker">Upload new feature image</label>
					</FieldsetTitle>
					<input
						type="file"
						id="file-picker"
						name="file-picker"
						accept={WHITELISTED_FILE_TYPES.join(',')}
						onChange={event => {
							const file = event.target.files[0];
							setLiveItem(assoc('fileName', file.name));
							setLiveFile(assoc('data', file));
						}}
					/>
					{validationErrors.data && (
						<ValidationError>{validationErrors.data}</ValidationError>
					)}
				</Fieldset>
			</Content>

			{deletingItem && (
				<ModalContainer onClose={closeDeleteItemDialog}>
					<DeleteItemDialog
						type="feature"
						name={deletingItem.name}
						isDeleting={isDeleting}
						onCancel={closeDeleteItemDialog}
						onConfirm={onDelete}
					/>
				</ModalContainer>
			)}

			{!isEmpty(apiErrors) && (
				<ModalContainer onClose={closeErrorDialog}>
					<ErrorDialog
						type="API"
						errors={apiErrors}
						button={
							<SecondaryButton onClick={() => window.location.reload()}>
								Reload
							</SecondaryButton>
						}
						onClose={closeErrorDialog}
					/>
				</ModalContainer>
			)}

			<NavigationPrompt
				disableNative
				when={(currentLocation, nextLocation) =>
					currentLocation.pathname !== nextLocation.pathname &&
					!equals(liveItem, committedItem)
				}
			>
				{({ onConfirm, onCancel }) => (
					<ModalContainer onClose={onCancel}>
						<NavigateAwayDialog onCancel={onCancel} onConfirm={onConfirm} />
					</ModalContainer>
				)}
			</NavigationPrompt>
		</form>
	);
}

Feature.propTypes = {
	featureId: PropTypes.number,
	solutionId: PropTypes.number,
	history: PropTypes.shape({
		push: PropTypes.func.isRequired,
	}).isRequired,
};

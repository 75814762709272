import React from 'react';

const Icon = ({ size, ...otherProps }) => {
	const props = {
		width: size,
		height: size,
		fill: 'currentcolor',
		...otherProps,
	};
	return (
		<svg viewBox="0 0 40 40" {...props}>
			<g fill="none" fillRule="evenodd">
				<path d="M0 0h40v40H0z" />
				<path
					fill="#76B75B"
					fillRule="nonzero"
					d="M9.75 2.5h14L34.25 13v21a3.51 3.51 0 0 1-3.5 3.5h-21a3.51 3.51 0 0 1-3.5-3.5V6a3.51 3.51 0 0 1 3.5-3.5zm6.24 22.8h-1.514c-.034.474-.166.824-.396 1.05-.23.227-.58.34-1.047.34-.518 0-.898-.194-1.138-.583-.24-.389-.36-1.004-.36-1.845v-.588c.006-.806.134-1.4.385-1.784.25-.383.628-.574 1.133-.574.464 0 .81.113 1.037.34.227.225.36.59.396 1.093H16c-.08-.87-.38-1.546-.898-2.027C14.584 20.24 13.9 20 13.053 20c-.612 0-1.15.152-1.612.457-.463.305-.819.743-1.068 1.313C10.124 22.34 10 23 10 23.75v.476c0 1.173.27 2.095.81 2.767.54.672 1.28 1.008 2.223 1.008.861 0 1.555-.24 2.081-.722.527-.481.819-1.14.876-1.979zm5.393.55a.756.756 0 0 1-.322.65c-.215.155-.514.233-.897.233-1.032 0-1.547-.43-1.547-1.29H17c0 .504.13.946.39 1.327.26.382.636.682 1.13.901.494.22 1.042.329 1.644.329.866 0 1.556-.192 2.068-.575.512-.383.768-.912.768-1.585 0-.606-.21-1.113-.628-1.52-.42-.406-1.087-.746-2.004-1.02-.498-.15-.875-.31-1.13-.482-.257-.17-.385-.383-.385-.636 0-.271.11-.49.328-.655.218-.166.524-.249.918-.249.409 0 .726.099.951.297.226.198.339.475.339.831H23c0-.467-.12-.884-.363-1.25a2.375 2.375 0 0 0-1.017-.854c-.437-.201-.935-.302-1.494-.302-.555 0-1.055.093-1.501.278-.446.185-.788.443-1.026.773s-.357.705-.357 1.125c0 .81.444 1.453 1.332 1.931.326.175.768.352 1.327.532.558.18.945.351 1.16.513.215.163.322.395.322.698zm6.11.166L25.758 20H24l2.668 8h1.654L31 20h-1.765l-1.743 6.016zM24 6v7h7l-7-7z"
				/>
			</g>
		</svg>
	);
};

export const CsvIcon = Icon;

CsvIcon.defaultProps = {
	size: 24,
};

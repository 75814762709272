import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { ApButtonLink, ApIcon } from '@alixpartners/ui-components';

import { LinesEllipsis } from 'components/LinesEllipsis';
import { MaxWidthLayout } from 'components/MaxWidthLayout';
import { Media } from 'components/Media';
import { BreadCrumbList } from 'components/BreadCrumb';
import { areasProps } from 'product/ProductPage';
import classNames from 'classnames';
import { useWindowSize } from 'hooks/useWindowSize';
import { getAnchor } from 'utils/urlUtils';
import { compareRenderedTextLengths } from 'utils/parseUtils';

import './ProductDetail.css';

export const ProductDetail = withRouter(({ history, product, heroFeature }) => {
	const { name, description, version, buttonText, appUrl } = product;
	const [isReadMoreOpened, setIsReadMoreOpened] = useState(false);
	const [initialized, setInitialized] = useState(false);
	const [prevScreenWidth, setPrevScreenWidth] = useState(false);
	const [showMore, setShowMore] = useState(null);
	const hasHeroFeature = !!heroFeature && !!heroFeature.url;
	const hasProductVersion = !!version && !!version.trim();
	const viewportWidth = useWindowSize().width;
	const isMobile = viewportWidth < 768;
	let maxLinesCollapsed = viewportWidth < 1167 && viewportWidth > 768 ? 4 : 7;

	if (isMobile) {
		maxLinesCollapsed = 5;
	}

	if (viewportWidth !== prevScreenWidth) {
		setPrevScreenWidth(viewportWidth);
		setTimeout(() => {
			const textContainer = document.querySelector('.LinesEllipsis div:first-child');
			textContainer &&
				setShowMore(!compareRenderedTextLengths(textContainer, description.length));
		}, 10);
	}
	useEffect(() => {
		if (!initialized) {
			setInitialized(true);
		}
		if (initialized && showMore === null) {
			// get the text container
			const textContainer = document.querySelector('.LinesEllipsis div:first-child');
			if (!textContainer) return;

			// set the state to show more or less
			setShowMore(!compareRenderedTextLengths(textContainer, description.length));
		}
	});
	const isAppUrlSamePage = appUrl && appUrl.includes(window.location.pathname);

	return (
		<section
			className={classNames('product-detail', {
				'product-detail--with-hero-feature': hasHeroFeature,
			})}
		>
			<MaxWidthLayout>
				<BreadCrumbList>
					<ApButtonLink
						theme="dark"
						onClick={() => {
							history.goBack();
						}}
					>
						<ApIcon
							iconName="baseline_keyboard_arrow_left"
							iconColor="var(--color-brand)"
						/>
						Back
					</ApButtonLink>
				</BreadCrumbList>
				<div className="product-detail__row">
					<div
						className={
							hasHeroFeature ? 'product-detail__left' : 'product-detail__row-expanded'
						}
					>
						<h2 className="product-detail__title">{name}</h2>
						{hasProductVersion && (
							<h3
								className="product-detail__version"
								title={version}
							>{`Version: ${version}`}</h3>
						)}
						<div className="product-detail__description">
							{product.description && (
								<LinesEllipsis
									text={description}
									className="product-detail__info"
									maxLine={isReadMoreOpened ? 30 : maxLinesCollapsed}
									ellipsis=""
								/>
							)}
							{showMore && (
								<div
									className="product-detail__read-more"
									onClick={() => {
										setIsReadMoreOpened(!isReadMoreOpened);
									}}
								>
									{`Read ${isReadMoreOpened ? 'less' : 'more'}`}
								</div>
							)}
						</div>
						{buttonText && appUrl && !isAppUrlSamePage && (
							<a
								href={appUrl}
								className="product-detail__product-button-url"
								target={isAppUrlSamePage ? '_self' : '_blank'}
								rel="noopener noreferrer"
							>
								<button className="button button--primary" type="button">
									{buttonText}
								</button>
							</a>
						)}
						{buttonText && appUrl && isAppUrlSamePage && (
							<div className="product-detail__product-button-url">
								<button
									className="button button--primary"
									type="button"
									onClick={() => {
										const anchor = getAnchor(appUrl);
										history.replace(`${window.location.pathname}#${anchor}`);
									}}
								>
									{buttonText}
								</button>
							</div>
						)}
					</div>
					{hasHeroFeature && (
						<div className="product-detail__right">
							<div className="product-detail__hero-image">
								<Media
									previewClassName="product-detail__video"
									extension={heroFeature.extension}
									url={heroFeature.url}
									previewImageUrl={heroFeature.previewImageUrl}
									alt={heroFeature.name || product.name}
									width="100%"
									height="100%"
									controls
								/>
							</div>
						</div>
					)}
				</div>
			</MaxWidthLayout>
		</section>
	);
});

ProductDetail.propTypes = {
	product: PropTypes.shape({
		id: PropTypes.number.isRequired,
		name: PropTypes.string.isRequired,
	}).isRequired,
	productVersion: PropTypes.string,
	heroFeature: PropTypes.shape({
		url: PropTypes.string.isRequired,
		extension: PropTypes.string.isRequired,
		name: PropTypes.string,
	}),
	...areasProps,
};

ProductDetail.defaultProps = {
	areas: [],
	heroFeature: null,
};

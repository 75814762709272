import { BLOCKED_FILE_TYPES } from 'data/blockedExtensions';

export function getFileExtension(fileName) {
	const ext = /^.+\.([^.]+)$/.exec(fileName);
	if (!ext) {
		return null;
	}
	return `.${ext[1]}`;
}

export function isValidateFileType(fileName) {
	const ext = getFileExtension(fileName);
	if (!ext) {
		return false;
	}
	return !BLOCKED_FILE_TYPES.includes(ext);
}

/**
 *
 * @param {string} url - Example URL format is https://alxp.blob.core.windows.net/example.png
 */
export function getFileNameFromUrl(url) {
	if (!url) {
		return '';
	}
	const startIndex = url.lastIndexOf('/') + 1;
	const endIndex = url.indexOf('?');
	return url.substring(startIndex, endIndex);
}

export function formatBytesInKiloBytes(size) {
	if (!size || isNaN(Number(size))) {
		return `Unknown size`;
	}
	return `${Math.ceil(size / 1000)} KB`;
}

export function formatFileExtension(extension) {
	const extensions = {
		'text/csv': 'CSV',
		'application/msword': 'DOC',
		'application/vnd.openxmlformats-officedocument.wordprocessingml.document': 'DOCX',
		'image/jpeg': 'JPG',
		'image/png': 'PNG',
		'application/pdf': 'PDF',
		'application/vnd.ms-powerpoint': 'PPT',
		'application/vnd.openxmlformats-officedocument.presentationml.presentation': 'PPTX',
		'application/rtf': 'RTF',
		'text/plain': 'TXT',
		'application/vnd.ms-excel': 'XLS',
		'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': 'XLSX',
		'application/zip': 'ZIP',
		'application/x-zip-compressed': 'ZIP',
		'application/x-rar-compressed': 'RAR',
		'application/octet-stream': 'RAR',
		'application/gzip': 'GZ',
		'application/x-gzip': 'GZ',
		'application/x-7z-compressed': '7Z',
		'video/mp4': 'MP4',
		'video/quicktime': 'MOV',
		'video/x-flv': 'FLV',
		'video/x-ms-wmv': 'WMV',
		'video/avi': 'AVI',
	};
	return extensions[extension] || 'Unknown extension';
}

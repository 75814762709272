import React from 'react';
import PropTypes from 'prop-types';
import { Tooltip } from 'react-tippy';

import './PopoutMenu.css';

export function PopoutMenu({ theme, options, renderOption, renderTrigger }) {
	const [open, setOpen] = React.useState(false);

	return (
		<Tooltip
			open={open}
			useContext
			trigger="manual"
			position="bottom-end"
			interactive
			animateFill={false}
			hideDelay={500}
			distance="0"
			className={`popout-menu ${theme}`}
			theme="light app-tooltip settings-tooltip popout-menu"
			style={{ display: 'inline-flex' }}
			onRequestClose={() => setOpen(false)}
			html={
				<ul className="popout-menu__list">
					{options.map(option => (
						<li
							className="popout-menu__list-item"
							key={option.label}
							onClick={() => setOpen(false)}
						>
							{renderOption(option)}
						</li>
					))}
				</ul>
			}
		>
			<div
				onClick={e => {
					setOpen(!open);
					e.stopPropagation();
				}}
			>
				{renderTrigger && renderTrigger()}
			</div>
		</Tooltip>
	);
}

PopoutMenu.propTypes = {
	theme: PropTypes.string,
	renderTrigger: PropTypes.func.isRequired,
	renderOption: PropTypes.func.isRequired,
};

PopoutMenu.defaultProps = {
	theme: 'dark-nav',
};

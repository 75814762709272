import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import './ProductGridSelectItem.css';

export function ProductGridSelectItem({
	index,
	item,
	onClick,
	onEdit,
	onDelete,
	ItemMenu,
}) {
	const { name, version, lastUpdatedDate } = item;
	return (
		<div
			className="product-grid-select-item"
			style={{ cssText: `--grid-item-index: ${index}` }}
			onClick={() => onClick(item)}
			onKeyPress={() => onClick(item)}
		>
			{ItemMenu && (
				<div className="product-grid-select-item__menu">
					<ItemMenu item={item} onEdit={onEdit} onDelete={onDelete} />
				</div>
			)}
			<div className="product-grid-select-item__contents">
				<div className="product-grid-select-item__title" title={name}>
					{name}
				</div>
			</div>
			<div className="product-grid-select-item__footer">
				<label className="product-grid-select-item__footer-label">
					Version: {version || '-'}
				</label>
				<label className="product-grid-select-item__footer-label">
					Updated: {lastUpdatedDate ? moment(lastUpdatedDate).format(' DD/MM/YYYY') : '-'}
				</label>
			</div>
		</div>
	);
}

ProductGridSelectItem.propTypes = {
	index: PropTypes.number.isRequired,
	item: PropTypes.shape({
		id: PropTypes.oneOfType([PropTypes.number, PropTypes.number]).isRequired,
		name: PropTypes.string.isRequired,
	}).isRequired,
	onClick: PropTypes.func,
	onDelete: PropTypes.func,
	onEdit: PropTypes.func,
	renderItemMenu: PropTypes.func,
};

import React from 'react';
import PropTypes from 'prop-types';
import HTMLEllipsis from 'react-lines-ellipsis/lib/html';
import anchorme from 'anchorme';
import classNames from 'classnames';

import './LinesEllipsis.css';

export function LinesEllipsis({ text, className, maxLine, ...props }) {
	return (
		<HTMLEllipsis
			unsafeHTML={anchorme(text || '', {
				attributes: [
					{
						name: 'target',
						value: '_blank',
					},
				],
			}).replaceAll('\n', '<br>')}
			maxLine={maxLine}
			basedOn="letters"
			className={classNames('line-ellipsis', className)}
			{...props}
		/>
	);
}

LinesEllipsis.propTypes = {
	text: PropTypes.string.isRequired,
	maxLine: PropTypes.number,
};

LinesEllipsis.defaultProps = {
	maxLine: 25,
};

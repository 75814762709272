import React from 'react';
import PropTypes from 'prop-types';
import { equals, isEmpty } from 'ramda';
import NavigationPrompt from 'react-router-navigation-prompt';

import { PrimaryButton, SecondaryButton, IconButton } from 'components/Button';
import { ModalContainer } from 'components/ModalContainer';
import { NavigateAwayDialog } from 'components/NavigateAwayDialog';
import { Label } from 'components/Label';
import { LabelWithCount } from 'components/LabelWithCount';
import { Input } from 'components/Input';
import { Toggle } from 'components/Toggle';
import { PageErrorDialog } from 'components/PageErrorDialog';
import { Textarea } from 'components/Textarea';
import { FilePicker } from 'components/FilePicker';
import { ErrorLabel } from 'components/ErrorLabel';

import {
	EditorPageFieldset,
	EditorPageSeparator,
	EditorPageSectionTitle,
	EditorSectionActions,
} from 'content-editor/EditorPageLayout';

import { PRODUCT_SECTION_PARAM_KEY, FEATURE_PARAM_KEY } from 'products/Products';
import { EditProductFeaturesUpload } from 'product-editor/EditProductFeaturesUpload';
import { PRODUCT_FEATURES } from 'product-editor/constants';
import {
	WHITELISTED_FILE_TYPES,
	WHITELISTED_IMAGE_TYPES,
	FEATURE_DESCRIPTION_MAX_LENGTH,
	getValidItem,
	getValidationErrors,
} from 'admin/tabs/Feature';
import { createFeature, updateFeature, uploadFile } from 'utils/apiUtils';
import { useIsMounted } from 'hooks/useIsMounted';
import { getNumberFromString } from 'utils/parseUtils';

import './EditProductFeaturesSection.css';

const DEFAULT_FEATURE = {
	name: '',
	isHero: false,
	description: '',
};

function getFeature(features, featureId) {
	return features
		? features.find(e => e.id === getNumberFromString(featureId))
		: DEFAULT_FEATURE;
}

function getPreviewImage(file) {
	return new Promise(resolve => {
		const reader = new FileReader();
		reader.onload = () => {
			resolve(reader.result);
		};
		reader.readAsDataURL(file);
	});
}

export function EditProductFeaturesSection({
	productId: solutionId,
	url,
	history,
	features,
	featureId,
	refreshFeaturesList,
}) {
	const isNew = featureId === 'new';
	const featureUrl = `${url}?${PRODUCT_SECTION_PARAM_KEY}=${PRODUCT_FEATURES}`;
	const defaultFeature = { ...DEFAULT_FEATURE, solutionId };

	const feature =
		(isNew ? defaultFeature : getFeature(features, featureId)) || defaultFeature;

	const [liveItem, setLiveItem] = React.useState(feature);
	const [previewImageFile, setPreviewImageFile] = React.useState(feature);
	const [previewImageEncodedUrl, setPreviewImageEncodedUrl] = React.useState(null);
	const [committedItem, setCommittedItem] = React.useState(null);
	const [isSaving, setIsSaving] = React.useState(false);
	const [liveFile, setLiveFile] = React.useState({});
	const [apiErrors, setApiErrors] = React.useState({});
	const [validationErrors, setValidationErrors] = React.useState({});

	const isMounted = useIsMounted();

	React.useEffect(() => {
		let newFeature = { ...defaultFeature };

		if (!isNew) {
			newFeature = getFeature(features, featureId);
		}

		setLiveItem(newFeature);
		setCommittedItem(newFeature);
		setLiveFile({});
		setPreviewImageFile({});
		setPreviewImageEncodedUrl(null);
	}, [featureId]);

	function closeErrorDialog() {
		setApiErrors({});
	}

	function handleChange(event) {
		const { name, value } = event.target;
		const newFeature = { ...liveItem };
		newFeature[name] = value;

		setLiveItem(newFeature);
	}

	async function handleUpdateFeature() {
		const validItem = getValidItem(liveItem);
		const errors = getValidationErrors(validItem, liveFile.data, isNew);
		if (!isEmpty(errors)) {
			setValidationErrors(errors);
			return;
		}
		setValidationErrors({});
		setIsSaving(true);
		const action = isNew ? createFeature : updateFeature;
		action(validItem)
			.then(item => {
				if (!isMounted()) {
					return;
				}

				if (!liveFile.data && !previewImageFile.data) {
					onFileSaveSuccess(item);
					return;
				} else {
					const queries = [];

					if (previewImageFile.data) {
						queries.push(uploadFile(item.previewImageUrl, previewImageFile.data));
					}

					if (liveFile.data) {
						queries.push(uploadFile(item.url, liveFile.data));
					}

					Promise.all(queries)
						.then(() => {
							if (!isMounted()) {
								return;
							}

							onFileSaveSuccess(item);
						})
						.catch(error => {
							if (!isMounted()) {
								return;
							}

							setIsSaving(false);
							setApiErrors({ file: error });
						});
				}
			})
			.catch(error => {
				if (!isMounted()) {
					return;
				}
				setIsSaving(false);
				setApiErrors({ feature: error });
			});
	}

	function onFileSaveSuccess(item) {
		const itemWithExtension = { ...item, extension };
		setLiveItem(itemWithExtension);
		setCommittedItem(itemWithExtension);
		setIsSaving(false);

		if (isNew) {
			refreshFeaturesList().then(() => {
				const newProductUrl = `${featureUrl}&${FEATURE_PARAM_KEY}=${item.id}`;
				history.push(newProductUrl);
			});
		} else {
			refreshFeaturesList();
		}
	}

	const { name, isHero, description } = liveItem;
	const extension =
		(liveFile && liveFile.data && liveFile.data.type) || liveItem.extension;

	const sectionTitle = isNew ? 'New feature' : 'Edit feature';
	const isFileTypeVideo = extension && extension.indexOf('video') !== -1;
	const previewImageUrl = previewImageEncodedUrl || liveItem.previewImageUrl;

	let videoFileUrl = null;
	if (isFileTypeVideo) {
		videoFileUrl = liveFile.data ? URL.createObjectURL(liveFile.data) : liveItem.url;
	}

	return (
		<section className="edit-products-feature-section">
			<EditorPageSectionTitle title={sectionTitle} />
			<EditorPageSeparator />
			<EditorPageFieldset>
				<Label htmlFor="product-feature-name" value="feature title" />
				<Input
					id="product-feature-name"
					name="name"
					type="text"
					autoFocus
					value={name}
					onChange={handleChange}
				/>
				<ErrorLabel error={validationErrors.name} />
			</EditorPageFieldset>
			<EditorPageFieldset>
				<LabelWithCount
					htmlFor="feature-description"
					value="feature Description"
					count={`${
						description ? description.length : 0
					} / ${FEATURE_DESCRIPTION_MAX_LENGTH}`}
				/>
				<Textarea
					id="feature-description"
					name="description"
					maxLength={FEATURE_DESCRIPTION_MAX_LENGTH}
					value={description || ''}
					onChange={handleChange}
				/>
				<ErrorLabel error={validationErrors.description} />
			</EditorPageFieldset>
			<EditorPageSeparator theme="dark" />
			<div className="edit-products-feature-section__hero-fieldset">
				<Toggle
					id="hero-feature"
					name="isHero"
					label="Use as Hero image/video"
					checked={isHero}
					onChange={checked => {
						handleChange({ target: { name: 'isHero', value: checked } });
					}}
				/>
			</div>
			<EditorPageFieldset>
				<EditProductFeaturesUpload
					selectedFileName={liveFile.data ? liveFile.data.name : liveItem.fileName}
					videoFileUrl={videoFileUrl}
					accept={WHITELISTED_FILE_TYPES.join()}
					label="Feature image or video"
					maxSize={100}
					onChange={files => {
						const file = files && files[0];
						if (!file) {
							return;
						}

						const newFeature = {
							...liveItem,
							fileName: file.name,
							previewImage: null,
							previewImageUrl: null,
						};

						setLiveItem(newFeature);
						setLiveFile({ data: file });
						setPreviewImageFile({});
						setPreviewImageEncodedUrl(null);
					}}
				/>
			</EditorPageFieldset>
			{isFileTypeVideo && (
				<EditorPageFieldset>
					<Label
						htmlFor="feature-media-picker-preview"
						value="cover image"
						className="edit-products-feature-section__cover-image-label"
					/>
					<div className="edit-products-feature-section__cover-image">
						<div className="edit-products-feature-section__cover-image-preview">
							<img src={previewImageUrl || '/images/cover_image.png'} alt="Cover" />
						</div>
						<div className="edit-products-feature-section__cover-image-picker">
							<FilePicker
								id="feature-media-picker-preview"
								label={previewImageUrl ? 'Upload new' : 'Upload custom'}
								name="file-picker"
								// Fix for issue when picking same file name after hiting use default
								key={previewImageEncodedUrl ? 'on' : 'off'}
								accept={WHITELISTED_IMAGE_TYPES.join()}
								onChange={event => {
									const file = event.target.files[0];
									if (!file) {
										return;
									}

									const newFeature = { ...liveItem, previewImage: file.name };
									setLiveItem(newFeature);
									setPreviewImageFile({ data: file });
									getPreviewImage(file).then(data => setPreviewImageEncodedUrl(data));
								}}
							/>
							{previewImageUrl && (
								<IconButton
									className="edit-products-feature-section__button-use-default"
									onClick={() => {
										const newFeature = {
											...liveItem,
											previewImage: null,
											previewImageUrl: null,
										};

										setLiveItem(newFeature);
										setPreviewImageFile({});
										setPreviewImageEncodedUrl(null);
									}}
								>
									Use Default
								</IconButton>
							)}
						</div>
					</div>
					{!previewImageUrl && (
						<div className="edit-products-feature-section__cover-image-default-text">
							(default)
						</div>
					)}
				</EditorPageFieldset>
			)}
			<EditorSectionActions isFixed>
				<SecondaryButton
					style={{ marginLeft: 'auto' }}
					onClick={() => {
						setLiveItem(committedItem);
						history.push(featureUrl);
					}}
				>
					Cancel
				</SecondaryButton>
				<PrimaryButton
					disabled={equals(liveItem, committedItem) || isSaving}
					onClick={handleUpdateFeature}
				>
					{isNew && (isSaving ? 'Adding feature...' : 'Add feature')}
					{!isNew && (isSaving ? 'Updating…' : 'Update')}
				</PrimaryButton>
			</EditorSectionActions>
			<PageErrorDialog apiErrors={apiErrors} onClose={closeErrorDialog} />
			<NavigationPrompt
				disableNative
				when={(currentLocation, nextLocation) =>
					currentLocation.pathname !== nextLocation.pathname &&
					!equals(liveItem, committedItem)
				}
			>
				{({ onConfirm, onCancel }) => (
					<ModalContainer onClose={onCancel}>
						<NavigateAwayDialog
							onCancel={onCancel}
							onConfirm={event => {
								onConfirm(event);
							}}
						/>
					</ModalContainer>
				)}
			</NavigationPrompt>
		</section>
	);
}

EditProductFeaturesSection.propTypes = {
	productId: PropTypes.number.isRequired,
	url: PropTypes.string.isRequired,
	history: PropTypes.object.isRequired,
	features: PropTypes.array.isRequired,
	featureId: PropTypes.string.isRequired,
	refreshFeaturesList: PropTypes.func.isRequired,
};

import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { assoc, equals, isEmpty } from 'ramda';
import NavigationPrompt from 'react-router-navigation-prompt';

import { DangerButton, PrimaryButton, SecondaryButton } from 'components/Button';
import { DeleteItemDialog } from 'components/DeleteItemDialog';
import { Input } from 'components/Input';
import { Label } from 'components/Label';
import { ModalContainer } from 'components/ModalContainer';
import { NavigateAwayDialog } from 'components/NavigateAwayDialog';
import { PageErrorDialog } from 'components/PageErrorDialog';
import { Select } from 'components/Select';

import {
	getValidItem,
	getValidationErrors,
	getApOfficeLocations,
	WHITELISTED_FILE_TYPES,
} from 'admin/tabs/Person';
import {
	EditorPageFieldset,
	EditorPageSeparator,
	EditorPageSectionTitle,
	EditorSectionActions,
} from 'content-editor/EditorPageLayout';
import { ErrorLabel } from 'components/ErrorLabel';
import { DEFAULT_PERSON } from 'experts/Experts';
import { ProductSelectList } from 'experts/ProductSelectList';
import { AreaSelectList } from 'experts/AreaSelectList';
import { createPerson, updatePerson, deletePerson, uploadFile } from 'utils/apiUtils';
import { sortByName } from 'utils/sortUtils';
import { getPageUrl } from 'utils/urlUtils';
import { mergeNullKeysToDefaults } from 'utils/parseUtils';
import { getFileNameFromUrl } from 'utils/fileUtils';
import { useIsMounted } from 'hooks/useIsMounted';
import { apOfficeLocations } from 'data/apOfficeLocations';

import './ExpertEditSection.css';

export function ExpertEditSection({
	history,
	person,
	isNew,
	onExpertUpdate,
	onChangeExpert,
}) {
	const [isSaving, setIsSaving] = React.useState(false);
	const [isDeleting, setIsDeleting] = React.useState(false);
	const [liveItem, setLiveItem] = React.useState(person);
	const [committedItem, setCommittedItem] = React.useState(person);
	const [liveFile, setLiveFile] = React.useState({});
	const [deletingItem, setDeletingItem] = React.useState(null);
	const [validationErrors, setValidationErrors] = React.useState({});
	const [apiErrors, setApiErrors] = React.useState({});

	const isMounted = useIsMounted();

	const editMode = !!person;

	const expertsUrl = getPageUrl('experts-base-url');

	React.useEffect(() => {
		const formattedItem = mergeNullKeysToDefaults({ ...DEFAULT_PERSON }, person);
		setLiveItem(formattedItem);
		setCommittedItem(formattedItem);
		return () => {
			setValidationErrors({});
		};
	}, [person]);

	function onSave() {
		const validItem = getValidItem(liveItem);
		const errors = getValidationErrors(validItem);
		if (!isEmpty(errors)) {
			setValidationErrors(errors);
			return;
		}
		setValidationErrors({});
		setIsSaving(true);
		const action = isNew ? createPerson : updatePerson;
		action(validItem)
			.then(item => {
				if (!isMounted()) {
					return;
				}
				onChangeExpert(false);
				if (!liveFile.data) {
					onFileSaveSuccess(item);
					return;
				} else {
					uploadFile(item.url, liveFile.data)
						.then(() => {
							if (!isMounted()) {
								return;
							}

							onFileSaveSuccess(item);
						})
						.catch(error => {
							if (!isMounted()) {
								return;
							}
							setIsSaving(false);
							setApiErrors({ file: error });
						});
				}
			})
			.catch(error => {
				if (!isMounted()) {
					return;
				}
				setIsSaving(false);
				setApiErrors({ person: error });
			});
	}

	function onFileSaveSuccess(item) {
		setCommittedItem(item);
		setLiveItem(item);
		setIsSaving(false);
		if (isNew) {
			history.push(`${expertsUrl}/${item.id}`);
		}
		onExpertUpdate();
	}

	function closeErrorDialog() {
		setApiErrors({});
	}

	function closeDeleteItemDialog() {
		setDeletingItem(null);
	}

	function onDelete() {
		setIsDeleting(true);
		deletePerson(deletingItem)
			.then(() => {
				if (!isMounted()) {
					return;
				}
				setIsDeleting(false);
				closeDeleteItemDialog();
				history.push(expertsUrl);
			})
			.catch(error => {
				if (!isMounted()) {
					return;
				}
				setIsDeleting(false);
				setApiErrors({ person: error });
			});
	}

	function updateLiveItem(event) {
		const { name, value } = event.target;
		const newLiveItem = { ...liveItem };
		newLiveItem[name] = value;
		setLiveItem(newLiveItem);
		onChangeExpert(!equals(newLiveItem, committedItem));
	}

	const mappedOfficeLocations = sortByName(getApOfficeLocations(apOfficeLocations));

	return (
		<section
			className={classNames('expert-edit-section', {
				'expert-edit-section__hint': !editMode,
			})}
		>
			{editMode && !!liveItem ? (
				<Fragment>
					<EditorPageSectionTitle title={isNew ? 'Add an expert' : 'Edit an expert'} />
					<EditorPageSeparator />
					<EditorPageFieldset>
						<Label htmlFor="expert-name" value="Expert Name" />
						<Input
							id="expert-name"
							placeholder="Enter name"
							name="name"
							type="text"
							autoFocus
							value={liveItem.name}
							onChange={updateLiveItem}
						/>
						<ErrorLabel error={validationErrors.name} />
					</EditorPageFieldset>
					<EditorPageFieldset>
						<Label htmlFor="expert-role" value="role" />
						<Input
							id="expert-name"
							placeholder="Enter role"
							name="role"
							type="text"
							value={liveItem.role || ''}
							onChange={updateLiveItem}
						/>
						<ErrorLabel error={validationErrors.role} />
					</EditorPageFieldset>
					<EditorPageFieldset>
						<Label htmlFor="expert-phoneNumber" value="Phone Number" />
						<Input
							id="expert-phoneNumber"
							placeholder="Enter phone number in international format"
							name="phoneNumber"
							type="text"
							value={liveItem.phoneNumber || ''}
							onChange={updateLiveItem}
						/>
						<ErrorLabel error={validationErrors.phoneNumber} />
					</EditorPageFieldset>
					<EditorPageFieldset>
						<Label htmlFor="expert-email" value="Email" />
						<Input
							id="expert-phoneNumber"
							placeholder="Enter email"
							name="email"
							type="text"
							value={liveItem.email || ''}
							onChange={updateLiveItem}
						/>
						<ErrorLabel error={validationErrors.email} />
					</EditorPageFieldset>
					<EditorPageFieldset>
						<Label htmlFor="expert-location" value="Location" />
						<Select
							id="expert-location"
							name="country"
							options={mappedOfficeLocations}
							label={liveItem.country || 'Select a location'}
							value={liveItem.country || ''}
							onChange={country => {
								updateLiveItem({ target: { name: 'country', value: country } });
							}}
						/>
						<ErrorLabel error={validationErrors.country} />
					</EditorPageFieldset>
					{(liveItem.url || liveItem.fileName) && (
						<EditorPageFieldset>
							<Label value="Current profile image" />
							<a
								className="expert-edit-section__link"
								href={liveItem.url}
								target="_blank"
								rel="noopener noreferrer"
							>
								{liveItem.url ? getFileNameFromUrl(liveItem.url) : liveItem.fileName}
							</a>
						</EditorPageFieldset>
					)}
					<EditorPageFieldset>
						<Label
							htmlFor="file-picker"
							value="Upload new profile image"
							className="expert-edit-section__file-picker-label"
						/>
						<input
							type="file"
							id="file-picker"
							className="expert-edit-section__file-picker-input"
							name="file-picker"
							accept={WHITELISTED_FILE_TYPES.join(',')}
							onChange={event => {
								const file = event.target.files[0];
								updateLiveItem({ target: { name: 'fileName', value: file.name } });
								setLiveFile(assoc('data', file));
							}}
						/>
						<ErrorLabel error={validationErrors.fileName} />
					</EditorPageFieldset>
					<EditorPageSeparator theme="dark" />
					{!isNew && (
						<Fragment>
							<ProductSelectList personId={liveItem.id} />
							<EditorPageSeparator theme="dark" />
						</Fragment>
					)}
					{!isNew && <AreaSelectList personId={liveItem.id} />}
					<EditorSectionActions isFixed>
						{!isNew && (
							<DangerButton
								disabled={isDeleting}
								onClick={() => setDeletingItem(liveItem)}
							>
								Remove expert
							</DangerButton>
						)}
						<SecondaryButton
							style={{ marginLeft: 'auto' }}
							onClick={() => {
								onChangeExpert(false);
								setLiveItem(committedItem);
								history.push(expertsUrl);
							}}
						>
							Cancel
						</SecondaryButton>
						<PrimaryButton
							disabled={equals(liveItem, committedItem) || isSaving}
							onClick={onSave}
						>
							{isNew && (isSaving ? 'Adding expert...' : 'Add an expert')}
							{!isNew && (isSaving ? 'Updating…' : 'Update')}
						</PrimaryButton>
					</EditorSectionActions>
				</Fragment>
			) : (
				<span>Please select an expert to edit</span>
			)}
			{deletingItem && (
				<ModalContainer onClose={closeDeleteItemDialog}>
					<DeleteItemDialog
						type="person"
						name={deletingItem.name}
						isDeleting={isDeleting}
						onCancel={closeDeleteItemDialog}
						onConfirm={onDelete}
					/>
				</ModalContainer>
			)}
			<PageErrorDialog apiErrors={apiErrors} onClose={closeErrorDialog} />

			<NavigationPrompt
				disableNative
				when={(currentLocation, nextLocation) =>
					currentLocation.pathname !== nextLocation.pathname &&
					!equals(liveItem, committedItem)
				}
			>
				{({ onConfirm, onCancel }) => (
					<ModalContainer onClose={onCancel}>
						<NavigateAwayDialog
							onCancel={onCancel}
							onConfirm={event => {
								onChangeExpert(false);
								onConfirm(event);
							}}
						/>
					</ModalContainer>
				)}
			</NavigationPrompt>
		</section>
	);
}

ExpertEditSection.propTypes = {
	history: PropTypes.object.isRequired,
	person: PropTypes.object,
	isNew: PropTypes.bool.isRequired,
	onExpertUpdate: PropTypes.func.isRequired,
	onChangeExpert: PropTypes.func.isRequired,
};

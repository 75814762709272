import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Router, Redirect, Route, Switch } from 'react-router-dom';
import ReactAI from 'react-appinsights';
import { createBrowserHistory } from 'history';
import { AuthenticationError } from 'components/AuthenticationError';
import { Overlay } from 'components/Overlay';
import { Loader } from 'components/Loader';
import { Home } from 'home/Home';
import { PageErrorDialog } from 'components/PageErrorDialog';
import { LegacyBrowserOverlay } from 'components/LegacyBrowserOverlay';
import {
	AuthenticationContextProvider,
	AuthenticationContextConsumer,
} from 'contexts/AuthenticationContext';
import { UnauthorizedDailog } from 'global/UnauthorizedDialog';
import { Admin } from 'admin/Admin';
import { SECTION_PARAM_KEY, ITEM_PARAM_KEY } from 'areas/Areas';
import {
	PRODUCT_SECTION_PARAM_KEY,
	FEATURE_PARAM_KEY,
	ATTACHMENT_PARAM_KEY,
} from 'products/Products';
import { Area } from 'area/Area';
import { ContentEditor } from 'content-editor/ContentEditor';
import { AreaPageEditor } from 'area-editor/AreaPageEditor';
import { AREA_DETAIL } from 'area-editor/constants';
import { ProductPage } from 'product/ProductPage';
import { ProductPageEditor } from 'product-editor/ProductPageEditor';
import { PRODUCT_DETAIL } from 'product-editor/constants';
import { Search } from 'search/Search';
import { SignIn } from 'sign-in/SignIn';
import {
	getPageUrl,
	getParamsFromQueryString,
	getUrlByTransformingName,
} from 'utils/urlUtils';
import { sortByOrder } from 'utils/sortUtils';
import { getAreas, getSolutions, getConfig, getEngagements } from 'utils/apiUtils';
import { HomeNavbar } from 'global/HomeNavbar';
import { MyEngagements } from 'my-engagements/myEngagements';

import { useIsMounted } from 'hooks/useIsMounted';
import { useWindowSize } from 'hooks/useWindowSize';

const history = createBrowserHistory();

// grab the application insights key and assign for the app
if (process.env.REACT_APP_INSIGHT_KEY) {
	ReactAI.init({ instrumentationKey: process.env.REACT_APP_INSIGHT_KEY }, history);
}

function formatAreas(areas) {
	return areas.map(area => ({
		...area,
		url: area.url || getUrlByTransformingName(area.name),
	}));
}

function formatSolutions(solutions) {
	return solutions
		? solutions.map(solution => ({
				...solution,
				url: solution.url || getUrlByTransformingName(solution.name),
		  }))
		: [];
}

function App({ user }) {
	const [homeNavbarProps, setHomeNavbarProps] = React.useState({});
	const [areas, setAreas] = React.useState([]);
	const [products, setProducts] = React.useState([]);
	const [isLoading, setIsLoading] = React.useState(true);
	const [apiErrors, setApiErrors] = React.useState({});
	const [config, setConfig] = React.useState({});
	const [engagements, setEngagements] = React.useState({});

	const isMounted = useIsMounted();
	const windowSize = useWindowSize();

	const loadData = () => {
		const oid = user && user.profile ? user.profile.oid : '';
		Promise.all([getSolutions(), getAreas(), getConfig(), getEngagements(oid)])
			.then(([solutions, areas, config, engagements]) => {
				if (!isMounted()) {
					return;
				}

				setProducts(sortByOrder(formatSolutions(solutions)));
				setAreas(sortByOrder(formatAreas(areas)));
				setConfig(config);
				setEngagements(engagements);
				setIsLoading(false);
			})
			.catch(error => {
				if (!isMounted()) {
					return;
				}
				setIsLoading(false);
				setApiErrors({ products: error });
			});
	};
	React.useEffect(loadData, []);

	function closeErrorDialog() {
		setApiErrors({});
	}

	if (!user) {
		return null;
	}

	if (isLoading) {
		return (
			<div className="home" style={{ minHeight: windowSize.height }}>
				<Overlay>
					<Loader />
				</Overlay>
			</div>
		);
	}

	const isAdmin =
		user &&
		user.profile &&
		user.profile.roles &&
		(user.profile.roles.includes('HPAdmin') ||
			user.profile.roles.includes('HPContentEditor'));
	const contentEditUrl = getPageUrl('content-editor');
	const areaContentEditUrl = getPageUrl('content-editor-areas');
	const productContentEditUrl = getPageUrl('content-editor-products');
	const homeNavbarPath = /^(?!.*(\/admin|\/new-engagement|\/content-editor|\/signin)).*$/;

	return (
		<Fragment>
			<LegacyBrowserOverlay />
			<Router history={history}>
				<Route
					exact
					path={homeNavbarPath}
					render={routeProps => (
						<HomeNavbar
							config={config}
							areas={areas}
							{...routeProps}
							{...homeNavbarProps}
						/>
					)}
				/>
				<Switch>
					<Route
						exact
						path="/"
						render={routeProps => <Home config={config} areas={areas} {...routeProps} />}
					/>
					<Route exact path="/signin" render={routeProps => <SignIn {...routeProps} />} />
					<Route
						exact
						path="/search"
						render={routeProps => {
							if (homeNavbarProps.selectedAreaId) {
								setHomeNavbarProps({ selectedAreaId: null });
							}
							return <Search config={config} areas={areas} {...routeProps} />;
						}}
					/>
					<Route
						path="/myEngagements"
						render={history => <MyEngagements user={user} engagements={engagements} />}
					/>
					<Route
						exact
						path={`/ip/:productUrl`}
						render={({ match }) => {
							const product = products.find(
								product => product.url === match.params.productUrl
							);

							if (!product) {
								return <Redirect to="/" />;
							}

							if (homeNavbarProps.selectedAreaId) {
								setHomeNavbarProps({ selectedAreaId: null });
							}

							return <ProductPage product={product} areas={areas} config={config} />;
						}}
					/>
					<Route
						path="/admin"
						render={({ history }) =>
							isAdmin ? <Admin /> : <UnauthorizedDailog history={history} />
						}
					/>
					<Route
						path={`${areaContentEditUrl}/:areaId`}
						render={({ history, location, match }) => {
							const contentEditorParams = getParamsFromQueryString(location);
							const sectionKey = contentEditorParams[SECTION_PARAM_KEY];
							const itemId = contentEditorParams[ITEM_PARAM_KEY];
							if (!sectionKey) {
								return (
									<Redirect to={`${match.url}?${SECTION_PARAM_KEY}=${AREA_DETAIL}`} />
								);
							}
							return isAdmin ? (
								<AreaPageEditor
									match={match}
									history={history}
									location={location}
									sectionKey={sectionKey}
									itemId={itemId}
									config={config}
									areas={areas}
									refreshData={loadData}
								/>
							) : (
								<UnauthorizedDailog history={history} />
							);
						}}
					/>
					<Route
						path={`${productContentEditUrl}/:productId`}
						render={({ history, location, match }) => {
							const contentEditorParams = getParamsFromQueryString(location);
							const sectionKey = contentEditorParams[PRODUCT_SECTION_PARAM_KEY];
							const featureId = contentEditorParams[FEATURE_PARAM_KEY];
							const attachmentId = contentEditorParams[ATTACHMENT_PARAM_KEY];
							if (!sectionKey) {
								return (
									<Redirect
										to={`${match.url}?${PRODUCT_SECTION_PARAM_KEY}=${PRODUCT_DETAIL}`}
									/>
								);
							}
							return isAdmin ? (
								<ProductPageEditor
									match={match}
									history={history}
									location={location}
									sectionKey={sectionKey}
									featureId={featureId}
									attachmentId={attachmentId}
									config={config}
									areas={areas}
									products={products}
									refreshData={loadData}
								/>
							) : (
								<UnauthorizedDailog history={history} />
							);
						}}
					/>

					<Route
						path={contentEditUrl}
						render={props =>
							isAdmin ? (
								<ContentEditor {...props} config={config} />
							) : (
								<UnauthorizedDailog history={history} />
							)
						}
					/>

					{/* Need to check if we need URL as `/areaName/areaId` */}
					<Route
						path="/:areaUrl"
						render={({ match }) => {
							const area = areas.find(a => a.url === match.params.areaUrl);

							if (!area) {
								return <Redirect to="/" />;
							}

							if (homeNavbarProps.selectedAreaId !== area.id) {
								setHomeNavbarProps({ selectedAreaId: area.id });
							}

							return <Area areaId={area.id} areas={areas} config={config} />;
						}}
					/>
					<Redirect to="/" />
				</Switch>
			</Router>
			<PageErrorDialog apiErrors={apiErrors} onClose={closeErrorDialog} />
		</Fragment>
	);
}

App.propTypes = {
	user: PropTypes.shape({
		profile: PropTypes.shape({
			email: PropTypes.string.isRequired,
		}).isRequired,
	}).isRequired,
};

export function AppWithAuthContext() {
	return (
		<AuthenticationContextProvider errorComponent={AuthenticationError}>
			<AuthenticationContextConsumer>
				{({ user }) => <App user={user} />}
			</AuthenticationContextConsumer>
		</AuthenticationContextProvider>
	);
}

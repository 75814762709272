import React from 'react';
import PropTypes from 'prop-types';

import { HtmlView } from 'components/HtmlView';
import { PageSectionTitle } from 'components/PageSectionTitle';
import { MaxWidthLayout } from 'components/MaxWidthLayout';

import './WhenToUse.css';

export function WhenToUse({ text }) {
	return (
		<section className="when-to-use">
			<MaxWidthLayout>
				<PageSectionTitle>When to use</PageSectionTitle>
				<div className="when-to-use__text">
					<HtmlView text={text} />
				</div>
			</MaxWidthLayout>
		</section>
	);
}

WhenToUse.propTypes = {
	text: PropTypes.string.isRequired,
};

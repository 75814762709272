import React from 'react';
import PropTypes from 'prop-types';
import { MaxWidthLayout } from 'components/MaxWidthLayout';
import { PageSectionTitle } from 'components/PageSectionTitle';
import { LoadMoreButton } from 'components/LoadMoreButton';
import { ProductListMasonry } from 'components/ProductListMasonry';

import './ProductList.css';

const breakpointColumnsObj = {
	default: 4,
	1166: 3,
	959: 2,
	767: 1,
};

export const ProductList = ({
	isKbArea,
	productsSectionTitle,
	products,
	isLoading,
	hasMoreProducts,
	onLoadMore,
}) => (
	<section className="product-list">
		<MaxWidthLayout>
			<PageSectionTitle>{productsSectionTitle}</PageSectionTitle>
			<ProductListMasonry
				showWhenToUse
				products={products}
				breakpointCols={breakpointColumnsObj}
				className="product-list__masonry-grid"
				columnClassName="product-list__masonry-grid-column"
				isExternalLink={isKbArea}
			/>
			{hasMoreProducts && onLoadMore && (
				<LoadMoreButton onClick={onLoadMore} isLoading={isLoading} />
			)}
		</MaxWidthLayout>
	</section>
);

ProductList.propTypes = {
	isKbArea: PropTypes.bool,
	productsSectionTitle: PropTypes.string.isRequired,
	onLoadMore: PropTypes.func,
	isLoading: PropTypes.bool,
	hasMoreProducts: PropTypes.bool,
	products: PropTypes.arrayOf(
		PropTypes.shape({
			id: PropTypes.number.isRequired,
			name: PropTypes.string.isRequired,
			introText: PropTypes.string,
			whenToUseInfo: PropTypes.string,
		})
	),
};

import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import { PageSectionTitle } from 'components/PageSectionTitle';
import { HtmlView } from 'components/HtmlView';
import { MaxWidthLayout } from 'components/MaxWidthLayout';

import './LatestUpdateSection.css';

const DAY = 'day';

export function formatUpdatedonDate(date) {
	if (!date) {
		return '-';
	}

	const todaysDate = moment();
	const momentDate = moment(date);

	if (momentDate.isSame(todaysDate, DAY)) {
		return 'Today';
	} else if (todaysDate.isAfter(momentDate, DAY)) {
		return momentDate.from(todaysDate);
	} else {
		return momentDate.from(todaysDate);
	}
}

export function LatestUpdateSection({ product }) {
	const { latestUpdateDescription, version } = product;

	return (
		<section className="latest-update__section">
			<MaxWidthLayout>
				<div className="latest-update__section-content">
					<PageSectionTitle>Latest Updates</PageSectionTitle>
					<div className="latest-update__section-details">
						<div className="latest-update__section-detail">
							<label className="latest-update__section-detail-label">
								Current version
							</label>
							<label className="latest-update__section-detail-value" title={version}>
								{version || '-'}
							</label>
						</div>
					</div>
					{latestUpdateDescription ? (
						<HtmlView
							text={latestUpdateDescription}
							className="latest-update__section-description"
						/>
					) : (
						<p className="latest-update__section-description latest-update__section-description--none">
							No description
						</p>
					)}
				</div>
			</MaxWidthLayout>
		</section>
	);
}

LatestUpdateSection.propTypes = {
	product: PropTypes.shape({
		version: PropTypes.string,
		latestUpdateDescription: PropTypes.string,
	}).isRequired,
};

import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import './Input.css';

export function Input({ className, ...props }) {
	return <input className={classNames('input', className)} {...props} />;
}

Input.propTypes = {
	type: PropTypes.string,
	placeholder: PropTypes.string,
};

Input.defaultProps = {
	type: 'text',
	placeholder: 'Please enter text',
};

import React from 'react';
import PropTypes from 'prop-types';

import { Label } from 'components/Label';
import { PageErrorDialog } from 'components/PageErrorDialog';

import { SearchableSelect } from 'components/SearchableSelect';
import { EditorPageFieldset } from 'content-editor/EditorPageLayout';
import { SelectedItemsList } from 'experts/SelectedItemsList';
import {
	getSolutions,
	getSolutionsByPerson,
	patchPersonForSolution,
} from 'utils/apiUtils';
import { useIsMounted } from 'hooks/useIsMounted';

import './ProductSelectList.css';

const PATCH_ADD = 'add';
const PATCH_REMOVE = 'remove';

function getMappedSolutions(solutions) {
	return solutions
		? solutions.map(solution => ({
				...solution,
				label: solution.name,
				value: solution.id,
		  }))
		: [];
}

export function ProductSelectList({ personId }) {
	const [products, setProducts] = React.useState([]);
	const [personProducts, setPersonProducts] = React.useState([]);
	const [apiErrors, setApiErrors] = React.useState({});

	const isMounted = useIsMounted();

	React.useEffect(() => {
		let queries = [getSolutions()];
		if (personId) {
			queries = [...queries, getSolutionsByPerson(personId)];
		}
		Promise.all(queries)
			.then(([solutions, personSolutions]) => {
				if (!isMounted()) {
					return;
				}
				setProducts(getMappedSolutions(solutions));
				setPersonProducts(getMappedSolutions(personSolutions));
			})
			.catch(error => {
				if (!isMounted()) {
					return;
				}
				setApiErrors({ products: error });
			});
	}, [personId]);

	function addOrRemovePerson(operation, solution) {
		patchPersonForSolution(operation, personId, solution.id)
			.then(() => {
				if (!isMounted()) {
					return;
				}
				if (operation === PATCH_ADD) {
					const newPersonProducts = [...personProducts];
					newPersonProducts.push(solution);
					setPersonProducts(newPersonProducts);
				} else if (operation === PATCH_REMOVE) {
					setPersonProducts(personProducts.filter(product => product.id !== solution.id));
				}
			})
			.catch(error => {
				if (!isMounted()) {
					return;
				}
				setApiErrors({ person: error });
			});
	}

	function closeErrorDialog() {
		setApiErrors({});
	}

	return (
		<div className="products-select-list">
			<EditorPageFieldset>
				<Label htmlFor="products-select" value="Products or Tools" />
				<SearchableSelect
					id="products-select"
					className="products-select"
					customPlaceHolderText="Select product"
					options={products}
					selectedValues={personProducts}
					onChange={items => {
						addOrRemovePerson(PATCH_ADD, items.slice(-1) && items.slice(-1)[0]);
					}}
				/>
				<SelectedItemsList
					listItems={personProducts}
					onDelete={item => {
						addOrRemovePerson(PATCH_REMOVE, item);
					}}
				/>
			</EditorPageFieldset>
			<PageErrorDialog apiErrors={apiErrors} onClose={closeErrorDialog} />
		</div>
	);
}

ProductSelectList.propTypes = {
	personId: PropTypes.number,
};

import React from 'react';
import PropTypes from 'prop-types';

import { LoadMoreButton } from 'components/LoadMoreButton';
import { GridListItems } from 'components/GridListItems';
import { MaxWidthLayout } from 'components/MaxWidthLayout';
import { PageSectionTitle } from 'components/PageSectionTitle';
import {
	LEVEL1_OPTIONS_KEY,
	DEFAULT_OPTION_VALUE_LABEL,
	MultiLevelFilter,
} from 'components/MultiLevelFilter';
import { ProductPerson, personType } from './ProductPerson';

import './ProductTeam.css';

function getFilterOptions(teamMembers) {
	return teamMembers
		.filter(e => e.country)
		.map(member => {
			const filterOption = {};
			filterOption[LEVEL1_OPTIONS_KEY] = member.country;
			return filterOption;
		});
}

export function ProductTeam({
	teamMembers,
	hasMorePersons,
	isLoading,
	onLoadMore,
	title,
	subTitle,
	darkTheme,
}) {
	const [regionsFilterOptions, setRegionFilterOptions] = React.useState({
		country: DEFAULT_OPTION_VALUE_LABEL,
	});
	const filterOptions = getFilterOptions(teamMembers);
	const filteredMembers =
		regionsFilterOptions.country === DEFAULT_OPTION_VALUE_LABEL
			? [...teamMembers]
			: teamMembers.filter(member => {
					return member.country === regionsFilterOptions.country;
			  });

	return (
		<section className="product-team-section">
			<MaxWidthLayout>
				<PageSectionTitle>{title}</PageSectionTitle>
				{subTitle && <div className="product-team-explanatory">{subTitle}</div>}
				<MultiLevelFilter
					options={filterOptions}
					level1Option={regionsFilterOptions.country}
					level1Key={LEVEL1_OPTIONS_KEY}
					level1OptionLabel="All"
					onChange={country => {
						setRegionFilterOptions({ country });
					}}
				/>
				<GridListItems
					className="product-team__grid"
					items={filteredMembers}
					renderItem={person => (
						<ProductPerson
							name={person.name}
							title={person.role}
							image={person.url}
							email={person.email}
							phoneNumber={person.phoneNumber}
							className={darkTheme ? 'dark-theme' : 'light-theme'}
						/>
					)}
				/>
				{hasMorePersons && <LoadMoreButton onClick={onLoadMore} isLoading={isLoading} />}
			</MaxWidthLayout>
		</section>
	);
}

ProductTeam.propTypes = {
	teamMembers: PropTypes.arrayOf(
		PropTypes.shape({ ...personType, role: PropTypes.string.isRequired })
	).isRequired,
	hasMorePersons: PropTypes.bool,
	isLoading: PropTypes.bool,
	onLoadMore: PropTypes.func.isRequired,
	title: PropTypes.string,
	subTitle: PropTypes.string,
	darkTheme: PropTypes.bool,
};

ProductTeam.defaultProps = {
	onLoadMore: () => {},
	darkTheme: false,
};

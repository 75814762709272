import React from 'react';
import PropTypes from 'prop-types';
import NavigationPrompt from 'react-router-navigation-prompt';
import { assoc, equals, isEmpty, uniq } from 'ramda';
import { DangerButton, PrimaryButton, SecondaryButton } from 'components/Button';
import { Overlay, NoDataOverlay } from 'components/Overlay';
import { Loader } from 'components/Loader';
import { ModalContainer } from 'components/ModalContainer';
import { DeleteItemDialog } from 'components/DeleteItemDialog';
import { ErrorDialog } from 'components/ErrorDialog';
import { NavigateAwayDialog } from 'components/NavigateAwayDialog';
import { EditableList } from 'components/EditableList';
import { TitleInput } from 'components/TitleInput';
import { Toggle } from 'components/Toggle';
import { SingleDatePicker } from 'components/DatePicker';
import {
	Header,
	Actions,
	Content,
	Section,
	SectionTitle,
	Fieldset,
	FieldsetTitle,
	Input,
	Textarea,
	ValidationError,
} from 'admin/Tab';
import {
	getSolution,
	getFilesBySolution,
	getPersonsBySolution,
	getOwnersBySolution,
	createSolution,
	updateSolution,
	deleteSolution,
	patchSolutionPersons,
	patchSolutionOwners,
} from 'utils/apiUtils';
import { sortByName } from 'utils/sortUtils';
import { mergeNullKeysToDefaults } from 'utils/parseUtils';
import { getPageUrl } from 'utils/urlUtils';
import { useIsMounted } from 'hooks/useIsMounted';
import { EditProductTeam as EditPerson } from 'content-editor/EditProductTeam';

export const SOLUTION_DESCRIPTION_LENGTH = 500;
export const SOLUTION_INTRO_TEXT_LENGTH = 340;
export const SOLUTION_WHEN_TO_USE_LENGTH = 5000;

export const DEFAULT_ITEM = {
	name: 'Unnamed Product or Tool',
	description: '',
	introText: '',
	whenToUseInfo: '',
	isActive: true,
	requireClientEnv: false,
	groupIds: [],
	personIds: [],
};

export function getValidItem(item) {
	return {
		...item,
		name: item.name.trim(),
		description: item.description.trim(),
		introText: item.introText.trim(),
		whenToUseInfo: item.whenToUseInfo.trim(),
		personIds: uniq(item.personIds),
	};
}

export function getValidationErrors(item) {
	const { name, description, introText, whenToUseInfo, appUrl, buttonText } = item;
	let errors = {};
	if (!name) {
		errors = assoc('name', 'Please enter a valid name', errors);
	}
	if (description && description.length > SOLUTION_DESCRIPTION_LENGTH) {
		errors = assoc(
			'description',
			`Description should not exceed ${SOLUTION_DESCRIPTION_LENGTH} characters`,
			errors
		);
	}
	if (introText && introText.length > SOLUTION_INTRO_TEXT_LENGTH) {
		errors = assoc(
			'introText',
			`Intro text should not exceed ${SOLUTION_INTRO_TEXT_LENGTH} characters`,
			errors
		);
	}
	if (whenToUseInfo && whenToUseInfo.length > SOLUTION_WHEN_TO_USE_LENGTH) {
		errors = assoc(
			'whenToUseInfo',
			`When to use text should not exceed ${SOLUTION_WHEN_TO_USE_LENGTH} characters`,
			errors
		);
	}
	if (!buttonText && appUrl) {
		errors = assoc('buttonText', 'Please enter a valid environment button name', errors);
	}
	if (!appUrl && buttonText) {
		errors = assoc('appUrl', 'Please enter a valid environment link', errors);
	}
	const urlValidator = /^(?:https?:\/\/(?:www\.)?|www\.)[a-z0-9]+(?:[-.][a-z0-9]+)*\.[a-z]{2,5}(?::[0-9]{1,5})?(?:\/\S*)?$/; //eslint-disable-line no-useless-escape
	if (appUrl && !urlValidator.test(appUrl)) {
		errors = assoc('appUrl', 'Please enter a valid environment link', errors);
	}
	return errors;
}

export function Solution({ solutionId, history }) {
	const isNew = !solutionId;

	const [isLoading, setIsLoading] = React.useState(true);
	const [isSaving, setIsSaving] = React.useState(false);
	const [isDeleting, setIsDeleting] = React.useState(false);
	const [committedItem, setCommittedItem] = React.useState(null);
	const [liveItem, setLiveItem] = React.useState(null);
	const [deletingItem, setDeletingItem] = React.useState(null);
	const [files, setFiles] = React.useState([]);
	const [persons, setPersons] = React.useState([]);
	const [owners, setOwners] = React.useState([]);
	const [validationErrors, setValidationErrors] = React.useState({});
	const [apiErrors, setApiErrors] = React.useState({});
	const isMounted = useIsMounted();

	const productURL = getPageUrl('product');

	React.useEffect(() => {
		let queries = [];
		if (!isNew) {
			queries = [
				...queries,
				getSolution(solutionId),
				getFilesBySolution(solutionId),
				getPersonsBySolution(solutionId),
				getOwnersBySolution(solutionId),
			];
		}
		Promise.all(queries)
			.then(([solution, files = [], persons = [], owners = []]) => {
				if (!isMounted()) {
					return;
				}

				const formattedItem = mergeNullKeysToDefaults(DEFAULT_ITEM, solution);
				setCommittedItem(formattedItem);
				setLiveItem(formattedItem);
				setFiles(sortByName(files));
				setPersons(persons);
				setOwners(owners);
				setIsLoading(false);
			})
			.catch(error => {
				if (!isMounted()) {
					return;
				}
				setIsLoading(false);
				setApiErrors({ solution: error });
			});
	}, []);

	function onCancel() {
		history.push(productURL);
	}

	function onSave() {
		const validItem = getValidItem(liveItem);
		const errors = getValidationErrors(validItem);
		if (!isEmpty(errors)) {
			setValidationErrors(errors);
			return;
		}
		setValidationErrors({});
		setIsSaving(true);
		const action = isNew ? createSolution : updateSolution;
		action(validItem)
			.then(item => {
				if (!isMounted()) {
					return;
				}
				setCommittedItem(item);
				setLiveItem(item);
				setIsSaving(false);
				if (isNew) {
					history.push(`${productURL}/${item.id}`);
				}
			})
			.catch(error => {
				if (!isMounted()) {
					return;
				}
				setIsSaving(false);
				setApiErrors({ solution: error });
			});
	}

	function onDelete() {
		setIsDeleting(true);
		deleteSolution(deletingItem)
			.then(() => {
				if (!isMounted()) {
					return;
				}
				setIsDeleting(false);
				history.push(productURL);
			})
			.catch(error => {
				if (!isMounted()) {
					return;
				}
				setIsDeleting(false);
				setApiErrors({ solution: error });
			});
	}

	function closeDeleteItemDialog() {
		setDeletingItem(null);
	}

	function closeErrorDialog() {
		setApiErrors({});
	}

	function refetchProductAndPersons() {
		const solutionId = liveItem.id;

		Promise.all([getSolution(solutionId), getPersonsBySolution(solutionId)])
			.then(([solution, persons]) => {
				if (!isMounted()) {
					return;
				}

				const formattedItem = mergeNullKeysToDefaults(DEFAULT_ITEM, solution);
				setCommittedItem(formattedItem);
				setLiveItem(formattedItem);
				setPersons(persons);
				setIsSaving(false);
			})
			.catch(error => {
				if (!isMounted()) {
					return;
				}
				setIsSaving(false);
				setApiErrors({ solution: error });
			});
	}

	function refetchProductAndOwners() {
		const solutionId = liveItem.id;

		Promise.all([getSolution(solutionId), getOwnersBySolution(solutionId)])
			.then(([solution, owners]) => {
				if (!isMounted()) {
					return;
				}

				const formattedItem = mergeNullKeysToDefaults(DEFAULT_ITEM, solution);
				setCommittedItem(formattedItem);
				setLiveItem(formattedItem);
				setOwners(owners);
				setIsSaving(false);
			})
			.catch(error => {
				if (!isMounted()) {
					return;
				}
				setIsSaving(false);
				setApiErrors({ solution: error });
			});
	}

	function updateSolutionForPersons(operation, persons) {
		const areaId = liveItem.id;
		setIsSaving(true);
		patchSolutionPersons(areaId, operation, persons)
			.then(data => {
				refetchProductAndPersons();
			})
			.catch(error => {
				setIsSaving(false);
				setApiErrors({ solution: error });
			});
	}

	function updateSolutionForOwners(operation, owners) {
		const areaId = liveItem.id;
		setIsSaving(true);
		patchSolutionOwners(areaId, operation, owners)
			.then(data => {
				refetchProductAndOwners();
			})
			.catch(error => {
				setIsSaving(false);
				setApiErrors({ solution: error });
			});
	}

	if (isLoading) {
		return (
			<Overlay>
				<Loader />
			</Overlay>
		);
	}

	if (!liveItem) {
		return <NoDataOverlay />;
	}

	return (
		<form>
			<Header>
				<TitleInput
					theme="admin"
					value={liveItem.name}
					onChange={event => setLiveItem(assoc('name', event.target.value))}
					autoSelect={isNew}
					autoFocus={!isNew}
				/>
				{validationErrors.name && (
					<ValidationError>{validationErrors.name}</ValidationError>
				)}
				<Actions>
					{!isNew && (
						<DangerButton disabled={isDeleting} onClick={() => setDeletingItem(liveItem)}>
							Delete
						</DangerButton>
					)}
					<SecondaryButton style={{ marginLeft: 'auto' }} onClick={onCancel}>
						Cancel
					</SecondaryButton>
					<PrimaryButton
						disabled={equals(liveItem, committedItem) || isSaving}
						onClick={onSave}
					>
						{isNew && (isSaving ? 'Creating…' : 'Create')}
						{!isNew && (isSaving ? 'Updating…' : 'Update')}
					</PrimaryButton>
				</Actions>
			</Header>

			<Content>
				<Section>
					<Fieldset>
						<FieldsetTitle>
							<label htmlFor="solution-active-toggle">Show on Search all page</label>
						</FieldsetTitle>
						<Toggle
							id="solution-active-toggle"
							checked={liveItem.isActive}
							onChange={checked => {
								setLiveItem(assoc('isActive', checked));
							}}
						/>
					</Fieldset>
					<Fieldset>
						<FieldsetTitle>
							<label htmlFor="solution-require-client-env">
								This requires ETS support to setup
							</label>
						</FieldsetTitle>
						<Toggle
							id="solution-require-client-env"
							checked={liveItem.requireClientEnv}
							onChange={checked => {
								setLiveItem(assoc('requireClientEnv', checked));
							}}
						/>
					</Fieldset>
					<Fieldset>
						<FieldsetTitle>
							<label htmlFor="area-intro-text">
								{`Intro text (Only ${SOLUTION_INTRO_TEXT_LENGTH} characters allowed)`}
							</label>
						</FieldsetTitle>
						<Textarea
							id="area-intro-text"
							maxLength={SOLUTION_INTRO_TEXT_LENGTH}
							value={liveItem.introText}
							onChange={event => setLiveItem(assoc('introText', event.target.value))}
						/>
						{validationErrors.introText && (
							<ValidationError>{validationErrors.introText}</ValidationError>
						)}
					</Fieldset>
					<Fieldset>
						<FieldsetTitle>
							<label htmlFor="solution-description">
								{`Description (Only ${SOLUTION_DESCRIPTION_LENGTH} characters allowed)`}
							</label>
						</FieldsetTitle>
						<Textarea
							id="solution-description"
							maxLength={SOLUTION_DESCRIPTION_LENGTH}
							value={liveItem.description}
							onChange={event => setLiveItem(assoc('description', event.target.value))}
						/>
						{validationErrors.description && (
							<ValidationError>{validationErrors.description}</ValidationError>
						)}
					</Fieldset>
					<Fieldset>
						<FieldsetTitle>
							<label htmlFor="solution-whenToUseInfo">
								<label htmlFor="area-intro-text">
									{`When to use info (Only ${SOLUTION_WHEN_TO_USE_LENGTH} characters allowed)`}
								</label>
							</label>
						</FieldsetTitle>
						<Textarea
							id="solution-whenToUseInfo"
							value={liveItem.whenToUseInfo}
							onChange={event => setLiveItem(assoc('whenToUseInfo', event.target.value))}
						/>
						{validationErrors.whenToUseInfo && (
							<ValidationError>{validationErrors.whenToUseInfo}</ValidationError>
						)}
					</Fieldset>
					<Fieldset>
						<FieldsetTitle>
							<label htmlFor="solution-version">Version</label>
						</FieldsetTitle>
						<Input
							id="solution-version"
							value={liveItem.version || ''}
							onChange={event => setLiveItem(assoc('version', event.target.value))}
						/>
					</Fieldset>
					<Fieldset>
						<FieldsetTitle>
							<label htmlFor="solution-last-updated-date">Updated on</label>
						</FieldsetTitle>
						<SingleDatePicker
							id="solution-last-updated-date"
							date={liveItem.lastUpdatedDate}
							onChange={date => {
								setLiveItem(assoc('lastUpdatedDate', date || ''));
							}}
						/>
					</Fieldset>
					<Fieldset>
						<FieldsetTitle>
							<label htmlFor="solution-latestUpdate-description">Latest updates</label>
						</FieldsetTitle>
						<Textarea
							id="solution-latestUpdate-description"
							value={liveItem.latestUpdateDescription || ''}
							onChange={event =>
								setLiveItem(assoc('latestUpdateDescription', event.target.value))
							}
						/>
					</Fieldset>
				</Section>
				{!isNew && (
					<React.Fragment>
						<Section>
							<SectionTitle>Files</SectionTitle>
							<EditableList
								type="file"
								items={files}
								onCreate={() => history.push(`/admin/new-file?solutionId=${solutionId}`)}
								onUpdate={item =>
									history.push(`/admin/files/${item.id}?solutionId=${solutionId}`)
								}
							/>
						</Section>
						{/* Edit Product Owner */}
						<EditPerson
							isAdmin
							productExperts={owners}
							updateProductForPersons={updateSolutionForOwners}
							setProductExperts={setOwners}
							titleText="Edit Product Owner"
							labeltext="Select Product Owners"
							dropdownPlaceholder="Select product owner"
						/>
						{/* Edit Team Member */}
						<EditPerson
							isAdmin
							productExperts={persons}
							updateProductForPersons={updateSolutionForPersons}
							setProductExperts={setPersons}
							titleText="Edit Team"
							labeltext="Select Experts"
							dropdownPlaceholder="Select an expert"
						/>
					</React.Fragment>
				)}
			</Content>

			{deletingItem && (
				<ModalContainer onClose={closeDeleteItemDialog}>
					<DeleteItemDialog
						type="solution"
						name={deletingItem.name}
						isDeleting={isDeleting}
						onCancel={closeDeleteItemDialog}
						onConfirm={onDelete}
					/>
				</ModalContainer>
			)}

			{!isEmpty(apiErrors) && (
				<ModalContainer onClose={closeErrorDialog}>
					<ErrorDialog
						type="API"
						errors={apiErrors}
						button={
							<SecondaryButton onClick={() => window.location.reload()}>
								Reload
							</SecondaryButton>
						}
						onClose={closeErrorDialog}
					/>
				</ModalContainer>
			)}

			<NavigationPrompt
				disableNative
				when={(currentLocation, nextLocation) =>
					currentLocation.pathname !== nextLocation.pathname &&
					!equals(liveItem, committedItem)
				}
			>
				{({ onConfirm, onCancel }) => (
					<ModalContainer onClose={onCancel}>
						<NavigateAwayDialog onCancel={onCancel} onConfirm={onConfirm} />
					</ModalContainer>
				)}
			</NavigationPrompt>
		</form>
	);
}

Solution.propTypes = {
	solutionId: PropTypes.number,
	history: PropTypes.shape({
		push: PropTypes.func.isRequired,
	}).isRequired,
};

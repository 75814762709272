import { mergeDeepLeft, assocPath } from 'ramda';
import queryString from 'qs';
import { acquireToken } from 'contexts/AuthenticationContext';

const { REACT_APP_API_URL } = process.env;

const FEATUERS_API = 'features';
const LATEST_UPDATES_API = 'activity-logs';

function makeApiUrl(path) {
	return `${REACT_APP_API_URL}/${path}`;
}

function addAuthHeaders(token, options = {}) {
	return assocPath(['headers', 'Authorization'], `Bearer ${token}`, options);
}

function jsonRequest(url, options = {}) {
	const defaultOptions = {
		headers: { 'content-type': 'application/json' },
	};

	return fetch(url, mergeDeepLeft(defaultOptions, options))
		.then(response => {
			if (!response.ok) {
				throw new Error(`ServerError (${response.status})`);
			}
			return response;
		})
		.then(response => response.text())
		.then(textResponse => {
			try {
				const jsonResponse = JSON.parse(textResponse);
				return jsonResponse;
			} catch {
				return textResponse;
			}
		})
		.catch(error => {
			console.error(error.message);
			throw new Error(`${error.message} \nfrom ${url}`);
		});
}

function requestWithToken(url, defaults, options) {
	return acquireToken().then(token => {
		if (!token) {
			throw new Error(`ServerError (401)`);
		}
		const authDefaults = addAuthHeaders(token, defaults);
		return jsonRequest(url, mergeDeepLeft(authDefaults, options));
	});
}

function fetchWithOperationLocation(url, options = {}) {
	const defaultOptions = {
		headers: { 'content-type': 'application/json' },
	};

	let fileUploadUrl;
	return acquireToken()
		.then(token => {
			if (!token) {
				throw new Error(`ServerError (401)`);
			}

			const authDefaults = addAuthHeaders(token, defaultOptions);
			return fetch(url, mergeDeepLeft(authDefaults, options)).then(response => {
				fileUploadUrl = response.headers.get('Operation-Location');
				if (!fileUploadUrl) {
					throw new Error('The server did not provide a Operation-Location resource');
				}
				try {
					const jsonResponse = response.json();
					return jsonResponse;
				} catch {
					return response.text();
				}
			});
		})
		.then(response => ({
			url: fileUploadUrl,
			item: response,
		}));
}

function getRequest(url, options = {}) {
	let defaults = { method: 'GET' };
	return requestWithToken(url, defaults, options);
}

function postRequest(url, options = {}) {
	let defaults = { method: 'POST' };
	return requestWithToken(url, defaults, options);
}

function putRequest(url, options = {}) {
	let defaults = { method: 'PUT' };
	return requestWithToken(url, defaults, options);
}

function patchRequest(url, options = {}) {
	let defaults = { method: 'PATCH' };
	return requestWithToken(url, defaults, options);
}

function deleteRequest(url, options = {}) {
	let defaults = { method: 'DELETE' };
	return requestWithToken(url, defaults, options);
}

export function getAreas() {
	const url = makeApiUrl('areas');
	return getRequest(url);
}

export function getAreasByPerson(personId) {
	const url = makeApiUrl(`persons/${personId}/areas`);
	return getRequest(url);
}

export function getArea(areaId) {
	const url = makeApiUrl(`areas/${areaId}`);
	return getRequest(url);
}

export function createArea(area) {
	const url = makeApiUrl('areas');
	const options = {
		body: JSON.stringify(area),
	};
	return postRequest(url, options);
}

export function updateArea(area) {
	const url = makeApiUrl(`areas/${area.id}`);
	const options = {
		body: JSON.stringify(area),
	};
	return putRequest(url, options);
}

export function updateAreaProducts(areaId, operation, products) {
	const url = makeApiUrl(`areas/${areaId}/products`);
	const options = {
		body: JSON.stringify({ operation, products }),
	};
	return patchRequest(url, options);
}

export function patchAreaPersons(areaId, operation, persons) {
	const url = makeApiUrl(`areas/${areaId}/persons`);
	const options = {
		body: JSON.stringify({ operation, persons }),
	};
	return patchRequest(url, options);
}

export function deleteArea(area) {
	const url = makeApiUrl(`areas/${area.id}`);
	return deleteRequest(url);
}

export function updateAreasOrder(areas, operation = 'replace') {
	const url = makeApiUrl('areas/order');
	const options = {
		body: JSON.stringify({ operation, areas }),
	};
	return patchRequest(url, options);
}

export function updateProductsOrder(products, operation = 'replace') {
	const url = makeApiUrl('solutions/order');
	const options = {
		body: JSON.stringify({ operation, products }),
	};
	return patchRequest(url, options);
}

export function getConfig() {
	const url = makeApiUrl('config');
	return getRequest(url);
}

export function getSolutions() {
	const url = makeApiUrl('solutions');
	return getRequest(url);
}

export function getEngagements(oid) {
	const url = makeApiUrl(`engagements/${oid}`);
	return getRequest(url);
}

export function getProductsByArea(areaId, { page, pageSize, orderBy, order } = {}) {
	const params = queryString.stringify({ page, pageSize, orderBy, order });
	const url = makeApiUrl(`areas/${areaId}/products?${params}`);
	return getRequest(url);
}

export function getSolutionsByPerson(personId) {
	const url = makeApiUrl(`persons/${personId}/solutions`);
	return getRequest(url);
}

export function getSolution(solutionId) {
	const url = makeApiUrl(`solutions/${solutionId}`);
	return getRequest(url);
}

export function createSolution(solution) {
	const url = makeApiUrl('solutions');
	const options = {
		body: JSON.stringify(solution),
	};
	return postRequest(url, options);
}

export function updateSolution(solution) {
	const url = makeApiUrl(`solutions/${solution.id}`);
	const options = {
		body: JSON.stringify(solution),
	};
	return putRequest(url, options);
}

export function patchSolutionPersons(solutionId, operation, persons) {
	const url = makeApiUrl(`solutions/${solutionId}/persons`);
	const options = {
		body: JSON.stringify({ operation, persons }),
	};
	return patchRequest(url, options);
}

export function patchSolutionOwners(solutionId, operation, persons) {
	const url = makeApiUrl(`solutions/${solutionId}/owners`);
	const options = {
		body: JSON.stringify({ operation, persons }),
	};
	return patchRequest(url, options);
}

export function patchSolutionFeatures(solutionId, operation, features) {
	const url = makeApiUrl(`solutions/${solutionId}/features`);
	const options = {
		body: JSON.stringify({ operation, features }),
	};
	return patchRequest(url, options);
}

export function deleteSolution(solution) {
	const url = makeApiUrl(`solutions/${solution.id}`);
	return deleteRequest(url);
}

export function getFiles() {
	const url = makeApiUrl('files');
	return getRequest(url);
}

export function getFile(fileId) {
	const url = makeApiUrl(`files/${fileId}`);
	return getRequest(url);
}

export function getFileMetadata(fileId) {
	const url = makeApiUrl(`files/${fileId}/download`);
	return acquireToken().then(token => {
		if (!token) {
			throw new Error(`ServerError (401)`);
		}

		return fetchWithOperationLocation(url).then(({ url, item }) => ({
			url,
			size: item.sizeInBytes,
			extension: item.extension,
		}));
	});
}

export function createFile(file) {
	const url = makeApiUrl('files');
	const options = {
		method: 'POST',
		body: JSON.stringify(file),
	};

	return postRequest(url, options);
}

export function uploadFile(url, file) {
	const options = {
		method: 'PUT',
		headers: {
			'x-ms-blob-type': 'BlockBlob',
			'Content-Type': file.type,
		},
		body: file,
	};
	return fetch(url, options);
}

export function updateFile(file) {
	const url = makeApiUrl(`files/${file.id}`);
	const options = {
		method: 'PUT',
		body: JSON.stringify(file),
	};

	return putRequest(url, options);
}

export function deleteFile(file) {
	const url = makeApiUrl(`files/${file.id}`);
	return deleteRequest(url);
}

export function getPersons() {
	const url = makeApiUrl('persons');
	return getRequest(url);
}

export function getPersonsByArea(areaId, { page, pageSize, orderBy, order } = {}) {
	const params = queryString.stringify({ page, pageSize, orderBy, order });
	const url = makeApiUrl(`areas/${areaId}/team?${params}`);
	return getRequest(url);
}

export function getPerson(personId) {
	const url = makeApiUrl(`persons/${personId}`);
	return getRequest(url);
}

export function getOwners() {
	const url = makeApiUrl('owners');
	return getRequest(url);
}

export function getOwnersByArea(areaId, { page, pageSize, orderBy, order } = {}) {
	const params = queryString.stringify({ page, pageSize, orderBy, order });
	const url = makeApiUrl(`areas/${areaId}/team?${params}`);
	return getRequest(url);
}

export function getOwner(ownerId) {
	const url = makeApiUrl(`owners/${ownerId}`);
	return getRequest(url);
}

export function createOwner(owner) {
	const url = makeApiUrl('owner');
	const options = {
		body: JSON.stringify(owner),
	};
	return postRequest(url, options);
}

export function updateOwner(owner) {
	const url = makeApiUrl(`owners/${owner.id}`);
	const options = {
		body: JSON.stringify(owner),
	};
	return putRequest(url, options);
}

export function createPerson(person) {
	const url = makeApiUrl('persons');
	const options = {
		body: JSON.stringify(person),
	};
	return postRequest(url, options);
}

export function updatePerson(person) {
	const url = makeApiUrl(`persons/${person.id}`);
	const options = {
		body: JSON.stringify(person),
	};
	return putRequest(url, options);
}

/*TODO - Need to have this patch directly on solution and product instead on person end point*/
export function patchPersonForSolution(operation, personId, solutionId) {
	const url = makeApiUrl(`persons/${personId}/solutions`);
	const options = {
		body: JSON.stringify({ operation, id: solutionId }),
	};
	return patchRequest(url, options);
}

/*TODO - Need to have this patch directly on solution and product instead on person end point*/
export function patchAreaForPerson(operation, personId, areaId) {
	const url = makeApiUrl(`persons/${personId}/areas`);
	const options = {
		body: JSON.stringify({ operation, id: areaId }),
	};
	return patchRequest(url, options);
}

export function patchOwnerForSolution(operation, ownerId, solutionId) {
	const url = makeApiUrl(`owners/${ownerId}/solutions`);
	const options = {
		body: JSON.stringify({ operation, id: solutionId }),
	};
	return patchRequest(url, options);
}

export function patchAreaForOwner(operation, ownerId, areaId) {
	const url = makeApiUrl(`owners/${ownerId}/areas`);
	const options = {
		body: JSON.stringify({ operation, id: areaId }),
	};
	return patchRequest(url, options);
}

export function deletePerson(person) {
	const url = makeApiUrl(`persons/${person.id}`);
	return deleteRequest(url);
}

export function deleteOwner(owner) {
	const url = makeApiUrl(`owners/${owner.id}`);
	return deleteRequest(url);
}

export function getToolsBySolution(solutionId) {
	const url = makeApiUrl(`solutions/tools/${solutionId}`);
	return getRequest(url);
}

export function getFilesBySolution(solutionId) {
	const url = makeApiUrl(`solutions/files/${solutionId}`);
	return getRequest(url);
}

export function getPersonsBySolution(solutionId) {
	const url = makeApiUrl(`solutions/persons/${solutionId}`);
	return getRequest(url);
}

export function getOwnersBySolution(solutionId) {
	const url = makeApiUrl(`solutions/owners/${solutionId}`);
	return getRequest(url);
}

export function getFeaturesBySolution(solutionId) {
	const url = makeApiUrl(`solutions/features/${solutionId}`);
	return getRequest(url);
}

export function getFeatures() {
	const url = makeApiUrl(FEATUERS_API);
	return getRequest(url);
}

export function getFeature(featureId) {
	const url = makeApiUrl(`${FEATUERS_API}/${featureId}`);
	return getRequest(url);
}

export function createFeature(feature) {
	const url = makeApiUrl(FEATUERS_API);
	const options = {
		body: JSON.stringify(feature),
	};
	return postRequest(url, options);
}

export function updateFeature(feature) {
	const url = makeApiUrl(`${FEATUERS_API}/${feature.id}`);
	const options = {
		body: JSON.stringify(feature),
	};
	return putRequest(url, options);
}

export function deleteFeature(feature) {
	const url = makeApiUrl(`${FEATUERS_API}/${feature.id}`);
	return deleteRequest(url);
}

export function getLatestUpdates() {
	const url = makeApiUrl(LATEST_UPDATES_API);
	return getRequest(url);
}

export function getLatestUpdate(latestUpdateId) {
	const url = makeApiUrl(`${LATEST_UPDATES_API}/${latestUpdateId}`);
	return getRequest(url);
}

export function createLatestUpdate(latestUpdate) {
	const url = makeApiUrl(LATEST_UPDATES_API);
	const options = {
		body: JSON.stringify(latestUpdate),
	};
	return postRequest(url, options);
}

export function updateLatestUpdate(latestUpdate) {
	const url = makeApiUrl(`${LATEST_UPDATES_API}/${latestUpdate.id}`);
	const options = {
		body: JSON.stringify(latestUpdate),
	};
	return putRequest(url, options);
}

export function deleteLatestUpdate(latestUpdate) {
	const url = makeApiUrl(`${LATEST_UPDATES_API}/${latestUpdate.id}`);
	return deleteRequest(url);
}

export function getLatestUpdatesBySolution(solutionId) {
	const url = makeApiUrl(`solutions/${LATEST_UPDATES_API}/${solutionId}`);
	return getRequest(url);
}

import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import classNames from 'classnames';

import { LinesEllipsis } from 'components/LinesEllipsis';
import { Media } from 'components/Media';

import { PRODUCT_SECTION_PARAM_KEY } from 'products/Products';
import { INPUT_NAMES, PRODUCT_DETAIL } from 'product-editor/constants';

import './ProductDetailSection.css';

export function ProductDetailSection({ url, product, features, selected, focusedInput }) {
	const { name, description, version } = product;
	const isDescriptionExist = !!description;
	const isNameExist = !!name;
	const descriptionFocused = focusedInput === INPUT_NAMES.description;

	const [heroFeature] = features.filter(e => e.isHero);
	const isVideoFeature =
		!!heroFeature &&
		!!heroFeature.extension &&
		heroFeature.extension.indexOf('video') !== -1;
	const videoWithNoPreview = isVideoFeature && !heroFeature.previewImageUrl;

	return (
		<Link to={`${url}?${PRODUCT_SECTION_PARAM_KEY}=${PRODUCT_DETAIL}`}>
			<section
				className={classNames('product-detail-section', {
					'product-detail-section--selected': selected,
				})}
			>
				<div className="product-detail-section__row">
					<div className="product-detail-section__left">
						{isNameExist ? (
							<h2 className="product-detail-section__name">{name}</h2>
						) : (
							<div className="product-detail-section__no-name-block">PRODUCT NAME</div>
						)}
						<div className="product-detail-section__version">
							Version:{' '}
							<span className="product-detail-section__version-value">
								{version || ''}
							</span>
						</div>
						<div
							className={classNames('product-detail-section__description', {
								'product-detail-section__no-description-block': !isDescriptionExist,
								'product-detail-section__description--selected': descriptionFocused,
							})}
						>
							{isDescriptionExist ? (
								<LinesEllipsis text={description} maxLine={2} />
							) : (
								<span>About Product</span>
							)}
						</div>
					</div>
					<div className="product-detail-section__right">
						<div
							className={`product-detail-section__hero-block ${
								videoWithNoPreview ? 'product-detail-section__hero-block--no-border' : ''
							}`}
						>
							{!!heroFeature ? (
								<Media
									extension={heroFeature.extension}
									url={heroFeature.url}
									previewImageUrl={heroFeature.previewImageUrl}
									alt={`${heroFeature.name} - ${heroFeature.fileName}`}
								/>
							) : (
								'Hero image/video'
							)}
						</div>
					</div>
				</div>
			</section>
		</Link>
	);
}

ProductDetailSection.propTypes = {
	url: PropTypes.string.isRequired,
	product: PropTypes.shape({
		name: PropTypes.string.isRequired,
		description: PropTypes.string,
	}).isRequired,
	features: PropTypes.arrayOf(
		PropTypes.shape({
			id: PropTypes.number.isRequired,
			name: PropTypes.string.isRequired,
			isHero: PropTypes.bool.isRequired,
		})
	).isRequired,
	focusedInput: PropTypes.string,
};

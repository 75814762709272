import React from 'react';

const Icon = ({ size, ...otherProps }) => {
	const props = {
		width: size,
		height: size,
		fill: 'currentcolor',
		...otherProps,
	};
	return (
		<svg viewBox="0 0 29 36" {...props}>
			<g fill="none" fillRule="evenodd">
				<path d="M-6-2h40v40H-6z" />
				<path
					d="M3.75.5h14L28.25 11v21a3.51 3.51 0 0 1-3.5 3.5h-21A3.51 3.51 0 0 1 .25 32V4A3.51 3.51 0 0 1 3.75.5zM12.964 8l.128.819-.221.036a.379.379 0 0 0-.182.114c-.052.07-.15.261-.226.762a6.42 6.42 0 0 0-.033 1.613c.043.404.132.86.263 1.362.271-1.06.443-2 .525-2.685.074-.629.014-1.006-.038-1.193a.885.885 0 0 0-.091-.036.34.34 0 0 0 .083.011c-.024-.082-.046-.12-.046-.121l.042-.023L12.964 8zm.916 9.412c.383.649.845 1.338 1.363 2.032.164.219.34.445.522.674-.213.036-.44.078-.688.126-.79.15-1.68.338-2.842.697a30.706 30.706 0 0 0 1.645-3.53zm4.238 4.706a14.266 14.266 0 0 1 2.508.319c.609.136 1.032.285 1.373.483.352.203.622.46.743.71.107.22.096.437.04.548-.065.125-.267.2-.385.235a1.47 1.47 0 0 1-.834-.005c-.272-.075-.752-.265-1.492-.751-.63-.41-1.236-.886-1.953-1.54zM7.529 24.47c-.596.835-1.067 1.358-1.4 1.678-.42.404-.727.586-.953.675.044-.201.15-.485.537-.912.267-.294.835-.842 1.816-1.441zM5.16 28c.099 0 .206-.01.323-.03.607-.108 1.222-.445 1.882-1.028.863-.764 1.857-1.998 2.874-3.565l.177-.276a27.327 27.327 0 0 1 1.543-.519 25.28 25.28 0 0 1 2.873-.698c.65-.122 1.16-.205 1.614-.26.284.318.567.625.842.91.727.755 1.342 1.305 1.988 1.778.656.486 1.243.811 1.747.967a2.552 2.552 0 0 0 1.59-.005c.573-.19.992-.545 1.21-1.027.245-.537.235-1.215-.024-1.81-.222-.52-.651-1.007-1.208-1.371-.44-.286-.944-.494-1.647-.674a12.844 12.844 0 0 0-1.962-.323c-.635-.057-1.29-.06-1.95-.015-.41-.478-.802-.964-1.146-1.417-.747-.987-1.369-1.953-1.8-2.795a16.464 16.464 0 0 1-.124-.254c.566-1.864.888-3.639 1.008-4.81.146-1.424-.196-2.052-.303-2.21l-.14-.163-.138-.082c-.164-.1-.35-.212-.54-.269-.28-.088-.497-.048-.65-.021-.09.01-.244.028-.435.104a1.74 1.74 0 0 0-.85.682c-.205.318-.33.729-.408 1.331-.077.59-.088 1.2-.033 1.816.058.63.199 1.359.418 2.17.118.44.271.95.518 1.545-.08.24-.163.481-.25.721-.797 2.183-1.907 4.175-2.691 5.452-.673.26-1.296.533-1.824.801-1.07.543-2.01 1.196-2.642 1.84-.52.53-.82 1.042-.94 1.605-.11.496-.072.962.109 1.314.145.28.43.586.99.586z"
					fill="#F75A56"
					fillRule="nonzero"
				/>
			</g>
		</svg>
	);
};

export const PdfIcon = Icon;

PdfIcon.defaultProps = {
	size: 24,
};

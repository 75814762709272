import React from 'react';
import PropTypes from 'prop-types';

import { Label } from 'components/Label';
import { LabelWithCount } from 'components/LabelWithCount';
import { Input } from 'components/Input';
import { Textarea } from 'components/Textarea';
import { Toggle } from 'components/Toggle';
import {
	EditorPageFieldset,
	EditorPageSeparator,
	EditorPageSectionTitle,
} from 'content-editor/EditorPageLayout';
import { ErrorLabel } from 'components/ErrorLabel';
import { Dropdown } from 'components/Dropdown';
import { INPUT_NAMES } from 'area-editor/constants';
import { AREA_DESCRIPTION_MAX_LENGTH } from 'admin/tabs/Area';
import { getUrlByTransformingName } from 'utils/urlUtils';

import './AreaDetailEditSection.css';

const URL_MAX_LENGTH = 100;

export function AreaDetailEditSection({
	area,
	errors,
	onEditArea,
	setFocusedInput,
	areaTypes,
}) {
	const {
		name,
		description,
		stats,
		typeId,
		url,
		isKbArea,
		productsSectionTitle,
		displayNameLink,
		displayNameUrl,
	} = area;
	const noStats = !stats || !stats.length;
	const urlInputEl = React.createRef();

	function updateStats(index, event) {
		const { name, value } = event.target;
		const newStats = [...stats];
		const newStat = { ...newStats[index] };
		newStat[name] = value;
		newStats[index] = newStat;
		onEditArea({
			target: {
				name: 'stats',
				value: newStats,
			},
		});
	}

	function handleTypeChange(value) {
		onEditArea({
			target: {
				name: 'typeId',
				value,
			},
		});
	}

	function handleNameChange(e) {
		if (!url) {
			const { value } = e.target;
			const generatedUrl = getUrlByTransformingName(value);
			urlInputEl.current.value = generatedUrl;
		}

		onEditArea(e);
	}

	function handleUrlChange(e) {
		const input = e.target;

		const cleanValue = input.value.toLowerCase().replace(/[^a-z0-9-]+/g, '');

		if (cleanValue !== input.value) {
			input.value = cleanValue;
		}

		onEditArea(e);
	}

	return (
		<section className="area-detail-edit-section">
			<EditorPageSectionTitle title="Edit an Area Page" />
			<EditorPageSeparator />
			<EditorPageFieldset>
				<Label htmlFor="area-name" value="Area Name" />
				<Input
					id="area-name"
					name="name"
					type="text"
					autoFocus
					value={name}
					onChange={handleNameChange}
				/>
				<ErrorLabel error={errors.name} />
			</EditorPageFieldset>
			<EditorPageFieldset>
				<Label htmlFor="area-page-url" value="Page URL" />
				<input
					id="area-page-url"
					className="input"
					ref={urlInputEl}
					name="url"
					pattern="[a-zA-Z0-9-]+"
					type="text"
					value={url}
					maxLength={URL_MAX_LENGTH}
					onChange={handleUrlChange}
				/>
				<ErrorLabel error={errors.url} />
			</EditorPageFieldset>
			<EditorPageSeparator />
			<div className="area-detail__fieldset" role="group">
				<Toggle
					id="kb-area-flag"
					name="isKbArea"
					label="Is KB Area?"
					checked={isKbArea}
					onChange={value => {
						onEditArea({ target: { name: 'isKbArea', value } });
					}}
				/>
			</div>
			<EditorPageFieldset>
				<Label
					htmlFor="area-page-products-section-title"
					value="Products Section title"
				/>
				<input
					id="area-page-products-section-title"
					className="input area-detail__products-section-title"
					name="productsSectionTitle"
					type="text"
					value={productsSectionTitle}
					onChange={onEditArea}
				/>
			</EditorPageFieldset>
			<EditorPageSeparator />
			<EditorPageFieldset>
				<Label htmlFor="area-type" value="Area Type" />
				<Dropdown
					defaultLabel="Select Type"
					options={areaTypes}
					selectedId={typeId}
					onChange={handleTypeChange}
				/>
			</EditorPageFieldset>
			<EditorPageSeparator theme="dark" />
			<EditorPageFieldset>
				<LabelWithCount
					htmlFor="area-description"
					value="Description"
					count={`${
						description ? description.length : 0
					} / ${AREA_DESCRIPTION_MAX_LENGTH}`}
				/>
				<Textarea
					id="area-description"
					name={INPUT_NAMES.description}
					maxLength={AREA_DESCRIPTION_MAX_LENGTH}
					value={description}
					onFocus={event => {
						setFocusedInput(event.target.name);
					}}
					onBlur={() => {
						setFocusedInput(null);
					}}
					onChange={onEditArea}
				/>
				<ErrorLabel error={errors.description} />
			</EditorPageFieldset>
			<EditorPageSeparator theme="dark" />
			<EditorPageFieldset>
				<Label
					htmlFor="area-page-products-button-display-name"
					value="Link Button Display Name"
				/>
				<input
					id="area-page-products-button-display-name"
					className="input area-detail__products-section-title"
					name="displayNameLink"
					type="text"
					value={displayNameLink}
					onChange={onEditArea}
				/>
			</EditorPageFieldset>
			<EditorPageFieldset>
				<Label htmlFor="area-page-products-button-url" value="Link Button Url" />
				<input
					id="area-page-products-button-url"
					className="input area-detail__products-section-title"
					name="displayNameUrl"
					type="text"
					value={displayNameUrl}
					onChange={onEditArea}
				/>
			</EditorPageFieldset>
			<EditorPageSeparator theme="dark" />
			<div className="area-detail-edit__stats">
				{stats.map((stat, index) => {
					const focusedStat = `${INPUT_NAMES.stats}${index + 1}`;
					const statLabelId = `stat-label-${index}`;
					const statValueId = `stat-value-${index}`;
					const statLabel = `Stats ${index + 1} name`;
					const { label, value } = stat;

					return (
						<div key={statLabelId} className="area-detail-edit__stat">
							<EditorPageFieldset className="area-detail-edit__stat-field">
								<Label htmlFor={statLabelId} value={statLabel} />
								<Input
									id={statLabelId}
									name="label"
									placeholder="Enter stat name"
									value={label}
									onChange={event => {
										updateStats(index, event);
									}}
									onFocus={event => {
										setFocusedInput(focusedStat);
									}}
									onBlur={event => {
										setFocusedInput(null);
									}}
								/>
							</EditorPageFieldset>
							<EditorPageFieldset className="area-detail-edit__stat-field">
								<Label htmlFor={statValueId} value="value" />
								<Input
									id={statValueId}
									name="value"
									value={value}
									placeholder=""
									maxLength="14"
									onChange={event => {
										updateStats(index, event);
									}}
									onFocus={event => {
										setFocusedInput(focusedStat);
									}}
									onBlur={event => {
										setFocusedInput(null);
									}}
								/>
							</EditorPageFieldset>
						</div>
					);
				})}
				{noStats && <div className="area-detail-edit__no-stats">No stats available</div>}
				<EditorPageSeparator theme="dark" />
			</div>
		</section>
	);
}

AreaDetailEditSection.propTypes = {
	area: PropTypes.object.isRequired,
	errors: PropTypes.object,
	onEditArea: PropTypes.func.isRequired,
	setFocusedInput: PropTypes.func.isRequired,
};

AreaDetailEditSection.defaultProps = {
	errors: {},
};

import React from 'react';
import { ApIcon } from '@alixpartners/ui-components';

import './FilterTablet.css';

export const FilterTablet = ({ title, onRemove }) => {
	return (
		<div className="filter-tablet">
			<div className="filter-tablet__title">{title}</div>
			<div className="filter-tablet__remove-icon" onClick={onRemove}>
				<ApIcon
					iconName="outline_cancel"
					iconColor="var(--color-grey-300)"
					iconSize="20px"
				/>
			</div>
		</div>
	);
};

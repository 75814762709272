import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { ApButtonLink, ApIcon } from '@alixpartners/ui-components';

import { LinesEllipsis } from 'components/LinesEllipsis';
import { MaxWidthLayout } from 'components/MaxWidthLayout';
import { GridListItems } from 'components/GridListItems';
import { BreadCrumbList } from 'components/BreadCrumb';

import { compareRenderedTextLengths } from 'utils/parseUtils';

import './AreaDetail.css';

export const AreaDetail = withRouter(({ history, area }) => {
	const [isReadMoreOpened, setIsReadMoreOpened] = React.useState(false);
	const { name, description, stats, displayNameLink, displayNameUrl } = area;
	const mappedStats = (stats || [])
		.filter(stat => !!stat.value && parseInt(stat.value, 10) !== 0)
		.map(stat => ({ ...stat, id: stat.label + stat.value }));
	const statsCount = mappedStats.length;
	const hasStats = statsCount > 0;
	const [leftColsCount, rightColsCount] = statsCount > 1 ? [5, 7] : [8, 4];
	const [showMore, setShowMore] = useState(null);
	const [initialized, setInitialized] = useState(false);

	const maxValueLength = mappedStats.reduce(
		(prev, current) => (prev.value.length > current.value.length ? prev : current),
		{ value: '' }
	).value.length;

	const fontSizeClass =
		maxValueLength > 4
			? `area-detail__term-value--type-${maxValueLength}`
			: 'area-detail__term-value--type-default';

	useEffect(() => {
		if (!initialized) {
			setInitialized(true);
		}
		if (initialized && showMore === null) {
			// get the text container
			const textContainer = document.querySelector('.LinesEllipsis div:first-child');
			if (!textContainer) return;

			// set the state to show more or less
			setShowMore(compareRenderedTextLengths(textContainer, description.length));
		}
	});

	return (
		<section className={`area-detail${!description ? ' decrease-height' : ''}`}>
			<MaxWidthLayout>
				<BreadCrumbList>
					<ApButtonLink
						theme="dark"
						onClick={() => {
							history.goBack();
						}}
					>
						<ApIcon
							iconName="baseline_keyboard_arrow_left"
							iconColor="var(--color-brand)"
						/>
						Back
					</ApButtonLink>
				</BreadCrumbList>
				<div className="area-detail__row">
					<div className={`area-detail__left area-detail__left-cols-${leftColsCount}`}>
						<h2 className="area-detail__title">{name}</h2>
						<div className="area-detail__description">
							{description && <LinesEllipsis text={description} />}
						</div>
						<div className="area-detail__description area-detail__description--mobile">
							{description && (
								<LinesEllipsis
									text={description}
									maxLine={isReadMoreOpened ? 30 : 3}
									isClamped={false}
								/>
							)}
							{showMore && (
								<div
									className="area-detail__read-more"
									onClick={() => {
										setIsReadMoreOpened(!isReadMoreOpened);
									}}
								>
									{`Read ${isReadMoreOpened ? 'less' : 'more'}`}
								</div>
							)}
						</div>
						<div className="area-button__link">
							<ApButtonLink theme="dark" target="_blank" href={displayNameUrl}>
								{displayNameLink}
							</ApButtonLink>
						</div>
					</div>
					{hasStats && (
						<div
							className={`area-detail__right area-detail__right-cols-${rightColsCount}`}
						>
							<GridListItems
								className="area-detail__grid"
								items={mappedStats}
								columnsCount={statsCount}
								renderItem={({ label, value }) => (
									<div className="area-detail__stat-item">
										<h5 className="area-detail__term-label">{label}</h5>
										<div className={`area-detail__term-value ${fontSizeClass}`}>
											{value}
										</div>
									</div>
								)}
							/>
						</div>
					)}
				</div>
			</MaxWidthLayout>
		</section>
	);
});

AreaDetail.propTypes = {
	area: PropTypes.object.isRequired,
};

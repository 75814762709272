import React from 'react';
import PropTypes from 'prop-types';
import { ErrorOutline } from 'components/icons/ErrorOutline';
import {
	Dialog,
	CloseButton,
	Title as DialogTitle,
	Header as DialogHeader,
	Content as DialogContent,
	Footer as DialogFooter,
} from 'components/Dialog';

import './ErrorDialog.css';

export function ErrorDialog({ type, errors, button, onClose }) {
	return (
		<Dialog className="error-dialog">
			<DialogHeader>
				<ErrorOutline className="error-dialog__title-icon" size={35} />
				<DialogTitle>
					{type} {Object.keys(errors).length > 1 ? 'Errors' : 'Error'}
				</DialogTitle>
				{onClose && <CloseButton onClick={onClose} />}
			</DialogHeader>
			<DialogContent className="error-dialog__content">
				<p>We're sorry, something didn't go as planned.</p>
				{Object.keys(errors).map((key, index) => (
					<pre key={index}>{`${key} - ${errors[key]}`}</pre>
				))}
				<p>Please try again.</p>
			</DialogContent>
			<DialogFooter>{button}</DialogFooter>
		</Dialog>
	);
}

ErrorDialog.propTypes = {
	type: PropTypes.string,
	errors: PropTypes.object.isRequired,
	button: PropTypes.node.isRequired,
	onClose: PropTypes.func,
};

ErrorDialog.defaultProps = {
	type: '',
};

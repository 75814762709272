import React from 'react';
import { ExpandMore, ExpandLess } from 'components/icons';

import './FilterCollapsible.css';

export const FilterCollapsible = ({ title, children, expanded }) => {
	const [isExpanded, setExpanded] = React.useState(!!expanded);

	return (
		<div className="filter-collapsible">
			<div
				className="filter-collapsible__header"
				onClick={() => setExpanded(!isExpanded)}
			>
				<div className="filter-collapsible__title">{title}</div>
				<div className="filter-collapsible__icon">
					{isExpanded ? <ExpandLess size={20} /> : <ExpandMore size={20} />}
				</div>
			</div>
			{isExpanded && <div className="filter-collapsible__body">{children}</div>}
		</div>
	);
};

import React from 'react';
import PropTypes from 'prop-types';
import { Label } from 'components/Label';
import { SearchableSelect } from 'components/SearchableSelect';
import { PageErrorDialog } from 'components/PageErrorDialog';

import {
	EditorPageFieldset,
	EditorPageSeparator,
	EditorPageSectionTitle,
} from 'content-editor/EditorPageLayout';
import { INPUT_NAMES } from 'area-editor/constants';
import { ExpertsReorderList } from 'content-editor/ExpertsReorderList';
import { getPersons } from 'utils/apiUtils';

import { useIsMounted } from 'hooks/useIsMounted';

function getMappedExperts(persons) {
	return persons
		? persons.map(person => ({
				...person,
				label: person.name,
				value: person.id,
		  }))
		: [];
}

/*TODO: Need to remove this once the Product team section has same options as EditAreaTeam.js*/
export function EditProductTeam({
	isAdmin,
	productExperts,
	setFocusedInput,
	updateProductForPersons,
	setProductExperts,
	titleText,
	labeltext,
	dropdownPlaceholder,
}) {
	const [experts, setExperts] = React.useState([]);
	const [apiErrors, setApiErrors] = React.useState({});

	const isMounted = useIsMounted();

	React.useEffect(() => {
		getPersons()
			.then(persons => {
				if (!isMounted()) {
					return;
				}
				setExperts(getMappedExperts(persons));
			})
			.catch(error => {
				if (!isMounted()) {
					return;
				}
				setApiErrors({ persons: error });
			});
	}, []);

	function closeErrorDialog() {
		setApiErrors({});
	}

	function onAddPerson(items) {
		const newPerson = items.slice(-1) && items.slice(-1)[0];
		if (newPerson) {
			setProductExperts(items);
			updateProductForPersons('add', [{ id: newPerson.id }]);
		}
	}

	function onReorderPersons(items, id, destinatonIndex) {
		setProductExperts(items);
		updateProductForPersons('replace', [{ id, order: destinatonIndex }]);
	}

	function onDeleteAreaPersons(person) {
		updateProductForPersons('remove', [{ id: person.id }]);
	}

	const mappedProductExperts = productExperts.map(e => ({ ...e, isVisible: true }));

	return (
		<section className="edit-product-team-section">
			<EditorPageSectionTitle title={titleText} isAdmin={isAdmin} />
			<EditorPageSeparator />
			<EditorPageFieldset>
				<Label htmlFor="product-experts-search" value={labeltext} />
				<SearchableSelect
					id="product-experts-search"
					className="product-experts-select"
					autoFocus={!isAdmin}
					name={INPUT_NAMES.searchExperts}
					customPlaceHolderText={dropdownPlaceholder}
					options={experts}
					selectedValues={getMappedExperts(productExperts)}
					onChange={onAddPerson}
					onFocus={event => {
						setFocusedInput(INPUT_NAMES.searchExperts);
					}}
					onBlur={event => {
						setFocusedInput(null);
					}}
				/>
			</EditorPageFieldset>
			<EditorPageSeparator theme="dark" />
			<ExpertsReorderList
				items={mappedProductExperts}
				onReorder={onReorderPersons}
				onDelete={onDeleteAreaPersons}
			/>
			<PageErrorDialog apiErrors={apiErrors} onClose={closeErrorDialog} />
		</section>
	);
}

EditProductTeam.propTypes = {
	titleText: PropTypes.string.isRequired,
	isAdmin: PropTypes.bool,
	productExperts: PropTypes.arrayOf(
		PropTypes.shape({
			id: PropTypes.number.isRequired,
			name: PropTypes.string.isRequired,
			order: PropTypes.number.isRequired,
			isVisible: PropTypes.bool.isRequired,
		})
	).isRequired,
	setFocusedInput: PropTypes.func,
	updateProductForPersons: PropTypes.func.isRequired,
	setProductExperts: PropTypes.func.isRequired,
};

EditProductTeam.defaultProps = {
	isAdmin: false,
	setFocusedInput: () => {},
};

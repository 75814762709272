import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import classNames from 'classnames';

import { LinesEllipsis } from 'components/LinesEllipsis';
import { SECTION_PARAM_KEY } from 'areas/Areas';
import { INPUT_NAMES, AREA_DETAIL } from 'area-editor/constants';
import { AreaStatsEditor } from 'area-editor/AreaStatsEditor';

import './AreaDetailSection.css';

export function AreaDetailSection({ url, area, selected, focusedInput, onEditArea }) {
	const { name, description, stats, displayNameLink } = area;
	const isDescriptionExist = !!description;
	const isNameExist = !!name;
	const descriptionFocused = focusedInput === INPUT_NAMES.description;

	return (
		<Link to={`${url}?${SECTION_PARAM_KEY}=${AREA_DETAIL}`}>
			<section className={classNames('area-detail-section', { selected })}>
				<div className="area-detail-section__row">
					<div className="area-detail-section__left">
						{isNameExist ? (
							<h2 className="area-detail-section__name">{name}</h2>
						) : (
							<div className="area-detail-section__no-name-block">AREA NAME</div>
						)}

						<div
							className={classNames('area-detail-section__intro-text', {
								'area-detail-section__no-intro-text-block': !isDescriptionExist,
								selected: descriptionFocused,
							})}
						>
							{isDescriptionExist ? (
								<LinesEllipsis text={description} />
							) : (
								<span>Short description</span>
							)}
						</div>
						<div className={'area-detail-section__intro-text'}>
							<LinesEllipsis
								text={displayNameLink ? displayNameLink : 'LINK BUTTON DISPLAY NAME'}
							/>
						</div>
					</div>
					<div className="area-detail-section__right">
						<AreaStatsEditor
							stats={stats}
							focusedInput={focusedInput}
							onEditArea={onEditArea}
						/>
					</div>
				</div>
			</section>
		</Link>
	);
}

AreaDetailSection.propTypes = {
	url: PropTypes.string.isRequired,
	area: PropTypes.object.isRequired,
	focusedInput: PropTypes.string,
};

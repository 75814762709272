import React from 'react';
import PropTypes from 'prop-types';

import { Dialog, Header, Title, Content, Footer, CloseButton } from 'components/Dialog';
import { SecondaryButton, PrimaryButton } from 'components/Button';
import { Label } from 'components/Label';
import { Input } from 'components/Input';
import { ErrorLabel } from 'components/ErrorLabel';

import './CreateProductDialog.css';

const defaultProductData = {
	name: '',
	aboutText: '',
	introText: '',
	description: '',
	personIds: [],
	isActive: true,
	isVisible: true,
};

const isEmptyObject = obj => Object.keys(obj).length === 0;

export function CreateProductDialog({ isCreating, onCancel, onSave }) {
	const [formData, setFormData] = React.useState({});
	const [validationErrors, setValidationErrors] = React.useState({});

	const handleChange = ({ target }) => {
		const name = target.name;
		const value = target.value;

		setFormData({ ...formData, [name]: value });
	};

	const getValidationErrors = () => {
		const isNameBlank = !formData.name;
		const errors = {};

		if (isNameBlank) {
			errors.name = 'Please enter name.';
		}

		return errors;
	};

	const handleFormSubmit = e => {
		e.preventDefault();

		handleSaveAndEdit();
	};

	const handleSave = (_1, doEdit = false) => {
		const errors = getValidationErrors(formData);

		if (!isEmptyObject(errors)) {
			setValidationErrors(errors);
			return;
		}

		onSave({ ...defaultProductData, ...formData }, doEdit);
	};

	const handleSaveAndEdit = () => {
		handleSave(null, true);
	};

	return (
		<Dialog className="create-product-dialog">
			<Header>
				<Title>Create new Product</Title>
				<CloseButton onClick={onCancel} size={28} />
			</Header>
			<Content>
				<form onSubmit={handleFormSubmit}>
					<Label htmlFor="product-name" value="name" />
					<Input
						id="product-name"
						name="name"
						type="text"
						autoFocus
						disabled={isCreating}
						onChange={handleChange}
					/>
					<ErrorLabel error={validationErrors.name} />
				</form>
			</Content>
			<Footer className="create-product-dialog__footer">
				<SecondaryButton
					className="create-product-dialog__cancel-button"
					disabled={isCreating}
					onClick={onCancel}
				>
					Cancel
				</SecondaryButton>

				<SecondaryButton disabled={isCreating} onClick={handleSave}>
					{isCreating ? 'Creating…' : 'Create'}
				</SecondaryButton>

				{!isCreating && (
					<PrimaryButton disabled={isCreating} onClick={handleSaveAndEdit}>
						Create and edit
					</PrimaryButton>
				)}
			</Footer>
		</Dialog>
	);
}

CreateProductDialog.propTypes = {
	isCreating: PropTypes.bool,
	onCancel: PropTypes.func.isRequired,
	onSave: PropTypes.func.isRequired,
};

import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Carousel from 'nuka-carousel';
import { ApModal, ApIcon } from '@alixpartners/ui-components';
import { RemoveScroll } from 'react-remove-scroll';
import { PageSectionTitle } from 'components/PageSectionTitle';
import { MaxWidthLayout } from 'components/MaxWidthLayout';
import { Media } from 'components/Media';
import { ChevronLeftIcon, ChevronRightIcon } from 'components/icons';
import { useEscKeydown } from 'hooks/useEscKeydown';
import { useWindowSize } from 'hooks/useWindowSize';

import './FeaturesCarousel.css';

export function FeaturesCarousel({ features }) {
	let slidesToShow = features.length > 3 ? 3 : features.length;
	const windowSize = useWindowSize();
	const isMobile = windowSize.width < 768;

	// For slide description lines trim we need fixed width value
	const [descriptionWidth, setDescriptionWidth] = React.useState(0);
	const [slideIndex, setSlideIndex] = React.useState(0);
	const [isModalOpen, setIsModalOpen] = React.useState(false);

	React.useEffect(() => {
		if (features.length) {
			const { clientWidth } = document.getElementsByClassName(
				'features-carousel__item'
			)[0];
			setDescriptionWidth(clientWidth);
		}
	});

	const [feature, setFeature] = React.useState(null);
	const items = features.map(feature => (
		<div
			className={classNames(
				`features-carousel__item ${isMobile && features.length === 1 ? 'full-size' : ''}`,
				{
					'features-carousel__item--no-action': feature && !feature.url,
				}
			)}
			key={feature.id}
			onClick={() => {
				setSlideIndex(features.indexOf(feature));
				setFeature(feature);
				setIsModalOpen(true);
			}}
		>
			<div className="features-carousel__img-wrapper">
				<Media
					extension={feature.extension}
					url={feature.url}
					alt={feature.name}
					previewImageUrl={feature.previewImageUrl}
				/>
			</div>
			{/* Need to wait for slide to mount (48 is max-height set for .features-carousel__img-title) */}
			{descriptionWidth > 48 && (
				<>
					<div className="features-carousel__img-title">{feature.name}</div>
					<div className="features-carousel__img-text">{feature.description}</div>
				</>
			)}
		</div>
	));

	const slideWidth = isMobile && items.length > 1 ? 0.82 : 1;

	function handleClose() {
		setFeature(null);
		setIsModalOpen(false);
	}

	useEscKeydown(handleClose);

	if (windowSize.width < 1167) {
		slidesToShow = isMobile ? 1 : 2;
	}

	const hideControls = (isMobile && items.length < 2) || (!isMobile && items.length <= 3);

	const featuresExpanded = features.map(feature => (
		<div className="feature-media-container" key={feature.id}>
			<div className="feature-media-name">{feature.name}</div>
			<Media
				className="features-media-carousel"
				extension={feature.extension}
				previewImageUrl={feature.previewImageUrl}
				url={feature.url}
				alt={feature.name}
				controls
			/>
			<div className="feature-media-description">{feature.description}</div>
		</div>
	));

	const handleAfterSlideChange = slideIndex => {
		setSlideIndex(slideIndex);
		setTimeout(() => setFeature(features[slideIndex]));
	};

	return (
		<section className="features-carousel">
			<MaxWidthLayout>
				<PageSectionTitle>Features</PageSectionTitle>
				<div className="features-carousel__body">
					<Carousel
						slidesToShow={slidesToShow}
						slideIndex={0}
						slideWidth={slideWidth}
						initialSlideHeight={300}
						dotsDisabled
						wrapAround={features.length !== 3}
						cellSpacing={0}
						withoutControls={hideControls}
						dragging={false}
						swiping={!isMobile}
						renderCenterLeftControls={({ previousSlide }) => (
							<button className="features-carousel__prev-btn" onClick={previousSlide}>
								<ChevronLeftIcon size={30} />
							</button>
						)}
						renderCenterRightControls={({ nextSlide }) => (
							<button className="features-carousel__next-btn" onClick={nextSlide}>
								<ChevronRightIcon size={30} />
							</button>
						)}
					>
						{items}
					</Carousel>
				</div>
			</MaxWidthLayout>
			{isModalOpen && feature && (
				<RemoveScroll>
					<ApModal
						className="feature-modal-container"
						isOpen={isModalOpen}
						isDismissible
						isFluid
						header={feature.name}
						hasClosed={handleClose}
					>
						<Carousel
							slideWidth={1}
							cellAlign="center"
							slidesToShow={1}
							initialSlideHeight={500}
							width={`${isMobile ? '100%' : '80vw'}`}
							wrapAround
							slideIndex={slideIndex}
							afterSlide={handleAfterSlideChange}
							enableKeyboardControls
							renderBottomCenterControls={isMobile ? undefined : () => {}}
							renderCenterLeftControls={({ previousSlide, currentSlide }) => {
								if (isMobile) return;
								return (
									<div
										className="feature-navigation feature-navigation--left"
										onClick={previousSlide}
									>
										<ApIcon
											iconName="baseline_keyboard_arrow_left"
											iconColor="var(--color-grey-600)"
											iconSize="48px"
										/>
									</div>
								);
							}}
							renderCenterRightControls={({ nextSlide, currentSlide }) => {
								if (isMobile) return;
								return (
									<div
										className="feature-navigation feature-navigation--right"
										onClick={nextSlide}
									>
										<ApIcon
											iconName="baseline_keyboard_arrow_right"
											iconColor="var(--color-grey-600)"
											iconSize="48px"
										/>
									</div>
								);
							}}
						>
							{featuresExpanded}
						</Carousel>
					</ApModal>
				</RemoveScroll>
			)}
		</section>
	);
}

FeaturesCarousel.propTypes = {
	features: PropTypes.arrayOf(
		PropTypes.shape({
			description: PropTypes.string.isRequired,
			name: PropTypes.string.isRequired,
			url: PropTypes.string,
		})
	).isRequired,
};

import React from 'react';
import PropTypes from 'prop-types';

import './ClearTextButton.css';

export function ClearTextButton({ onClick }) {
	return (
		<button type="button" className="clear-text-button" onClick={onClick}>
			Clear Text
		</button>
	);
}

ClearTextButton.propTypes = {
	onClick: PropTypes.func.isRequired,
};

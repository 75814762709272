import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

import { SECTION_PARAM_KEY } from 'areas/Areas';

import './EditorConfigOptions.css';

export function EditorConfigOptions({ url, options }) {
	return (
		<section className="editor-config-options__section">
			<ul className="editor-config-options__list">
				{options.map(option => (
					<li
						key={option.key}
						id={option.key}
						className="editor-config-options__list-item"
					>
						<Link to={`${url}?${SECTION_PARAM_KEY}=${option.key}`}>{option.label}</Link>
					</li>
				))}
			</ul>
		</section>
	);
}

EditorConfigOptions.propTypes = {
	url: PropTypes.string.isRequired,
	options: PropTypes.arrayOf(
		PropTypes.shape({
			key: PropTypes.string.isRequired,
			label: PropTypes.string.isRequired,
		})
	),
};

import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { assoc, equals, isEmpty } from 'ramda';

import { PrimaryButton, SecondaryButton } from 'components/Button';
import { ChevronLeft } from 'components/icons/ChevronLeft';
import { Overlay, NoDataOverlay } from 'components/Overlay';
import { Loader } from 'components/Loader';
import { MaxWidthLayout } from 'components/MaxWidthLayout';
import { PageErrorDialog } from 'components/PageErrorDialog';

import { EditorHeader } from 'content-editor/EditorHeader';
import {
	EditorPageLayout,
	EditorPageLayoutLeft,
	EditorPageLayoutRight,
} from 'content-editor/EditorPageLayout';
import { EditorConfigOptions } from 'content-editor/EditorConfigOptions';
import { EditorPageSavingLoader } from 'content-editor/EditorPageSavingLoader';
import { EditProductTeam } from 'content-editor/EditProductTeam';
import { ProductDetailSection } from 'product-editor/ProductDetailSection';
import { ProductDetailEditSection } from 'product-editor/ProductDetailEditSection';
import { ProductFeaturesSection } from 'product-editor/ProductFeaturesSection';
import { EditProductWhenToUseSection } from 'product-editor/EditProductWhenToUseSection';
import { EditProductLatestUpdatesSection } from 'product-editor/EditProductLatestUpdatesSection';
import {
	PRODUCT_DETAIL,
	PRODUCT_EDITOR_OPTIONS,
	PRODUCT_FEATURES,
	PRODUCT_WHEN_TO_USE,
	PRODUCT_OWNERS,
	PRODUCT_TEAM,
	PRODUCT_ATTACHMENTS,
	PRODUCT_LATEST_UPDATES,
} from 'product-editor/constants';
import { EditProductFeaturesSection } from 'product-editor/EditProductFeaturesSection';
import { ProductTeamSection } from 'product-editor/ProductTeamSection';
import { ProductWhenToUseSection } from 'product-editor/ProductWhenToUseSection';
import { ProductAttachments } from 'product-editor/ProductAttachments';
import { DEFAULT_ITEM, getValidItem, getValidationErrors } from 'admin/tabs/Solution';
import {
	getSolution,
	updateSolution,
	getFeaturesBySolution,
	patchSolutionFeatures,
	updateAreaProducts,
	getOwnersBySolution,
	getPersonsBySolution,
	patchSolutionPersons,
	patchSolutionOwners,
	getFilesBySolution,
} from 'utils/apiUtils';
import { getNumberFromString, mergeNullKeysToDefaults } from 'utils/parseUtils';
import { getPageUrl, getUrlByTransformingName } from 'utils/urlUtils';
import { useIsMounted } from 'hooks/useIsMounted';
import { useWindowSize } from 'hooks/useWindowSize';

import './ProductPageEditor.css';
import { EditProductAttachmentSection } from './EditProductAttachmentSection';

function formatProduct(product) {
	const { name, url } = product;
	if (!url) {
		return { ...product, url: getUrlByTransformingName(name) };
	}
	return product;
}

export function ProductPageEditor({
	match,
	history,
	location,
	sectionKey,
	featureId,
	attachmentId,
	config,
	products,
	areas,
	refreshData,
}) {
	const { url, params } = match;
	const productId = getNumberFromString(params.productId);
	const [isLoading, setIsLoading] = React.useState(true);
	const [isSaving, setIsSaving] = React.useState(false);
	const [committedItem, setCommittedItem] = React.useState(null);
	const [liveItem, setLiveItem] = React.useState(null);
	const [features, setFeatures] = React.useState([]);
	const [owners, setOwners] = React.useState([]);
	const [team, setTeam] = React.useState([]);
	const [attachments, setAttachments] = React.useState([]);
	const [apiErrors, setApiErrors] = React.useState({});
	const [validationErrors, setValidationErrors] = React.useState({});
	const [focusedInput, setFocusedInput] = React.useState(null);
	const { areaTypes } = config;

	const isMounted = useIsMounted();
	const windowSize = useWindowSize();

	React.useEffect(() => {
		let queries = [
			getSolution(productId),
			getFeaturesBySolution(productId),
			getOwnersBySolution(productId),
			getPersonsBySolution(productId),
			getFilesBySolution(productId),
		];
		Promise.all(queries)
			.then(([product, features = [], owners = [], team = [], attachments = []]) => {
				if (!isMounted()) {
					return;
				}

				const formattedProduct = formatProduct(
					mergeNullKeysToDefaults(DEFAULT_ITEM, product)
				);
				setCommittedItem(formattedProduct);
				setLiveItem(formattedProduct);
				setFeatures(features);
				setOwners(owners);
				setTeam(team);
				setAttachments(attachments);
				setIsLoading(false);
			})
			.catch(error => {
				if (!isMounted()) {
					return;
				}
				setIsLoading(false);
				setApiErrors({ product: error });
			});
	}, []);

	function validateUniqueAttr(attr, validItem, items, errors) {
		const urlDuplicationFound = items.find(
			item =>
				item.id !== validItem.id &&
				(item[attr] || '').toLowerCase() === (validItem[attr] || '').toLowerCase()
		);

		if (urlDuplicationFound) {
			return assoc(
				attr,
				`Product with this ${attr} allready exist. Please enter unique ${attr}`,
				errors
			);
		}

		return errors;
	}

	function onSave() {
		let shouldUpdateProduct = !equals(liveItem, committedItem);
		const shouldUpdateAreas = !equals(liveItem.areas, committedItem.areas);

		const validItem = getValidItem(liveItem);
		if (!validItem.url) {
			validItem.url = getUrlByTransformingName(validItem.name);
			shouldUpdateProduct = true;
		}
		let errors = getValidationErrors(validItem);
		errors = validateUniqueAttr('name', validItem, products, errors);
		errors = validateUniqueAttr('url', validItem, products, errors);

		if (!isEmpty(errors)) {
			setValidationErrors(errors);
			return;
		}

		setValidationErrors({});
		setIsSaving(true);

		const queries = [];

		if (shouldUpdateProduct) {
			queries.push(updateSolution(validItem));
		}

		const areasUpdateQueries = [];
		if (shouldUpdateAreas) {
			const committedAreas = committedItem.areas;
			const liveAreas = liveItem.areas;

			const addedAreas = liveAreas.filter(liveArea => {
				return !committedAreas.find(committedArea => committedArea.id === liveArea.id);
			});
			const removedAreas = committedAreas.filter(committedArea => {
				return !liveAreas.find(liveArea => committedArea.id === liveArea.id);
			});
			addedAreas.length &&
				areasUpdateQueries.push(
					addedAreas.map(addedArea =>
						updateAreaProducts(addedArea.id, 'add', [{ id: productId }])
					)
				);
			removedAreas.length &&
				areasUpdateQueries.push(
					removedAreas.map(removedArea =>
						updateAreaProducts(removedArea.id, 'remove', [{ id: productId }])
					)
				);
		}

		return Promise.all(areasUpdateQueries)
			.then(() => Promise.all(queries))
			.then(() => getSolution(productId))
			.then(item => {
				if (!isMounted()) {
					return;
				}

				if (shouldUpdateProduct) {
					setCommittedItem(item);
					setLiveItem(item);
				}
				setIsSaving(false);
				refreshData();
			})
			.catch(error => {
				if (!isMounted()) {
					return;
				}
				setIsSaving(false);
				refreshData();
				setApiErrors({ product: error });
			});
	}

	function updateSolutionForPersons(operation, persons) {
		setIsSaving(true);
		patchSolutionPersons(productId, operation, persons)
			.then(data => {
				refetchProductAndPersons();
			})
			.catch(error => {
				setIsSaving(false);
				setApiErrors({ product: error });
			});
	}

	function updateSolutionForOwners(operation, owners) {
		setIsSaving(true);
		patchSolutionOwners(productId, operation, owners)
			.then(data => {
				refetchProductAndOwners();
			})
			.catch(error => {
				setIsSaving(false);
				setApiErrors({ product: error });
			});
	}

	function refetchProductAndPersons() {
		Promise.all([getSolution(productId), getPersonsBySolution(productId)])
			.then(([solution, team]) => {
				if (!isMounted()) {
					return;
				}

				const formattedItem = mergeNullKeysToDefaults(DEFAULT_ITEM, solution);
				setCommittedItem(formattedItem);
				setLiveItem(formattedItem);
				setTeam(team);
				setIsSaving(false);
			})
			.catch(error => {
				if (!isMounted()) {
					return;
				}
				setIsSaving(false);
				setApiErrors({ product: error });
			});
	}

	function refetchProductAndOwners() {
		Promise.all([getSolution(productId), getOwnersBySolution(productId)])
			.then(([solution, owners]) => {
				if (!isMounted()) {
					return;
				}

				const formattedItem = mergeNullKeysToDefaults(DEFAULT_ITEM, solution);
				setCommittedItem(formattedItem);
				setLiveItem(formattedItem);
				setOwners(owners);
				setIsSaving(false);
			})
			.catch(error => {
				if (!isMounted()) {
					return;
				}
				setIsSaving(false);
				setApiErrors({ product: error });
			});
	}

	function closeErrorDialog() {
		setApiErrors({});
	}

	function handleSaveLiveItem(event) {
		setLiveItem(assoc(event.target.name, event.target.value));
	}

	function handleChangeFeatures(operation, id, newFeatures) {
		setFeatures(newFeatures);
		setIsSaving(true);
		patchSolutionFeatures(productId, operation, [{ id }])
			.then(() => {
				setIsSaving(false);
			})
			.catch(error => {
				if (!isMounted()) {
					return;
				}
				setIsSaving(false);
				setApiErrors({ features: error });
			});
	}

	function handleReorderFeatures(newFeatures, id, order) {
		setFeatures(newFeatures);
		setIsSaving(true);
		patchSolutionFeatures(productId, 'replace', [{ id, order }])
			.then(() => {
				setIsSaving(false);
			})
			.catch(error => {
				if (!isMounted()) {
					return;
				}
				setIsSaving(false);
				setApiErrors({ features: error });
			});
	}

	function refreshFeaturesList() {
		return getFeaturesBySolution(productId)
			.then(features => {
				if (!isMounted()) {
					return;
				}

				setFeatures(features);
			})
			.catch(error => {
				if (!isMounted()) {
					return;
				}
				setApiErrors({ product: error });
			});
	}

	function refreshAttachmentList() {
		return getFilesBySolution(productId)
			.then(attachments => {
				if (!isMounted()) {
					return;
				}

				setAttachments(attachments);
			})
			.catch(error => {
				if (!isMounted()) {
					return;
				}
				setApiErrors({ attachments: error });
			});
	}

	const detailSelected = sectionKey === PRODUCT_DETAIL;
	const featuresSectionSelected = sectionKey === PRODUCT_FEATURES && !!featureId;
	const ownersSectionSelected = sectionKey === PRODUCT_OWNERS;
	const teamsSectionSelected = sectionKey === PRODUCT_TEAM;
	const whenToUseProductSelected = sectionKey === PRODUCT_WHEN_TO_USE;
	const attachmentsSelected = sectionKey === PRODUCT_ATTACHMENTS;
	const latestUpdateSelected = sectionKey === PRODUCT_LATEST_UPDATES;

	if (isLoading) {
		return (
			<Overlay style={{ minHeight: windowSize.height }}>
				<Loader />
			</Overlay>
		);
	}

	if (!liveItem) {
		return <NoDataOverlay style={{ minHeight: windowSize.height }} />;
	}

	return (
		<div className="product-page-editor">
			<EditorHeader
				left={() => (
					<Link to={getPageUrl('content-editor-products')}>
						<SecondaryButton className="product-page-editor__back-button">
							<ChevronLeft /> Back to Products
						</SecondaryButton>
					</Link>
				)}
				right={() => (
					<div className="product-page-editor__actions">
						<EditorPageSavingLoader isSaving={isSaving} />
						<PrimaryButton
							className="product-page-editor__publish-button"
							disabled={equals(liveItem, committedItem) || isSaving}
							onClick={onSave}
						>
							PUBLISH
						</PrimaryButton>
					</div>
				)}
			/>
			<MaxWidthLayout>
				<EditorPageLayout>
					<EditorPageLayoutLeft>
						<ProductDetailSection
							url={url}
							product={liveItem}
							features={features}
							focusedInput={focusedInput}
							selected={detailSelected}
						/>
						<ProductFeaturesSection
							url={url}
							history={history}
							location={location}
							features={features}
							selectedFeatureId={featureId}
							onChange={handleChangeFeatures}
							onReorder={handleReorderFeatures}
						/>
						<ProductWhenToUseSection
							url={url}
							product={liveItem}
							focusedInput={focusedInput}
							selected={whenToUseProductSelected}
						/>
						<ProductTeamSection
							sectionTitle="Product Owners"
							sectionName={PRODUCT_OWNERS}
							url={url}
							team={owners}
						/>
						<ProductTeamSection
							sectionTitle="TEAM"
							sectionName={PRODUCT_TEAM}
							url={url}
							team={team}
						/>
						<ProductAttachments
							url={url}
							history={history}
							location={location}
							attachmentId={attachmentId}
							attachments={attachments}
						/>
					</EditorPageLayoutLeft>
					<EditorPageLayoutRight>
						{detailSelected && (
							<ProductDetailEditSection
								product={liveItem}
								errors={validationErrors}
								setFocusedInput={setFocusedInput}
								onEditProduct={handleSaveLiveItem}
								areas={areas}
								areaTypes={areaTypes}
							/>
						)}
						{featuresSectionSelected && (
							<EditProductFeaturesSection
								productId={liveItem.id}
								url={url}
								history={history}
								features={features}
								featureId={featureId}
								setIsSaving={setIsSaving}
								refreshFeaturesList={refreshFeaturesList}
							/>
						)}
						{whenToUseProductSelected && (
							<EditProductWhenToUseSection
								product={liveItem}
								errors={validationErrors}
								setFocusedInput={setFocusedInput}
								onEditProduct={handleSaveLiveItem}
								onSaveProduct={onSave}
							/>
						)}
						{ownersSectionSelected && (
							<EditProductTeam
								productExperts={owners}
								updateProductForPersons={updateSolutionForOwners}
								setProductExperts={setOwners}
								titleText="Edit Product Owners"
								labeltext="Select Product Owners"
								dropdownPlaceholder="Select product owner"
							/>
						)}
						{teamsSectionSelected && (
							<EditProductTeam
								productExperts={team}
								updateProductForPersons={updateSolutionForPersons}
								setProductExperts={setTeam}
								titleText="Edit Team"
								labeltext="Select team member"
								dropdownPlaceholder="Select team member"
							/>
						)}
						{attachmentsSelected && (
							<EditProductAttachmentSection
								productId={productId}
								url={url}
								history={history}
								attachmentId={attachmentId}
								attachments={attachments}
								refreshAttachmentList={refreshAttachmentList}
							/>
						)}
						{latestUpdateSelected && (
							<EditProductLatestUpdatesSection
								product={liveItem}
								errors={validationErrors}
								setFocusedInput={setFocusedInput}
								onEditProduct={handleSaveLiveItem}
								onSaveProduct={onSave}
							/>
						)}
						{detailSelected && (
							<EditorConfigOptions url={url} options={PRODUCT_EDITOR_OPTIONS} />
						)}
					</EditorPageLayoutRight>
				</EditorPageLayout>
			</MaxWidthLayout>
			<PageErrorDialog apiErrors={apiErrors} onClose={closeErrorDialog} />
		</div>
	);
}

ProductPageEditor.propTypes = {
	match: PropTypes.object.isRequired,
	featureId: PropTypes.string,
	attachmentId: PropTypes.string,
	history: PropTypes.shape({
		push: PropTypes.func.isRequired,
	}).isRequired,
	sectionKey: PropTypes.string.isRequired,
	products: PropTypes.arrayOf(
		PropTypes.shape({
			id: PropTypes.number.isRequired,
			name: PropTypes.string.isRequired,
		})
	),
	areas: PropTypes.arrayOf(
		PropTypes.shape({
			id: PropTypes.number.isRequired,
			name: PropTypes.string.isRequired,
		})
	),
};

import './GridSelect.css';

import React from 'react';
import PropTypes from 'prop-types';
import { TransitionGroup, CSSTransition } from 'react-transition-group';

import { GridSelectAddButton } from './GridSelectAddButton';
import { GridSelectMenu } from './GridSelectMenu';

const renderCreateButton = props => <GridSelectAddButton {...props} />;

export function GridSelect({
	items,
	onCreate,
	onClick,
	onEdit,
	onDelete,
	renderItem,
	ItemMenu = GridSelectMenu,
}) {
	const modifiedItems = [];
	const buttonItem = renderCreateButton({ onClick: onCreate });
	const renderedItems = items.map((item, index) =>
		renderItem({
			index: index + 1,
			item,
			onClick,
			onEdit,
			onDelete,
			ItemMenu,
		})
	);

	if (typeof onCreate === 'function' && items) modifiedItems.push(buttonItem);
	modifiedItems.push(...renderedItems);

	return (
		<TransitionGroup className="grid-select">
			{modifiedItems.map((component, index) => (
				<CSSTransition
					key={`grid-select-item-${
						index > 0 && items[index - 1] ? items[index - 1].id : index
					}`}
					classNames="transition"
					timeout={{ enter: 300, exit: 300 }}
				>
					{component}
				</CSSTransition>
			))}
		</TransitionGroup>
	);
}

GridSelect.propTypes = {
	items: PropTypes.arrayOf(
		PropTypes.shape({
			id: PropTypes.oneOfType([PropTypes.number, PropTypes.number]).isRequired,
			name: PropTypes.string.isRequired,
			auditDetails: PropTypes.shape({
				createdOn: PropTypes.string,
				createdBy: PropTypes.string,
				updateOn: PropTypes.string,
				updatedBy: PropTypes.string,
			}),
			warnings: PropTypes.arrayOf([PropTypes.string]),
		})
	).isRequired,
	onCreate: PropTypes.func,
	onClickItem: PropTypes.func,
	renderItemMenu: PropTypes.func,
};

GridSelect.defaultProps = {
	onCreate: undefined,
	onClickItem: undefined,
	renderItemMenu: undefined,
};

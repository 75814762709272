import React from 'react';
import PropTypes from 'prop-types';

import { IconButton } from 'components/Button';
import { CloseIcon } from 'components/icons';

import './SelectedItemsList.css';

export function SelectedItemsList({ listItems, onDelete }) {
	const hasListItems = listItems && listItems.length > 0;

	return (
		hasListItems && (
			<ul className="selected-items__list">
				{listItems.map(product => {
					const { id, name } = product;
					return (
						<li key={id} className="selected-items__list-item">
							{name}
							<IconButton
								className="selected-items__list-delete-button"
								onClick={() => {
									onDelete(product);
								}}
							>
								<CloseIcon />
							</IconButton>
						</li>
					);
				})}
			</ul>
		)
	);
}

SelectedItemsList.propTypes = {
	listItems: PropTypes.array.isRequired,
	onDelete: PropTypes.func.isRequired,
};

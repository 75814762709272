import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import Fuse from 'fuse.js';

import { GridSelect } from 'components/GridSelect';
import { Overlay } from 'components/Overlay';
import { Loader } from 'components/Loader';
import { PageErrorDialog } from 'components/PageErrorDialog';
import { MaxWidthLayout } from 'components/MaxWidthLayout';
import { PageSectionTitle } from 'components/PageSectionTitle';
import { EditorPageReorderLabel } from 'content-editor/EditorPageLayout';
import { ModalContainer } from 'components/ModalContainer';
import { DeleteItemDialog } from 'components/DeleteItemDialog';
import { SearchInput } from 'components/SearchInput';
import { ProductGridSelectItem } from './ProductGridSelectItem';
import { ReorderItemsDialog } from 'components/ReorderItemsDialog';
import { EditorGridTitleRow } from 'content-editor/EditorGridTitleRow';
import { SortDropdown } from 'search/SortDropdown';
import { CreateProductDialog } from './CreateProductDialog';
import {
	getSolutions,
	createSolution,
	deleteSolution,
	updateProductsOrder,
} from 'utils/apiUtils';
import { getPageUrl } from 'utils/urlUtils';
import { sortItemsByProp } from 'utils/sortUtils';
import { useIsMounted } from 'hooks/useIsMounted';
import { useWindowSize } from 'hooks/useWindowSize';

import './Products.css';

export const PRODUCT_SECTION_PARAM_KEY = 'section';
export const FEATURE_PARAM_KEY = 'featureId';
export const ATTACHMENT_PARAM_KEY = 'attchmentId';

const fuseOptions = {
	shouldSort: false,
	threshold: 0.2,
	location: 0,
	distance: 100,
	maxPatternLength: 32,
	minMatchCharLength: 1,
	keys: ['name'],
};

const blockTitle = 'Products';
const sortOptions = [
	{
		id: 0,
		name: 'Spotlight',
	},
	{
		id: 2,
		name: 'Name (A-Z)',
	},
	{
		id: 3,
		name: 'Name (Z-A)',
	},
];

const sortMapping = {
	0: 'order',
	2: 'name',
	3: 'name_desc',
};

export function Products({ history }) {
	const baseUrl = getPageUrl('content-editor-products');
	const onEdit = item => {
		history.push(`${baseUrl}/${item.id}?${PRODUCT_SECTION_PARAM_KEY}=detail`);
	};
	const onCreate = () => setCreateDialogOpen(true);

	const [products, setProducts] = React.useState([]);
	const [isLoading, setIsLoading] = React.useState(true);
	const [apiErrors, setApiErrors] = React.useState({});
	const [filter, setFilter] = React.useState('');
	const [createDialogOpen, setCreateDialogOpen] = React.useState(false);
	const [reorderDialogOpen, setReorderDialogOpen] = React.useState(false);
	const [isDeleting, setIsDeleting] = React.useState(false);
	const [isCreating, setIsCreating] = React.useState(false);
	const [deletingItem, setDeleteItem] = React.useState(null);
	const isMounted = useIsMounted();
	const windowSize = useWindowSize();
	const closeErrorDialog = () => setApiErrors({});
	const fetchProducts = () => {
		getSolutions()
			.then(products => {
				if (!isMounted()) {
					return;
				}

				setProducts(sortItemsByProp(products, 'order'));
				setIsLoading(false);
			})
			.catch(error => {
				if (!isMounted()) {
					return;
				}
				setIsLoading(false);
				setApiErrors({ products: error });
			});
	};

	React.useEffect(fetchProducts, []);

	const openReorderDialog = () => setReorderDialogOpen(true);
	const closeCreateDialog = () => setCreateDialogOpen(false);
	const closeDeleteDialog = () => setDeleteItem(null);
	const closeReorderDialog = () => setReorderDialogOpen(false);
	const handleDeleteArea = () => {
		setIsDeleting(true);

		deleteSolution(deletingItem)
			.then(() => {
				setIsDeleting(false);
				closeDeleteDialog();
				fetchProducts();
			})
			.catch(errors => {
				setApiErrors({ product: errors });
			});
	};
	const handleCreateSolution = (item, redirect) => {
		setIsCreating(true);

		createSolution(item)
			.then(item => {
				setIsCreating(false);
				closeCreateDialog();
				fetchProducts();
				if (redirect) {
					history.push(`${baseUrl}/${item.id}`);
				}
			})
			.catch(errors => {
				setApiErrors({ product: errors });
			});
	};
	const handleReorderAction = list => {
		const data = [];
		list.map((item, index) => {
			return data.push({ id: item.id, order: index });
		});
		updateProductsOrder(data).then(() => {
			closeReorderDialog();
			fetchProducts();
		});
	};

	const fuse = new Fuse(products, fuseOptions);
	const filteredProducts = filter ? fuse.search(filter) : products;
	const renderItem = props => <ProductGridSelectItem {...props} />;

	if (isLoading) {
		return (
			<Overlay style={{ minHeight: windowSize.height }}>
				<Loader />
			</Overlay>
		);
	}

	return (
		<section className="products-list">
			<MaxWidthLayout fullWidth>
				<EditorGridTitleRow
					left={() => <PageSectionTitle noBottomMargin>{blockTitle}</PageSectionTitle>}
					right={() => (
						<Fragment>
							<SortDropdown
								onSort={value => {
									setProducts(sortItemsByProp(products, value));
								}}
								sortOptions={sortOptions}
								sortMapping={sortMapping}
							/>
							<EditorPageReorderLabel
								title={`Reorder ${blockTitle}`}
								action={openReorderDialog}
							/>
						</Fragment>
					)}
				/>
				<SearchInput value={filter} placeholder="Search a product" onChange={setFilter} />
				<GridSelect
					items={filteredProducts}
					onCreate={onCreate}
					onClick={onEdit}
					onEdit={onEdit}
					onDelete={setDeleteItem}
					renderItem={renderItem}
				/>
			</MaxWidthLayout>

			{deletingItem && (
				<ModalContainer onClose={closeDeleteDialog}>
					<DeleteItemDialog
						type="product"
						name={deletingItem.name}
						isDeleting={isDeleting}
						onCancel={closeDeleteDialog}
						onConfirm={handleDeleteArea}
					/>
				</ModalContainer>
			)}

			{createDialogOpen && (
				<ModalContainer onClose={closeCreateDialog}>
					<CreateProductDialog
						isCreating={isCreating}
						onCancel={closeCreateDialog}
						onSave={handleCreateSolution}
					/>
				</ModalContainer>
			)}

			{reorderDialogOpen && (
				<ModalContainer onClose={closeReorderDialog}>
					<ReorderItemsDialog
						title={`Reorder ${blockTitle}`}
						itemsList={products}
						onCancel={closeReorderDialog}
						onConfirm={handleReorderAction}
					/>
				</ModalContainer>
			)}

			<PageErrorDialog apiErrors={apiErrors} onClose={closeErrorDialog} />
		</section>
	);
}

Products.propTypes = {
	history: PropTypes.object.isRequired,
};

import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { AddIcon } from 'components/icons';

import './GridSelect.css';
import './GridSelectAddButton.css';

export function GridSelectAddButton({ className, onClick }) {
	return (
		<button
			className={classNames('grid-select-add-button', className)}
			onClick={onClick}
			onKeyPress={onClick}
		>
			<AddIcon size={38} />
		</button>
	);
}

GridSelectAddButton.propTypes = {
	onClick: PropTypes.func,
};

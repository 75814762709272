import React from 'react';
import PropTypes from 'prop-types';

import { Label } from 'components/Label';
import { SearchableSelect } from 'components/SearchableSelect';

import { EditorPageFieldset, EditorPageSeparator } from 'content-editor/EditorPageLayout';
import { SelectedItemsList } from 'experts/SelectedItemsList';

function getOptions(list, areaTypes = []) {
	return list
		? list.map(item => {
				const areaType = areaTypes.find(areaType => item.typeId === areaType.id);
				return {
					...item,
					label: (areaType ? `${areaType.name} / ${item.name}` : item.name) || '',
					value: item.id,
				};
		  })
		: [];
}

export function ProductAreasSearchableList({ product, areas, areaTypes, onEditProduct }) {
	function onAdd(areas) {
		onEditProduct({
			target: {
				name: 'areas',
				value: areas.map(({ id, name, typeId }) => ({ id, name, typeId })),
			},
		});
	}

	function onDelete(area) {
		const areas = product.areas || [];

		onEditProduct({
			target: { name: 'areas', value: areas.filter(a => a.id !== area.id) },
		});
	}

	const productAreas = product.areas || [];

	const options = getOptions(areas, areaTypes);

	return (
		<div className="product-areas">
			<EditorPageFieldset>
				<Label htmlFor="product-ares-search" value="Areas" />
				<SearchableSelect
					id="product-ares-search"
					className="product-ares-search"
					customPlaceHolderText="Select areas"
					options={options}
					selectedValues={getOptions(productAreas)}
					onChange={onAdd}
					sortBy="label"
				/>
				<SelectedItemsList listItems={productAreas} onDelete={onDelete} />
			</EditorPageFieldset>
			<EditorPageSeparator theme="dark" />
		</div>
	);
}

ProductAreasSearchableList.propTypes = {
	product: PropTypes.shape({
		id: PropTypes.number.isRequired,
	}).isRequired,
	onEditProduct: PropTypes.func.isRequired,
};

import React, { useRef } from 'react';
import { Col, Container, Row } from 'react-grid-system';
import { ApIcon } from '@alixpartners/ui-components';
import { formatPrice } from 'utils/parseUtils';
import moment from 'moment';
import { Tooltip } from 'react-tippy';
import './myEngagements.css';
import 'react-tippy/dist/tippy.css';

function formatEmailData(engagement, user) {
	const EMAIL_GREETING = 'Dear CCSI Team,';
	const EMAIL_FOOTER = `Kind Regards,
${user.profile.name}`;

	return {
		etsEmail: 'it-ccsi-request@alixpartners.com',
		decommissionSubject: `Request to decommission ${engagement.projectName} - ${
			engagement.codeName
		}`,
		manageUserSubject: `User request/removal : ${engagement.projectName} - ${
			engagement.codeName
		}`,
		decommissionBody: `${EMAIL_GREETING}
		
We are finished with this environment. Please start the decommissioning process. Relevant details below
App / Tool: ${engagement.projectName}
CodeName: ${engagement.codeName}
Resource group: ${engagement.resourceGroupName}
URL: ${engagement.url}

${EMAIL_FOOTER}
`,
		manageUserBody: `${EMAIL_GREETING}

Please extend/remove access for <ENTER USER EMAIL ADRESS> to ${engagement.projectName}, 
UI URL: ${engagement.url}, 
Resourcegroup: ${engagement.resourceGroupName}, 
AD App Id: ${engagement.aadAppId}

${EMAIL_FOOTER}
`,
	};
}

export function Engagements({ engagements, expandEngagement, expandedId, user }) {
	function copyAction(element) {
		const copyText = element && element.current ? element.current.props.children : '';
		const input = document.getElementById('copyInput');
		input.value = copyText.toString();
		input.select();
		document.execCommand('copy');
	}
	const managingDirectorEmailRef = useRef();
	const projectManagerEmailRef = useRef();
	const informationOwnerEmailRef = useRef();
	return (
		<Container fluid style={{ padding: 0 }}>
			{engagements.map((engagement, key) => {
				const emailData = formatEmailData(engagement, user);
				return (
					<div
						key={key}
						className={`engagement-block ${
							engagement.environmentId === expandedId ? 'expanded' : ''
						}`}
					>
						<div className="engagement-block-content">
							<Row
								onClick={() => {
									expandEngagement(engagement.environmentId);
								}}
							>
								<Col md={3} className="product-name">
									<div className="mobile-title">Product Name</div>
									{engagement.projectName}
								</Col>
								<Col md={3} className="code-name">
									<div className="mobile-title">Codename</div>
									{engagement.codeName}
								</Col>
								<Col md={2} className="billing-code">
									<div className="mobile-title">Billing / Matter code</div>
									<div className="code-block">{engagement.billCode}</div>
								</Col>
								<Col md={4} className="engagement-url">
									<div className="mobile-title">URL to the app</div>
									<a
										href={engagement.url}
										target="_blank"
										rel="noopener noreferrer"
										className="project_url"
									>
										{engagement.url}
									</a>
								</Col>
							</Row>
							<Row className="expanded-body">
								<Col md={3}>
									<div className="users-info-headers">
										{engagement.managingDirector ? (
											`${engagement.managingDirector.firstName} ${
												engagement.managingDirector.lastName
											}`
										) : (
											<div>&nbsp;</div>
										)}
									</div>
									<div className="user-role-value">Managing Director</div>
									<div
										className={
											engagement.managingDirector && engagement.managingDirector.email
												? 'engagement-email'
												: 'no-email'
										}
										onClick={() => {
											copyAction(managingDirectorEmailRef);
										}}
										ref={managingDirectorEmailRef}
									>
										{engagement.managingDirector
											? engagement.managingDirector.email
											: 'No Data'}
									</div>
								</Col>
								<Col md={3}>
									<div className="users-info-headers">
										{engagement.projectManager ? (
											`${engagement.projectManager.firstName} ${
												engagement.projectManager.lastName
											}`
										) : (
											<div>&nbsp;</div>
										)}
									</div>
									<div className="user-role-value">Project Manager</div>
									<div
										className={
											engagement.projectManager && engagement.projectManager.email
												? 'engagement-email'
												: 'no-email'
										}
										onClick={() => {
											copyAction(projectManagerEmailRef);
										}}
										ref={projectManagerEmailRef}
									>
										{engagement.projectManager
											? engagement.projectManager.email
											: 'No Data'}
									</div>
								</Col>
								<Col md={2}>
									<div className="users-info-headers">
										{engagement.informationOwner ? (
											`${engagement.informationOwner.firstName} ${
												engagement.informationOwner.lastName
											}`
										) : (
											<div>&nbsp;</div>
										)}
									</div>
									<div className="user-role-value">Information Owner</div>
									<div
										className={
											engagement.informationOwner && engagement.informationOwner.email
												? 'engagement-email'
												: 'no-email'
										}
										onClick={() => {
											copyAction(informationOwnerEmailRef);
										}}
										ref={informationOwnerEmailRef}
									>
										{engagement.informationOwner
											? engagement.informationOwner.email
											: 'No Data'}
									</div>
								</Col>
								<Col md={4}>
									<div className="info-section">
										<Row>
											<Col
												xl={4}
												md={2}
												lg={3}
												sm={1}
												xs={0}
												className="info-section-first-column"
											/>
											<Col xl={4} md={6} lg={5} sm={6} xs={7} className="title">
												Created:
											</Col>
											<Col xl={4} md={4} lg={4} sm={6} xs={5}>
												{engagement.createdOn
													? moment(engagement.createdOn).format('DD.MM.YYYY')
													: ''}
											</Col>
										</Row>
										<Row>
											<Col
												xl={4}
												md={2}
												lg={3}
												sm={1}
												xs={0}
												className="info-section-first-column"
											/>
											<Col xl={4} md={6} lg={5} sm={6} xs={7} className="title">
												Last released:
											</Col>
											<Col xl={4} md={4} lg={4} sm={6} xs={5}>
												{engagement.updatedOn
													? moment(engagement.updatedOn).format('DD.MM.YYYY')
													: ''}
											</Col>
										</Row>
										<Row>
											<Col
												xl={4}
												md={2}
												lg={3}
												sm={1}
												xs={0}
												className="info-section-first-column"
											/>
											<Col xl={4} md={6} lg={5} sm={6} xs={7} className="title">
												<div className="forecat-title">
													Forecast cost
													<div className="forecast-icon">
														<Tooltip
															position="bottom-end"
															interactive={true}
															arrow={true}
															distance={10}
															animateFill={false}
															hideDelay={500}
															size="regular"
															className="qwert"
															theme="light"
															style={{ height: '60px' }}
															html={
																<div className="engagement-tippy-text">Next 30 days</div>
															}
														>
															<ApIcon
																iconName="outline_info"
																iconSize="20"
																iconColor="#737373"
															/>
														</Tooltip>
													</div>
													:
												</div>
											</Col>
											<Col xl={4} md={4} lg={4} sm={6} xs={5}>
												<div className="forecast-block">
													<div className="forecast-value">
														{formatPrice(engagement.projectedCost)}
													</div>
												</div>
											</Col>
										</Row>
										<Row>
											<Col
												xl={4}
												md={2}
												lg={3}
												sm={1}
												xs={0}
												className="info-section-first-column"
											/>
											<Col xl={4} md={6} lg={5} sm={6} xs={7} className="title">
												Cost to date:
											</Col>
											<Col xl={4} md={4} lg={4} sm={6} xs={5}>
												{formatPrice(engagement.costToDate)}
											</Col>
										</Row>
									</div>
								</Col>
							</Row>
						</div>
						<div className="bottom-row">
							<div className="request-decommission">
								<a
									href={`mailto:${emailData.etsEmail}?subject=${encodeURIComponent(
										emailData.decommissionSubject
									)}&body=${encodeURIComponent(emailData.decommissionBody)}`}
									className="request-decommission-block"
								>
									<ApIcon
										iconName="baseline_email_outline"
										iconSize="20"
										iconColor="#737373"
									/>
									<div className="request-decommission-text">Request decommission</div>
								</a>
							</div>
							<div className="add-user">
								<a
									href={`mailto:${emailData.etsEmail}?subject=${encodeURIComponent(
										emailData.manageUserSubject
									)}&body=${encodeURIComponent(emailData.manageUserBody)}`}
									className="request-decommission-block"
								>
									<ApIcon
										iconName="baseline_email_outline"
										iconSize="20"
										iconColor="#737373"
									/>
									<div className="request-decommission-text">Add/Remove User</div>
								</a>
							</div>
						</div>
					</div>
				);
			})}
		</Container>
	);
}

import React from 'react';
import PropTypes from 'prop-types';
import { Dialog, Content, Footer } from 'components/Dialog';
import { SecondaryButton, DangerButton } from 'components/Button';

export function NavigateAwayDialog({ onCancel, onConfirm }) {
	return (
		<Dialog style={{ height: 'auto' }}>
			<Content>
				<p>Navigating away will lose the changes you made on this screen.</p>
			</Content>
			<Footer>
				<SecondaryButton autoFocus onClick={onCancel}>
					Cancel
				</SecondaryButton>
				<DangerButton onClick={onConfirm}>Continue</DangerButton>
			</Footer>
		</Dialog>
	);
}

NavigateAwayDialog.propTypes = {
	onCancel: PropTypes.func.isRequired,
	onConfirm: PropTypes.func.isRequired,
};

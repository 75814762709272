import './FilterCheckbox.css';
import React, { useRef } from 'react';

function generateHash() {
	// I generate the UID from two parts here
	// to ensure the random number provide enough bits.
	let firstPart = (Math.random() * 46656) | 0;
	let secondPart = (Math.random() * 46656) | 0;
	firstPart = `000${firstPart.toString(36)}`.slice(-3);
	secondPart = `000${secondPart.toString(36)}`.slice(-3);
	return firstPart + secondPart;
}

export const FilterCheckbox = props => {
	const uniqueName = `id_${props.id || generateHash()}`;
	const inputRef = useRef(null);

	const titleClick = () => {
		if (inputRef) {
			inputRef.current.click();
		}
	};

	const {
		name,
		title,
		value,
		width,
		height,
		checked,
		defaultChecked,
		onChange,
		onClick,
		disabled,
		color,
	} = props;

	const controlProps = onChange
		? { defaultChecked, checked, onChange }
		: { checked, readOnly: true, onClick };
	const iconWidth = 12;
	const scaleFactor = width / iconWidth - (width / iconWidth) * 0.2;
	const style = {
		width,
		height,
	};

	if (color) {
		style.backgroundColor = color;
		style.borderColor = color;
	}

	const checkboxTitleClassName = `checkbox-title ${disabled ? 'disabled' : ''}`;

	return (
		<div className="filter-checkbox">
			<div className="checkbox-label">
				<input
					ref={inputRef}
					key={`checkbox-${name}-${uniqueName}`}
					id={uniqueName}
					className="checkbox-native-input"
					type="checkbox"
					name={name}
					value={value}
					disabled={disabled}
					{...controlProps}
				/>
				<label
					htmlFor={uniqueName}
					className={`checkbox-button${disabled ? ' disabled' : ''}`}
					style={style}
				>
					<svg
						className="checkbox-icon"
						width={iconWidth}
						height={iconWidth}
						version="1"
						xmlns="http://www.w3.org/2000/svg"
						style={{ transform: `scale(${scaleFactor})` }}
					>
						<path d="M1 5l3 4 7-8" strokeWidth="2" />
					</svg>
				</label>
			</div>
			{title && (
				<span className={checkboxTitleClassName} onClick={titleClick}>
					{title}
				</span>
			)}
		</div>
	);
};

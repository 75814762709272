import React from 'react';

const Icon = ({ size, ...otherProps }) => {
	const props = {
		width: size,
		height: size,
		fill: 'currentcolor',
		...otherProps,
	};
	return (
		<svg viewBox="0 0 24 24" {...props}>
			<path fill="none" d="M0 0h24v24H0V0z" />
			<path d="M14 2H6c-1.1 0-1.99.9-1.99 2L4 20c0 1.1.89 2 1.99 2H18c1.1 0 2-.9 2-2V8l-6-6zM6 20V4h7v5h5v11H6z" />
		</svg>
	);
};

export const DriveFileIcon = Icon;

DriveFileIcon.defaultProps = {
	size: 24,
};

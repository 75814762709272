import React from 'react';
import classNames from 'classnames';

import './Tab.css';

export function Header({ className, ...props }) {
	return <header className={classNames('tab__header', className)} {...props} />;
}

export function Title({ className, ...props }) {
	// eslint-disable-next-line jsx-a11y/heading-has-content
	return <h1 className={classNames('tab__title', className)} {...props} />;
}

export function Content({ className, ...props }) {
	return <div className={classNames('tab__content', className)} {...props} />;
}

export function Section({ className, ...props }) {
	return <section className={classNames('tab__section', className)} {...props} />;
}

export function SectionTitle({ className, ...props }) {
	// eslint-disable-next-line jsx-a11y/heading-has-content
	return <h2 className={classNames('tab__section-title', className)} {...props} />;
}

export function FieldsetRow({ className, ...props }) {
	return <div className={classNames('tab__fieldset-row', className)} {...props} />;
}

export function Fieldset({ className, ...props }) {
	return <fieldset className={classNames('tab__fieldset', className)} {...props} />;
}

export function FieldsetTitle({ className, ...props }) {
	// eslint-disable-next-line jsx-a11y/heading-has-content
	return <h3 className={classNames('tab__fieldset-title', className)} {...props} />;
}

export function Input({ className, ...props }) {
	return <input className={classNames('tab__input', className)} {...props} />;
}

export function Textarea({ className, ...props }) {
	return <textarea className={classNames('tab__textarea', className)} {...props} />;
}

export function ValidationError({ className, ...props }) {
	return <strong className={classNames('tab__validation-error', className)} {...props} />;
}

export function Actions({ className, ...props }) {
	return <div className={classNames('tab__actions', className)} {...props} />;
}

import React from 'react';
import PropTypes from 'prop-types';

import './Loader.css';

export function Loader({ size }) {
	return (
		<div
			className="loader"
			style={{
				width: `${size}px`,
				height: `${size}px`,
				borderWidth: `${size / 12}px`,
			}}
		/>
	);
}

Loader.propTypes = {
	size: PropTypes.number,
};

Loader.defaultProps = {
	size: 44,
};

import React from 'react';
import PropTypes from 'prop-types';

import { LabelWithCount } from 'components/LabelWithCount';
import { Textarea } from 'components/Textarea';
import { ErrorLabel } from 'components/ErrorLabel';
import {
	EditorPageFieldset,
	EditorPageSeparator,
	EditorPageSectionTitle,
} from 'content-editor/EditorPageLayout';
import { ClearTextButton } from 'content-editor/ClearTextButton';
import { INPUT_NAMES } from 'product-editor/constants';
import { SOLUTION_WHEN_TO_USE_LENGTH } from 'admin/tabs/Solution';

export function EditProductWhenToUseSection({
	product,
	errors,
	onEditProduct,
	setFocusedInput,
	onSaveProduct,
}) {
	return (
		<section className="edit-product-when-to-use-section">
			<EditorPageSectionTitle title="Edit When to Use" />
			<EditorPageSeparator />
			<EditorPageFieldset>
				<LabelWithCount
					htmlFor="product-when-to-use"
					value="When to use"
					count={`${
						product.whenToUseInfo ? product.whenToUseInfo.length : 0
					} / ${SOLUTION_WHEN_TO_USE_LENGTH}`}
				/>
				<Textarea
					id="product-when-to-use"
					name={INPUT_NAMES.whenToUseInfo}
					maxLength={SOLUTION_WHEN_TO_USE_LENGTH}
					value={product.whenToUseInfo}
					style={{ minHeight: '300px' }}
					autoFocus
					onFocus={event => {
						setFocusedInput(event.target.name);
					}}
					onBlur={() => {
						setFocusedInput(null);
						onSaveProduct();
					}}
					onChange={onEditProduct}
				/>
				<ErrorLabel error={errors.whenToUseInfo} />
			</EditorPageFieldset>
			<EditorPageSeparator theme="dark" />
			<EditorPageFieldset>
				<ClearTextButton
					onClick={event => {
						event.preventDefault();
						onEditProduct({ target: { name: INPUT_NAMES.whenToUseInfo, value: '' } });
					}}
				/>
			</EditorPageFieldset>
		</section>
	);
}

EditProductWhenToUseSection.propTypes = {
	product: PropTypes.object.isRequired,
	errors: PropTypes.object,
	onEditProduct: PropTypes.func.isRequired,
	setFocusedInput: PropTypes.func.isRequired,
};

EditProductWhenToUseSection.defaultProps = {
	errors: {},
};

/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { AlixIPLogo } from 'components/icons';

import { browserVersion, browserName } from 'react-device-detect';

import './LegacyBrowserOverlay.css';

const LEGACY_BROWSERS = {
	IE: 11,
	Safari: 11,
};

export const LegacyBrowserOverlay = () => {
	if (
		!LEGACY_BROWSERS.hasOwnProperty(browserName) ||
		Number(browserVersion.split('.')[0]) > LEGACY_BROWSERS[browserName]
	) {
		return null;
	}

	const copyToClipboard = () => {
		const dummy = document.createElement('input');
		const text = window.location.href;

		document.body.appendChild(dummy);
		dummy.value = text;
		dummy.select();
		document.execCommand('copy');
		document.body.removeChild(dummy);
	};

	return (
		<div className="legacy-overlay">
			<div className="legacy-overlay__header">
				<div>
					<AlixIPLogo size={72} className="legacy-overlay__logo" />
				</div>
				<div>
					<p className="legacy-overlay__copy">
						is best viewed in{' '}
						<span className="legacy-overlay__copy--highlighted">Google Chrome</span>{' '}
						browser.
					</p>
				</div>
			</div>
			<div className="legacy-overlay__image" />
			<a
				href="javascript:;"
				className="legacy-overlay__copy-url"
				onClick={copyToClipboard}
			>
				Copy url to paste to Chrome
			</a>
		</div>
	);
};

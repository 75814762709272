import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Downshift from 'downshift';
import Fuse from 'fuse.js';
import { Link } from 'react-router-dom';

import { Input } from 'components/Input';
import { IconButton } from 'components/Button';
import { CloseIcon, SearchIcon } from 'components/icons';
import { getPageUrl } from 'utils/urlUtils';

import './ExpertsSearch.css';

const fuseOptions = {
	shouldSort: true,
	threshold: 0.2,
	location: 0,
	distance: 100,
	maxPatternLength: 32,
	minMatchCharLength: 1,
	keys: ['name', 'role', 'country'],
};

function itemToString(item) {
	return item ? item.name : '';
}

function getItems(fuse, expertOptions, filter) {
	return filter ? fuse.search(filter) : expertOptions;
}

export function ExpertsSearch({ expertOptions, onSelect }) {
	const fuse = new Fuse(expertOptions, fuseOptions);
	const expertsUrl = getPageUrl('experts-base-url');

	return (
		<div className="experts-search">
			<Downshift itemToString={itemToString} onChange={onSelect}>
				{({
					getInputProps,
					getToggleButtonProps,
					getItemProps,
					isOpen,
					clearSelection,
					selectedItem,
					inputValue,
					highlightedIndex,
				}) => (
					<div>
						<div className="experts-search__controls">
							<Input
								className="experts-search__input"
								{...getInputProps({ placeholder: 'Search an expert' })}
							/>
							{selectedItem ? (
								<IconButton
									className="experts-search__controls-close"
									onClick={clearSelection}
									aria-label="clear selection"
								>
									<CloseIcon />
								</IconButton>
							) : (
								<IconButton
									className="experts-search__controls-search"
									{...getToggleButtonProps()}
								>
									<SearchIcon />
								</IconButton>
							)}
						</div>
						<div
							className={classNames('experts-search__results', {
								'experts-search__results--open': isOpen,
							})}
						>
							<div className="experts-search__results-header">
								<div className="experts-search__results-name">Experts Name</div>
								<div className="experts-search__results-role">Role</div>
								<div className="experts-search__results-location">Location</div>
							</div>
							<ul className="experts-search__results-list">
								{isOpen &&
									getItems(fuse, expertOptions, inputValue).map((item, index) => (
										<Link key={item.id} to={`${expertsUrl}/${item.id}`}>
											<li
												className={classNames('experts-search__results-list-item', {
													isActive: highlightedIndex === index,
													isSelected: selectedItem === item,
												})}
												{...getItemProps({
													item,
													index,
												})}
											>
												<div className="experts-search__results-name">{item.name}</div>
												<div className="experts-search__results-role">{item.role}</div>
												<div className="experts-search__results-location">
													{item.country}
												</div>
											</li>
										</Link>
									))}
							</ul>
						</div>
					</div>
				)}
			</Downshift>
		</div>
	);
}

ExpertsSearch.propTypes = {
	expertOptions: PropTypes.arrayOf(
		PropTypes.shape({
			name: PropTypes.string.isRequired,
			id: PropTypes.number.isRequired,
			role: PropTypes.string,
			country: PropTypes.string,
		})
	).isRequired,
	onSelect: PropTypes.func.isRequired,
};

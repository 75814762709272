import React from 'react';
import PropTypes from 'prop-types';

import { Dialog, Header, Title, Content, Footer, CloseButton } from 'components/Dialog';
import { SecondaryButton, PrimaryButton } from 'components/Button';
import { Label } from 'components/Label';
import { Input } from 'components/Input';
import { ErrorLabel } from 'components/ErrorLabel';
import { Dropdown } from 'components/Dropdown';

import { EditorPageFieldset } from 'content-editor/EditorPageLayout';

import './CreateAreaDialog.css';

const defaultAreaData = {
	name: '',
	aboutText: '',
	description: '',
	persons: [],
	stats: [
		{ label: 'stat 1', value: '' },
		{ label: 'stat 2', value: '' },
		{ label: 'stat 3', value: '' },
	],
	latestUpdatesDescription: '',
	typeId: null,
};

const isEmptyObject = obj => Object.keys(obj).length === 0;

export function CreateAreaDialog({ config, areas, isCreating, onCancel, onSave }) {
	const [formData, setFormData] = React.useState(defaultAreaData);
	const [validationErrors, setValidationErrors] = React.useState({});

	const handleChange = ({ target }) => {
		const name = target.name;
		const value = target.value;

		setFormData({ ...formData, [name]: value });
	};

	const getValidationErrors = () => {
		const isNameBlank = !formData.name;
		const isNameDuplicated = !areas.find(area => area.name === formData.name);
		const errors = {};

		if (isNameBlank) {
			errors.name = 'Please enter name.';
		} else if (!isNameDuplicated) {
			errors.name = 'This name already exists. Please enter a unique name.';
		}

		return errors;
	};

	const handleFormSubmit = e => {
		e.preventDefault();

		handleSaveAndEdit();
	};

	const handleSave = (_1, doEdit = false) => {
		const errors = getValidationErrors(formData);

		if (!isEmptyObject(errors)) {
			setValidationErrors(errors);
			return;
		}

		onSave({ ...defaultAreaData, ...formData }, doEdit);
	};

	const handleSaveAndEdit = () => {
		handleSave(null, true);
	};

	const { areaTypes } = config;

	return (
		<Dialog className="create-area-dialog">
			<Header>
				<Title>Create new area page</Title>
				<CloseButton onClick={onCancel} size={28} />
			</Header>
			<Content>
				<form onSubmit={handleFormSubmit}>
					<EditorPageFieldset>
						<Label htmlFor="area-name" value="area name" />
						<Input
							id="area-name"
							name="name"
							type="text"
							autoFocus
							disabled={isCreating}
							onChange={handleChange}
						/>
						<ErrorLabel error={validationErrors.name} />
					</EditorPageFieldset>
					<EditorPageFieldset>
						<Label htmlFor="area-type" value="Area Type" />
						<Dropdown
							defaultLabel="Select Type"
							options={areaTypes}
							selectedId={formData.typeId}
							onChange={value => {
								handleChange({
									target: {
										name: 'typeId',
										value,
									},
								});
							}}
						/>
					</EditorPageFieldset>
				</form>
			</Content>
			<Footer className="create-area-dialog__footer">
				<SecondaryButton
					className="create-area-dialog__cancel-button"
					disabled={isCreating}
					onClick={onCancel}
				>
					Cancel
				</SecondaryButton>

				<SecondaryButton disabled={isCreating} onClick={handleSave}>
					{isCreating ? 'Creating…' : 'Create'}
				</SecondaryButton>

				{!isCreating && (
					<PrimaryButton disabled={isCreating} onClick={handleSaveAndEdit}>
						Create and edit
					</PrimaryButton>
				)}
			</Footer>
		</Dialog>
	);
}

CreateAreaDialog.propTypes = {
	isCreating: PropTypes.bool,
	onCancel: PropTypes.func.isRequired,
	onSave: PropTypes.func.isRequired,
};

import React from 'react';
import PropTypes from 'prop-types';

import { LabelWithCount } from 'components/LabelWithCount';
import { Textarea } from 'components/Textarea';
import { ErrorLabel } from 'components/ErrorLabel';
import {
	EditorPageFieldset,
	EditorPageSeparator,
	EditorPageSectionTitle,
} from 'content-editor/EditorPageLayout';
import { ClearTextButton } from 'content-editor/ClearTextButton';
import { INPUT_NAMES } from 'product-editor/constants';
import { SOLUTION_WHEN_TO_USE_LENGTH } from 'admin/tabs/Solution';

export function EditProductLatestUpdatesSection({
	product,
	errors,
	onEditProduct,
	setFocusedInput,
	onSaveProduct,
}) {
	return (
		<section className="edit-product-latest-updates-section">
			<EditorPageSectionTitle title="Edit Latest Updates" />
			<EditorPageSeparator />
			<EditorPageFieldset>
				<LabelWithCount
					htmlFor="product-latest-updates"
					value="Description"
					count={`${
						product.latestUpdateDescription ? product.latestUpdateDescription.length : 0
					} / ${SOLUTION_WHEN_TO_USE_LENGTH}`}
				/>
				<Textarea
					id="product-latest-updates"
					name={INPUT_NAMES.latestUpdateDescription}
					maxLength={SOLUTION_WHEN_TO_USE_LENGTH}
					value={product.latestUpdateDescription || ''}
					style={{ minHeight: '300px' }}
					autoFocus
					onFocus={event => {
						setFocusedInput(event.target.name);
					}}
					onBlur={() => {
						setFocusedInput(null);
						onSaveProduct();
					}}
					onChange={onEditProduct}
				/>
				<ErrorLabel error={errors.latestUpdateDescription} />
			</EditorPageFieldset>
			<EditorPageSeparator theme="dark" />
			<EditorPageFieldset>
				<ClearTextButton
					onClick={event => {
						event.preventDefault();
						onEditProduct({
							target: { name: INPUT_NAMES.latestUpdateDescription, value: '' },
						});
					}}
				/>
			</EditorPageFieldset>
		</section>
	);
}

EditProductLatestUpdatesSection.propTypes = {
	product: PropTypes.object.isRequired,
	errors: PropTypes.object,
	onEditProduct: PropTypes.func.isRequired,
	setFocusedInput: PropTypes.func.isRequired,
};

EditProductLatestUpdatesSection.defaultProps = {
	errors: {},
};

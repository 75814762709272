import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {
	ApIcon,
	ApButtonMain,
	ApButtonSecondary,
	ApButtonIcon,
} from '@alixpartners/ui-components';

import { MoreIcon, AlixIPLogo } from 'components/icons';
import { externalUrls } from 'utils/urlUtils';
import { AppTooltip } from 'components/AppTooltip';
import { AppNavbarSeparator } from 'global/AppNavbar';
import { SERVICES_KEY, INDUSTRIES_KEY, KNOWLEDGEBASE_KEY } from 'global/HomeNavbar';

import './ResponsiveMenu.css';

const LEVEL_1 = 1;
const LEVEL_2 = 2;

export function ResponsiveMenu({
	history,
	searchAllUrl,
	services,
	industries,
	knowledgeBaseLinks,
	triggerNewEngagmentModal,
}) {
	const [isOpen, setIsOpen] = React.useState(false);
	const [currentLevel, setCurrentLevel] = React.useState(null);
	const [subNavKey, setSubNavKey] = React.useState(null);

	const isServices = services && services.length > 0;
	const isIndustries = industries && industries.length > 0;
	const isKnowledgeBaseLinks = knowledgeBaseLinks && knowledgeBaseLinks.length > 0;

	return (
		<div className="responsive-menu">
			<AppTooltip
				open={isOpen}
				theme="mobile-menu"
				touchHold
				tippyProps={{
					trigger: 'click',
					onRequestClose: () => {
						setIsOpen(false);
					},
				}}
				html={
					<div className="responsive-menu__wrapper">
						<div className="responsive-menu__header">
							<div className="responsive-menu__logo">
								<AlixIPLogo />
							</div>
							<ApButtonIcon
								iconName="baseline_close"
								onClick={() => {
									setIsOpen(false);
								}}
							/>
						</div>
						<div className="responsive-menu__container">
							<div
								className={classNames('responsive-menu__level1', {
									'slide-to-left': currentLevel && currentLevel !== LEVEL_1,
								})}
							>
								<ul>
									<li
										className="homepage-navbar__mobile-nav-link"
										onClick={() => {
											history.push(searchAllUrl);
											setIsOpen(false);
										}}
									>
										<div>Search all</div>
										<ApIcon iconName="baseline_arrow_forward" iconColor="#5CB247" />
									</li>
									{isServices && (
										<li
											className="homepage-navbar__mobile-nav-link"
											onClick={() => {
												setCurrentLevel(LEVEL_2);
												setSubNavKey(SERVICES_KEY);
											}}
										>
											<div>By service</div>
											{/* TODO: use css vars  */}
											<ApIcon
												iconName="baseline_keyboard_arrow_right"
												iconColor="#999999"
											/>
										</li>
									)}
									{isIndustries && (
										<li
											className="homepage-navbar__mobile-nav-link"
											onClick={() => {
												setCurrentLevel(LEVEL_2);
												setSubNavKey(INDUSTRIES_KEY);
											}}
										>
											<div>By industry</div>
											<ApIcon
												iconName="baseline_keyboard_arrow_right"
												iconColor="#999999"
											/>
										</li>
									)}
									<li
										className="homepage-navbar__mobile-nav-link"
										onClick={() => {
											setCurrentLevel(LEVEL_2);
											setSubNavKey(KNOWLEDGEBASE_KEY);
										}}
									>
										<div>Knowledge Base</div>
										<ApIcon
											iconName="baseline_keyboard_arrow_right"
											iconColor="#999999"
										/>
									</li>
									<li
										className="homepage-navbar__mobile-nav-link"
										onClick={() => {
											setIsOpen(false);
										}}
									>
										<a href={externalUrls['companies']}>Companies</a>
									</li>
									<li
										className="homepage-navbar__mobile-nav-link"
										onClick={() => {
											history.push('/myEngagements');
											setIsOpen(false);
										}}
									>
										My Engagements
									</li>
								</ul>
								<div className="responsive-menu__footer">
									<ApButtonMain
										onClick={() => {
											setIsOpen(false);
											triggerNewEngagmentModal();
										}}
									>
										New Engagement
									</ApButtonMain>
								</div>
							</div>
							<div
								className={classNames('responsive-menu__level2', {
									'responsive-menu__level2--on': currentLevel === LEVEL_2,
								})}
							>
								<div className="responsive-menu__level2-header">
									<ApButtonSecondary
										onClick={() => {
											setCurrentLevel(LEVEL_1);
										}}
									>
										<ApIcon iconName="baseline_keyboard_arrow_left" iconColor="#999999" />
										BACK
									</ApButtonSecondary>
								</div>
								{isServices && (
									<ul
										className={classNames('homepage-navbar__mobile-sub-nav', {
											'homepage-navbar__mobile-sub-nav--show': subNavKey === SERVICES_KEY,
										})}
									>
										{services.map(service => (
											<li
												key={service.id}
												className="homepage-navbar__mobile-nav-link"
												onClick={() => {
													history.push(`/${service.url}`);
													setIsOpen(false);
												}}
											>
												<div>{service.name}</div>
												<ApIcon iconName="baseline_arrow_forward" iconColor="#5CB247" />
											</li>
										))}
									</ul>
								)}
								{isIndustries && (
									<ul
										className={classNames('homepage-navbar__mobile-sub-nav', {
											'homepage-navbar__mobile-sub-nav--show':
												subNavKey === INDUSTRIES_KEY,
										})}
									>
										{industries.map(industry => (
											<li
												key={industry.id}
												className="homepage-navbar__mobile-nav-link"
												onClick={() => {
													history.push(`/${industry.url}`);
													setIsOpen(false);
												}}
											>
												<div>{industry.name}</div>
												<ApIcon iconName="baseline_arrow_forward" iconColor="#5CB247" />
											</li>
										))}
									</ul>
								)}
								{isKnowledgeBaseLinks && (
									<ul
										className={classNames('homepage-navbar__mobile-sub-nav', {
											'homepage-navbar__mobile-sub-nav--show':
												subNavKey === KNOWLEDGEBASE_KEY,
										})}
									>
										{knowledgeBaseLinks.map(knowledgeBaseLink => (
											<li
												key={knowledgeBaseLink.url}
												onClick={() => {
													history.push(`${knowledgeBaseLink.url}`);
													setIsOpen(false);
												}}
												className="homepage-navbar__mobile-nav-link"
											>
												<div>{knowledgeBaseLink.name}</div>
												<ApIcon iconName="baseline_arrow_forward" iconColor="#5CB247" />
											</li>
										))}
									</ul>
								)}
							</div>
						</div>
					</div>
				}
			>
				<MoreIcon
					className="responsive-menu__more-icon"
					size={24}
					onClick={() => {
						setCurrentLevel(null);
						setIsOpen(true);
					}}
				/>
			</AppTooltip>
			<div className="homepage-navbar__item">
				<AppNavbarSeparator />
			</div>
		</div>
	);
}

ResponsiveMenu.propTypes = {
	history: PropTypes.object.isRequired,
	searchAllUrl: PropTypes.string.isRequired,
	services: PropTypes.arrayOf(
		PropTypes.shape({
			id: PropTypes.number,
			name: PropTypes.string,
			url: PropTypes.string,
		})
	),
	industries: PropTypes.arrayOf(
		PropTypes.shape({
			id: PropTypes.number,
			name: PropTypes.string,
			url: PropTypes.string,
		})
	),
	knowledgeBaseLinks: PropTypes.arrayOf(
		PropTypes.shape({
			name: PropTypes.string,
			url: PropTypes.string,
		})
	),
	triggerNewEngagmentModal: PropTypes.func.isRequired,
};

import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import './Button.css';

function Button({ className, ...props }) {
	return <button className={classNames('button', className)} {...props} />;
}

Button.propTypes = {
	type: PropTypes.string,
	children: PropTypes.node.isRequired,
};

Button.defaultProps = {
	type: 'button',
};

export function PrimaryButton({ className, ...props }) {
	return <Button className={classNames('button--primary', className)} {...props} />;
}

export function SecondaryButton({ className, ...props }) {
	return <Button className={classNames('button--secondary', className)} {...props} />;
}

export function DangerButton({ className, ...props }) {
	return <Button className={classNames('button--danger', className)} {...props} />;
}

export function IconButton({ className, ...props }) {
	return <Button className={classNames('button--icon', className)} {...props} />;
}

export function AddButton({ className, ...props }) {
	return <SecondaryButton className={classNames('button--add', className)} {...props} />;
}

import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { isEmpty } from 'ramda';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { IconButton } from 'components/Button';
import { CloseIcon } from 'components/icons';
import { DragHandle } from 'components/icons/DragHandle';

import './ExpertsReorderList.css';

export function ExpertsReorderList({
	droppableId,
	items,
	onUpdate,
	onDelete,
	onReorder,
}) {
	function onDragEnd(result) {
		const { source, destination, reason } = result;

		// dropped outside the list
		if (!destination) {
			return;
		}

		const isRecognisedDropEvent = reason === 'DROP';
		const isWithinDesiredDropZone = destination.droppableId === droppableId;
		if (isWithinDesiredDropZone && isRecognisedDropEvent) {
			const newItems = [...items];
			const [removed] = newItems.splice(source.index, 1);
			newItems.splice(destination.index, 0, removed);
			onReorder(newItems, removed.id, destination.index);
		}
	}

	return (
		<div className="experts-reorder-list">
			{!isEmpty(items) && (
				<DragDropContext onDragEnd={onDragEnd}>
					<Droppable droppableId={droppableId} ignoreContainerClipping>
						{(provided, snapshot) => (
							<ul
								ref={provided.innerRef}
								className={classNames({
									'expert-list--dragging': snapshot.isDraggingOver,
								})}
							>
								{items.map((item, index) => {
									return (
										<Draggable key={item.id} draggableId={item.id} index={index}>
											{(provided, snapshot) => (
												<li
													ref={provided.innerRef}
													{...provided.draggableProps}
													className={classNames('expert-list__item', {
														'expert-list__item-drag-row': snapshot.isDragging,
													})}
												>
													<div
														className="expert-list__item-drag-handle"
														{...provided.dragHandleProps}
													>
														<DragHandle />
													</div>
													<div className="expert-list__name" title={item.name}>
														{item.name}
													</div>
													{onDelete && (
														<IconButton
															className="expert-list__item-delete"
															onClick={() => onDelete(item)}
														>
															<CloseIcon size={24} />
														</IconButton>
													)}
												</li>
											)}
										</Draggable>
									);
								})}
							</ul>
						)}
					</Droppable>
				</DragDropContext>
			)}
		</div>
	);
}

ExpertsReorderList.propTypes = {
	droppableId: PropTypes.string,
	items: PropTypes.arrayOf(
		PropTypes.shape({
			id: PropTypes.number.isRequired,
			name: PropTypes.string.isRequired,
			order: PropTypes.number.isRequired,
			isVisible: PropTypes.bool.isRequired,
		})
	).isRequired,
	onUpdate: PropTypes.func,
	onDelete: PropTypes.func,
	onReorder: PropTypes.func.isRequired,
};

ExpertsReorderList.defaultProps = {
	droppableId: 'expert-list-droppable',
	onUpdate: null,
	onDelete: null,
};

import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { assoc, equals, isEmpty } from 'ramda';
import NavigationPrompt from 'react-router-navigation-prompt';

import { PrimaryButton, SecondaryButton, DangerButton } from 'components/Button';
import { ModalContainer } from 'components/ModalContainer';
import { NavigateAwayDialog } from 'components/NavigateAwayDialog';
import { Label } from 'components/Label';
import { LabelWithCount } from 'components/LabelWithCount';
import { Input } from 'components/Input';
import { PageErrorDialog } from 'components/PageErrorDialog';
import { Textarea } from 'components/Textarea';
import { ErrorLabel } from 'components/ErrorLabel';
import { DeleteItemDialog } from 'components/DeleteItemDialog';

import {
	EditorPageFieldset,
	EditorPageSeparator,
	EditorPageSectionTitle,
	EditorSectionActions,
} from 'content-editor/EditorPageLayout';
import { PRODUCT_SECTION_PARAM_KEY, ATTACHMENT_PARAM_KEY } from 'products/Products';
import { PRODUCT_ATTACHMENTS } from 'product-editor/constants';

import { createFile, updateFile, deleteFile, uploadFile } from 'utils/apiUtils';
import { useIsMounted } from 'hooks/useIsMounted';
import { getNumberFromString } from 'utils/parseUtils';
import { isValidateFileType, getFileNameFromUrl } from 'utils/fileUtils';

import './EditProductAttachmentSection.css';

const DEFAULT_ITEM = {
	name: '',
	fileName: '',
	description: '',
	solutionId: null,
};

const WHITELISTED_FILE_TYPES = [
	'.csv',
	'.doc',
	'.docx',
	'.png',
	'.jpg',
	'.jpeg',
	'.pdf',
	'.ppt',
	'.pptx',
	'.rtf',
	'.txt',
	'.xls',
	'.xlsx',
	'.zip',
	'.7z',
	'.rar',
	'.gz',
	'.mp4',
	'.mov',
	'.avi',
	'.flv',
	'.wmv',
];

const ATTACHMENT_DESCRIPTION_MAX_LENGTH = 130;

function getValidItem(item) {
	return {
		...item,
		name: item.name.trim(),
		description: item.description.trim(),
	};
}

function getValidationErrors(item, data, isNew) {
	const { name, solutionId, fileName } = item;
	let errors = {};
	if (!name) {
		errors = assoc('name', 'Please enter a valid name', errors);
	}
	if (!solutionId) {
		errors = assoc('solutionId', 'Please select a solution', errors);
	}
	//TODO : do we need to remove the file name required constraint to add remove text button as per design
	if ((isNew && !data) || !isValidateFileType(fileName)) {
		errors = assoc(
			'data',
			`Please try to upload a	file in following format: ${WHITELISTED_FILE_TYPES.join(
				', '
			)}.`,
			errors
		);
	}

	return errors;
}

function getAttachment(attachments, attachmentId) {
	return attachments
		? attachments.find(e => e.id === getNumberFromString(attachmentId))
		: DEFAULT_ITEM;
}

export function EditProductAttachmentSection({
	productId: solutionId,
	url,
	history,
	attachments,
	attachmentId,
	refreshAttachmentList,
}) {
	const existingAttachment = getAttachment(attachments, attachmentId);
	if (!attachmentId || (!existingAttachment && attachmentId !== 'new')) return null;

	const isNew = attachmentId === 'new';
	const attachmentUrl = `${url}?${PRODUCT_SECTION_PARAM_KEY}=${PRODUCT_ATTACHMENTS}`;
	const defaultAttachment = { ...DEFAULT_ITEM, solutionId };

	const attachment =
		(isNew ? defaultAttachment : getAttachment(attachments, attachmentId)) ||
		defaultAttachment;

	const [isSaving, setIsSaving] = React.useState(false);
	const [isDeleting, setIsDeleting] = React.useState(false);
	const [committedItem, setCommittedItem] = React.useState(attachment);
	const [liveItem, setLiveItem] = React.useState(attachment);
	const [committedFile, setCommittedFile] = React.useState({});
	const [liveFile, setLiveFile] = React.useState({});
	const [deletingItem, setDeletingItem] = React.useState(null);
	const [validationErrors, setValidationErrors] = React.useState({});
	const [apiErrors, setApiErrors] = React.useState({});

	const isMounted = useIsMounted();

	React.useEffect(() => {
		let newAttachment = { ...defaultAttachment };

		if (!isNew) {
			newAttachment = getAttachment(attachments, attachmentId);
		}

		setLiveItem(newAttachment);
		setCommittedItem(newAttachment);
		setLiveFile({});
		setCommittedFile({});
	}, [attachmentId]);

	function closeErrorDialog() {
		setApiErrors({});
	}

	function closeDeleteItemDialog() {
		setDeletingItem(null);
	}

	function handleChange(event) {
		const { name, value } = event.target;
		const newAttachment = { ...liveItem };
		newAttachment[name] = value;
		setLiveItem(newAttachment);
	}

	async function handleUpdateAttachment() {
		const validItem = getValidItem(liveItem);
		const errors = getValidationErrors(validItem, liveFile.data, isNew);
		if (!isEmpty(errors)) {
			setValidationErrors(errors);
			return;
		}
		setValidationErrors({});
		setIsSaving(true);
		const action = isNew ? createFile : updateFile;
		action(validItem)
			.then(item => {
				if (!isMounted()) {
					return;
				}

				if (!liveFile.data) {
					onFileSaveSuccess(item);
					return;
				} else {
					const queries = [];

					if (liveFile.data) {
						queries.push(uploadFile(item.url, liveFile.data));
					}

					Promise.all(queries)
						.then(() => {
							if (!isMounted()) {
								return;
							}

							onFileSaveSuccess(item);
						})
						.catch(error => {
							if (!isMounted()) {
								return;
							}

							setIsSaving(false);
							setApiErrors({ file: error });
						});
				}
			})
			.catch(error => {
				if (!isMounted()) {
					return;
				}
				setIsSaving(false);
				setApiErrors({ attachment: error });
			});
	}

	function onFileSaveSuccess(item) {
		const itemWithExtension = { ...item, extension };
		setLiveItem(itemWithExtension);
		setCommittedItem(itemWithExtension);
		setCommittedFile({});
		setLiveFile({});
		setIsSaving(false);

		if (isNew) {
			refreshAttachmentList().then(() => {
				const newAttachmentUrl = `${attachmentUrl}&${ATTACHMENT_PARAM_KEY}=${item.id}`;
				history.push(newAttachmentUrl);
			});
		} else {
			refreshAttachmentList();
		}
	}

	function onDelete() {
		setIsDeleting(true);
		deleteFile(deletingItem)
			.then(() => {
				if (!isMounted()) {
					return;
				}
				setIsDeleting(false);
				if (solutionId) {
					history.push(attachmentUrl);
				}
				refreshAttachmentList();
			})
			.catch(error => {
				if (!isMounted()) {
					return;
				}
				setIsDeleting(false);
				setApiErrors({ file: error });
			});
	}

	const { name, description, fileName, url: liveItemUrl } = liveItem;
	const extension =
		(liveFile && liveFile.data && liveFile.data.type) || liveItem.extension;

	const sectionTitle = isNew ? 'New attachment' : 'Edit attachment';

	return (
		<section className="edit-products-attachment__section">
			<EditorPageSectionTitle title={sectionTitle} />
			<EditorPageSeparator />
			<EditorPageFieldset>
				<Label htmlFor="attachment-name" value="name" />
				<Input
					id="attachment-name"
					name="name"
					type="text"
					autoFocus
					value={name}
					onChange={handleChange}
				/>
				<ErrorLabel error={validationErrors.name} />
			</EditorPageFieldset>
			<EditorPageFieldset>
				<LabelWithCount
					htmlFor="attachment-description"
					value="Description"
					count={`${
						description ? description.length : 0
					} / ${ATTACHMENT_DESCRIPTION_MAX_LENGTH}`}
				/>
				<Textarea
					id="attachment-description"
					name="description"
					maxLength={ATTACHMENT_DESCRIPTION_MAX_LENGTH}
					value={description || ''}
					onChange={handleChange}
				/>
				<ErrorLabel error={validationErrors.description} />
			</EditorPageFieldset>
			<EditorPageSeparator theme="dark" />
			{(liveItemUrl || fileName) && (
				<Fragment>
					<EditorPageFieldset>
						<Label value="Uploaded a file" />
						<div className="edit-products-attachment__file-row">
							<a
								className="edit-products-attachment__file-link"
								href={liveItemUrl}
								target="_blank"
								rel="noopener noreferrer"
							>
								{liveItemUrl ? getFileNameFromUrl(liveItemUrl) : fileName}
							</a>
							{/* TODO: We need to add this once the api constraint is removed on the file name */}
							{/* <SecondaryButton
								onClick={() => {
									setLiveItem({ ...liveItem, fileName: null, url: null });
									setLiveFile({})
								}}
							>
								Remove file
							</SecondaryButton> */}
						</div>
						<ErrorLabel error={validationErrors.data} />
					</EditorPageFieldset>
					<EditorPageSeparator theme="dark" />
				</Fragment>
			)}
			<EditorPageFieldset>
				<Label
					htmlFor="file-picker"
					value="Upload new attachment"
					className="edit-products-attachment__file-picker-label"
				/>
				<input
					type="file"
					id="file-picker"
					className="edit-products-attachment__file-picker-input"
					name="file-picker"
					accept={WHITELISTED_FILE_TYPES.join(',')}
					onChange={event => {
						const file = event.target.files[0];
						handleChange({ target: { name: 'fileName', value: file.name } });
						setLiveFile(assoc('data', file));
						setValidationErrors({ ...validationErrors, data: null });
					}}
				/>
				{!fileName && <ErrorLabel error={validationErrors.data} />}
			</EditorPageFieldset>
			<EditorSectionActions isFixed>
				{!isNew && (
					<DangerButton disabled={isDeleting} onClick={() => setDeletingItem(liveItem)}>
						Remove attachment
					</DangerButton>
				)}
				<SecondaryButton
					style={{ marginLeft: 'auto' }}
					onClick={() => {
						setLiveItem(committedItem);
						history.push(attachmentUrl);
					}}
				>
					Cancel
				</SecondaryButton>
				<PrimaryButton
					disabled={
						(equals(liveItem, committedItem) && equals(liveFile, committedFile)) ||
						isSaving
					}
					onClick={handleUpdateAttachment}
				>
					{isNew && (isSaving ? 'Adding attachment...' : 'Add attachment')}
					{!isNew && (isSaving ? 'Updating…' : 'Update')}
				</PrimaryButton>
			</EditorSectionActions>
			<PageErrorDialog apiErrors={apiErrors} onClose={closeErrorDialog} />
			{deletingItem && (
				<ModalContainer onClose={closeDeleteItemDialog}>
					<DeleteItemDialog
						type="file"
						name={deletingItem.name}
						isDeleting={isDeleting}
						onCancel={closeDeleteItemDialog}
						onConfirm={onDelete}
					/>
				</ModalContainer>
			)}
			<NavigationPrompt
				disableNative
				when={(currentLocation, nextLocation) =>
					currentLocation.pathname !== nextLocation.pathname &&
					!equals(liveItem, committedItem)
				}
			>
				{({ onConfirm, onCancel }) => (
					<ModalContainer onClose={onCancel}>
						<NavigateAwayDialog
							onCancel={onCancel}
							onConfirm={event => {
								onConfirm(event);
							}}
						/>
					</ModalContainer>
				)}
			</NavigationPrompt>
		</section>
	);
}

EditProductAttachmentSection.propTypes = {
	productId: PropTypes.number.isRequired,
	url: PropTypes.string.isRequired,
	history: PropTypes.object.isRequired,
	attachments: PropTypes.array.isRequired,
	attachmentId: PropTypes.string.isRequired,
	refreshAttachmentList: PropTypes.func.isRequired,
};

import React from 'react';
import PropTypes from 'prop-types';

import './AreaGridSelectItem.css';

export function AreaGridSelectItem({
	types,
	index,
	item,
	onClick,
	onEdit,
	onDelete,
	ItemMenu,
}) {
	return (
		<div
			className="area-grid-select-item"
			style={{ cssText: `--grid-item-index: ${index}` }}
			onClick={() => onClick(item)}
			onKeyPress={() => onClick(item)}
		>
			{ItemMenu && (
				<div className="area-grid-select-item__menu">
					<ItemMenu item={item} onEdit={onEdit} onDelete={onDelete} />
				</div>
			)}
			<div className="area-grid-select-item__contents">
				<div className="area-grid-select-item__title" title={item.name}>
					{item.name}
				</div>
			</div>
			<div className="area-grid-select-item__footer">
				<div className="area-grid-select-item__footer-line">
					<div className="product-grid-select-item__footer-type">
						{types[item.typeId] || 'None'}
					</div>
				</div>
			</div>
		</div>
	);
}

AreaGridSelectItem.propTypes = {
	index: PropTypes.number.isRequired,
	item: PropTypes.shape({
		id: PropTypes.oneOfType([PropTypes.number, PropTypes.number]).isRequired,
		name: PropTypes.string.isRequired,
	}).isRequired,
	onClick: PropTypes.func,
	onDelete: PropTypes.func,
	onEdit: PropTypes.func,
	renderItemMenu: PropTypes.func,
};

import React from 'react';

const Icon = ({ size, ...otherProps }) => {
	const props = {
		width: size,
		height: size,
		fill: 'currentcolor',
		...otherProps,
	};
	return (
		<svg viewBox="0 0 40 40" {...props}>
			<g fill="none" fillRule="evenodd">
				<path d="M0 0h40v40H0z" />
				<path
					fill="#B0B5E8"
					d="M9.75 2.5h14L34.25 13v21a3.51 3.51 0 0 1-3.5 3.5h-21a3.51 3.51 0 0 1-3.5-3.5V6a3.51 3.51 0 0 1 3.5-3.5zm.618 25h20.588v-1.667H9.544V27.5h.824zm0-5h20.588v-1.667H9.544V22.5h.824zm0 10h20.588v-1.667H9.544V32.5h.824zM24 6v7h7l-7-7z"
				/>
			</g>
		</svg>
	);
};

export const DefaultFileIcon = Icon;

DefaultFileIcon.defaultProps = {
	size: 24,
};

import React from 'react';
import PropTypes from 'prop-types';
import { NavLink as Link } from 'react-router-dom';
import classNames from 'classnames';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

import { PageSectionTitle } from 'components/PageSectionTitle';
import { LinesEllipsis } from 'components/LinesEllipsis';
import { Media } from 'components/Media';
import { CloseButton } from 'components/Dialog';
import { DragHandle } from 'components/icons/DragHandle';
import { formatUpdatedonDate } from 'product/LatestUpdateSection';
import { SECTION_PARAM_KEY, ITEM_PARAM_KEY } from 'areas/Areas';
import { AREA_TOOLS_PRODUCTS } from 'area-editor/constants';
import { EditorAddButton } from 'content-editor/EditorAddButton';
import { handleOnDrop } from 'utils/sortUtils';
import { useScrollIntoView } from 'hooks/useScrollIntoView';

import './AreaToolsSection.css';

export function AreaToolsSection({
	droppableId,
	url,
	history,
	location,
	areaProducts: products,
	onChange,
	selectedProductId,
	onReorder,
}) {
	const hasProducts = !!products;
	const newProductUrl = `${url}?${SECTION_PARAM_KEY}=${AREA_TOOLS_PRODUCTS}&${ITEM_PARAM_KEY}=new`;

	function onDragEnd(result) {
		handleOnDrop(droppableId, result, products).then(({ newItems, id, index }) => {
			onReorder(newItems, id, index);
		});
	}

	const isDragDisabled = products && products.length === 1;

	return (
		<section className="area-tools">
			<PageSectionTitle>Products</PageSectionTitle>
			<Link
				className="area-tools__add-button"
				to={newProductUrl}
				isActive={(match, location) =>
					match ? true : `${location.pathname}${location.search}` === newProductUrl
				}
			>
				<EditorAddButton />
			</Link>
			<NewProduct />
			<DragDropContext onDragEnd={onDragEnd}>
				<Droppable droppableId={droppableId} ignoreContainerClipping>
					{(provided, snapshot) => (
						<div
							ref={provided.innerRef}
							className={classNames('area-tools__container', {
								'area-tools__container--no-products': !hasProducts,
								'area-tools__container--dragging': snapshot.isDraggingOver,
							})}
						>
							{hasProducts &&
								products.map((product, index) => (
									<Draggable key={product.id} draggableId={product.id} index={index}>
										{(provided, snapshot) => (
											<Product
												isDragDisabled={isDragDisabled}
												provided={provided}
												snapshot={snapshot}
												url={url}
												location={location}
												product={product}
												onDelete={() => {
													const newProducts = [...products];
													const [deletedProduct] = newProducts.splice(index, 1);
													if (selectedProductId === product.id) {
														history.push(
															`${url}?${SECTION_PARAM_KEY}=${AREA_TOOLS_PRODUCTS}`
														);
													}
													onChange('remove', deletedProduct.id, newProducts);
												}}
											/>
										)}
									</Draggable>
								))}
						</div>
					)}
				</Droppable>
			</DragDropContext>
		</section>
	);
}

AreaToolsSection.propTypes = {
	url: PropTypes.string.isRequired,
	area: PropTypes.object.isRequired,
	droppableId: PropTypes.string.isRequired,
	onChange: PropTypes.func.isRequired,
};

AreaToolsSection.defaultProps = {
	droppableId: 'area-tools-droppable-id',
};

function NewProduct() {
	return (
		<div className="area-tools__new-product">Set the product in the configurator</div>
	);
}

function Product({
	isDragDisabled,
	provided,
	snapshot,
	url,
	location,
	product,
	onDelete,
}) {
	const {
		id,
		name,
		version,
		lastUpdatedDate,
		heroFeatureUrl,
		extension,
		introText,
		whenToUseInfo,
		previewImageUrl,
	} = product;
	const productUrl = `${url}?${SECTION_PARAM_KEY}=${AREA_TOOLS_PRODUCTS}&${ITEM_PARAM_KEY}=${id}`;
	const [isDeleting, setIsDeleting] = React.useState(false);
	const handleDelete = e => {
		e.stopPropagation();
		e.preventDefault();
		setIsDeleting(true);
		setTimeout(onDelete, 300);
	};

	const isActive = `${location.pathname}${location.search}` === productUrl;
	const ref = useScrollIntoView(isActive);

	return (
		<div ref={provided.innerRef} {...provided.draggableProps}>
			<Link
				to={productUrl}
				className={classNames('area-tools__product-button', {
					'area-tools__product-button--removed': isDeleting,
					'area-tools__product-button--dragging': snapshot.isDragging,
				})}
				isActive={(match, location) =>
					match ? true : `${location.pathname}${location.search}` === productUrl
				}
			>
				<div ref={ref} className="area-tools__row">
					<div
						className={classNames('area-tools__product-drag-handle', {
							'area-tools__product-drag-handle--disabled': isDragDisabled,
						})}
						{...provided.dragHandleProps}
					>
						<DragHandle />
					</div>
					<div className="area-tools__column">
						<h3 className="area-tools__product-title">{name}</h3>
						<div className="area-tools__product-info">
							{version && <h4>Version {version || '-'}</h4>}
							{lastUpdatedDate && <h4>Updated {formatUpdatedonDate(lastUpdatedDate)}</h4>}
						</div>
					</div>
					<div className="area-tools__column">
						{introText && (
							<div className="area-tools__product-description">
								<LinesEllipsis text={introText} maxLine={4} />
							</div>
						)}
					</div>
					<div className="area-tools__column">
						{whenToUseInfo && (
							<div className="area-tools__product-description">
								<LinesEllipsis text={whenToUseInfo} maxLine={4} />
							</div>
						)}
					</div>
					<div className="area-tools__column">
						<div
							className={classNames('area-tools__product-media', {
								'area-tools__product-media--default': !heroFeatureUrl,
							})}
						>
							<Media
								extension={extension}
								url={heroFeatureUrl}
								previewImageUrl={previewImageUrl}
								alt="hero feature image"
								width="auto"
								height="auto"
							/>
						</div>
					</div>
					<CloseButton
						className="area-tools__close-button"
						onClick={handleDelete}
						size={24}
					/>
				</div>
			</Link>
		</div>
	);
}

import React, { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import AOS from 'aos';

import { Page } from 'global/Page';
import { Footer } from 'global/Footer';
import { AlixIPLogo } from 'components/icons';

import ServiceList from 'home/ServiceList';
// Text constants
import {
	TEXT_VALUES,
	CDOEmail,
	CDOEmailTitle,
	serviceListRowsCount,
} from 'home/constants';
import {
	BENCHMARKS_URL,
	REGISTERS_URL,
	COMMODITIES_URL,
	CODELIBRARY_URL,
} from 'global/HomeNavbar';

import './Home.css';
import '../../node_modules/aos/dist/aos.css';

export function Home({ areas, config }) {
	const [initialized, setInitialized] = useState(false);
	const areaTypeMap = {};
	config &&
		config.areaTypes &&
		config.areaTypes.forEach(item => {
			return (areaTypeMap[item.name] = item.id);
		});
	const serviceAreas = areas.filter(item => {
		return item.typeId === areaTypeMap['Services'];
	});

	useEffect(() => {
		if (!initialized) {
			AOS.init({
				easing: 'ease-in',
				offset: 100,
				once: true,
			});
			setInitialized(true);
		}
	});
	return (
		<Page>
			<div className="home-page-container">
				<div className="home-page-container__top-block-container">
					<section className="home-page-container__top-block">
						<div className="home-page-container__top-block-logo-container">
							<div
								data-aos="fade"
								data-aos-easing="ease-in-out"
								data-aos-duration="600"
								data-aos-delay="200"
								className="home-page-container__top-block-logo"
							>
								&nbsp;
							</div>
						</div>
						<div
							data-aos="fade-left"
							data-aos-easing="ease"
							data-aos-duration="600"
							data-aos-delay="400"
							className="home-page-container__top-block-info"
						>
							<h3 className="home-page-container__top-block-title">
								<AlixIPLogo size={182} />
							</h3>
							<div className="home-page-container__top-block-text">
								{TEXT_VALUES.topBlockText}
							</div>
						</div>
					</section>
				</div>

				<section className="home-page-container__services-block">
					<div className="home-page-container__services-block-left">
						<div
							data-aos="fade-right"
							data-aos-easing="ease"
							data-aos-duration="600"
							data-aos-delay="200"
							data-aos-offset="0"
							className="home-page-container__services-block-left-content"
						>
							<h3 className="home-page-container__services-block-title">
								{TEXT_VALUES.serviceBlockHeader1}&nbsp;
								<span className="home-page-container__green-text">
									{TEXT_VALUES.serviceBlockHeader2}
								</span>
							</h3>
							<div className="home-page-container__services-block-text">
								{TEXT_VALUES.servicesBlockText}
							</div>
							<NavLink
								to="/search"
								className="home-page-container__services-block-button"
							>
								<button className="button button--primary" type="button">
									{TEXT_VALUES.servicesBlockButtonText}
								</button>
							</NavLink>
						</div>
					</div>
					<div
						data-aos="fade-left"
						data-aos-easing="ease"
						data-aos-duration="600"
						data-aos-delay="400"
						className="home-page-container__services-block-right"
					>
						<div className="home-page-container__services-block-right-content">
							<div className="home-page-container__services-list-container">
								<ServiceList
									services={serviceAreas}
									title="By Service"
									rowsToDisplay={serviceListRowsCount}
								/>
							</div>
						</div>
						<div className="home-page-container_honeycomb-background">&nbsp;</div>
					</div>
				</section>

				<section className="home-page-container__knowledge-base-block">
					<div
						data-aos="fade"
						data-aos-easing="ease"
						data-aos-duration="10"
						data-aos-delay="200"
						className="home-page-container__knowledge-base-left"
					>
						<div className="home-page-container__knowledge-base-background-container">
							&nbsp;
						</div>
						<div className="home-page-container__knowledge-base-tiles-container">
							<NavLink
								to={BENCHMARKS_URL}
								className="home-page-container__knowledge-base-tile"
								data-aos="fade-right"
								data-aos-easing="ease"
								data-aos-delay="1000"
								data-aos-duration="600"
							>
								<div className="home-page-container__knowledge-base-tile-benchmarks">
									<div className="home-page-container__benchmark-logo">&nbsp;</div>
								</div>
								<div className="home-page-container__knowledge-base-tile-bottom">
									<h4 className="home-page-container__knowledge-tile-header">
										{TEXT_VALUES.knowledgeTileBenchmarkHeader}
									</h4>
									<div className="home-page-container__knowledge-tile-text">
										{TEXT_VALUES.knowledgeTileBenchmarkText}
									</div>
									<div className="home-page-container__knowledge-tile-arrow">
										<div className="home-page-container__arrow">&nbsp;</div>
									</div>
								</div>
							</NavLink>
							<NavLink
								to={COMMODITIES_URL}
								className="home-page-container__knowledge-base-tile"
								data-aos="fade-right"
								data-aos-easing="ease"
								data-aos-delay="700"
								data-aos-duration="600"
							>
								<div className="home-page-container__knowledge-base-tile-commodities">
									<div className="home-page-container__commodities-logo">&nbsp;</div>
								</div>
								<div className="home-page-container__knowledge-base-tile-bottom">
									<h4 className="home-page-container__knowledge-tile-header">
										{TEXT_VALUES.knowledgeTileCommoditiesHeader}
									</h4>
									<div className="home-page-container__knowledge-tile-text">
										{TEXT_VALUES.knowledgeTileCommoditiesText}
									</div>
									<div className="home-page-container__knowledge-tile-arrow">
										<div className="home-page-container__arrow">&nbsp;</div>
									</div>
								</div>
							</NavLink>
							<NavLink
								to={REGISTERS_URL}
								className="home-page-container__knowledge-base-tile"
								data-aos="fade-right"
								data-aos-easing="ease"
								data-aos-delay="400"
								data-aos-duration="600"
							>
								<div className="home-page-container__knowledge-base-tile-registers">
									<div className="home-page-container__registers-logo">&nbsp;</div>
								</div>
								<div className="home-page-container__knowledge-base-tile-bottom">
									<h4 className="home-page-container__knowledge-tile-header">
										{TEXT_VALUES.knowledgeTileRegistersHeader}
									</h4>
									<div className="home-page-container__knowledge-tile-text">
										{TEXT_VALUES.knowledgeTileRegistersText}
									</div>
									<div className="home-page-container__knowledge-tile-arrow">
										<div className="home-page-container__arrow">&nbsp;</div>
									</div>
								</div>
							</NavLink>
						</div>
					</div>
					<div
						data-aos="fade-left"
						data-aos-easing="ease"
						data-aos-duration="600"
						data-aos-delay="0"
						className="home-page-container__knowledge-base-right"
					>
						<h3 className="home-page-container__info-block-title">
							{TEXT_VALUES.knowledgeBlockDesc}
							<div className="home-page-container__info-block-title home-page-container__green-text">
								{TEXT_VALUES.knowledgeBlockTitle}
							</div>
						</h3>
						<div className="home-page-container__info-block-text">
							{TEXT_VALUES.knowledgeBlockText}
						</div>
					</div>
				</section>

				{/* 
				<section className="home-page-container__build-tool-block">
					<div className="home-page-container__build-tool-left">
						<div
							data-aos="fade-right"
							data-aos-easing="ease"
							data-aos-delay="0"
							data-aos-duration="600"
							className="home-page-container__build-tool-left-content"
						>
							<h3 className="home-page-container__info-block-title home-page-container__green-text">
								{TEXT_VALUES.toolBlockTitle}
							</h3>
							<h3 className="home-page-container__info-block-title">
								{TEXT_VALUES.toolBlockDesc}
							</h3>
							<div className="home-page-container__info-block-text">
								{TEXT_VALUES.toolBlockText}
							</div>
							<NavLink
								to={homePageUrls.buildOwnTool}
								className="home-page-container__info-block-button"
							>
								<button className="button button--primary" type="button">
									{TEXT_VALUES.toolBlockButtonText}
								</button>
							</NavLink>
						</div>
					</div>
					<div className="home-page-container__build-tool-right">
						<div className="home-page-container__build-tool-right-content">
							<div
								data-aos="fade-left"
								data-aos-easing="ease"
								data-aos-delay="500"
								data-aos-duration="600"
								className="home-page-container__build-tool-background-block"
							>
								&nbsp;
							</div>
							<div
								data-aos="fade-left"
								data-aos-easing="ease"
								data-aos-delay="400"
								data-aos-duration="600"
								className="home-page-container__built-tool-container"
							>
								<div className="home-page-container__build-tool-schema-block" />
							</div>
						</div>
					</div>
					<div />
				</section> */}

				<section className="home-page-container__involved-block">
					<h2
						data-aos="zoom-in"
						data-aos-easing="ease"
						data-aos-duration="900"
						className="home-page-container__involved-title"
					>
						{TEXT_VALUES.involvedBlockTitle}
					</h2>
					<div
						data-aos="zoom-in"
						data-aos-easing="ease"
						data-aos-duration="900"
						className="home-page-container__involved-text"
					>
						{TEXT_VALUES.involvedBlockText}
					</div>
					<div className="home-page-container__involved-actions-container">
						<NavLink
							data-aos="fade-right"
							data-aos-easing="ease"
							data-aos-duration="700"
							data-aos-delay="200"
							to="ip/cdo"
							className="home-page-container__involved-tile"
						>
							<div className="home-page-container__involved-tile--top">
								<div className="home-page-container__cdo-image">&nbsp;</div>
								<div className="home-page-container__cdo-logo">&nbsp;</div>
							</div>
							<div className="home-page-container__involved-tile--bottom">
								<h4 className="home-page-container_involved-tile-title">
									{TEXT_VALUES.cdoTitleTitle}
								</h4>
								<div className="home-page-container__involved-tile-arrow">
									<div className="home-page-container__arrow">&nbsp;</div>
								</div>
							</div>
						</NavLink>
						<a
							data-aos="fade-up"
							data-aos-easing="ease"
							data-aos-duration="700"
							data-aos-delay="600"
							href={`mailto:${CDOEmail}?subject=${CDOEmailTitle}`}
							className="home-page-container__involved-tile"
						>
							<div className="home-page-container__involved-tile--top">
								<div className="home-page-container__tell-us-image">&nbsp;</div>
								<div className="home-page-container__feedback-logo">&nbsp;</div>
							</div>
							<div className="home-page-container__involved-tile--bottom">
								<h4 className="home-page-container_involved-tile-title">
									{TEXT_VALUES.tellUsTitleTitle}
								</h4>
								<div className="home-page-container__involved-tile-arrow">
									<div className="home-page-container__arrow">&nbsp;</div>
								</div>
							</div>
						</a>
						<NavLink
							data-aos="fade-left"
							data-aos-easing="ease"
							data-aos-duration="700"
							data-aos-delay="400"
							to={CODELIBRARY_URL}
							className="home-page-container__involved-tile"
						>
							<div className="home-page-container__involved-tile--top">
								<div className="home-page-container__codelibrary-logo">&nbsp;</div>
								<div className="home-page-container__codelibrary-icon">&nbsp;</div>
							</div>
							<div className="home-page-container__involved-tile--bottom">
								<h4 className="home-page-container_involved-tile-title">
									{TEXT_VALUES.codeLibraryTileTitle}
								</h4>
								<div className="home-page-container__involved-tile-arrow">
									<div className="home-page-container__arrow">&nbsp;</div>
								</div>
							</div>
						</NavLink>
					</div>
				</section>
			</div>
			<div
				data-aos="fade-in"
				data-aos-easing="ease"
				data-aos-duration="800"
				data-aos-delay="200"
				data-aos-offset="-100"
			>
				<Footer />
			</div>
		</Page>
	);
}

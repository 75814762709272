import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import './Label.css';

export function Label({ value, className, ...props }) {
	return (
		<label className={classNames('label', className)} {...props}>
			{value}
		</label>
	);
}

Label.propTypes = {
	value: PropTypes.string,
	className: PropTypes.string,
};

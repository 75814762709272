import React from 'react';
import PropTypes from 'prop-types';
import { NavLink as Link } from 'react-router-dom';
import classNames from 'classnames';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

import { PageSectionTitle } from 'components/PageSectionTitle';
import { LinesEllipsis } from 'components/LinesEllipsis';
import { Media } from 'components/Media';
import { CloseButton } from 'components/Dialog';
import { DragHandle } from 'components/icons/DragHandle';
import { PRODUCT_SECTION_PARAM_KEY, FEATURE_PARAM_KEY } from 'products/Products';
import { PRODUCT_FEATURES } from 'product-editor/constants';
import { EditorAddButton } from 'content-editor/EditorAddButton';
import { handleOnDrop } from 'utils/sortUtils';
import { getNumberFromString } from 'utils/parseUtils';
import { useScrollIntoView } from 'hooks/useScrollIntoView';

import './ProductFeaturesSection.css';

export function ProductFeaturesSection({
	droppableId,
	url,
	history,
	location,
	features,
	onChange,
	selectedFeatureId,
	onReorder,
}) {
	const hasFeatures = !!features;
	const newFeatureUrl = `${url}?${PRODUCT_SECTION_PARAM_KEY}=${PRODUCT_FEATURES}&${FEATURE_PARAM_KEY}=new`;

	function onDragEnd(result) {
		handleOnDrop(droppableId, result, features).then(({ newItems, id, index }) => {
			onReorder(newItems, id, index);
		});
	}

	const isDragDisabled = features && features.length === 1;

	return (
		<section className="product-features">
			<PageSectionTitle>Features</PageSectionTitle>
			<Link
				className="product-features__add-button"
				to={newFeatureUrl}
				isActive={(match, location) =>
					match ? true : `${location.pathname}${location.search}` === newFeatureUrl
				}
			>
				<EditorAddButton />
			</Link>
			<NewFeature />
			<DragDropContext onDragEnd={onDragEnd}>
				<Droppable droppableId={droppableId} ignoreContainerClipping>
					{(provided, snapshot) => (
						<div
							ref={provided.innerRef}
							className={classNames('product-features__container', {
								'product-features__container--no-products': !hasFeatures,
								'product-features__container--dragging': snapshot.isDraggingOver,
							})}
						>
							{hasFeatures &&
								features.map((feature, index) => (
									<Draggable key={feature.id} draggableId={feature.id} index={index}>
										{(provided, snapshot) => (
											<Feature
												isDragDisabled={isDragDisabled}
												provided={provided}
												snapshot={snapshot}
												url={url}
												location={location}
												feature={feature}
												onDelete={() => {
													const newFeatures = [...features];
													const [deletedFeature] = newFeatures.splice(index, 1);
													if (getNumberFromString(selectedFeatureId) === feature.id) {
														history.push(
															`${url}?${PRODUCT_SECTION_PARAM_KEY}=${PRODUCT_FEATURES}`
														);
													}
													onChange('remove', deletedFeature.id, newFeatures);
												}}
											/>
										)}
									</Draggable>
								))}
						</div>
					)}
				</Droppable>
			</DragDropContext>
		</section>
	);
}

ProductFeaturesSection.propTypes = {
	url: PropTypes.string.isRequired,
	features: PropTypes.arrayOf(
		PropTypes.shape({
			id: PropTypes.number.isRequired,
			name: PropTypes.string.isRequired,
		})
	).isRequired,
	droppableId: PropTypes.string.isRequired,
	onChange: PropTypes.func.isRequired,
	onReorder: PropTypes.func.isRequired,
};

ProductFeaturesSection.defaultProps = {
	droppableId: 'product-features-droppable-id',
};

function NewFeature() {
	return (
		<div className="product-features__new-feature">
			Set the feature in the configurator
		</div>
	);
}

function Feature({
	isDragDisabled,
	provided,
	snapshot,
	url,
	location,
	feature,
	onDelete,
}) {
	const {
		id,
		name,
		url: mediaUrl,
		previewImageUrl,
		extension,
		fileName,
		description,
	} = feature;
	const featureUrl = `${url}?${PRODUCT_SECTION_PARAM_KEY}=${PRODUCT_FEATURES}&${FEATURE_PARAM_KEY}=${id}`;
	const [isDeleting, setIsDeleting] = React.useState(false);
	const handleDelete = e => {
		e.stopPropagation();
		e.preventDefault();
		setIsDeleting(true);
		setTimeout(onDelete, 300);
	};

	const isActive = `${location.pathname}${location.search}` === featureUrl;
	const ref = useScrollIntoView(isActive);

	return (
		<div ref={provided.innerRef} {...provided.draggableProps}>
			<Link
				to={featureUrl}
				className={classNames('product-features__button', {
					'product-features__button--removed': isDeleting,
					'product-features__button--dragging': snapshot.isDragging,
				})}
				isActive={(match, location) =>
					match ? true : `${location.pathname}${location.search}` === featureUrl
				}
			>
				<div ref={ref} className="product-features__row">
					<div
						className={classNames('product-features__drag-handle', {
							'product-features__drag-handle--disabled': isDragDisabled,
						})}
						{...provided.dragHandleProps}
					>
						<DragHandle />
					</div>
					<div className="product-features__name">
						<LinesEllipsis text={name} maxLine={4} />
					</div>
					{description && (
						<div className="product-features__description">
							<LinesEllipsis text={description} maxLine={4} />
						</div>
					)}
					<div className="product-features__media-column">
						<div
							className={classNames('product-features__media', {
								'product-features__media--default': !featureUrl,
							})}
						>
							<Media
								extension={extension}
								url={mediaUrl}
								previewImageUrl={previewImageUrl}
								alt={`${name} - ${fileName}`}
								width="auto"
								height="auto"
							/>
						</div>
					</div>
					<CloseButton
						className="product-features__close-button"
						onClick={handleDelete}
						size={24}
					/>
				</div>
			</Link>
		</div>
	);
}

import React from 'react';
import PropTypes from 'prop-types';

import './EditorGridTitleRow.css';

export function EditorGridTitleRow({ left, right }) {
	return (
		<div className="editor-grid-title-row">
			<div className="editor-grid-title-row__left">{left()}</div>
			<div className="editor-grid-title-row__right">{right()}</div>
		</div>
	);
}

EditorGridTitleRow.propTypes = {
	left: PropTypes.func.isRequired,
	right: PropTypes.func.isRequired,
};

import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import VideoPlayer from 'react-player';

import { IconButton } from 'components/Button';
import { PhotoIcon, PlayVideoIcon } from 'components/icons';

import './Media.css';

export function Media({
	className,
	previewClassName,
	style,
	extension,
	url,
	alt,
	width,
	height,
	controls,
	previewImageUrl,
}) {
	return (
		<Fragment>
			{extension && extension.indexOf('video') !== -1 ? (
				<VideoPlayerWithPreview
					width={width}
					height={height}
					url={url}
					alt={alt}
					previewImageUrl={previewImageUrl}
					controls={controls}
					style={{ ...style }}
					className={previewClassName}
				/>
			) : (
				<div className={className}>
					{url ? <img src={url} alt={alt} /> : <PhotoIcon size={30} />}
				</div>
			)}
		</Fragment>
	);
}

Media.propTypes = {
	className: PropTypes.string,
	previewClassName: PropTypes.string,
	extension: PropTypes.string,
	url: PropTypes.string,
	previewImageUrl: PropTypes.string,
	alt: PropTypes.string,
	width: PropTypes.string,
	height: PropTypes.string,
	controls: PropTypes.bool,
	style: PropTypes.object,
};

Media.defaultProps = {
	className: '',
	previewClassName: '',
	extension: '',
	width: '100%',
	height: '100%',
	alt: 'Media',
	style: {
		maxWidth: '100%',
		maxHeight: '100%',
	},
};

export function VideoPlayerWithPreview({
	className,
	style,
	url,
	width,
	height,
	controls,
	previewImageUrl,
	alt,
}) {
	const [playVideo, setPlayVideo] = React.useState(false);

	return playVideo ? (
		<VideoPlayer
			width={width}
			height={height}
			url={url}
			controls={controls}
			style={{ ...style }}
			playing
		/>
	) : (
		<div className={`${className} video-preview`}>
			{previewImageUrl ? (
				<img src={previewImageUrl} alt={alt} />
			) : (
				<img
					src="/images/cover_image.png"
					alt={alt}
					className="video-preview__default-image"
				/>
			)}
			<IconButton
				className={`video-preview__button-icon ${
					!controls ? 'video-preview__button-icon--disabled' : ''
				}`}
				type="button"
				onClick={controls ? setPlayVideo : null}
			>
				<PlayVideoIcon size={60} />
			</IconButton>
		</div>
	);
}

import React from 'react';
import PropTypes from 'prop-types';
import { Dialog, Header, Content, Footer } from 'components/Dialog';
import { SecondaryButton, DangerButton } from 'components/Button';

import './DeleteItemDialog.css';

export function DeleteItemDialog({ type, name, isDeleting, onCancel, onConfirm }) {
	return (
		<Dialog className="delete-item-dialog">
			<Header>Are you sure ?</Header>
			<Content>
				<p className="delete-item-dialog__paragraph">
					Deleting the {type}{' '}
					<strong className="delete-item-dialog__name">“{name}”</strong> will be permanent
					and will delete it from everyone’s view.
				</p>
			</Content>
			<Footer>
				<SecondaryButton autoFocus disabled={isDeleting} onClick={onCancel}>
					Cancel
				</SecondaryButton>
				<DangerButton disabled={isDeleting} onClick={onConfirm}>
					{isDeleting ? 'Deleting…' : 'Delete'}
				</DangerButton>
			</Footer>
		</Dialog>
	);
}

DeleteItemDialog.propTypes = {
	type: PropTypes.string.isRequired,
	name: PropTypes.string.isRequired,
	isDeleting: PropTypes.bool,
	onCancel: PropTypes.func.isRequired,
	onConfirm: PropTypes.func.isRequired,
};

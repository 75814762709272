import React from 'react';

const Icon = ({ size, ...otherProps }) => {
	const props = {
		width: size,
		height: size,
		fill: 'currentcolor',
		...otherProps,
	};
	return (
		<svg viewBox="0 0 24 24" {...props}>
			<path d="M10 6L8.59 7.41 13.17 12l-4.58 4.59L10 18l6-6z" />
			<path d="M0 0h24v24H0z" fill="none" />
		</svg>
	);
};

export const ChevronRight = Icon;

ChevronRight.defaultProps = {
	size: 24,
};

import React from 'react';
import PropTypes from 'prop-types';
import anchorme from 'anchorme';
import classNames from 'classnames';

import './HtmlView.css';

export function HtmlView({ text, className, ...props }) {
	return (
		<div
			className={classNames('html-view', className)}
			{...props}
			dangerouslySetInnerHTML={{
				__html: anchorme(text || '', {
					attributes: [
						{
							name: 'target',
							value: '_blank',
						},
					],
				}),
			}}
		/>
	);
}

HtmlView.propTypes = {
	text: PropTypes.string.isRequired,
};

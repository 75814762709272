import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import { SingleDatePicker as SingleDatePickerComponent } from 'react-dates';

import './DatePicker.css';

export function SingleDatePicker({ onChange, date, placeholder, ...dateRangeProps }) {
	const [isFocused, setIsFocused] = React.useState(false);
	const [startDate, setStartDate] = React.useState(date);

	function handleDateChange(date) {
		if (date) {
			setStartDate(date);
			onChange(date.toISOString());
		}
	}

	return (
		<SingleDatePickerComponent
			isOutsideRange={() => false} // Prevents cell disabling for dates before current day
			onDateChange={handleDateChange}
			onFocusChange={() => setIsFocused(!isFocused)}
			focused={isFocused}
			date={startDate === null ? null : moment(startDate)}
			openDirection="up"
			placeholder={placeholder}
			{...dateRangeProps}
		/>
	);
}

SingleDatePicker.propTypes = {
	date: PropTypes.string,
	placeholder: PropTypes.string,
	onChange: PropTypes.func,
};

SingleDatePicker.defaultProps = {
	date: null,
	placeholder: 'MM/DD/YYYY',
	onChange: () => {},
};

import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { NavLink as Link } from 'react-router-dom';

import { PageSectionTitle } from 'components/PageSectionTitle';
import { PRODUCT_SECTION_PARAM_KEY, ATTACHMENT_PARAM_KEY } from 'products/Products';
import { PRODUCT_ATTACHMENTS } from 'product-editor/constants';
import { EditorAddButton } from 'content-editor/EditorAddButton';
import { FileItemContent } from 'product/FileItem';

import { getNumberFromString } from 'utils/parseUtils';

import './ProductAttachments.css';

export function ProductAttachments({ url, attachmentId, attachments }) {
	const isNew = attachmentId === 'new';
	const newAttachmentUrl = `${url}?${PRODUCT_SECTION_PARAM_KEY}=${PRODUCT_ATTACHMENTS}&${ATTACHMENT_PARAM_KEY}=new`;

	return (
		<section className="product-attachments__section">
			<PageSectionTitle>Attachments</PageSectionTitle>
			<Link
				className="product-attachments__add-button"
				to={newAttachmentUrl}
				isActive={(match, location) =>
					match ? true : `${location.pathname}${location.search}` === newAttachmentUrl
				}
			>
				<EditorAddButton />
			</Link>
			<div className="product-attachments__grid">
				{isNew && <NewAttachment />}
				{attachments.map(attachment => (
					<Link
						className={classNames('product-attachments__grid-item', {
							'product-attachments__grid-item--active':
								attachment.id === getNumberFromString(attachmentId),
						})}
						key={attachment.id}
						to={`${url}?${PRODUCT_SECTION_PARAM_KEY}=${PRODUCT_ATTACHMENTS}&${ATTACHMENT_PARAM_KEY}=${
							attachment.id
						}`}
					>
						<FileItemContent {...attachment} />
					</Link>
				))}
			</div>
		</section>
	);
}

function NewAttachment({ attachment }) {
	return (
		<div className="product-attachements__new">Set the attachment in configurator</div>
	);
}

ProductAttachments.propTypes = {
	url: PropTypes.string.isRequired,
	attachmentId: PropTypes.string,
	attachments: PropTypes.arrayOf(
		PropTypes.shape({
			id: PropTypes.number.isRequired,
		})
	).isRequired,
};

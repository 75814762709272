import React from 'react';
import debounce from 'lodash.debounce';

export function useWindowSize() {
	function getWindowSize() {
		return {
			width: window.innerWidth,
			height: window.innerHeight,
		};
	}

	const [windowSize, setWindowSize] = React.useState(getWindowSize());

	React.useEffect(() => {
		const handleResize = debounce(() => {
			setWindowSize(getWindowSize());
		}, 150);
		window.addEventListener('resize', handleResize);
		return () => {
			window.removeEventListener('resize', handleResize);
		};
	}, []);

	return windowSize;
}

import React from 'react';
import PropTypes from 'prop-types';

import { Label } from 'components/Label';

import './FilePicker.css';

export function FilePicker({ id, name, label, accept, onChange }) {
	return (
		<div className="file-picker">
			<Label htmlFor={id} value={label} className="file-picker__label" />
			<input
				type="file"
				className="file-picker__input"
				id={id}
				name={name}
				accept={accept}
				onChange={onChange}
			/>
		</div>
	);
}

FilePicker.propTypes = {
	id: PropTypes.string.isRequired,
	name: PropTypes.string.isRequired,
	label: PropTypes.string.isRequired,
	accept: PropTypes.string.isRequired,
	onChange: PropTypes.func.isRequired,
};

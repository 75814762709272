import qs from 'qs';

import { getNumberFromString } from 'utils/parseUtils';

export function getSolutionIdFromQueryString(location) {
	return getNumberFromString(
		qs.parse(location.search, {
			ignoreQueryPrefix: true,
		}).solutionId
	);
}

export function getValueFromQueryString(location, queryParam) {
	return getNumberFromString(
		qs.parse(location.search, {
			ignoreQueryPrefix: true,
		})[queryParam]
	);
}

export function getParamsFromQueryString(location) {
	return qs.parse(location.search, {
		ignoreQueryPrefix: true,
	});
}

export function getQueryString(searchParams, options) {
	return qs.stringify(searchParams, { sort: false, ...options });
}

export function getFileNameFromUrl(url) {
	if (!url) {
		return '';
	}
	const startIndex = url.lastIndexOf('/') + 1;
	const endIndex = url.indexOf('?');
	return url.substring(startIndex, endIndex);
}

export function getUrlByTransformingName(name) {
	return name
		? name
				.trim()
				.toLowerCase()
				.replace(/[^a-z0-9\s]+/g, '')
				.replace(/\s+/g, '-')
		: '';
}

//We can keep this as env variables so that this can be managed at the release
export function getAppPrimaryURL() {
	return '/';
}

const appUrls = {
	product: '/admin/products',
	'content-editor': '/content-editor',
	'content-editor-areas': '/content-editor/areas',
	'content-editor-products': '/content-editor/products',
	'experts-url': '/content-editor/experts/:expertId?',
	'experts-base-url': '/content-editor/experts',
};

export function getPageUrl(type) {
	return appUrls[type] || getAppPrimaryURL();
}

export const externalUrls = {
	benchmarks: 'https://alxpbbkbui-appprod.azurewebsites.net/',
	commodities: 'https://alxpkbhpwebaz.azurewebsites.net/commodities',
	companies: 'http://companies.alixip.com',
};

export function getAnchor(url) {
	const urlData = url ? decodeURI(url).split('#') : decodeURI(document.URL).split('#');
	return urlData.length > 1 ? urlData[1] : null;
}

export function scrollToAnchor() {
	const headerOverlap = 60;
	const anchor = getAnchor();
	if (anchor) {
		const sectionToScrollArr = Array.prototype.filter.call(
			document.querySelectorAll('h2.page-section-title'),
			item => {
				return item.innerText.toLowerCase() === anchor.toLowerCase();
			}
		);
		const sectionToScroll = sectionToScrollArr[0];
		if (sectionToScroll && sectionToScroll.scrollIntoView) {
			sectionToScroll.scrollIntoView();
			window.scrollBy(0, -headerOverlap);
		}
	}
}

import React from 'react';
import { NavLink } from 'react-router-dom';
import Masonry from 'react-masonry-css';
import { withRouter } from 'react-router-dom';
import { ProductMasonry } from 'components/ProductMasonry';

import './ProductListMasonry.css';

export const ProductListMasonry = withRouter(
	({
		products,
		history,
		breakpointCols,
		className,
		columnClassName,
		showWhenToUse,
		isExternalLink,
	}) => (
		<Masonry
			breakpointCols={breakpointCols}
			className={className}
			columnClassName={columnClassName}
		>
			{products.map((product, index) => (
				<div key={product.id} className="product-masonry__item">
					{isExternalLink ? (
						<a href={product.appUrl} target="_blank" rel="noopener noreferrer">
							<ProductMasonry
								showWhenToUse={showWhenToUse}
								product={product}
								index={index}
							/>
						</a>
					) : (
						<NavLink
							key={product.id}
							to={`/ip/${product.url}`}
							className="product-masonry__item"
						>
							<ProductMasonry
								showWhenToUse={showWhenToUse}
								product={product}
								index={index}
							/>
						</NavLink>
					)}
				</div>
			))}
		</Masonry>
	)
);

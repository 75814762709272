import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { AddIcon } from 'components/icons';

import './EditorAddButton.css';

export function EditorAddButton({ selected, onClick }) {
	return (
		<button
			className={classNames('editor-add-button', {
				'editor-add-button--selected': selected,
			})}
			onClick={onClick}
			onKeyPress={onClick}
		>
			<AddIcon size={32} />
		</button>
	);
}

EditorAddButton.propTypes = {
	selected: PropTypes.bool,
	onClick: PropTypes.func,
};

import React, { useState } from 'react';
import { ProjectSection } from './projectSection';
import { Col, Container, Row } from 'react-grid-system';
import { Footer } from 'global/Footer';
import { MaxWidthLayout } from 'components/MaxWidthLayout';
import './myEngagements.css';

export function MyEngagements({ user, engagements }) {
	const [expandedEngagement, setExpandedEngagement] = useState(null);
	if (!engagements || !engagements.length) {
		return (
			<MaxWidthLayout fullWidth>
				<div className="my-engagements-container">
					<div className="no-data-container">You don't have any active engagements</div>
				</div>
			</MaxWidthLayout>
		);
	}
	const groupedEngagements = engagements.reduce(function(r, a) {
		r[a.projectName] = r[a.projectName] || {
			name: a.projectName,
			url: a.url,
			engagements: [],
		};
		r[a.projectName].engagements.push(a);
		return r;
	}, Object.create(null));
	const dataList = Object.keys(groupedEngagements) || [];

	const expandEngagement = id => {
		expandedEngagement === id ? setExpandedEngagement(null) : setExpandedEngagement(id);
	};

	return (
		<>
			<div className="my-engagements-background">
				<MaxWidthLayout fullWidth>
					<div className="my-engagements-container">
						<div className="page-title">My Engagements</div>
						<div className="page-content-wrapper">
							<div className="page-content">
								<div className="my-engagements-item-columns">
									<Container fluid>
										<Row>
											<Col sm={2} md={3} className="engagement-column">
												Product Name
											</Col>
											<Col sm={2} md={3} className="engagement-column">
												Code Name
											</Col>
											<Col sm={2} md={2} className="engagement-column">
												Billing / Matter code
											</Col>
											<Col sm={2} md={4} className="engagement-column">
												URL to the App
											</Col>
										</Row>
									</Container>
								</div>
								{dataList.map((projectName, key) => (
									<div key={key}>
										<ProjectSection
											engagements={groupedEngagements[projectName].engagements}
											expandEngagement={expandEngagement}
											expandedId={expandedEngagement}
											user={user}
										/>
									</div>
								))}
								<input type="text" id="copyInput" className="copyTextInput" />
							</div>
						</div>
					</div>
				</MaxWidthLayout>
			</div>
			<Footer />
		</>
	);
}

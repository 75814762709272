import React from 'react';
import PropTypes from 'prop-types';
import Select, { components } from 'react-select';

import { ArrowDropDown } from 'components/icons/ArrowDropDown';
import { SearchIcon } from 'components/icons/SearchIcon';
import { sortByName } from 'utils/sortUtils';

import './SearchableSelect.css';

function DropdownIndicator(props) {
	return (
		<components.DropdownIndicator {...props}>
			<ArrowDropDown className="arrow-icon" />
			<SearchIcon className="search-icon" size="16" />
		</components.DropdownIndicator>
	);
}

export function SearchableSelect({
	id,
	name,
	className,
	options,
	selectedValues,
	isMulti,
	isClearable,
	isRtl,
	isSearchable,
	customPlaceHolderText,
	onChange,
	onFocus,
	onBlur,
	sortBy,
	...props
}) {
	const [showPlaceholder, setShowPlaceholder] = React.useState(true);

	options = sortByName(options, sortBy);
	return (
		<div className="searchable-select__wrapper">
			<Select
				className={`searchable-select ${className}`}
				id={id}
				name={name}
				isMulti={isMulti}
				isClearable={isClearable}
				isRtl={isRtl}
				isSearchable={isSearchable}
				placeholder=""
				options={options}
				onChange={onChange}
				value={selectedValues}
				classNamePrefix="searchable-select"
				components={{ DropdownIndicator }}
				onFocus={event => {
					setShowPlaceholder(false);
					onFocus && onFocus(event);
				}}
				onBlur={event => {
					setShowPlaceholder(true);
					onBlur && onBlur(event);
				}}
				{...props}
			/>
			{showPlaceholder && (
				<span
					className="searchable-select__placeholder"
					onClick={() => {
						setShowPlaceholder(false);
					}}
				>
					{customPlaceHolderText}
				</span>
			)}
		</div>
	);
}

SearchableSelect.propTypes = {
	id: PropTypes.string,
	name: PropTypes.string,
	className: PropTypes.string,
	options: PropTypes.arrayOf(
		PropTypes.oneOfType([
			PropTypes.shape({
				label: PropTypes.string.isRequired,
				options: PropTypes.arrayOf(
					PropTypes.shape({
						label: PropTypes.string.isRequired,
						value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
					})
				),
			}),
			PropTypes.shape({
				label: PropTypes.string.isRequired,
				value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
			}),
		])
	).isRequired,
	selectedValues: PropTypes.arrayOf(
		PropTypes.shape({
			label: PropTypes.string.isRequired,
			value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
		})
	),
	isMulti: PropTypes.bool,
	isClearable: PropTypes.bool,
	isRtl: PropTypes.bool,
	isSearchable: PropTypes.bool,
	customPlaceHolderText: PropTypes.string,
	onChange: PropTypes.func.isRequired,
	onFocus: PropTypes.func,
	onBlur: PropTypes.func,
};

SearchableSelect.defaultProps = {
	id: 'searchable-select',
	selectedValues: [],
	isMulti: true,
	isClearable: false,
	isRtl: false,
	isSearchable: true,
	customPlaceHolderText: 'Find by name...',
	onFocus: () => {},
	onBlur: () => {},
	sortBy: 'name',
};

export const TEXT_VALUES = {
	topBlockTitle: 'AlixIP',
	topBlockText:
		'Powering insight driven consulting. Find, use and build digital client tools and products',
	serviceBlockHeader1: 'Discover and deploy the firm’s digital',
	serviceBlockHeader2: 'tools and products',
	servicesBlockText:
		'Discover tools and products carefully curated by service or use the search below to find the perfect solution for your engagement',
	servicesBlockButtonText: 'Find the right solution',
	knowledgeBlockTitle: 'Knowledge Base',
	knowledgeBlockDesc: 'Access and contribute to the firm wide',
	knowledgeBlockText:
		'Harvest the value of strategic data assets to power insight driven consulting. Get to the heart of the matter faster.',
	knowledgeTileBenchmarkHeader: 'Benchmarks',
	knowledgeTileCommoditiesHeader: 'Commodities',
	knowledgeTileRegistersHeader: 'Registers',
	knowledgeTileBenchmarkText: 'Find performance metrics to quickly enhance your analysis',
	knowledgeTileCommoditiesText: 'Search, view and download the latest commodity prices',
	knowledgeTileRegistersText:
		'Authoritative lists of information you can trust and build services on',
	toolBlockTitle: 'ToolBuilder:',
	toolBlockDesc: 'transform, analyze and present data quickly',
	toolBlockText:
		'Build client tools in a modern, secure and accelerated environment to transform, analyze and present data quickly ',
	toolBlockButtonText: 'Read more',
	involvedBlockTitle: 'Make digital a part of everything we do',
	involvedBlockText:
		'Help build strategic digital assets to augment and support how you work. Contribute to our resources. Be part of the future roadmap.',
	cdoTitleTitle: 'Learn more about the CDO',
	tellUsTitleTitle: 'Need help or more information',
	voteTitleTitle: 'Submit or vote for an idea',
	codeLibraryTileTitle: 'Build fast with our Code Library',
};

export const CDOEmail = 'cdo@alixpartners.com';
export const CDOEmailTitle = 'Talk to the CDO';

export const homePageUrls = {
	cdo: 'ip/cdo',
	buildOwnTool: 'ip/tool-builder',
};

export const serviceListRowsCount = 5;

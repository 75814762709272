import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useAutoSelectInputRef } from 'hooks/useAutoSelectInputRef';

import './TitleInput.css';

export function TitleInput({ className, autoSelect, theme, ...props }) {
	const ref = useAutoSelectInputRef();
	return (
		<input
			ref={autoSelect ? ref : undefined}
			className={classNames('title-input', `title-input--theme-${theme}`, className)}
			{...props}
		/>
	);
}

TitleInput.propTypes = {
	type: PropTypes.string,
	autoSelect: PropTypes.bool,
	theme: PropTypes.oneOf(['admin', 'dialog']).isRequired,
};

TitleInput.defaultProps = {
	type: 'text',
	autoSelect: false,
};

import React from 'react';
import { NavLink } from 'react-router-dom';
import { ApCdoLogo, ApFooter } from '@alixpartners/ui-components';

export const Footer = () => (
	<ApFooter
		right={() => (
			<NavLink to="ip/cdo">
				<ApCdoLogo />
			</NavLink>
		)}
	/>
);

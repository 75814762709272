import React from 'react';
import PropTypes from 'prop-types';
import { Label } from 'components/Label';
import { SearchableSelect } from 'components/SearchableSelect';
import { PageErrorDialog } from 'components/PageErrorDialog';

import {
	EditorPageFieldset,
	EditorPageSeparator,
	EditorPageSectionTitle,
} from 'content-editor/EditorPageLayout';
import { INPUT_NAMES } from 'area-editor/constants';
import { ExpertsReorderList } from 'content-editor/ExpertsReorderList';
import { getPersons } from 'utils/apiUtils';

import { useIsMounted } from 'hooks/useIsMounted';

function getMappedExperts(persons) {
	return persons
		? persons.map(person => ({
				...person,
				label: person.name,
				value: person.id,
		  }))
		: [];
}

export function EditAreaTeam({
	isAdmin,
	areaExperts,
	areaPersonsHash,
	setAreaPersonsHash,
	setFocusedInput,
	updateAreaForPersons,
	setAreaExperts,
}) {
	const [experts, setExperts] = React.useState([]);
	const [apiErrors, setApiErrors] = React.useState({});

	const isMounted = useIsMounted();

	React.useEffect(() => {
		getPersons()
			.then(persons => {
				if (!isMounted()) {
					return;
				}
				setExperts(getMappedExperts(persons));
			})
			.catch(error => {
				if (!isMounted()) {
					return;
				}
				setApiErrors({ persons: error });
			});
	}, []);

	function closeErrorDialog() {
		setApiErrors({});
	}

	function onAddPerson(items) {
		const newPerson = items.slice(-1) && items.slice(-1)[0];
		if (newPerson) {
			const newHashAreaPersons = {};
			items.forEach((e, index) => {
				const newAreaPerson = {
					personId: e.id,
					order: index,
				};
				newHashAreaPersons[e.id] = { ...newAreaPerson };
			});
			setAreaPersonsHash(newHashAreaPersons);
			setAreaExperts(items);
			updateAreaForPersons('add', [{ id: newPerson.id }]);
		}
	}

	function onReorderPersons(items, id, destinatonIndex) {
		const newHashAreaPersons = {};
		items.forEach((e, index) => {
			const newAreaPerson = {
				personId: e.id,
				order: index,
			};
			newHashAreaPersons[e.id] = { ...newAreaPerson };
		});
		setAreaPersonsHash(newHashAreaPersons);
		setAreaExperts(items);
		updateAreaForPersons('replace', [{ id, order: destinatonIndex }]);
	}

	function onDeleteAreaPersons(person) {
		updateAreaForPersons('remove', [{ id: person.id }]);
	}

	return (
		<section className="edit-area-team-section">
			<EditorPageSectionTitle title="Edit Team" isAdmin={isAdmin} />
			<EditorPageSeparator />
			<EditorPageFieldset>
				<Label htmlFor="area-experts-search" value="Select Experts" />
				<SearchableSelect
					id="area-experts-search"
					className="area-experts-select"
					autoFocus={!isAdmin}
					name={INPUT_NAMES.searchExperts}
					customPlaceHolderText="Select an expert"
					options={experts}
					selectedValues={getMappedExperts(areaExperts)}
					onChange={onAddPerson}
					onFocus={event => {
						setFocusedInput(INPUT_NAMES.searchExperts);
					}}
					onBlur={event => {
						setFocusedInput(null);
					}}
				/>
			</EditorPageFieldset>
			<EditorPageSeparator theme="dark" />
			<ExpertsReorderList
				items={areaExperts}
				onReorder={onReorderPersons}
				onDelete={onDeleteAreaPersons}
			/>
			<PageErrorDialog apiErrors={apiErrors} onClose={closeErrorDialog} />
		</section>
	);
}

EditAreaTeam.propTypes = {
	isAdmin: PropTypes.bool,
	areaExperts: PropTypes.arrayOf(
		PropTypes.shape({
			id: PropTypes.number.isRequired,
			name: PropTypes.string.isRequired,
			order: PropTypes.number.isRequired,
		})
	).isRequired,
	areaPersonsHash: PropTypes.object.isRequired,
	setAreaPersonsHash: PropTypes.func.isRequired,
	setFocusedInput: PropTypes.func,
	updateAreaForPersons: PropTypes.func.isRequired,
	setAreaExperts: PropTypes.func.isRequired,
};

EditAreaTeam.defaultProps = {
	isAdmin: false,
	setFocusedInput: () => {},
};

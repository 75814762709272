import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import classNames from 'classnames';

import { GridSelectAddButton } from 'components/GridSelectAddButton';
import { ProductPerson } from 'product/ProductPerson';
import { getPageUrl } from 'utils/urlUtils';
import { getStringFromNumber } from 'utils/parseUtils';

import './ExpertsGrid.css';

export const NEW_EXPERT = 'new';

export function ExpertsGrid({ disableAddButton, persons, expertId }) {
	const expertsUrl = getPageUrl('experts-base-url');

	return (
		<div className="experts-grid">
			{!disableAddButton ? (
				<Link to={`${expertsUrl}/${NEW_EXPERT}`}>
					<GridSelectAddButton
						className={classNames('experts-grid__add-button', {
							'experts-grid__add-button--selected': expertId === NEW_EXPERT,
						})}
					/>
				</Link>
			) : (
				<GridSelectAddButton
					className={classNames('experts-grid__add-button', {
						'experts-grid__add-button--disabled': disableAddButton,
					})}
				/>
			)}
			{persons.map(person => {
				const { id, name, role, url, email, phoneNumber } = person;
				return (
					<Link key={id} to={`${expertsUrl}/${id}`}>
						<ProductPerson
							theme="light"
							className={classNames({
								selected: expertId === getStringFromNumber(id),
							})}
							name={name}
							title={role}
							image={url}
							email={email}
							phoneNumber={phoneNumber}
						/>
					</Link>
				);
			})}
		</div>
	);
}

ExpertsGrid.propTypes = {
	disableAddButton: PropTypes.bool.isRequired,
	persons: PropTypes.array.isRequired,
	expertId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

import { assoc, append, mergeWithKey } from 'ramda';

export function getStringFromNumber(numberValue) {
	return numberValue === null || isNaN(numberValue) ? '' : `${numberValue}`;
}

export function getNumberFromString(stringValue) {
	return stringValue === '' || isNaN(stringValue) ? null : Number(stringValue);
}

export function mergeNullKeysToDefaults(defaultItem, item) {
	if (!item) {
		return defaultItem;
	}
	return mergeWithKey(
		(key, defaultValue, value) => {
			return value === null ? defaultValue : value;
		},
		defaultItem,
		item
	);
}

export function getParagraphs(description) {
	if (!description) {
		return [];
	}
	return description.split('\n').filter(paragraph => Boolean(paragraph));
}

export function isUrlValid(url) {
	const regex = /^(https?|ftp):\/\/(((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!$&'()*+,;=]|:)*@)?(((\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5]))|((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.?)(:\d*)?)(\/((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!$&'()*+,;=]|:|@)+(\/(([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!$&'()*+,;=]|:|@)*)*)?)?(\?((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!$&'()*+,;=]|:|@)|[\uE000-\uF8FF]|\/|\?)*)?(#((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!$&'()*+,;=]|:|@)|\/|\?)*)?$/;
	return regex.test(url);
}

export function isEmailValid(email) {
	const regex = /^((([a-z]|\d|[!#$%&'*+-/=?^_`{|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+(\.([a-z]|\d|[!#$%&'*+-/=?^_`{|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+)*))@((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.?$/;
	return regex.test(email);
}

export function isPhoneNumberValid(phoneNumber) {
	const regex = /^\+(?:[0-9] ?){6,14}[0-9]$/;
	return regex.test(phoneNumber);
}

function getEngagementClientId(item) {
	return item.engagement && item.engagement.clientId;
}

export function getItemsWithNoEngagement(items) {
	return items.filter(item => !getEngagementClientId(item));
}

export function getItemsWithClientEngagement(items, client) {
	if (!client) {
		return [];
	}
	return items.filter(item => getEngagementClientId(item) === client.id);
}

export function getItemsBySolution(items, solution) {
	if (!solution) {
		return [];
	}
	return items.filter(item => item.solutionId === solution.id);
}

export function getEntityLabelFromType(type) {
	const entityLabels = {
		client: 'Client',
		group: 'Group',
		product: 'Product / Tool',
		file: 'File',
		person: 'Subject Matter Expert',
		'latest-update': 'Latest Update',
	};
	return entityLabels[type] || type;
}

export function makeOrderedIndexList(max) {
	return [...Array(max).keys()];
}

export function groupBy(items, key) {
	return items.reduce(
		(acc, currentItem) =>
			assoc(currentItem[key], append(currentItem, acc[currentItem[key]] || []), acc),
		{}
	);
}

export function getKeyValueObject(items, keyName, valueName) {
	const data = {};

	items.forEach(item => {
		return (data[item[keyName]] = item[valueName]);
	});
	return data;
}

export function formatPrice(number) {
	let suffix = '';
	let reduction = 1;
	if (number >= 1000000000) {
		suffix = 'B';
		reduction = 1000000000;
	} else if (number >= 1000000) {
		suffix = 'M';
		reduction = 1000000;
	} else if (number >= 1000) {
		suffix = 'K';
		reduction = 1000;
	}

	return `$${Math.round((number / reduction + Number.EPSILON) * 100) / 100}${suffix}`;
}

// Compare rendered text lengths
export const compareRenderedTextLengths = (textContainer, length) => {
	// calculate the length of the html as rendered text
	var div = document.createElement('div');
	div.innerHTML = textContainer.innerHTML;
	return div.textContent.length === length;
};
